<template>
  <div>
    <CustomSwitch
      :value="fractionalDeposits.enabled"
      helperText="Allows your users
        to customize their deposit distribution in Transact."
      label="Fractional Deposits"
      @input="toggleFractionalDepositFeature"
    />
    <div class="card" v-if="fractionalDeposits.enabled">
      <div class="card-body">
        <div class="form-group">
          <label>Select a primary distribution type</label>
          <v-select
            v-model="fractionalDeposits.distribution.default"
            @input="updateType"
            :options="distributionTypeOptions"
            :reduce="(option) => option.value"
            :clearable="false"
          />
          <small class="form-text text-muted">
            <span v-if="fractionalDeposits.distribution.default === 'total'">
              A total distribution will suggest the user switches 100% of their paycheck
            </span>
            <span v-if="fractionalDeposits.distribution.default === 'fixed'">
              A fixed distribution will suggest the user switches a fixed amount of their paycheck
            </span>
            <span v-if="fractionalDeposits.distribution.default === 'percent'">
              A percent distribution will suggest the user switches a percentage of their paycheck
            </span>
          </small>
        </div>
        <div class="form-group" v-if="fractionalDeposits.distribution.default === 'percent'">
          <label>Suggested Percent (whole percentages)</label>
          <div class="input-group input-group-merge mb-2">
            <input
              v-model="fractionalDeposits.distribution.percentAmount"
              @input="updateAmount($event, 'percentAmount')"
              type="number"
              class="form-control"
              placeholder="25"
              min="0"
              max="100"
              step="1"
              oninput="validity.valid||(value='');"
            />
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="form-group" v-if="fractionalDeposits.distribution.default !== 'total'">
          <p v-if="fractionalDeposits.distribution.default === 'percent'">
            Some payroll systems only support fixed amounts. For these payroll systems, we will
            suggest the amount you specify here.
          </p>
          <label>Suggested Amount (whole dollars)</label>
          <div class="input-group input-group-merge input-group-reverse mb-2">
            <input
              v-model="fractionalDeposits.distribution.fixedAmount"
              @input="updateAmount($event, 'fixedAmount')"
              type="number"
              class="form-control"
              placeholder="100"
              min="0"
              step="1"
              oninput="validity.valid||(value='');"
            />
            <div class="input-group-text px-2">$</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSwitch from '@/components/MainContent/CustomSwitch'
import { capitalize, debounce } from 'lodash-es'
import { DISTRIBUTION_TYPES } from '@/utils/constants'

export default {
  name: 'FractionalDepositFeatureSettings',
  components: {
    CustomSwitch,
  },
  props: {
    data: Object,
    fixedFractionalDepositLimitSettings: Object,
    percentFractionalDepositLimitSettings: Object,
  },
  data: () => ({
    distributionTypeOptions: [
      {
        value: DISTRIBUTION_TYPES.TOTAL,
        label: capitalize(DISTRIBUTION_TYPES.TOTAL),
      },
      {
        value: DISTRIBUTION_TYPES.FIXED,
        label: capitalize(DISTRIBUTION_TYPES.FIXED),
      },
      {
        value: DISTRIBUTION_TYPES.PERCENT,
        label: capitalize(DISTRIBUTION_TYPES.PERCENT),
      },
    ],
    fractionalDeposits: {
      enabled: undefined,
      distribution: {
        fixedAmount: undefined,
        percentAmount: undefined,
        default: DISTRIBUTION_TYPES.TOTAL,
      },
    },
  }),
  methods: {
    toggleFractionalDepositFeature() {
      this.fractionalDeposits.enabled = !this.fractionalDeposits.enabled
      this.updateFractionalDepositSettings()
    },
    updateAmount: debounce(function ($event, type) {
      this.fractionalDeposits.distribution[type] = $event.target.value
      this.updateFractionalDepositSettings()
    }, 1000),
    updateType(value) {
      this.fractionalDeposits.distribution.default = value
      this.updateFractionalDepositSettings()
    },
    async updateFractionalDepositSettings() {
      await this.cleanFractionalDepositConfigs()
      if (!this.fractionalDeposits.enabled) {
        this.clearFractionalDepositSettings()
      }
      this.$emit('input', this.fractionalDeposits)
    },
    cleanFractionalDepositConfigs() {
      const distributionType = this.fractionalDeposits.distribution.default

      if (distributionType === DISTRIBUTION_TYPES.TOTAL) {
        this.fractionalDeposits.distribution.fixedAmount = undefined
        this.fractionalDeposits.distribution.percentAmount = undefined
      } else if (distributionType === DISTRIBUTION_TYPES.FIXED) {
        this.fractionalDeposits.distribution.percentAmount = undefined
      }
    },
    clearFractionalDepositSettings() {
      // retain limits if the user reenables fractional deposits later,
      // or enables it through a different mechanism.
      this.fractionalDeposits = {
        enabled: false,
        distribution: {
          fixedLimits: this.fixedFractionalDepositLimitSettings,
          percentLimits: this.percentFractionalDepositLimitSettings,
          fixedAmount: undefined,
          percentAmount: undefined,
          default: DISTRIBUTION_TYPES.TOTAL,
        },
      }
    },
  },
  mounted() {
    this.fractionalDeposits = this.data || {
      enabled: false,
      distribution: { default: DISTRIBUTION_TYPES.TOTAL },
    }
  },
}
</script>
