<template>
  <div>
    <EmulatorSectionHeader title="Fonts" />
    <EmulatorCustomizationDropdown
      v-for="font in fontsToCustomize"
      :key="font.path"
      :label="font.label"
      :namespace="font.namespace"
      :path="font.path"
      :category="font.label + ' Font'"
      :options="fontNameList"
      :selectionChangedHandler="onFontCustomization"
      dataType="string"
    />
  </div>
</template>

<script>
import EmulatorSectionHeader from '@/components/Modules/Emulator/EmulatorSectionHeader'
import EmulatorCustomizationDropdown from '@/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationDropdown'
import { mapState } from 'vuex'
import { requestDataFromTransact } from '@/utils/emulator'
import { EMULATOR_EVENT_TYPES } from '@/utils/constants'

export default {
  name: 'EmulatorFonts',
  components: {
    EmulatorSectionHeader,
    EmulatorCustomizationDropdown,
  },
  computed: {
    ...mapState('emulator', ['supportedFonts', 'fontsToCustomize']),
    fontNameList() {
      return this.supportedFonts.map((font) => {
        return font.label
      })
    },
  },
  methods: {
    onFontCustomization(args) {
      this.$analytics.track({
        event: 'Font Customization',
        payload: {
          fontName: args.value,
          fontArea: args.label,
        },
      })
    },
  },
  mounted() {
    requestDataFromTransact({
      eventType: EMULATOR_EVENT_TYPES.GET_SUPPORTED_FONTS,
      payload: {
        pathToVuexData: 'defaults.fonts.supportedFonts',
        namespace: 'theme',
        storeActionPath: 'emulator/updateSupportedFonts',
      },
    })
  },
}
</script>
