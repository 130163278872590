var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _vm.shouldShowIncome
        ? _c(
            "div",
            { staticClass: "col min-col-3" },
            [
              _c("TaskIncomeChartCard", {
                attrs: {
                  header: "Income",
                  text: _vm._f("currency")(_vm.income),
                  textQualifier: _vm.incomeType,
                  isDerived:
                    _vm.derivedOutputMetadata.income ||
                    _vm.derivedOutputMetadata.incomeType,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.annualIncome
        ? _c(
            "div",
            { staticClass: "col min-col-3" },
            [
              _c("TaskIncomeChartCard", {
                attrs: {
                  header: "Annual Income",
                  text: _vm._f("currency")(_vm.annualIncome),
                  isDerived: _vm.derivedOutputMetadata.annualIncome,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hourlyIncome
        ? _c(
            "div",
            { staticClass: "col min-col-3" },
            [
              _c("TaskIncomeChartCard", {
                attrs: {
                  header: "Hourly Income",
                  text: _vm._f("currency")(_vm.hourlyIncome),
                  isDerived: _vm.derivedOutputMetadata.hourlyIncome,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.payCycle
        ? _c(
            "div",
            { staticClass: "col min-col-3" },
            [
              _c("TaskIncomeChartCard", {
                attrs: {
                  header: "Pay Cycle",
                  text: _vm._f("startCase")(_vm.payCycle),
                  isDerived: _vm.derivedOutputMetadata.payCycle,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.nextExpectedPayDate
        ? _c(
            "div",
            { staticClass: "col min-col-3" },
            [
              _c("TaskIncomeChartCard", {
                attrs: {
                  header: "Next Expected Pay Date",
                  text: _vm._f("moment")(_vm.nextExpectedPayDate, "MM/DD/YYYY"),
                  isDerived: _vm.derivedOutputMetadata.nextExpectedPayDate,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm.hasStatements
      ? _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h4", { staticClass: "card-header-title" }, [
              _vm._v("Monthly Comparison"),
            ]),
            _c(
              "ul",
              { staticClass: "nav nav-tabs nav-tabs-sm card-header-tabs" },
              _vm._l(_vm.yearsOfPaymentHistory, function (year) {
                return _c("li", { key: year, staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: year === _vm.selectedYear },
                      attrs: { href: "javascript:;", "data-bs-toggle": "tab" },
                      on: {
                        click: function ($event) {
                          return _vm.onYearSelect(year)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(year) + " ")]
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "card-body" },
            [_c("BasicBarChart", { attrs: { chartData: _vm.chartData } })],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }