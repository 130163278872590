var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EmulatorSectionHeader", { attrs: { title: "Demo" } }),
      _c("div", { staticClass: "section" }, [
        _c("h6", { staticClass: "header-pretitle mb-3" }, [
          _vm._v("Customer name"),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.settings.demo.customer.name,
                    expression: "settings.demo.customer.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "Molecular Bank", type: "text" },
                domProps: { value: _vm.settings.demo.customer.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.settings.demo.customer,
                      "name",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "section" }, [
        _c("h6", { staticClass: "header-pretitle mb-3" }, [_vm._v("Logo")]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-auto" }, [
            _c(
              "div",
              { staticClass: "logo-wrap" },
              [
                _c("SecureImage", {
                  staticClass: "avatar-img",
                  style: "background-color: '#FAFAFA';",
                  attrs: { source: _vm.customerLogoThumbnail },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                staticClass: "form-control",
                class: { "is-invalid": _vm.imageUploadError },
                attrs: { type: "file" },
                on: {
                  change: function ($event) {
                    return _vm.processFile($event)
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }