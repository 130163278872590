<template>
  <Wrapper
    v-if="task._id"
    :title="productTitle[0].toUpperCase() + productTitle.substring(1)"
    :pretitle="task.user.identifier"
    :image="`/images/icon-${task.product}.svg`"
    :imageTitle="task.product"
  >
    <TaskOverview />
  </Wrapper>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('task')
import TaskOverview from '@/components/Modules/Task/TaskOverview'
import { getProductTitle } from '@/utils/task'

export default {
  name: 'TaskDetail',
  components: { TaskOverview },
  computed: {
    ...mapState(['task']),
    productTitle() {
      return getProductTitle(this.task)
    },
  },
  methods: {
    ...mapMutations(['resetTask']),
    ...mapActions(['getTask', 'updateStatus']),
  },
  async mounted() {
    this.resetTask()
    try {
      const { id } = this.$route.params
      await this.getTask(id)
    } catch (error) {
      this.$toasted.show('Error loading task.')
      console.log(error)
    }
  },
}
</script>
