var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("TopNav", {
        class: _vm.fullscreen ? "" : "mb-5",
        attrs: {
          title: _vm.title,
          imageFileId: _vm.imageFileId,
          pretitle: _vm.pretitle,
          badge: _vm.badge,
          image: _vm.image,
          imageTitle: _vm.imageTitle,
        },
      }),
      _c("FadeTransition", [
        _vm.fullscreen
          ? _c("div", [_vm._t("default")], 2)
          : _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row justify-between" }, [
                _vm.hasNavigationSlot
                  ? _c(
                      "div",
                      { staticClass: "col-8 col-md-3 col-sm-12 col-xs-12" },
                      [_vm._t("navigation")],
                      2
                    )
                  : _vm._e(),
                _vm.hasNavigationSlot
                  ? _c(
                      "div",
                      { staticClass: "col-12 col-md-9 col-sm-12" },
                      [_vm._t("default")],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "col-12" },
                      [_vm._t("default")],
                      2
                    ),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }