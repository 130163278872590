var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
    _c("div", {
      staticClass: "color-swatch",
      style: "background-color: " + _vm.color,
    }),
    _vm.showLabel
      ? _c("span", { staticClass: "small text-secondary" }, [
          _vm._v(_vm._s(_vm.color)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }