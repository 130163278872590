var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown show d-inline" }, [
    _c(
      "a",
      {
        staticClass: "dropdown-toggle small text-muted text-capitalize",
        attrs: {
          href: "#",
          "aria-expanded": "false",
          role: "button",
          id: "environmentDropdown",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
        },
      },
      [
        _vm.changingEnvironment
          ? _c("span", {
              staticClass: "spinner-border spinner-border-sm",
              attrs: { role: "status" },
            })
          : _c("span", [_vm._v(" " + _vm._s(_vm.activeEnvironment) + " ")]),
        _c("ChevronDownIcon", { attrs: { size: "14" } }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dropdown-menu",
        attrs: { "aria-labelledby": "environmentDropdown" },
      },
      _vm._l(_vm.availableEnvironments, function (environment) {
        return _c(
          "a",
          {
            key: environment,
            staticClass: "dropdown-item",
            attrs: { href: "javascript:;" },
          },
          [
            _c(
              "span",
              {
                staticClass: "text-capitalize",
                on: {
                  click: function ($event) {
                    return _vm.toggleEnvironment(environment)
                  },
                },
              },
              [_vm._v(_vm._s(environment))]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }