var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-10" },
    [
      _c(
        "Card",
        {
          staticClass: "border card-inactive",
          scopedSlots: _vm._u(
            [
              _vm.node.addendum
                ? {
                    key: "footer",
                    fn: function () {
                      return [_c("span", [_vm._v(_vm._s(_vm.node.addendum))])]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("div", [
            _c("h2", { staticClass: "scenario-sim-header" }, [
              _vm._v("Current Accounts"),
            ]),
            _c("span", { staticClass: "text-secondary" }, [
              _vm._v(_vm._s(_vm.node.prompt)),
            ]),
          ]),
          _vm.hasOptions
            ? _c(
                "div",
                { staticClass: "btn-group-toggle row justify-between mt-5" },
                _vm._l(_vm.node.options, function (option, index) {
                  return _c("div", { key: index, staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn my-1 w-100",
                        class: {
                          "btn-secondary": _vm.selectedOption === option.value,
                          "btn-white": _vm.selectedOption !== option.value,
                        },
                        attrs: { value: option.value },
                        on: {
                          click: function () {
                            return _vm.toggleShowChildren(option.value)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(option.label) + " ")]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm.results
        ? _c(
            "Card",
            {
              staticClass: "border",
              scopedSlots: _vm._u(
                [
                  _vm.results.exceptions.length > 0
                    ? {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "h3",
                              {
                                staticClass: "text-center scenario-sim-header",
                              },
                              [_vm._v("Exceptions")]
                            ),
                            _vm._l(
                              _vm.results.exceptions,
                              function (exception, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "Alert",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { type: "warning" },
                                      },
                                      [_vm._v(_vm._s(exception.explanation))]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c("div", [
                _c("h2", { staticClass: "scenario-sim-header" }, [
                  _vm._v("Outcome"),
                ]),
                _c("p", { staticClass: "text-secondary" }, [
                  _vm._v(
                    " Given the options you selected, these are the changes you could expect to see: "
                  ),
                ]),
              ]),
              _c("Card", { staticClass: "card-inactive" }, [
                _c("div", [
                  _c("span", [
                    _vm._v("New Account: " + _vm._s(_vm.newAccountInfo)),
                  ]),
                ]),
                _vm.results.oldAccounts
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.results.oldAccounts,
                        function (account, index) {
                          return _c("div", { key: index }, [
                            _c("span", [
                              _vm._v(
                                "Old Account " +
                                  _vm._s(index) +
                                  ": " +
                                  _vm._s(account)
                              ),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.results.oldAccount
                  ? _c("div", [
                      _c("span", [
                        _vm._v(
                          "Old Account: " + _vm._s(_vm.results.oldAccount)
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "text-left col" },
                [
                  _c("p", { staticClass: "text-secondary" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.results.explainer) +
                        " This user can expect to see the change reflected in their payroll within 1-2 pay cycles. "
                    ),
                  ]),
                  _vm.results.addendum
                    ? _c(
                        "Alert",
                        {
                          staticClass: "mt-2 mb-0",
                          attrs: { type: "secondary" },
                        },
                        [_vm._v(_vm._s(_vm.results.addendum))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._l(_vm.filteredOptions, function (option) {
            return _c("RecursiveScenarios", {
              key: option.value,
              attrs: { node: option },
            })
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }