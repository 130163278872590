<template>
  <div class="row">
    <div class="col-12">
      <div class="align-items-center mb-4">
        <h1>Deposit Logic Simulator</h1>
        <small class="text-secondary">
          This is a generalized version of our direct deposit switch logic; what occurs in our
          system may vary slightly from this UI due to limitations imposed by payroll providers.
        </small>
      </div>
      <Card class="border card-inactive">
        <div>
          <h2 class="scenario-sim-header">Distribution</h2>
          <p class="text-secondary">What type of distribution would you like to add?</p>
        </div>
        <div class="btn-group-toggle row justify-between mt-5">
          <div v-for="(scenario, index) in scenarios" :key="index" class="col">
            <button
              class="btn w-100"
              :class="{
                'btn-secondary': distributionType === scenario.value,
                'btn-white': distributionType !== scenario.value,
              }"
              :value="scenario.value"
              @click="clearAmountAndSetDistributionType"
            >
              {{ scenario.label }}
            </button>
          </div>
        </div>
        <div
          v-if="distributionTypeIsFractional"
          class="mt-4 input-group input-group-merge col"
          :class="{ 'input-group-reverse': distributionTypeIsFixed }"
        >
          <input
            class="form-control"
            type="number"
            min="0"
            step="1"
            :max="distributionTypeIsFixed ? null : 100"
            placeholder="Enter a distribution amount (optional)"
            @input="(e) => updateDistributionAmount(e.target.value)"
            @keypress="validateInteger"
            :value="distributionAmount"
          />
          <div class="input-group-text">
            {{ distributionTypeIsFixed ? '$' : '%' }}
          </div>
        </div>
      </Card>
    </div>
    <div v-if="distributionType" class="col-12">
      <RecursiveScenarios
        v-for="scenario in scenarios.filter((scenario) => scenario.value === distributionType)"
        :key="scenario.value"
        :node="scenario"
      />
      <div class="row align-items-center justify-content-center mb-4">
        <button class="btn btn-sm btn-outline-secondary w-25" @click="reset">Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/MainContent/Card'
import RecursiveScenarios from '@/components/Modules/Resources/ScenarioSimulator/RecursiveScenarios'
import { mapActions, mapState } from 'vuex'
import { SIMULATION_SCENARIOS } from '@/lib/simulation-scenarios'
import { DISTRIBUTION_TYPES } from '@/utils/constants'

export default {
  name: 'DepositScenarioSimulator',
  components: { Card, RecursiveScenarios },
  data() {
    return {
      scenarios: Object.values(SIMULATION_SCENARIOS.options),
      distributionTypes: DISTRIBUTION_TYPES,
    }
  },
  computed: {
    ...mapState('scenarioSimulator', ['distributionAmount', 'distributionType']),
    distributionTypeIsFixed() {
      return this.distributionType === DISTRIBUTION_TYPES.FIXED
    },
    distributionTypeIsFractional() {
      return this.distributionType === DISTRIBUTION_TYPES.PERCENT || this.distributionTypeIsFixed
    },
  },
  methods: {
    ...mapActions('scenarioSimulator', [
      'updateDistributionType',
      'updateDistributionAmount',
      'resetScenario',
    ]),
    validateInteger(e) {
      const digitPressed = e.charCode >= 48 && e.charCode <= 57
      const nonPrintableNonBackspaceCharacterPressed = e.charCode !== 8 && e.charCode === 0
      const isValid = nonPrintableNonBackspaceCharacterPressed || digitPressed

      if (!isValid) {
        e.preventDefault()
      }
    },
    clearAmountAndSetDistributionType(e) {
      this.updateDistributionAmount(undefined)

      this.$analytics.track({
        event: SIMULATION_SCENARIOS.analyticsEvents.selectDistributionToAdd,
        payload: {
          distributionType: e.target.value,
        },
      })

      this.updateDistributionType(e.target.value)
    },
    reset() {
      this.$analytics.track({
        event: SIMULATION_SCENARIOS.analyticsEvents.scenarioReset,
        payload: {
          distributionType: this.distributionType,
          distributionAmount: this.distributionAmount ?? '',
        },
      })

      this.resetScenario()
    },
  },
  mounted() {
    this.resetScenario()
  },
}
</script>

<style lang="scss">
.btn-selected {
  outline: 1px solid var(--bs-gray-900);
  outline-offset: 2px;
}
.scenario-sim-header {
  color: var(--bs-dark);
}
</style>
