var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._m(0),
      _vm.events.length
        ? _c("div", { staticClass: "col-auto me-0" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-text btn-sm me-0",
                on: {
                  click: function ($event) {
                    return _vm.$emit("clear")
                  },
                },
              },
              [_vm._v("Reset")]
            ),
          ])
        : _vm._e(),
    ]),
    _vm.events.length
      ? _c("div", [
          _c(
            "ul",
            { staticClass: "list-group list-group-flush" },
            _vm._l(_vm.reversedEvents, function (event) {
              return _c(
                "li",
                { key: event.guid, staticClass: "list-group-item p-3" },
                [_c("EmulatorEventEntry", { attrs: { event: event } })],
                1
              )
            }),
            0
          ),
        ])
      : _c("div", { staticClass: "card-body text-muted" }, [
          _vm._v("No events yet."),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h4", { staticClass: "card-header-title" }, [_vm._v("Events")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }