<template>
  <button v-if="fileId" class="btn btn-sm btn-white" @click="openTaskOutputFile">
    <slot></slot>
  </button>
</template>

<script>
import TaskService from '@/services/TaskService'

export default {
  name: 'ViewTaskOutputFileButton',
  props: ['taskId', 'fileId'],
  methods: {
    async openTaskOutputFile() {
      let toast
      try {
        toast = this.$toasted.show('Retrieving file', { duration: null })

        const response = await TaskService.generateTaskOutputFileUrl({
          taskId: this.taskId,
          fileId: this.fileId,
        })

        toast.goAway()
        window.open(response.body.url, '_blank')
      } catch (error) {
        console.log(error)
        toast.goAway()
        this.$toasted.error('Error in retrieving file')
      }
    },
  },
}
</script>
