import CustomerService from '@/services/CustomerService'
import { BANNO_STATUSES, ENVIRONMENTS } from '@/utils/constants'
import { merge, omit } from 'lodash-es'
import { getCustomerPermissions } from '@/lib/permissions'
import {
  filterWebhookEventsByScopes,
  sortWebhookEventTypesCanonically,
  webhookEvents,
} from '@/lib/webhook-events'

export default {
  namespaced: true,
  state: {
    activeSettingsTab: undefined,
    activeCustomer: undefined,
    customer: undefined,
    childCustomers: [],
    childCustomerSearchOptions: {},
    childCustomerSearchResults: [],
    defaultChildCustomerSearchResults: [],
  },
  mutations: {
    setActiveSettingsTab(state, tab) {
      state.activeSettingsTab = tab
    },
    setCustomer(state, customer) {
      state.customer = customer
    },
    setActiveCustomer(state, customer) {
      state.activeCustomer = customer
    },
    setActiveCustomerCredentials(state, credentials) {
      state.activeCustomer.credentials = credentials
    },
    setChildCustomers(state, customers) {
      state.childCustomers = customers
    },
    setChildCustomerSearchOptions(state, childCustomerSearchOptions) {
      state.childCustomerSearchOptions = childCustomerSearchOptions
    },
    setChildCustomerSearchResults(state, customers) {
      state.childCustomerSearchResults = customers
    },
    setDefaultChildCustomerSearchOptions(state, defaultChildCustomerSearchOptions) {
      state.defaultChildCustomerSearchOptions = defaultChildCustomerSearchOptions
    },
  },
  getters: {
    availablePermissions(state) {
      console.log('AVAILABLE:')
      return Object.values(getCustomerPermissions(state.activeCustomer))
    },
    bannoStatus(state) {
      const bannoIsEnabled = state.activeCustomer?.features?.banno?.enabled
      const bannoDetailsSaved =
        state.activeCustomer?.features?.banno?.clientId ||
        state.activeCustomer?.features?.banno?.clientSecret ||
        state.activeCustomer?.features?.banno?.environment

      if (bannoIsEnabled) {
        return BANNO_STATUSES.ACTIVE
      } else if (!bannoIsEnabled && bannoDetailsSaved) {
        return BANNO_STATUSES.INACTIVE
      }

      return BANNO_STATUSES.DISABLED
    },
    hasApiKey(state) {
      return !!state.activeCustomer?.credentials?.key
    },
    hasApiSecrets(state) {
      return state.activeCustomer?.credentials?.secrets?.length > 0
    },
    hasApiSecretsTokens(state) {
      const apiSecrets = state.activeCustomer?.credentials?.secrets ?? []
      return apiSecrets.some((secret) => !!secret.token)
    },
    webhookEndpoints(state) {
      return state.activeCustomer?.webhook?.endpoints ?? []
    },
    hasWebhooks(state, getters) {
      return getters.webhookEndpoints.length > 0
    },
    availableWebhookEventTypes(state) {
      const webhookEventsFilteredByScopes = filterWebhookEventsByScopes(
        webhookEvents,
        state.activeCustomer?.scopes
      )
      return Object.keys(webhookEventsFilteredByScopes)
    },
    testableWebhookEventTypes(state, getters) {
      const eventTypes = getters.webhookEndpoints.flatMap((endpoint) => endpoint.eventTypes)
      const uniqueEventTypes = Array.from(new Set(eventTypes))
      const eventTypesSortedCanonically = sortWebhookEventTypesCanonically(uniqueEventTypes)
      return eventTypesSortedCanonically
    },
    products(state) {
      return state.activeCustomer?.products ?? []
    },
    scopes(state) {
      return state.activeCustomer?.scopes ?? []
    },
    testCredentialsEnabled(state) {
      return state.activeCustomer.features?.testCredentials?.enabled
    },
    usesSso(state) {
      return state.activeCustomer?.features?.consoleSso?.enabled
    },
    managesRolesInIdp(state) {
      return state.activeCustomer?.features?.consoleSso?.manageRolesInIdp
    },
    credentials(state) {
      return state.activeCustomer?.credentials
    },
    hasUserLinkContinuousAccessEnabled(state) {
      return state.activeCustomer?.features?.userLinkContinuousAccess?.enabled
    },
  },
  actions: {
    async getCustomer({ commit, dispatch }, specificEnvironment) {
      const response = await CustomerService.get(specificEnvironment)
      commit('setCustomer', response.body.data)
      await dispatch('initActiveCustomer', specificEnvironment)
    },
    async getChildCustomers({ commit }, { specificEnvironment }) {
      try {
        const response = await CustomerService.children({ specificEnvironment })
        commit('setChildCustomers', response.body.data.docs)
        commit('setChildCustomerSearchResults', response.body.data.docs)
        commit('setChildCustomerSearchOptions', omit(response.body.data, ['docs']))
        commit('setDefaultChildCustomerSearchOptions', omit(response.body.data, ['docs']))
      } catch (error) {
        console.log(error)
      }
    },
    async searchChildCustomers({ commit }, { queryStringParams, specificEnvironment }) {
      try {
        const response = await CustomerService.children({ queryStringParams, specificEnvironment })
        commit('setChildCustomerSearchResults', response.body.data.docs)
        commit('setChildCustomerSearchOptions', omit(response.body.data, ['docs']))
      } catch (error) {
        console.log(error)
      }
    },
    async getChildCustomer({ state }, specificEnvironment) {
      const savedActiveCustomerId = localStorage.getItem('activeCustomerId')
      const activeCustomerIsParent = savedActiveCustomerId === state.customer._id

      if (activeCustomerIsParent) {
        return
      }

      const cachedChildCustomer = state.childCustomers.find(
        (customer) => customer._id === savedActiveCustomerId
      )

      if (cachedChildCustomer) {
        return cachedChildCustomer
      }

      try {
        const response = await CustomerService.getChildCustomer(specificEnvironment)
        return response?.body?.data
      } catch (error) {
        console.log(error)
      }
    },
    async switchToCustomer({ commit, state }, selectedCustomer) {
      localStorage.setItem('activeCustomerId', selectedCustomer._id)
      commit('setActiveCustomer', selectedCustomer)
      commit('setChildCustomerSearchResults', state.childCustomers)
      commit('setChildCustomerSearchOptions', state.defaultChildCustomerSearchOptions)
    },
    async initActiveCustomer({ state, dispatch, commit }, specificEnvironment) {
      const savedActiveCustomerId = localStorage.getItem('activeCustomerId')
      await dispatch('getChildCustomers', { specificEnvironment })
      const foundChildCustomer = await dispatch('getChildCustomer', specificEnvironment)
      // If there is a activeCustomerId saved, and it is not the parentCompany id, then find customer we should use
      if (
        savedActiveCustomerId &&
        savedActiveCustomerId !== state.customer._id &&
        foundChildCustomer
      ) {
        await dispatch('getChildCustomers', { specificEnvironment })

        commit('setActiveCustomer', foundChildCustomer)
      } else {
        localStorage.setItem('activeCustomerId', state.customer._id)
        commit('setActiveCustomer', state.customer)
      }
    },
    async updateCustomer(
      { commit, getters },
      { payload, serviceFn = 'update', syncToSandbox = false }
    ) {
      try {
        const customer = await CustomerService[serviceFn](payload, syncToSandbox)
        let customerCredentials = {}

        if (getters.hasApiSecrets) {
          customerCredentials = await CustomerService.getCredentials({
            queryStringParams: {
              // do not include query string params unless they're true
              includeKey: getters.hasApiKey || undefined,
              includeToken: getters.hasApiSecretsTokens || undefined,
            },
          })
        }

        const newActiveCustomer = merge(customer, customerCredentials)

        commit('setActiveCustomer', newActiveCustomer.body.data)
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    async updateCustomerTransactConfiguration({ dispatch }, { payload, environmentsToUpdate }) {
      await Promise.all(
        environmentsToUpdate.map((specificEnvironment) =>
          CustomerService.updateTransactConfiguration({ payload, specificEnvironment })
        )
      )

      await dispatch('getCustomer')
    },
    async testCustomerWebhooks(_, { testEvent }) {
      await CustomerService.testWebhooks({ testEvent })
    },
    async addSecret({ dispatch }, secretName) {
      await CustomerService.addSecret({ secretName })
      await dispatch('getCustomerCredentials', { includeKey: true, includeToken: true })
    },
    async removeSecret({ dispatch }, secretId) {
      await CustomerService.removeSecret({ secretId })
      await dispatch('getCustomerCredentials', { includeKey: true, includeToken: true })
    },
    async getAccessToken({}, { queryStringParams = {} }) {
      return CustomerService.getAccessToken({ queryStringParams })
    },
    async getCustomerCredentials({ commit }, queryStringParams) {
      const response = await CustomerService.getCredentials({ queryStringParams })

      commit('setActiveCustomerCredentials', response.body.data.credentials)
    },
  },
}
