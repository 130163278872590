var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EmulatorSectionHeader", { attrs: { title: "Login Screen" } }),
      _vm.testCredentialsEnabled
        ? _c(
            "div",
            [
              _c("p", { staticClass: "text-secondary" }, [
                _vm._v(
                  " Use the following test usernames to invoke an outcome. Any password is acceptable. "
                ),
              ]),
              _vm._m(0),
              _vm._l(_vm.categorizedTestCredentials, function (category, key) {
                return _c("div", { key: key, staticClass: "mt-4" }, [
                  _c("h4", [_vm._v(_vm._s(category.title))]),
                  _c(
                    "ul",
                    { staticClass: "list-group username-list" },
                    _vm._l(category.credentials, function (credentialSet) {
                      return _c(
                        "li",
                        {
                          key: credentialSet.username,
                          staticClass: "list-group-item",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "" + credentialSet.description,
                                      expression:
                                        "`${credentialSet.description}`",
                                    },
                                  ],
                                  staticClass: "fw-bold title",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(credentialSet.title) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "copy-credential" }, [
                            _c(
                              "small",
                              { staticClass: "text-secondary credential" },
                              [
                                _vm._v(
                                  _vm._s(_vm.loginTestCredential(credentialSet))
                                ),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:copy",
                                    value:
                                      _vm.loginTestCredential(credentialSet),
                                    expression:
                                      "loginTestCredential(credentialSet)",
                                    arg: "copy",
                                  },
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:success",
                                    value: _vm.onCopy,
                                    expression: "onCopy",
                                    arg: "success",
                                  },
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:error",
                                    value: _vm.onCopyError,
                                    expression: "onCopyError",
                                    arg: "error",
                                  },
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      "Copy " +
                                      _vm.loginTestCredential(credentialSet),
                                    expression:
                                      "`Copy ${loginTestCredential(credentialSet)}`",
                                  },
                                ],
                                staticClass: "btn p-0",
                              },
                              [
                                _c("CopyIcon", {
                                  staticClass: "me-1 p-0 m-0",
                                  attrs: { size: "14" },
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Copy"),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              }),
            ],
            2
          )
        : _c(
            "div",
            [
              _c("Alert", { staticClass: "mb-4", attrs: { type: "warning" } }, [
                _vm._v(
                  " Test credentials are disabled. You may only use real credentials for testing. "
                ),
              ]),
              _vm.showLinkToEnableTestCredentials
                ? _c(
                    "p",
                    { staticClass: "text-secondary" },
                    [
                      _vm._v(
                        " If you would like to use test credentials, they can be enabled on "
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "settings-general" } } },
                        [_vm._v("the Features page")]
                      ),
                      _vm._v(". "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._m(1),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://docs.atomicfi.com/reference/transact-sdk#testing",
            target: "_blank",
          },
        },
        [_vm._v(" Read more about testing in our Docs")]
      ),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://docs.atomicfi.com/reference/transact-sdk#testing",
            target: "_blank",
          },
        },
        [_vm._v(" Read more about testing in our Docs")]
      ),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }