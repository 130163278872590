<template>
  <div class="d-flex align-items-center">
    <div class="pe-3">
      <!-- Avatar -->
      <div class="avatar" :class="avatarClass">
        <img :src="customerLogo" alt="..." class="avatar-img rounded-circle" />
      </div>
    </div>
    <div :class="{ 'pe-0': condensed }">
      <!-- Title -->
      <h3 class="text-focus mb-1 name">
        <span class="d-sm-inline" :class="{ 'd-none': condensed }" :title="customerTitle">{{
          customer.name | truncate(truncateWidth)
        }}</span>
        <ShuffleIcon size="15" class="ms-sm-2 text-muted switcher-icon" v-if="showSwitcher" />
      </h3>

      <!-- Time -->
      <p class="small text-muted mb-0" v-if="showTimestamp">
        <time datetime="2018-05-24"> Created {{ customer.createdAt | moment('from', 'now') }}</time>
      </p>

      <!-- Approval Alert -->
      <p v-if="!customer.approved" class="small text-dark mb-0 d-flex align-items-center">
        {{ customer.stage | startCase }}
        <AlertTriangleIcon size="16" class="ms-2 text-danger" />
      </p>
    </div>
  </div>
</template>

<script>
import { AlertTriangleIcon, ShuffleIcon } from 'vue-feather-icons'

export default {
  props: ['customer', 'condensed', 'truncateWidth', 'avatarClass', 'showSwitcher', 'showTimestamp'],
  name: 'CustomerListItem',
  components: { ShuffleIcon, AlertTriangleIcon },
  computed: {
    customerLogo() {
      return this.customer.branding?.logo?.url ?? '/images/workspace-placeholder.svg'
    },
    customerTitle() {
      const customerNameIsTruncated = this.customer.name.length > this.truncateWidth

      return customerNameIsTruncated ? this.customer.name : undefined
    },
  },
}
</script>

<style scoped lang="scss">
.avatar-img {
  object-fit: contain !important;
}

.avatar-xs {
  top: -3px;
  position: relative;
}

h3 {
  font-weight: 400;
  transition: all 0.3s;
  color: #000;
}

.switcher-icon {
  transition: all 0.3s;
  font-size: 14px;
  position: relative;
  bottom: 1px;

  &:hover {
    color: #000;
  }
}

.d-flex {
  &:hover {
    h3 {
      transition: all 0.3s;
      color: black !important;
    }

    .switcher-icon {
      transition: all 0.3s;
      color: black !important;
    }
  }
}
</style>
