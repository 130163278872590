<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import { defaultDoughnutOptions, defaultDoughnutDummyData } from '@/utils/chart'

export default {
  props: ['options', 'chartData', 'useDummyData'],
  extends: Doughnut,
  name: 'DoughnutChart',
  mixins: [reactiveProp],
  methods: {
    triggerRenderChart() {
      let options = this.options || defaultDoughnutOptions()
      const data =
        !this.chartData || this.useDummyData ? defaultDoughnutDummyData() : this.chartData
      this.renderChart(data, options)
    },
  },
  mounted() {
    this.triggerRenderChart()
  },
  watch: {
    'chartData.labels': function () {
      this.triggerRenderChart()
    },
  },
}
</script>
