import { stringify } from 'querystringify'
import { isNil } from 'lodash-es'

export default (url, queryStringParams = {}) => {
  return url + stringify(_cleanQueryStringParams(queryStringParams), true)
}

function _cleanQueryStringParams(queryStringParams) {
  return JSON.parse(JSON.stringify(queryStringParams, _replaceNil))
}

function _replaceNil(_key, value) {
  return isNil(value) ? undefined : value
}
