import Vue from 'vue'
import store from '@/store'
import urlGenerator from '@/lib/url-generator'

export default {
  list(queryStringParams) {
    return Vue.http.get(
      urlGenerator(
        `${store.getters['environment/atomicCustomerAPI']}/conversion-optimization`,
        queryStringParams
      )
    )
  },
  toggle(id, payload) {
    return Vue.http.patch(
      `${store.getters['environment/atomicCustomerAPI']}/conversion-optimization/${id}`,
      payload
    )
  },
}
