<template>
  <div class="card mt-4">
    <div class="card-header">
      <div class="col ms-n3">
        <h6 class="card-title text-uppercase text-muted mb-2">Gross Amount</h6>
        <h2 class="mb-0">{{ statement.grossAmount | currency }}</h2>
      </div>

      <ViewTaskOutputFileButton :taskId="taskId" :fileId="statement.paystub"
        >View PDF</ViewTaskOutputFileButton
      >
    </div>
    <div class="card-body" v-if="hasDeductionData">
      <BreakdownBar :sections="breakdownBarSections" />
      <BreakdownTable :items="breakdownTableItems" />
    </div>
    <div class="card-footer" v-if="statement.hours">
      <div class="row align-items-center">
        <div class="col">
          <small class="text-muted"> {{ statement.hours }} hours worked</small>
        </div>
        <div class="col-auto"></div>
      </div>
    </div>
  </div>
</template>
<script>
import BreakdownBar from '@/components/MainContent/BreakdownBar'
import BreakdownTable from '@/components/MainContent/BreakdownTable'
import ViewTaskOutputFileButton from '@/components/MainContent/ViewTaskOutputFileButton'
import sumOfDecimals from '@/lib/sum-of-decimals'
import { groupBy } from 'lodash-es'

const NET_AMOUNT_COLOR = '#4b39ef'
const DEDUCTION_CATEGORY_CONFIG = [
  { category: 'taxes', color: '#d2ddec' },
  { category: 'retirement', color: '#a6c5f7' },
  { category: 'benefits', color: '#5897fb' },
  { category: 'other', color: 'orange' },
]

export default {
  name: 'TaskStatementBreakdown',
  components: { BreakdownBar, BreakdownTable, ViewTaskOutputFileButton },
  props: ['taskId', 'statement'],
  computed: {
    deductionsByCategory() {
      const deductionsByCategories = groupBy(this.statement.deductions, 'category')
      const sortedDeductionsByCategories = DEDUCTION_CATEGORY_CONFIG.map(({ category }) => ({
        category,
        deductions: deductionsByCategories[category],
      })).filter(({ deductions }) => deductions && deductions.length)

      return sortedDeductionsByCategories.map(({ category, deductions }) => {
        return {
          amount: sumOfDecimals(deductions.map(({ amount }) => amount)),
          deductions,
          category,
          color: DEDUCTION_CATEGORY_CONFIG.find(
            ({ category: configCategory }) => configCategory === category
          ).color,
        }
      })
    },
    breakdownBarSections() {
      return [
        ...this.deductionsByCategory.map(({ amount, color }) => ({
          color,
          length: (amount * 100) / this.statement.grossAmount,
        })),
        {
          color: NET_AMOUNT_COLOR,
          length: (this.statement.netAmount * 100) / this.statement.grossAmount,
        },
      ]
    },
    breakdownTableItems() {
      return [
        ...this.deductionsByCategory.map(({ category, amount, deductions, color }) => ({
          amount,
          color,
          hasSubCategories: deductions.length > 0,
          label: category,
          percentage: amount / this.statement.grossAmount,
          subCategories: deductions,
        })),
        {
          amount: this.statement.netAmount,
          color: NET_AMOUNT_COLOR,
          label: 'netPay',
          percentage: this.statement.netAmount / this.statement.grossAmount,
          hasSubCategories: this.statement.netAmountAdjustments?.length > 0,
          subCategories: this.statement.netAmountAdjustments,
        },
      ]
    },
    hasDeductionData() {
      return this.statement.deductions?.length
    },
  },
}
</script>
<style scoped lang="scss"></style>
