export default {
  gray: {
    0: '#EEEEEE',
    100: '#95AAC9',
    300: '#E3EBF6',
    600: '#95AAC9',
    700: '#6E84A3',
    900: '#283E59',
  },
  primary: {
    100: '#D2DDEC',
    300: '#A6C5F7',
    700: '#2C7BE5',
  },
  black: '#12263F',
  white: '#FFFFFF',
  indigo: '#4B39EF',
  red: '#DC267F',
  yellow: '#FFB000',
  transparent: 'transparent',
}
