<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h4 class="card-header-title">Send a test event</h4>
        </div>
        <div v-if="webhookResponses.length" class="col-auto text-end">
          <button type="button" @click="clearWebhookResponses" class="btn btn-light btn-sm">
            Clear logs
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-2">Event</h4>
          <v-select
            v-model="eventType"
            :options="testableWebhookEventTypes"
            :clearable="false"
            placeholder="Select Event"
          />
        </div>

        <div v-if="showProductSelect" class="col-12 col-md-6 mt-4">
          <h4 class="mb-2">Product</h4>
          <v-select
            v-model="productType"
            :options="productTypeOptions"
            :reduce="(option) => option.value"
            :clearable="false"
          />
        </div>

        <div v-if="showDistributionSelect" class="col-12 col-md-6 mt-4">
          <h4 class="mb-2">Distribution</h4>
          <v-select
            v-model="distributionType"
            :options="distributionTypeOptions"
            :reduce="(option) => option.value"
            :clearable="false"
          />
        </div>
      </div>
    </div>
    <div v-if="sentTestEvent && !webhookResponses.length" class="card-body">
      <div class="row">
        <div class="col-auto">
          <div class="spinner-border spinner-border-sm text-secondary" />
        </div>
        <div class="col">
          <p class="text-muted mb-0">
            Atomic attempts to deliver your webhooks up to three times with a 30 second delay
            between each failed response.
          </p>
        </div>
      </div>
    </div>
    <div class="card-body" v-else-if="webhookResponses.length">
      <div
        v-for="(response, idx) in webhookResponses"
        :key="idx"
        :class="[idx !== webhookResponses.length - 1 ? 'mb-5' : '']"
      >
        <SettingsWebhooksResponse :response="response" />
      </div>
    </div>
    <div class="card-footer">
      <div
        v-tooltip="!hasWebhooks ? 'Add an endpoint to send a test webhook' : undefined"
        class="disabled-button-tooltip-container"
      >
        <button
          class="btn btn-primary mt-0"
          @click="testWebhooks"
          :disabled="!hasWebhooks || !eventType"
        >
          Send event
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SettingsWebhooksResponse from '@/components/Modules/Settings/SettingsWebhooksResponse'
import SettingsBase from '@/components/Modules/Settings/SettingsBase'
import { WEBHOOK_EVENT_TYPES } from '@atomicfi/webhook-shared'
import { createSampleEventPayload } from '@/lib/webhook-events'

export default {
  name: 'SettingsTestWebhooks',
  extends: SettingsBase,
  components: { SettingsWebhooksResponse },
  data: () => ({
    isLoadingSecrets: true,
    sentTestEvent: false,
    webhookResponses: [],
    eventType: undefined,
    productType: 'deposit',
    productTypeOptions: [
      { value: 'deposit', label: 'Deposit' },
      { value: 'verify', label: 'Verify' },
    ],
    distributionType: 'total',
    distributionTypeOptions: [
      {
        value: 'total',
        label: 'Entire Balance',
      },
      {
        value: 'fixed',
        label: 'Fixed',
      },
      {
        value: 'percent',
        label: 'Percent',
      },
    ],
  }),
  computed: {
    ...mapGetters('customer', ['hasWebhooks', 'testableWebhookEventTypes']),
    ...mapState('environment', ['activeEnvironment']),
    showDistributionSelect() {
      return (
        this.eventType === WEBHOOK_EVENT_TYPES.TASK_STATUS_UPDATED && this.productType === 'deposit'
      )
    },
    showProductSelect() {
      return [
        WEBHOOK_EVENT_TYPES.TASK_STATUS_UPDATED,
        WEBHOOK_EVENT_TYPES.TASK_AUTHENTICATION_STATUS_UPDATED,
      ].includes(this.eventType)
    },
  },
  watch: {
    activeEnvironment() {
      this.subscribeToRealtimeEvent()
    },
  },
  methods: {
    ...mapActions('customer', ['getCustomerCredentials']),
    async testWebhooks() {
      this.sentTestEvent = true

      const testEvent = createSampleEventPayload({
        eventType: this.eventType,
        productType: this.productType,
        distributionType: this.distributionType,
      })

      await this.testCustomerWebhooks({ testEvent })

      this.$analytics.track({
        event: 'Send Test Webhook',
        payload: {
          distributionType: this.distributionType,
          eventType: this.eventType,
          product: this.productType,
        },
      })

      this.$toasted.success('Test event sent')
    },
    subscribeToRealtimeEvent() {
      this.channel = this.$ably.channels.get(`${this.activeCustomer._id}:webhook`)
      this.channel.unsubscribe(this.webhookResponseListener)

      this.webhookResponseListener = ({
        data: {
          success,
          failed,
          data: { request, response = {}, endpointUrl },
        },
      }) => {
        const status = response.status || 'Unknown'

        this.webhookResponses.unshift({
          createdAt: new Date(),
          success,
          failed,
          request,
          response: {
            data: response.data ? JSON.parse(response.data) : 'Could not get response',
            status,
            headers: response.headers || {},
          },
          endpointUrl,
        })

        this.$analytics.track({
          event: 'Receive Test Webhook Response',
          payload: {
            failed,
            status,
            success,
          },
        })
      }

      this.channel.subscribe('webhook-response', this.webhookResponseListener)
    },
    clearWebhookResponses() {
      this.webhookResponses = []
      this.sentTestEvent = false
    },
  },
  async mounted() {
    await this.getCustomerCredentials()
    this.isLoadingSecrets = false
    this.secretId = this.activeCustomer.credentials.secrets[0]._id
    this.subscribeToRealtimeEvent()
  },
  beforeDestroy() {
    this.channel.unsubscribe(this.webhookResponseListener)
  },
}
</script>

<style scoped>
.disabled-button-tooltip-container {
  display: inline-block;
}
</style>
