<template>
  <ModalForm
    id="remove-secret-modal"
    title="Remove Secret"
    :onSubmit="deleteSecret"
    @success="onSuccess"
    submitButtonText="Remove Secret"
    :submitButtonHidden="secretInUse"
    @hidden="onHide"
    v-slot="{ disabled }"
  >
    <div v-if="secretInUse">
      <p>
        This secret is currently being used to sign a webhook payload. Please remove it from the
        following endpoints before deleting.
      </p>
      <div class="list-group">
        <div class="list-group-item" v-for="endpoint in endpointsThatUseSecret" :key="endpoint._id">
          {{ endpoint.url }}
        </div>
      </div>
    </div>
    <validation-provider
      v-else
      :rules="{ is: secretNameToRemove, required: true }"
      v-slot="{ classes, errors, touched }"
    >
      <div class="form-group">
        <label for="removeSecretText">
          Please type "{{ secretNameToRemove }}" to confirm you want to remove this secret
        </label>
        <input
          id="removeSecretText"
          type="text"
          :class="classes"
          class="form-control"
          v-model="removeSecretText"
          :placeholder="secretNameToRemove"
          :disabled="disabled"
        />
        <div class="invalid-feedback" v-if="touched">{{ errors[0] }}</div>
      </div>
    </validation-provider>
  </ModalForm>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import ModalForm from '@/components/MainContent/ModalForm'
import SettingsBase from '@/components/Modules/Settings/SettingsBase'

export default {
  name: 'SettingsCredentialsRemoveSecretModal',
  extends: SettingsBase,
  components: {
    ModalForm,
    ValidationProvider,
  },
  data: () => ({
    removeSecretText: undefined,
  }),
  computed: {
    endpointsThatUseSecret() {
      return (
        this.activeCustomer.webhook.endpoints.filter(
          (endpoint) => endpoint.secretId === this.secretToRemove?._id
        ) ?? []
      )
    },
    secretInUse() {
      return this.endpointsThatUseSecret?.length > 0
    },
    secretNameToRemove() {
      return this.secretToRemove ? this.secretToRemove.name || 'Default' : undefined
    },
  },
  props: {
    secretToRemove: Object,
  },
  methods: {
    ...mapActions('customer', ['removeSecret']),
    async deleteSecret() {
      await this.removeSecret(this.secretToRemove._id)

      this.$analytics.track({
        event: 'Remove API Secret',
        payload: {
          secretName: this.secretToRemove.name,
        },
      })

      this.resetState()
    },
    onSuccess() {
      this.$toasted.success('Secret Removed')
    },
    onHide() {
      this.resetState()
    },
    resetState() {
      this.removeSecretText = undefined
    },
  },
}
</script>
