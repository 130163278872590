var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-3" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col-auto" }, [
          _c("div", { staticClass: "avatar avatar-md" }, [
            _c("img", {
              staticClass: "avatar-img rounded-circle",
              attrs: { src: _vm.user.picture, alt: "..." },
            }),
          ]),
        ]),
        _c("div", { staticClass: "col ms--2" }, [
          _c("h4", { staticClass: "card-title mb-1" }, [
            _c("span", { staticClass: "text-capitalize" }, [
              _vm._v(
                _vm._s(_vm.user.givenName) + " " + _vm._s(_vm.user.familyName)
              ),
            ]),
            _vm.isAuthenticatedUser
              ? _c("span", { staticClass: "text-muted ms-1" }, [
                  _vm._v(" (You)"),
                ])
              : _vm._e(),
          ]),
          _c("p", { staticClass: "card-text small mb-1" }, [
            _vm._v(_vm._s(_vm.userRole)),
          ]),
        ]),
        _c("div", { staticClass: "col-auto text-end" }, [
          _vm.user.emailVerified
            ? _c("span", { staticClass: "badge bg-success-soft" }, [
                _vm._v("Verified"),
              ])
            : _c("span", { staticClass: "badge bg-secondary-soft" }, [
                _vm._v("Invited"),
              ]),
        ]),
        _c("div", { staticClass: "col-1" }, [
          _c("div", { staticClass: "dropdown text-center" }, [
            _c(
              "a",
              {
                staticClass: "dropdown-ellipses dropdown-toggle",
                attrs: {
                  href: "#!",
                  role: "button",
                  "data-toggle": "dropdown",
                },
              },
              [
                _c("MoreVerticalIcon", {
                  staticClass: "vertical-icon",
                  attrs: { size: "16" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  attrs: { href: "javascript:" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("viewRolePermissions", _vm.user)
                    },
                  },
                },
                [_vm._v(" View Permissions ")]
              ),
              _vm.canEditRole
                ? _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("editRole", _vm.user)
                        },
                      },
                    },
                    [_vm._v(" Edit Role ")]
                  )
                : _vm._e(),
              _vm.canDeleteUser
                ? _c(
                    "a",
                    {
                      staticClass: "dropdown-item text-danger",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete", _vm.user)
                        },
                      },
                    },
                    [_vm._v(" Delete ")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }