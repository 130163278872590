<template>
  <div>
    <Alert class="mb-3" type="info">
      This page is for viewing roles and permissions only.
      <span v-if="showEditRoleMessage">
        To edit user roles, go to the
        <router-link :to="{ name: 'settings-team' }" class="alert-link">Team page</router-link>.
      </span>
    </Alert>
    <Card>
      <UserRoleSelector :options="roles" :isCollapsible="false" :needsValidation="false" />
    </Card>
    <Card>
      <label for="permissionSelector">Permission</label>
      <v-select
        v-model="selectedPermission"
        :options="availablePermissions"
        :getOptionLabel="getPermissionLabel"
        id="permissionSelector"
      />
      <div class="mt-4">
        <PermissionRolesTable v-if="selectedPermission" :permission="selectedPermission" />
        <p v-else>Please select a permission.</p>
      </div>
    </Card>
  </div>
</template>

<script>
import { hasPermissions, roles } from '@/lib/authorization'
import Alert from '@/components/MainContent/Alert'
import Card from '@/components/MainContent/Card'
import SettingsBase from '@/components/Modules/Settings/SettingsBase'
import UserRoleSelector from '@/components/Shared/UserRoleSelector'
import PermissionRolesTable from '@/components/Modules/Settings/RolePermissions/PermissionRolesTable'
import { startCase } from 'lodash-es'
import { mapGetters } from 'vuex'
import { PERMISSIONS } from '@/lib/permissions'

export default {
  name: 'SettingsRolePermissions',
  extends: SettingsBase,
  components: {
    PermissionRolesTable,
    Alert,
    Card,
    UserRoleSelector,
  },
  data() {
    return {
      roles,
      selectedPermission: undefined,
    }
  },
  computed: {
    ...mapGetters('customer', ['availablePermissions', 'managesRolesInIdp']),
    showEditRoleMessage() {
      return hasPermissions(PERMISSIONS.EDIT_USER_ROLES) && !this.managesRolesInIdp
    },
  },
  methods: {
    getPermissionLabel(permission) {
      return startCase(permission)
    },
  },
}
</script>
