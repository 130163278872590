var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("div", { staticClass: "pe-3" }, [
      _c("div", { staticClass: "avatar", class: _vm.avatarClass }, [
        _c("img", {
          staticClass: "avatar-img rounded-circle",
          attrs: { src: _vm.customerLogo, alt: "..." },
        }),
      ]),
    ]),
    _c("div", { class: { "pe-0": _vm.condensed } }, [
      _c(
        "h3",
        { staticClass: "text-focus mb-1 name" },
        [
          _c(
            "span",
            {
              staticClass: "d-sm-inline",
              class: { "d-none": _vm.condensed },
              attrs: { title: _vm.customerTitle },
            },
            [
              _vm._v(
                _vm._s(_vm._f("truncate")(_vm.customer.name, _vm.truncateWidth))
              ),
            ]
          ),
          _vm.showSwitcher
            ? _c("ShuffleIcon", {
                staticClass: "ms-sm-2 text-muted switcher-icon",
                attrs: { size: "15" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showTimestamp
        ? _c("p", { staticClass: "small text-muted mb-0" }, [
            _c("time", { attrs: { datetime: "2018-05-24" } }, [
              _vm._v(
                " Created " +
                  _vm._s(
                    _vm._f("moment")(_vm.customer.createdAt, "from", "now")
                  )
              ),
            ]),
          ])
        : _vm._e(),
      !_vm.customer.approved
        ? _c(
            "p",
            { staticClass: "small text-dark mb-0 d-flex align-items-center" },
            [
              _vm._v(
                " " + _vm._s(_vm._f("startCase")(_vm.customer.stage)) + " "
              ),
              _c("AlertTriangleIcon", {
                staticClass: "ms-2 text-danger",
                attrs: { size: "16" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }