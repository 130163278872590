<template>
  <Wrapper title="Tasks">
    <Tasks />
  </Wrapper>
</template>

<script>
import Tasks from '@/components/Modules/Task/Tasks'

export default {
  name: 'TaskList',
  components: { Tasks },
}
</script>
