<template>
  <ModalForm
    id="select-environment-modal"
    title="Select Environment"
    :onSubmit="submit"
    submitButtonText="Publish"
    @hidden="onHide"
  >
    <validation-provider name="Select Environment" rules="required">
      <div class="form-group form-check" v-for="option in options" :key="option">
        <input
          class="form-check-input"
          type="checkbox"
          :id="`select-environment-${option}`"
          :value="option"
          v-model="environmentsToUpdate"
        />
        <label class="form-check-label text-capitalize" :for="`select-environment-${option}`">{{
          option
        }}</label>
      </div>
    </validation-provider>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/MainContent/ModalForm.vue'
import { ENVIRONMENTS } from '@/utils/constants'

export default {
  name: 'EmulatorSelectEnvironmentModal',
  data: () => ({
    environmentsToUpdate: [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.SANDBOX],
    options: [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.SANDBOX],
  }),
  components: {
    ModalForm,
  },
  methods: {
    submit() {
      this.$emit('submit', this.environmentsToUpdate)
    },
    onHide() {
      this.resetState()
    },
    resetState() {
      this.environmentsToUpdate = [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.SANDBOX]
    },
  },
}
</script>
