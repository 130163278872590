import Vue from 'vue'
import store from '@/store'

export default {
  get(id) {
    return Vue.http.get(`${store.getters['environment/atomicCustomerAPI']}/task/${id}`)
  },
  list({ filters, skip }) {
    return Vue.http.post(`${store.getters['environment/atomicCustomerAPI']}/task/list`, {
      ...filters,
      skip,
    })
  },
  generateTaskOutputFileUrl({ taskId, fileId }) {
    return Vue.http.get(
      `${store.getters['environment/atomicCustomerAPI']}/task/${taskId}/file/${fileId}/generate-url`
    )
  },
  getTaskEvents({ taskId }) {
    return Vue.http.get(`${store.getters['environment/atomicCustomerAPI']}/task/${taskId}/events`)
  },
}
