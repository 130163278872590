var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar", class: _vm.logoClasses },
    [
      _vm.branding.logo
        ? _c("SecureImage", {
            staticClass: "avatar-img",
            style:
              "background-color: " +
              _vm.logoBackgroundColor +
              "; padding: " +
              _vm.imagePadding +
              ";",
            attrs: { source: _vm.branding.logo.url },
          })
        : _vm._e(),
      !_vm.branding.logo && _vm.name
        ? _c("span", { staticClass: "avatar-title rounded-circle" }, [
            _vm._v(_vm._s(_vm.name.charAt(0))),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }