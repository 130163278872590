var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ConversionOptimizationManager", {
    attrs: {
      conversionOptimizationType: _vm.CONVERSION_OPTIMIZATION_TYPES.SURVEY,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }