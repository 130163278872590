<template>
  <MiniMetric
    title="Unique Users"
    :value="readableUniqueUserCounts.toLocaleString()"
    :isLoading="isLoading"
  >
    <template #icon>
      <div class="chart chart-sparkline">
        <ChartWrapper
          v-if="!isLoading"
          :chartType="CHART_TYPES.LINE"
          :chartData="chartData"
          :spark="true"
          :useDummyData="sampleDataMode"
        />
      </div>
    </template>
  </MiniMetric>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ChartWrapper from '@/components/Charts/ChartWrapper'
import MiniMetric from '@/components/Modules/Activity/Elements/MiniMetric'
import { CHART_TYPES } from '@/utils/constants'

export default {
  name: 'UniqueUsers',
  components: {
    MiniMetric,
    ChartWrapper,
  },
  computed: {
    ...mapState('activity', [
      'activeDateRangeOption',
      'sampleDataMode',
      'notBinnedUniqueUsers',
      'binnedUniqueUsers',
    ]),
    ...mapGetters('environment', ['isSandbox']),
    readableUniqueUserCounts() {
      if (this.sampleDataMode) return 24903
      if (this.isLoading || !this.notBinnedUniqueUsers) return 0
      return this.notBinnedUniqueUsers[0]?.count
    },
    chartData() {
      if (this.sampleDataMode) return {}
      return {
        labels: this.binnedUniqueUsers.map(function (bin) {
          return bin.date
        }),
        datasets: [
          {
            data: this.binnedUniqueUsers.map(function (bin) {
              return bin.count
            }),
          },
        ],
      }
    },
  },
  methods: {
    ...mapActions('activity', ['getBinnedUniqueUsers', 'getNotBinnedUniqueUsers']),
    ...mapMutations('activity', ['setSampleDataMode']),
    async getData() {
      this.isLoading = true
      try {
        await this.getBinnedUniqueUsers()
        await this.getNotBinnedUniqueUsers()

        if (
          !this.binnedUniqueUsers?.length ||
          !this.notBinnedUniqueUsers?.length ||
          this.isSandbox
        ) {
          this.setSampleDataMode(true)
        } else {
          this.setSampleDataMode(false)
        }
      } catch (error) {
        this.setSampleDataMode(true)
      }

      this.isLoading = false
    },
  },
  mounted() {
    this.getData()
  },
  data: () => ({
    isLoading: false,
    CHART_TYPES,
  }),
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.setSampleDataMode(false)
      this.getData()
    },
  },
}
</script>
