<template>
  <div class="d-flex flex-column align-items-center">
    <div class="color-swatch" :style="`background-color: ${color}`" />
    <span v-if="showLabel" class="small text-secondary">{{ color }}</span>
  </div>
</template>

<script>
export default {
  name: 'ColorSwatch',
  props: {
    color: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.color-swatch {
  border-radius: 6px;
  height: 40px;
  outline: solid 1px #d2ddec;
  width: 40px;
}
</style>
