<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import { defaultBarOptions, defaultBarDummyData } from '@/utils/chart'

export default {
  props: ['options', 'chartData', 'useDummyData'],
  extends: Bar,
  name: 'BarChart',
  mixins: [reactiveProp],
  methods: {
    triggerRenderChart() {
      let options = this.options || defaultBarOptions()
      const data = !this.chartData || this.useDummyData ? defaultBarDummyData() : this.chartData
      this.renderChart(data, options)
    },
  },
  mounted() {
    this.triggerRenderChart()
  },
  watch: {
    'chartData.labels': function () {
      this.triggerRenderChart()
    },
  },
}
</script>
