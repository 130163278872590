<template>
  <div class="form-group form-check">
    <input
      :checked="value"
      :disabled="disabled"
      :id="id"
      @input="onInput"
      class="form-check-input"
      type="checkbox"
    />
    <label class="form-check-label" :for="id">{{ label }}</label>
    <span v-if="required">*</span>
    <small class="form-text text-muted" v-if="helperText">{{ helperText }}</small>
  </div>
</template>

<script>
import { uniqueId } from 'lodash-es'

export default {
  name: 'Checkbox',
  props: {
    value: { type: Boolean, default: false },
    label: { type: String, required: true },
    helperText: { type: String },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      id: uniqueId('checkbox-'),
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.checked)
    },
  },
}
</script>
