<template>
  <button
    class="btn btn-outline-secondary"
    type="button"
    @click="onClickHandler"
    :disabled="disabled"
    :v-tooltip="toolTip"
    :aria-label="toolTip"
  >
    <RotateCcwIcon size="14" />
  </button>
</template>

<script>
import { RotateCcwIcon } from 'vue-feather-icons'

export default {
  name: 'EmulatorCustomizationResetToDefault',
  components: {
    RotateCcwIcon,
  },
  props: {
    onClickHandler: Function,
    disabled: Boolean,
    toolTip: String,
  },
}
</script>

<style></style>
