<template>
  <b-navbar toggleable="lg" class="navbar-light" :class="{ emulatorFullscreen: fullscreenMode }">
    <!-- Sandbox Notice -->
    <div v-if="isSandbox" class="test-data">
      <span class="badge bg-warning-soft merge-into-header">Sandbox</span>
    </div>
    <div v-if="accessNotEnabled" class="test-data">
      <span class="badge bg-warning-soft merge-into-header"
        >Contact sales for production access.
        <a
          @click="switchToSandbox"
          href="javascript:;"
          class="text-warning text-decoration-underline"
          >Return to Sandbox</a
        ></span
      >
    </div>
    <div class="container">
      <div class="navbar-brand">
        <div class="row">
          <div class="col">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          </div>
          <div class="col-auto">
            <div class="mt-2">
              <CustomerSwitcher />
            </div>
          </div>
        </div>
      </div>

      <!-- User -->
      <div class="row align-items-center gx-0 order-lg-3">
        <div class="col col-adjust d-flex align-items-center pe-3">
          <EnvironmentSwitcher />
        </div>

        <div class="col col-adjust user-avatar">
          <div class="navbar-user mt-2">
            <div class="dropdown show">
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                id="userDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="avatar avatar-sm avatar-online" v-if="profile">
                  <img
                    v-if="profile.picture"
                    :src="profile.picture"
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                  <span v-else class="avatar-title rounded-circle text-capitalize">{{
                    initials
                  }}</span>
                </div>
              </a>
              <div class="dropdown-menu dropdown-push-left" aria-labelledby="userDropdown">
                <a class="dropdown-item" href="#" @click="logout()">Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-collapse id="nav-collapse" is-nav class="order-0">
        <TopNavItems :activeCustomer="activeCustomer" />
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import EnvironmentSwitcher from '@/components/MainContent/EnvironmentSwitcher'
import CustomerSwitcher from '@/components/TopNav/CustomerSwitcher.vue'
import TopNavItems from '@/components/TopNav/TopNavItems.vue'
import { ENVIRONMENTS } from '@/utils/constants'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'TopNav',
  props: ['title', 'imageFileId', 'pretitle', 'badge', 'image', 'imageTitle'],
  components: {
    CustomerSwitcher,
    EnvironmentSwitcher,
    TopNavItems,
  },
  data() {
    return {
      profile: this.$auth.user,
    }
  },
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapGetters('environment', ['isSandbox', 'accessNotEnabled']),
    ...mapState('customer', ['activeCustomer', 'usesPaycheck']),
    ...mapState('emulator', ['fullscreenMode']),
    initials() {
      if (!this.profile || !this.profile.name) return
      return this.profile.name.charAt(0)
    },
  },
  methods: {
    ...mapActions('environment', ['updateActiveEnvironment']),
    handleLoginEvent(data) {
      this.profile = data.profile
    },
    logout() {
      this.$auth.logout()
    },
    switchToSandbox() {
      this.updateActiveEnvironment(ENVIRONMENTS.SANDBOX)
    },
  },
}
</script>

<style scoped lang="scss">
.navbar {
  transition: all 300ms ease-in-out;

  &.emulatorFullscreen {
    transform: translateY(-70px);
  }
}
.dropdown-push-left {
  left: -110px;
}

.test-data {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  border-top: 2px solid #f6c343;
  text-align: center;
  padding-top: 6px;
}
.merge-into-header {
  position: relative;
  top: -8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.col-adjust {
  height: 44px;
  &.user-avatar {
    position: relative;
    bottom: 5px;
  }
}
</style>
