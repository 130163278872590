var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.enabled
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _vm._v(
            " These settings only apply when fractional deposit is enabled. "
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      [
        _c("label", [
          _vm._v(
            "Configure the minimum and maximum amounts users can select for fractional deposit."
          ),
        ]),
        _c("div", { staticClass: "flex-container" }, [
          _c(
            "span",
            { staticClass: "form-text text-muted help-text" },
            [
              _c("info-icon", {
                staticClass: "info-icon",
                attrs: { size: "1.2x" },
              }),
              _vm._v(
                " Warning and error messages can be customized in the emulator. "
              ),
              _c(
                "router-link",
                {
                  staticClass: "nav-link nav-link-override",
                  attrs: { to: "/emulator" },
                },
                [_vm._v("Take Me There")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "Card",
          {
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [_c("h4", [_vm._v("Fixed")])]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("SettingsFractionalDepositLimit", {
              attrs: {
                limitDirection: "minimum",
                data: _vm.data.fixed.minimum || {},
              },
              on: {
                input: function ($event) {
                  return _vm.updateLimit(
                    $event,
                    "minimum",
                    _vm.distributionTypes.FIXED
                  )
                },
              },
            }),
            _c("SettingsFractionalDepositLimit", {
              attrs: {
                limitDirection: "maximum",
                data: _vm.data.fixed.maximum || {},
              },
              on: {
                input: function ($event) {
                  return _vm.updateLimit(
                    $event,
                    "maximum",
                    _vm.distributionTypes.FIXED
                  )
                },
              },
            }),
          ],
          1
        ),
        _c(
          "Card",
          {
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [_c("h4", [_vm._v("Percent")])]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("SettingsFractionalDepositLimit", {
              attrs: {
                limitDirection: "minimum",
                data: _vm.data.percent.minimum || {},
                fractionalType: _vm.distributionTypes.PERCENT,
                max: 100,
              },
              on: {
                input: function ($event) {
                  return _vm.updateLimit(
                    $event,
                    "minimum",
                    _vm.distributionTypes.PERCENT
                  )
                },
              },
            }),
            _c("SettingsFractionalDepositLimit", {
              attrs: {
                limitDirection: "maximum",
                data: _vm.data.percent.maximum || {},
                fractionalType: _vm.distributionTypes.PERCENT,
                max: 100,
              },
              on: {
                input: function ($event) {
                  return _vm.updateLimit(
                    $event,
                    "maximum",
                    _vm.distributionTypes.PERCENT
                  )
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }