<template>
  <ul class="navbar-nav me-lg-auto">
    <li class="nav-item">
      <router-link class="nav-link" to="/" :class="{ active: isActive('activity') }">
        Activity
      </router-link>
    </li>
    <li v-if="hasPermissions(PERMISSIONS.SEARCH_TASKS)" class="nav-item">
      <router-link class="nav-link" to="/tasks/list" :class="{ active: isActive('tasks') }">
        Tasks
      </router-link>
    </li>
    <li v-if="hasPermissions(PERMISSIONS.USE_EMULATOR)" class="nav-item">
      <router-link class="nav-link" to="/emulator" :class="{ active: isActive('emulator') }">
        Emulator
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'resources-figma' }"
        :class="{ active: isActive('resources') }"
      >
        Resources
      </router-link>
    </li>
    <li data-test-id="nav-customers" v-if="canManageChildCustomers" class="nav-item">
      <router-link class="nav-link" to="/customers" :class="{ active: isActive('customers') }">
        Customers
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="settingsRoute" :class="{ active: isActive('settings') }">
        Settings
      </router-link>
    </li>
  </ul>
</template>

<script>
import { hasAPermission, hasPermissions, hasScope } from '@/lib/authorization'
import { PERMISSIONS } from '@/lib/permissions'
import { SCOPES } from '@atomicfi/constants-shared'

export default {
  name: 'TopNavItems',
  props: {
    activeCustomer: Object,
  },
  data() {
    return {
      PERMISSIONS,
      SCOPES,
    }
  },
  computed: {
    canManageChildCustomers() {
      return this.activeCustomer?.features?.supportsChildCustomers
    },
    settingsRoute() {
      return hasPermissions(PERMISSIONS.MANAGE_INSTITUTION_SETTINGS)
        ? { name: 'settings-general' }
        : { name: 'settings-team' }
    },
  },
  methods: {
    hasAPermission,
    hasPermissions,
    hasScope,
    isActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some((path) => {
        return this.$route.path.indexOf(path) !== -1 // current path starts with this path string
      })
    },
  },
}
</script>
