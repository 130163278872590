var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MiniMetric", {
    attrs: {
      title: "Unique Users",
      value: _vm.readableUniqueUserCounts.toLocaleString(),
      isLoading: _vm.isLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "chart chart-sparkline" },
              [
                !_vm.isLoading
                  ? _c("ChartWrapper", {
                      attrs: {
                        chartType: _vm.CHART_TYPES.LINE,
                        chartData: _vm.chartData,
                        spark: true,
                        useDummyData: _vm.sampleDataMode,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }