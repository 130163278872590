var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EmulatorCustomizationInputWrapper",
    { attrs: { label: _vm.label } },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm._t("default", null, { value: _vm.value }),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _vm.isInput
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mutatedValue,
                            expression: "mutatedValue",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: _vm.placeholderValue,
                          type: "text",
                        },
                        domProps: { value: _vm.mutatedValue },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.mutatedValue = $event.target.value
                            },
                            _vm.inputHandler,
                          ],
                        },
                      })
                    : _vm.isTextArea
                    ? _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mutatedValue,
                            expression: "mutatedValue",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: _vm.placeholderValue,
                          type: "text",
                        },
                        domProps: { value: _vm.mutatedValue },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.mutatedValue = $event.target.value
                            },
                            _vm.inputHandler,
                          ],
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("EmulatorCustomizationResetToDefault", {
                    attrs: {
                      disabled: !_vm.mutatedValue,
                      onClickHandler: _vm.resetToDefaultValue,
                      toolTip: "Reset to Default",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm.$slots.postInput
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [_vm._t("postInput")], 2),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }