<template>
  <div>
    <LineChart
      v-if="chartType === CHART_TYPES.LINE"
      :chartData="chartData"
      :options="chartOptions"
      :useDummyData="useDummyData"
      class="chart"
      :spark="spark"
      :class="{ 'chart-sparkline': spark }"
    />
    <DoughnutChart
      v-else-if="chartType === CHART_TYPES.DOUGHNUT"
      :chartData="chartData"
      :options="chartOptions"
      :useDummyData="useDummyData"
      class="chart"
    />
    <BarChart
      v-else-if="chartType === CHART_TYPES.BAR"
      :chartData="chartData"
      :options="chartOptions"
      :useDummyData="useDummyData"
      class="chart"
    />
  </div>
</template>

<script>
import LineChart from './ChartTypes/LineChart'
import DoughnutChart from './ChartTypes/DoughnutChart'
import BarChart from './ChartTypes/BarChart'
import { CHART_TYPES } from '@/utils/constants'

export default {
  name: 'ChartWrapper',
  components: {
    BarChart,
    DoughnutChart,
    LineChart,
  },
  props: {
    chartType: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
    useDummyData: {
      type: Boolean,
      required: false,
    },
    spark: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return { CHART_TYPES }
  },
}
</script>
