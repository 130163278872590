<template>
  <Form
    :onSubmit="update"
    @success="onSuccess"
    @error="onError"
    submitButtonText="Update"
    v-slot="{ disabled }"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="institution-name" class="mb-1">Name</label>
          <small class="form-text text-muted"
            >This name will be used in communications with your customers.</small
          >
          <input
            id="institution-name"
            class="form-control"
            placeholder="ABC Credit Union"
            type="text"
            v-model="fields.name"
            :disabled="disabled"
          />
        </div>
        <div class="form-group">
          <label for="institution-abbreviation" class="mb-1">Abbreviation</label>
          <small class="form-text text-muted"
            >This shortened version of the name may also be used in communications with your
            customers.</small
          >
          <input
            id="institution-abbreviation"
            class="form-control"
            placeholder="ABCU"
            type="text"
            v-model="fields.nickName"
            :disabled="disabled"
          />
        </div>
        <div class="row">
          <div v-for="logo in logos" :key="logo.path" class="col-12 col-md-6">
            <div class="form-group">
              <label for="institution-abbreviation" class="mb-1">
                <span class="d-inline-block align-middle me-1">{{ logo.label }}</span>
              </label>
              <small class="form-text text-muted mb-4">{{ logo.helperText }}</small>
              <div class="logo-outer" v-if="fields[`branding.${logo.path}.url`]">
                <div class="logo-inner" :class="logo.wrapperClass">
                  <img
                    class="logo"
                    :src="fields[`branding.${logo.path}.url`]"
                    :alt="`${fields.name} ${logo.label} preview`"
                  />
                  <button
                    class="btn btn-rounded-circle btn-white btn-sm upper-right"
                    @click="() => removeLogo(logo.path)"
                  >
                    <XIcon size="14" />
                  </button>
                </div>
              </div>
              <Dropzone
                v-else
                @updatedFiles="(files) => setLogo(logo.path, files)"
                :multi="false"
                :public="true"
                :accepted-files="'image/png'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import SettingsBase from './SettingsBase'
import CustomerService from '@/services/CustomerService'
import Dropzone from '@/components/MainContent/Dropzone'
import Form from '@/components/MainContent/Form'
import { XIcon } from 'vue-feather-icons'

export default {
  name: 'SettingsGeneral',
  extends: SettingsBase,
  components: { Dropzone, Form, XIcon },
  data: () => ({
    fields: {
      name: '',
      nickName: '',
      'branding.logo.url': '',
      'branding.darkLogo.url': '',
    },
    logos: [
      {
        path: 'logo',
        label: 'Logo',
        helperText: 'We recommend a square PNG file around 100x100 resolution.',
      },
      {
        path: 'darkLogo',
        label: 'Dark Mode Logo',
        helperText: 'A variant of your logo used when dark mode is enabled.',
        wrapperClass: 'dark',
      },
    ],
  }),
  methods: {
    async update() {
      await CustomerService.update({ ...this.fields })
      this.getCustomer()
    },
    onSuccess() {
      this.$toasted.success('Update successful.')
    },
    onError() {
      this.$toasted.show('Error updating.')
    },
    removeLogo(field) {
      this.fields[`branding.${field}.url`] = null
    },
    setLogo(field, files) {
      this.fields[`branding.${field}.url`] = files[0].url
    },
  },
  mounted() {
    this.fields.name = this.activeCustomer.name
    this.fields.nickName =
      this.activeCustomer.nickName || this.activeCustomer.name.match(/[A-Z]/g).join('')
    this.fields['branding.logo.url'] = this.activeCustomer.branding?.logo?.url
    this.fields['branding.darkLogo.url'] = this.activeCustomer.branding?.darkLogo?.url
  },
}
</script>

<style lang="scss" scoped>
.logo-outer {
  align-content: center;
  display: flex;
  justify-content: center;
}

.logo-inner {
  align-items: center;
  border: 1px solid var(--bs-light);
  border-radius: 50%;
  display: inline-flex;
  height: 100px;
  justify-content: center;
  position: relative;
  width: 100px;

  &.dark {
    background-color: black;
    border: none;
  }
}

.logo {
  display: block;
  max-height: 50%;
  max-width: 50%;
  object-fit: scale-down;
}

.upper-right {
  position: absolute;
  right: -4px;
  top: 0;
}

.help {
  cursor: help;
}
</style>
