<template>
  <div class="row align-items-center">
    <nav
      class="navbar navbar-vertical navbar-light"
      style="position: relative; border-width: 0; background-color: inherit"
    >
      <ul class="navbar-nav mb-md-3">
        <li>
          <h6 class="header-pretitle">General</h6>
        </li>
        <EmulatorNavigationItem name="initialization" :icon="PlayCircleIcon" />
        <EmulatorNavigationItem v-if="demoIsAvailable" name="demo" :icon="ZapIcon" />
        <li class="mt-4">
          <h6 class="header-pretitle">Theme</h6>
        </li>
        <EmulatorNavigationItem name="styles" :icon="EyeIcon" :requiresInitialization="true" />
        <EmulatorNavigationItem name="fonts" :icon="TypeIcon" :requiresInitialization="true" />
        <li class="mt-4" v-if="screensToCustomize.length">
          <h6 class="header-pretitle">Screens</h6>
        </li>
        <EmulatorNavigationItem
          v-for="screen in screensToCustomize"
          :name="screen.name"
          :icon="screen.icon"
          :key="screen.name"
          :requiresInitialization="true"
        />
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { EyeIcon, PlayCircleIcon, ZapIcon, TypeIcon } from 'vue-feather-icons'
import EmulatorNavigationItem from '@/components/Modules/Emulator/EmulatorNavigationItem.vue'
import { featureUniqueForAtomic } from '@/lib/customer-customizations'

export default {
  name: 'EmulatorNavigation',
  components: {
    EmulatorNavigationItem,
  },
  computed: {
    ...mapState('emulator', ['activeNavItem', 'scope']),
    ...mapGetters('emulator', ['screensToCustomize']),
    demoIsAvailable() {
      return featureUniqueForAtomic()
    },
  },
  data() {
    return {
      EyeIcon,
      PlayCircleIcon,
      ZapIcon,
      TypeIcon,
    }
  },
}
</script>

<style scoped lang="scss"></style>
