import Vue from 'vue'
import store from '@/store'
import urlGenerator from '@/lib/url-generator'

export default {
  generate(payload) {
    return Vue.http.post(
      urlGenerator(`${store.getters['environment/atomicCustomerAPI']}/file/generate`),
      payload
    )
  },
  upload(payload) {
    return Vue.http.post(
      urlGenerator(`${store.getters['environment/atomicCustomerAPI']}/file/upload`),
      payload
    )
  },
}
