<template>
  <validation-observer v-slot="{ handleSubmit, invalid }" tag="div">
    <form @submit.prevent="handleSubmit(submit)">
      <Card>
        <template #header>
          <h4 v-if="title" class="card-header-title">{{ title }}</h4>
        </template>
        <slot :disabled="isProcessing"> </slot>
        <template #footer>
          <button
            type="submit"
            :class="`btn btn-${submitButtonVariant}`"
            :disabled="isProcessing || invalid"
          >
            <span
              v-if="isProcessing"
              aria-hidden="true"
              class="spinner-border spinner-border-sm"
            ></span>
            {{ submitButtonText }}
          </button>
        </template>
      </Card>
    </form>
  </validation-observer>
</template>

<script>
import Card from '@/components/MainContent/Card'

export default {
  name: 'Form',
  components: {
    Card,
  },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    submitButtonText: {
      type: String,
      default: 'Submit',
    },
    submitButtonVariant: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      state: 'idle',
    }
  },
  computed: {
    isProcessing() {
      return this.state === 'processing'
    },
  },
  methods: {
    async submit() {
      try {
        this.state = 'processing'

        const submitResult = await this.onSubmit()

        this.state = 'idle'

        this.$emit('success', submitResult)
      } catch (error) {
        this.state = 'error'
        this.$emit('error', error)
      }
    },
  },
}
</script>
