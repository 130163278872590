<template>
  <EmulatorMutationPreview :mutation="mutation" arrowClass="mb-3">
    <template #before>
      <ColorSwatch :color="mutation.valueBeforeChanges" :showLabel="true" />
    </template>
    <template #after>
      <ColorSwatch :color="mutation.value" :showLabel="true" />
    </template>
  </EmulatorMutationPreview>
</template>

<script>
import ColorSwatch from '@/components/MainContent/ColorSwatch'
import EmulatorMutationPreview from '@/components/Modules/Emulator/EmulatorMutationPreview'

export default {
  name: 'EmulatorColorMutationPreview',
  components: {
    ColorSwatch,
    EmulatorMutationPreview,
  },
  props: {
    mutation: {
      type: Object,
      required: true,
    },
  },
}
</script>
