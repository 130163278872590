<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('customer', ['activeCustomer']),
    ...mapState('environment', ['activeEnvironment']),
  },
  methods: {
    ...mapMutations('customer', ['setActiveSettingsTab']),
    ...mapActions('customer', ['getCustomer', 'updateCustomer', 'testCustomerWebhooks']),
  },
  watch: {
    activeEnvironment() {
      this.getCustomer()
    },
  },
  async mounted() {
    this.setActiveSettingsTab(this.$route.name)
  },
}
</script>
