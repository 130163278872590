<template>
  <div>
    <div class="form-group">
      <CustomSwitch
        label="Allow users to add cards"
        helper-text="Card details are encrypted and stored securely for 24 hours."
        :value="payLink.userProvidedCards.enabled"
        @input="onChangeUserProvidedCardsEnabled"
      />
    </div>
    <div v-if="payLink.userProvidedCards.enabled" class="form-group">
      <label>Approved BIN Codes</label>
      <br />
      <small class="text-muted">
        Only allow users to add cards with the following BIN codes. Enter as comma-separated list of
        6-digit codes (e.g., "123456, 567890").</small
      >
      <input
        placeholder="123456, 567890"
        class="form-control mt-3"
        type="text"
        :value="payLink.userProvidedCards.binCodes.join(', ')"
        @change="onChangeBinCodes"
        :pattern="binCodesPattern"
      />
    </div>
  </div>
</template>

<script>
import CustomSwitch from '@/components/MainContent/CustomSwitch.vue'

export default {
  name: 'SettingsFeaturesPayLink',
  components: { CustomSwitch },
  props: {
    data: Object,
  },
  data: () => ({
    payLink: {
      userProvidedCards: { enabled: false, binCodes: [] },
    },
  }),
  methods: {
    onChangeUserProvidedCardsEnabled(value) {
      this.payLink.userProvidedCards.enabled = value
      this.emitChanges()
    },
    onChangeBinCodes(event) {
      if (!event.target.checkValidity()) {
        event.target.reportValidity()
      } else {
        const commaWithOptionalWhiteSpace = /,\s?/
        this.payLink.userProvidedCards.binCodes = event.target.value
          .split(commaWithOptionalWhiteSpace)
          .map((value) => value.trim())
          .filter(Boolean)
        this.emitChanges()
      }
    },
    emitChanges() {
      this.$emit('input', this.payLink)
    },
  },
  created() {
    this.payLink = this.data || this.payLink

    const commaSeparatedListOf6DigitCodes = /(\d{6})*(,\s*(\d{6})*)*/
    // Need to use `source` to exclude leading and trailing slashes for use
    // in template.
    this.binCodesPattern = commaSeparatedListOf6DigitCodes.source
  },
}
</script>

<style scoped></style>
