<template>
  <div>
    <div
      class="card"
      v-for="{ totalWages, year, form, parsedData } in w2s"
      :key="year + totalWages"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <div class="d-flex flex-row mt-n2">
              <div class="me-4">
                <h6 class="text-muted text-uppercase m-0">type</h6>
                <div>W2</div>
              </div>
              <div class="me-4">
                <h6 class="text-muted text-uppercase m-0">year</h6>
                <div>
                  {{ year | moment('timezone', 'Etc/UTC', 'YYYY') }}
                </div>
              </div>

              <div>
                <h6 class="text-muted text-uppercase m-0">total wages</h6>
                <div>
                  {{ totalWages | currency }}
                </div>
              </div>
            </div>
          </div>
          <div class="col text-right align-self-center">
            <ViewTaskOutputFileButton :taskId="taskId" :fileId="form"
              >View Form</ViewTaskOutputFileButton
            >
          </div>
        </div>
      </div>
      <div class="card-body" v-if="!isParsedDataEmpty(parsedData)">
        <h4 v-b-toggle="'collapse-parsedData'">
          <ChevronRightIcon size="1.2x" class="toggle-icon"></ChevronRightIcon>
          Parsed Data
        </h4>
      </div>
      <b-collapse :id="'collapse-parsedData'">
        <div class="list-group">
          <div
            class="list-group-item"
            v-for="(value, parentLabel) in parsedData"
            :key="parentLabel"
          >
            <div
              v-if="
                typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean'
              "
            >
              <div class="row align-items-center">
                <small class="col text-uppercase text-muted">
                  {{ parentLabel | startCase }}
                </small>
                <div class="col-auto">
                  {{ value | booleanValues }}
                </div>
              </div>
            </div>
            <div v-if="Array.isArray(value) && value.length > 0">
              <small class="col text-uppercase text-muted">
                {{ parentLabel | startCase }}
              </small>
              <div v-if="parentLabel.toUpperCase() === 'CODES'">
                <div v-for="object in value" :key="object">
                  <div class="row align-items-center">
                    <small class="col text-muted"> Code | Amount </small>
                    <div class="col-auto">{{ object.code }} | {{ object.amount }}</div>
                  </div>
                </div>
              </div>
              <div v-else-if="parentLabel.toUpperCase() === 'OTHER'">
                <div v-for="object in value" :key="object">
                  <div class="row align-items-center">
                    <small class="col text-muted"> Description | Amount </small>
                    <div class="col-auto">{{ object.description }} | {{ object.amount }}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-for="(object, index) in value" :key="object + index">
                  <div v-for="(data, label) in object" :key="data">
                    <div class="row align-items-center">
                      <small class="col text-muted">
                        {{ label | startCase }}
                      </small>
                      <div class="col-auto">
                        {{ data }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="typeof value === 'object' && !Array.isArray(value)">
              <small class="col text-uppercase text-muted">
                {{ parentLabel | startCase }}
              </small>
              <div v-for="(data, label) in value" :key="data">
                <div class="row align-items-center">
                  <small class="col text-muted">
                    {{ label | startCase }}
                  </small>
                  <div class="col-auto">
                    {{ data }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import ViewTaskOutputFileButton from '@/components/MainContent/ViewTaskOutputFileButton'
import { ChevronRightIcon } from 'vue-feather-icons'
import { isEmpty } from 'lodash-es'

export default {
  name: 'TaskW2s',
  components: { ChevronRightIcon, ViewTaskOutputFileButton },
  props: ['taskId', 'w2s'],
  methods: {
    isParsedDataEmpty(parsedData) {
      if (typeof parsedData === 'string' || typeof parsedData === 'number') {
        return false
      }
      if (typeof parsedData === 'object') {
        if (isEmpty(parsedData)) {
          return true
        }
        return Object.values(parsedData).every((value) => this.isParsedDataEmpty(value))
      }
    },
  },
  filters: {
    booleanValues: (value) => {
      if (value === true) {
        return 'Yes'
      }
      if (value === false) {
        return 'No'
      }

      return value
    },
  },
}
</script>

<style scoped="scss">
h4 {
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 0;
}
.toggle-icon {
  transition: 300ms transform ease-in-out;
}
.not-collapsed > .toggle-icon {
  transform: rotate(90deg);
}
</style>
