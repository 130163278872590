<template>
  <div>
    <div v-if="uploading" class="dz-message">
      <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <form class="dropzone dropzone-multiple">
        <div id="dropzone-message" style="display: none">
          <span class="dropzone-title">Drop files here or click to select</span>
        </div>
        <div class="dz-preview">
          <div class="dz-preview-inner">
            <div></div>
          </div>
        </div>
        <div class="fallback">
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="customFileUploadMultiple" multiple />
            <label class="custom-file-label" for="customFileUploadMultiple">Choose file</label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone'
import FileService from '@/services/FileService'
Dropzone.autoDiscover = false

export default {
  name: 'Dropzone',
  props: ['limit', 'public', 'multi', 'preloadFiles', 'acceptedFiles'],
  data() {
    return {
      fileList: [],
      progress: 0,
      uploading: false,
    }
  },
  methods: {
    async addFile(file) {
      const response = await FileService.generate({
        key: file.key,
        public: this.public,
      })
      this.fileList.push(response.data.data)
      this.$emit('updatedFiles', this.fileList)
      // If it's a single upload, go ahead and remove the file once it's been passed up to the parent component
      if (!this.multi) {
        this.dropzone.removeAllFiles()
      }
      this.uploading = false
    },
    removeFile(fileToRemove) {
      this.fileList = this.fileList.filter((file) => file._id !== fileToRemove._id)
    },
  },
  mounted() {
    if (this.preloadFiles && this.preloadFiles.length) this.fileList = this.preloadFiles
    const vm = this
    const dropzoneContainer = this.$el.querySelector('.dropzone')
    const previewsContainer = this.$el.querySelector('.dz-preview')
    const previewTemplate = this.$el.querySelector('.dz-preview-inner')
    // var $dropzone = document.querySelector('.dropzone')
    // var $dropzonePreview = document.querySelector('.dz-preview')
    let options = {
      // The URL will be changed for each new file being processing
      url: '/',
      // Since we're going to do a `PUT` upload to S3 directly
      method: 'put',
      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        let _send = xhr.send
        xhr.send = () => {
          _send.call(xhr, file)
        }
      },
      // Upload one file at a time since we're using the S3 pre-signed URL scenario
      parallelUploads: 1,
      uploadMultiple: false,
      thumbnailWidth: null,
      thumbnailHeight: null,
      // maxFiles: 1,
      previewsContainer: previewsContainer,
      previewTemplate: previewTemplate.innerHTML,
      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      header: '',
      // Customize the wording
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,

      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,

      // Only allow for certain file types if needed
      acceptedFiles: this.acceptedFiles,

      // Here we request a signed upload URL when a file being accepted
      async accept(file, done) {
        try {
          const payload = {
            filePath: file.name,
            public: vm.public,
            size: file.size,
            name: file.name,
          }
          vm.uploading = true

          const response = await FileService.upload(payload)
          file.uploadURL = response.data.data.url
          file.key = response.data.data.key
          done()
          // Manually process each file
          setTimeout(() => vm.dropzone.processFile(file))
        } catch (error) {
          console.log('error uploading', error)
        }
      },
    }

    // Instantiate Dropzone
    this.dropzone = new Dropzone(dropzoneContainer, options)

    // Remove empty placeholders after we grab innerHTML
    // previewTemplate.parentNode.removeChild(previewTemplate)

    // Track progress
    vm.dropzone.on('uploadprogress', function (file, progress) {
      vm.progress = progress
    })

    // Set signed upload URL for each file
    vm.dropzone.on('processing', (file) => {
      vm.dropzone.options.url = file.uploadURL
    })

    // Add the file to the DB once it's been uploaded
    vm.dropzone.on('success', async (file) => {
      vm.addFile(file)
    })
  },
}
</script>

<style lang="scss"></style>
