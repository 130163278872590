var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("h4", { staticClass: "card-header-title" }, [
                _vm._v(
                  _vm._s(_vm._f("startCase")(_vm.conversionOptimizationType)) +
                    "s"
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          " To improve the Atomic experience and increase conversion rate, our team periodically runs " +
            _vm._s(_vm.conversionOptimizationType) +
            "s to make data driven decisions around modifications. Please opt in to help us make our product better for our shared customers. "
        ),
      ]),
      _vm._t("introduction"),
      _c(
        "div",
        { staticClass: "global-toggle-wrapper bg-secondary-light" },
        [
          _c("div", [
            _c("p", { staticClass: "global-toggle-label" }, [
              _vm._v(
                "Enable all " + _vm._s(_vm.conversionOptimizationType) + "s"
              ),
            ]),
            _c("span", { staticClass: "global-toggle-helper-text" }, [
              _vm._v(
                _vm._s(
                  _vm.globalToggleSetting
                    ? "You will be opted in to all " +
                        _vm.CONVERSION_OPTIMIZATION_STATUSES.ACTIVE +
                        " and " +
                        _vm.CONVERSION_OPTIMIZATION_STATUSES.UPCOMING +
                        " " +
                        _vm.conversionOptimizationType +
                        "s."
                    : "You will need to opt in to " +
                        _vm.CONVERSION_OPTIMIZATION_STATUSES.ACTIVE +
                        " and " +
                        _vm.CONVERSION_OPTIMIZATION_STATUSES.UPCOMING +
                        " " +
                        _vm.conversionOptimizationType +
                        "s individually."
                )
              ),
            ]),
          ]),
          _c("CustomSwitch", {
            attrs: {
              value: _vm.globalToggleSetting,
              label:
                "Global opt-in for " + _vm.conversionOptimizationType + "s",
              showLabelText: false,
              formGroupWrap: false,
            },
            on: { input: _vm.onGlobalToggleChange },
          }),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "nav nav-tabs" },
        _vm._l(_vm.tabs, function (tab) {
          return _c("li", { key: tab, staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.currentTab === tab },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return function () {
                      return _vm.setCurrentTab(tab)
                    }.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm._f("startCase")(tab)) + " ")]
            ),
          ])
        }),
        0
      ),
      _c(
        "ContentLoader",
        {
          attrs: {
            contentLoaded: _vm.state === "loaded",
            contentLoading: _vm.state === "loading",
          },
        },
        [
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              { staticClass: "tab-pane active", attrs: { role: "tabpanel" } },
              [
                !_vm.conversionOptimizations.length
                  ? _c("EmptyState", { staticClass: "mt-4 mb-0" }, [
                      _c("div", { staticClass: "d-block mb-3" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block mx-auto p-3 bg-secondary-light rounded",
                          },
                          [_c("LayersIcon")],
                          1
                        ),
                      ]),
                      _vm._v(
                        " No " +
                          _vm._s(_vm.currentTab) +
                          " " +
                          _vm._s(_vm.conversionOptimizationType) +
                          "s. "
                      ),
                    ])
                  : _vm._e(),
                _vm._l(
                  _vm.conversionOptimizations,
                  function (conversionOptimization) {
                    return _c("ConversionOptimizationCard", {
                      key: conversionOptimization._id,
                      attrs: { conversionOptimization: conversionOptimization },
                    })
                  }
                ),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }