<template>
  <div class="table-wrapper scroll-shadow">
    <table class="table table-sm table-nowrap">
      <thead>
        <tr>
          <th>{{ label | startCase }}</th>
          <th class="text-center">Production</th>
          <th class="text-center">Sandbox</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in rowData" :key="data">
          <td>{{ data | startCase }}</td>
          <PermissionCell :hasPermission="hasPermissionFunction(ENVIRONMENTS.PRODUCTION, data)" />
          <PermissionCell :hasPermission="hasPermissionFunction(ENVIRONMENTS.SANDBOX, data)" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ENVIRONMENTS } from '@/utils/constants'
import PermissionCell from '@/components/Modules/Settings/RolePermissions/PermissionCell.vue'

export default {
  name: 'HasPermissionTable',
  components: {
    PermissionCell,
  },
  data() {
    return {
      ENVIRONMENTS,
    }
  },
  props: {
    hasPermissionFunction: {
      type: Function,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    rowData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  position: sticky;
  top: 0;
}

.table-wrapper {
  max-height: 580px;
  overflow-y: auto;
}
</style>
