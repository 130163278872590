<template>
  <EmulatorMutationPreview :mutation="mutation">
    <template #before>
      <div class="d-flex w-50 justify-content-end">
        <span class="previous-value text-secondary">{{ mutation.valueBeforeChanges }}</span>
      </div>
    </template>
    <template #after>
      <div class="d-flex w-50 justify-content-start">
        <span class="text-primary">{{ mutation.value }}</span>
      </div>
    </template>
  </EmulatorMutationPreview>
</template>

<script>
import EmulatorMutationPreview from '@/components/Modules/Emulator/EmulatorMutationPreview'

export default {
  name: 'EmulatorStringMutationPreview',
  components: {
    EmulatorMutationPreview,
  },
  props: {
    mutation: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.previous-value {
  text-decoration: line-through;
}
</style>
