import moment from 'moment'
import { TRANSACT_EVENTS } from '@/utils/constants'

const BIN_SIZES = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
}

// A filter can be scoped to specific events using the 'events' property
// If the 'events' property is not present, it will be allowed on all events
// These could be dynamic, but we'd like to wait and see how people use a limited subset
export const TRANSACT_EVENT_FILTERS = {
  company: {
    options: [
      'ADP',
      'DoorDash',
      'Walmart',
      'Uber',
      'Social Security Beneficiaries',
      'Paychex Flex',
      'Paycom',
      'Amazon',
      'Social Security',
      'Paylocity',
      'UPS',
      'Ceridian Dayforce',
      'Paycor',
      'Gusto',
      'Lyft',
      'Dollar General',
      'Target',
      'DFAS myPay',
    ],
    events: [
      TRANSACT_EVENTS.LOGIN,
      TRANSACT_EVENTS.SUBMITTED_CREDENTIALS,
      TRANSACT_EVENTS.DISTRIBUTION_CONFIRMATION,
      TRANSACT_EVENTS.AUTHENTICATION_FAILED,
      TRANSACT_EVENTS.TASK_DONE,
    ],
  },
  payroll: {
    options: [
      'ADP',
      'ADP Workforce Now',
      'DoorDash',
      'Uber',
      'Workday',
      'Paychex Flex',
      'One Walmart',
      'Paycom',
      'DailyPay',
      'Amazon A to Z',
      'Social Security',
      'Paylocity',
      'UPS',
      'Ceridian Dayforce',
      'Paycor',
      'Gusto',
      'Lyft',
      'DGMe',
      'UKG Pro',
      'DFAS myPay',
    ],
    events: [
      TRANSACT_EVENTS.LOGIN,
      TRANSACT_EVENTS.SUBMITTED_CREDENTIALS,
      TRANSACT_EVENTS.DISTRIBUTION_CONFIRMATION,
      TRANSACT_EVENTS.AUTHENTICATION_FAILED,
      TRANSACT_EVENTS.TASK_DONE,
    ],
  },
  status: { options: ['completed', 'failed'], events: [TRANSACT_EVENTS.TASK_DONE] },
}

export function getFriendlyEventName(rawName) {
  const map = {
    [`${TRANSACT_EVENTS.INITIALIZED_TRANSACT}`]: 'Opened Atomic',
    [`${TRANSACT_EVENTS.SUBMITTED_CREDENTIALS}`]: 'Submitted Credentials',
    [`${TRANSACT_EVENTS.TASK_DONE}`]: 'Completed Task',
  }
  if (map[rawName]) return map[rawName]
  return rawName
}

export function decideBinSize(days) {
  if (days <= 3) {
    return BIN_SIZES.HOUR
  } else if (days <= 21) {
    return BIN_SIZES.DAY
  } else {
    return BIN_SIZES.WEEK
  }
}

export function generateChartData({ binSize, startDate, endDate, items }) {
  const labelFormat = createLabelFormat(binSize)
  let labels = {}
  // Create date objects for start and end date of range
  let start = moment(startDate).startOf('day')
  let end = moment(endDate).endOf('day')

  while (start <= end) {
    // Create a label, and put items into it
    labels[moment(start).format(labelFormat)] = items.filter((item) => {
      // Get the item date
      const itemDateText = item.date || item.start_date // Depending on bin size, could be either 'date' key or 'start_date' key
      if (
        start.format('YYYY-MM-DD') === itemDateText ||
        (binSize === BIN_SIZES.WEEK && start.week() === moment(itemDateText).week())
      ) {
        if (binSize === BIN_SIZES.HOUR && start.hours() !== item.hour) {
          return false
        }
        return true
      }
    })

    start = moment(start).add(1, binSize)
  }
  return labels
}

export function createDataSetFromChartData({ generatedChartData, filter }) {
  return [
    ...Object.keys(generatedChartData).map((label) => {
      return generatedChartData[label].find(filter)?.count || 0
    }),
  ]
}

function createLabelFormat(binSize) {
  if (binSize === BIN_SIZES.HOUR) {
    // Sep 21, 8am
    return 'MMM D, ha'
  } else {
    // Sep 21
    return 'MMM D'
  }
}

export function funnelSuggestions() {
  return [
    {
      title: 'Top of Funnel',
      description: 'How many users find something to log into?',
      image: '/images/funnel/1.jpg',
      steps: [
        TRANSACT_EVENTS.INITIALIZED_TRANSACT,
        TRANSACT_EVENTS.LOGIN,
        TRANSACT_EVENTS.SUBMITTED_CREDENTIALS,
      ],
      filters: {},
    },
    {
      title: 'Successful Outcomes',
      description: 'How many users have successful tasks?',
      image: '/images/funnel/2.jpg',
      steps: [
        TRANSACT_EVENTS.INITIALIZED_TRANSACT,
        TRANSACT_EVENTS.SUBMITTED_CREDENTIALS,
        TRANSACT_EVENTS.TASK_DONE,
      ],
      filters: { 2: [{ property: 'status', operator: 'is', value: 'completed' }] },
    },
    {
      title: 'Multi-Factor Auth',
      description: 'How often do users trigger MFA?',
      image: '/images/funnel/3.jpg',
      steps: [
        TRANSACT_EVENTS.INITIALIZED_TRANSACT,
        TRANSACT_EVENTS.SUBMITTED_CREDENTIALS,
        TRANSACT_EVENTS.MFA,
      ],
      filters: {},
    },
    {
      title: 'ADP Conversion Rate',
      description: 'See payroll-specific conversion rates.',
      image: '/images/funnel/4.jpg',
      steps: [
        TRANSACT_EVENTS.LOGIN,
        TRANSACT_EVENTS.SUBMITTED_CREDENTIALS,
        TRANSACT_EVENTS.TASK_DONE,
      ],
      filters: {
        0: [{ property: 'company', operator: 'is', value: 'ADP' }],
        2: [{ property: 'status', operator: 'is', value: 'completed' }],
      },
    },
  ]
}

export function topConnectionsSampleData() {
  return [
    {
      count: 1054,
      name: 'ADP',
      _id: '5e4c4d18b7d75c37aac54a8f',
      cache: {
        branding: {
          logo: {
            url: 'https://atomicfi-public-local.s3.amazonaws.com/4751bb4b-726c-4f85-8833-691ee7e29658_adp.svg',
            backgroundColor: '#FFFFFF',
          },
          color: '#486AC3',
        },
      },
    },
    {
      count: 891,
      name: 'Walmart',
      _id: '5ec2fbf4398fed000810a42c',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/f5f26549-14f2-4f14-a3b9-704fb430e207_walmart.svg',
          },
          color: '#051D43',
        },
      },
    },
    {
      count: 842,
      name: 'Paychex Flex',
      _id: '5fc810d63279fe0009c493ec',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/72907a7e-68f8-4985-8edb-4c02ca4bded5_Paychex_logo.svg',
          },
          color: '#004B8D',
        },
      },
    },
    {
      count: 629,
      name: 'Gusto',
      _id: '5e4c4d1ab7d75c37aac56b7c',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/94974167-2aec-4bc4-b20a-85354a92690e_gusto.svg',
          },
          color: '#F45D48',
        },
      },
    },
    {
      count: 414,
      name: 'Amazon',
      _id: '5e4c4d18b7d75c37aac54a47',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/800eab29-cfdc-4f8f-a3f2-701f534f3901_amazon.png',
          },
          color: '#FF9900',
        },
      },
    },
    {
      count: 319,
      name: 'Paycom',
      _id: '5f5007f218d35500081c0c83',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/5df3854f-a624-42cc-a3a1-2d57762ef74a_paycom.png',
          },
          color: '#04924D',
        },
      },
    },
    {
      count: 304,
      name: 'Uber',
      _id: '5e06ef8bbf631d1a52bad8c0',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/19358678-c975-458c-849b-991e08a8dab9_Uber_logo_2018.svg',
          },
          color: '#000000',
        },
      },
    },
    {
      count: 301,
      name: 'Geico',
      _id: '612d2d9d453c410009d11435',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/e6e2c18e-e8a8-46f0-8821-1b44dff73aae_GEICO-symbol.jpeg',
          },
          color: '#114EAB',
        },
      },
    },
    {
      count: 242,
      name: 'Galaxy Donuts',
      _id: '612d6e9b2330fb0009c79ea8',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/27e748ba-77ce-4693-b16e-2fe8f52ec091_Screen Shot 2021-08-30 at 5.03.55 PM.png',
          },
          color: '#27DDDD',
        },
      },
    },
    {
      count: 209,
      name: 'DoorDash',
      _id: '5df8043e6e517060b0629b41',
      cache: {
        branding: {
          logo: {
            url: 'https://atomicfi-public-local.s3.amazonaws.com/8903fe39-e1e8-44ef-8a28-d564ada983b8_zap.svg',
            backgroundColor: '#FFFFFF',
          },
          color: '#FF3009',
        },
      },
    },
    {
      count: 201,
      name: 'Target',
      _id: '5f0df90a2031f400089944e4',
      cache: {
        branding: {
          logo: {
            url: 'https://cdn-public.atomicfi.com/6b3c3baf-0454-4f26-816e-88bb835db83b_Target-01.svg',
          },
          color: '#CC0003',
        },
      },
    },
  ]
}
