<template>
  <div>
    <EmulatorSectionHeader title="Demo" />
    <div class="section">
      <h6 class="header-pretitle mb-3">Customer name</h6>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Molecular Bank"
              type="text"
              v-model="settings.demo.customer.name"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <h6 class="header-pretitle mb-3">Logo</h6>
      <div class="row">
        <div class="col-auto">
          <div class="logo-wrap">
            <SecureImage
              :source="customerLogoThumbnail"
              class="avatar-img"
              :style="`background-color: '#FAFAFA';`"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input
              class="form-control"
              :class="{ 'is-invalid': imageUploadError }"
              type="file"
              @change="processFile($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import EmulatorSectionHeader from '@/components/Modules/Emulator/EmulatorSectionHeader'
import SecureImage from '@/components/MainContent/SecureImage'

export default {
  name: 'EmulatorDemo',
  components: {
    EmulatorSectionHeader,
    SecureImage,
  },
  data: () => ({
    customerLogo: undefined,
    imageUploadError: false,
  }),

  computed: {
    ...mapState('emulator', ['settings']),
    customerLogoThumbnail() {
      return (
        this.customerLogo ||
        'https://cdn-public.atomicfi.com/aa10e7d1-5d88-41d4-8fa7-ede77f4217ba_molecular-bank-logo.png'
      )
    },
  },
  methods: {
    ...mapMutations('emulator', ['setDemoCustomerLogo']),
    async processFile(event) {
      const file = event.target.files[0]
      // Larger image sizes base the Base64 encoded string too large to be rendered
      // 431 (Request Header Fields Too Large)
      if (file.size > 4000) {
        this.$toasted.error('Image is too large. Make sure it is no larger than 50px x 50px', {
          duration: 5000,
          position: 'bottom-right',
        })
        this.imageUploadError = true
        return
      } else {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.customerLogo = reader.result
          this.setDemoCustomerLogo({
            logo: reader.result,
          })
        }
        this.imageUploadError = false
        if (file) {
          reader.readAsDataURL(file)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.logo-wrap {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;

  .avatar-img {
    object-fit: contain;
    height: auto;
  }
}
</style>
