var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "navbar-light",
      class: { emulatorFullscreen: _vm.fullscreenMode },
      attrs: { toggleable: "lg" },
    },
    [
      _vm.isSandbox
        ? _c("div", { staticClass: "test-data" }, [
            _c(
              "span",
              { staticClass: "badge bg-warning-soft merge-into-header" },
              [_vm._v("Sandbox")]
            ),
          ])
        : _vm._e(),
      _vm.accessNotEnabled
        ? _c("div", { staticClass: "test-data" }, [
            _c(
              "span",
              { staticClass: "badge bg-warning-soft merge-into-header" },
              [
                _vm._v("Contact sales for production access. "),
                _c(
                  "a",
                  {
                    staticClass: "text-warning text-decoration-underline",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.switchToSandbox },
                  },
                  [_vm._v("Return to Sandbox")]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "navbar-brand" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [_c("b-navbar-toggle", { attrs: { target: "nav-collapse" } })],
                1
              ),
              _c("div", { staticClass: "col-auto" }, [
                _c("div", { staticClass: "mt-2" }, [_c("CustomerSwitcher")], 1),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row align-items-center gx-0 order-lg-3" }, [
            _c(
              "div",
              { staticClass: "col col-adjust d-flex align-items-center pe-3" },
              [_c("EnvironmentSwitcher")],
              1
            ),
            _c("div", { staticClass: "col col-adjust user-avatar" }, [
              _c("div", { staticClass: "navbar-user mt-2" }, [
                _c("div", { staticClass: "dropdown show" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle",
                      attrs: {
                        href: "#",
                        role: "button",
                        id: "userDropdown",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm.profile
                        ? _c(
                            "div",
                            { staticClass: "avatar avatar-sm avatar-online" },
                            [
                              _vm.profile.picture
                                ? _c("img", {
                                    staticClass: "avatar-img rounded-circle",
                                    attrs: {
                                      src: _vm.profile.picture,
                                      alt: "...",
                                    },
                                  })
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "avatar-title rounded-circle text-capitalize",
                                    },
                                    [_vm._v(_vm._s(_vm.initials))]
                                  ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-push-left",
                      attrs: { "aria-labelledby": "userDropdown" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [_vm._v("Logout")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "b-collapse",
            {
              staticClass: "order-0",
              attrs: { id: "nav-collapse", "is-nav": "" },
            },
            [
              _c("TopNavItems", {
                attrs: { activeCustomer: _vm.activeCustomer },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }