<template>
  <div>
    <div class="card">
      <div class="list-group">
        <div class="list-group-item" v-for="{ label, value } in outputs" :key="value">
          <div class="row align-items-center">
            <small class="col text-uppercase text-muted">
              {{ label }}
            </small>
            <div class="col-auto">
              {{ value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { identity, startCase, isNil } from 'lodash-es'

export default {
  name: 'TaskUserIdentity',
  props: [
    'firstName',
    'lastName',
    'dateOfBirth',
    'phone',
    'email',
    'ssn',
    'postalCode',
    'state',
    'city',
    'address',
  ],
  computed: {
    outputs() {
      const FIELDS_CONFIG = [
        { key: 'firstName' },
        { key: 'lastName' },
        {
          key: 'dateOfBirth',
          formatter: (dateOfBirth) => dateOfBirth ? this.$moment(dateOfBirth).format('MM/DD/YYYY') : undefined,
        },
        { key: 'phone', formatter: this.$options.filters.phone },
        { key: 'email' },
        { key: 'ssn' },
        { key: 'postalCode' },
        { key: 'state' },
        { key: 'city' },
        { key: 'address' },
      ]

      const sortedOutputs = FIELDS_CONFIG.map(({ key, formatter = identity }) => ({
        label: startCase(key),
        value: formatter(this[key]),
      })).filter(({ value }) => !isNil(value))

      return sortedOutputs
    },
  },
}
</script>
