import { ENVIRONMENTS } from '@/utils/constants'

export const ATOMIC_CUSTOMER_APIS = [
  process.env.VUE_APP_SANDBOX_ATOMIC_CUSTOMER_API,
  process.env.VUE_APP_PRODUCTION_ATOMIC_CUSTOMER_API,
]

export function getAtomicCustomerAPIFromEnvironment(environment) {
  return environment === ENVIRONMENTS.PRODUCTION
    ? process.env.VUE_APP_PRODUCTION_ATOMIC_CUSTOMER_API
    : process.env.VUE_APP_SANDBOX_ATOMIC_CUSTOMER_API
}
