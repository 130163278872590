<template>
  <div>
    <EmulatorSectionHeader title="Review Changes" />
    <div class="card" v-for="mutation in pendingMutations" :key="mutation.id">
      <div class="card-header pe-1 me-2">
        <div class="col">
          <h6 class="header-pretitle mb-2">{{ mutation.category }}</h6>
          <h4 class="card-header-title me-auto">{{ mutation.label }}</h4>
        </div>
        <div class="col-auto pe-0 me-0">
          <button
            @click="removeMutationHandler(mutation)"
            class="btn btn-link btn-sm"
            v-tooltip="'Remove'"
          >
            <XIcon size="14" />
          </button>
        </div>
      </div>
      <EmulatorColorMutationPreview
        v-if="mutation.dataType === EMULATOR_DATA_TYPES.COLOR"
        :mutation="mutation"
      />
      <EmulatorImageMutationPreview
        v-else-if="mutation.dataType === EMULATOR_DATA_TYPES.IMAGE"
        :mutation="mutation"
      />
      <EmulatorStringMutationPreview v-else :mutation="mutation" />
    </div>
  </div>
</template>

<script>
import EmulatorSectionHeader from '@/components/Modules/Emulator/EmulatorSectionHeader'
import EmulatorColorMutationPreview from '@/components/Modules/Emulator/EmulatorColorMutationPreview'
import EmulatorImageMutationPreview from '@/components/Modules/Emulator/EmulatorImageMutationPreview'
import EmulatorStringMutationPreview from '@/components/Modules/Emulator/EmulatorStringMutationPreview'
import { XIcon } from 'vue-feather-icons'
import { mapMutations, mapState } from 'vuex'
import { EMULATOR_EVENT_TYPES, EMULATOR_DATA_TYPES } from '@/utils/constants'
import { emitEmulatorEvent } from '@/utils/emulator'

export default {
  name: 'EmulatorMutationReview',
  components: {
    EmulatorSectionHeader,
    EmulatorColorMutationPreview,
    EmulatorImageMutationPreview,
    EmulatorStringMutationPreview,
    XIcon,
  },
  data: () => ({
    EMULATOR_DATA_TYPES,
  }),
  computed: {
    ...mapState('emulator', ['pendingMutations']),
  },
  methods: {
    ...mapMutations('emulator', ['removeMutation', 'setActiveNavItem']),
    removeMutationHandler(mutation) {
      emitEmulatorEvent({
        type: EMULATOR_EVENT_TYPES.UPDATE_CUSTOMIZATION,
        payload: {
          namespace: mutation.namespace,
          path: mutation.path,
          value: mutation.valueBeforeChanges,
        },
      })
      this.removeMutation(mutation.id)
      if (!this.pendingMutations.length) this.setActiveNavItem(undefined)
    },
  },
}
</script>
