var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("HasPermissionTable", {
    attrs: {
      label: _vm.userRole.label,
      rowData: _vm.availablePermissions,
      hasPermissionFunction: _vm.userRoleHasPermission,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }