var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ModalForm", {
    attrs: {
      id: "remove-secret-modal",
      title: "Remove Secret",
      onSubmit: _vm.deleteSecret,
      submitButtonText: "Remove Secret",
      submitButtonHidden: _vm.secretInUse,
    },
    on: { success: _vm.onSuccess, hidden: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var disabled = ref.disabled
          return [
            _vm.secretInUse
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      " This secret is currently being used to sign a webhook payload. Please remove it from the following endpoints before deleting. "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "list-group" },
                    _vm._l(_vm.endpointsThatUseSecret, function (endpoint) {
                      return _c(
                        "div",
                        { key: endpoint._id, staticClass: "list-group-item" },
                        [_vm._v(" " + _vm._s(endpoint.url) + " ")]
                      )
                    }),
                    0
                  ),
                ])
              : _c("validation-provider", {
                  attrs: {
                    rules: { is: _vm.secretNameToRemove, required: true },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var classes = ref.classes
                        var errors = ref.errors
                        var touched = ref.touched
                        return [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "removeSecretText" } },
                              [
                                _vm._v(
                                  ' Please type "' +
                                    _vm._s(_vm.secretNameToRemove) +
                                    '" to confirm you want to remove this secret '
                                ),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.removeSecretText,
                                  expression: "removeSecretText",
                                },
                              ],
                              staticClass: "form-control",
                              class: classes,
                              attrs: {
                                id: "removeSecretText",
                                type: "text",
                                placeholder: _vm.secretNameToRemove,
                                disabled: disabled,
                              },
                              domProps: { value: _vm.removeSecretText },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.removeSecretText = $event.target.value
                                },
                              },
                            }),
                            touched
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }