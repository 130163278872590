var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c("div", [
        _c("label", [_vm._v(_vm._s(_vm.limitLabel))]),
        _c("div", { staticClass: "limit-settings-row" }, [
          _c(
            "div",
            {
              staticClass: "input-group input-group-merge mb-2 row-amount",
              class: { "input-group-reverse": _vm.isFixed },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.amount,
                    expression: "amount",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "number",
                  placeholder: "No limit",
                  min: "0",
                  step: "1",
                  autocomplete: "off",
                  max: _vm.max,
                },
                domProps: { value: _vm.amount },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.amount = _vm._n($event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("div", { staticClass: "input-group-text px-2" }, [
                _vm._v(_vm._s(_vm.isFixed ? "$" : "%")),
              ]),
            ]
          ),
          _c("div", { staticClass: "row-required" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.type,
                  expression: "type",
                },
              ],
              staticClass: "form-check-input limit-check",
              attrs: {
                type: "checkbox",
                id: _vm.limitDirection + "-" + _vm.fractionalType + "-type",
              },
              domProps: {
                checked: Array.isArray(_vm.type)
                  ? _vm._i(_vm.type, null) > -1
                  : _vm.type,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.type,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.type = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.type = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.type = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: {
                  for: _vm.limitDirection + "-" + _vm.fractionalType + "-type",
                },
              },
              [_vm._v("Enforced")]
            ),
          ]),
        ]),
      ]),
      _c("small", { staticClass: "form-text text-muted" }, [
        _vm.amountIsNotSet
          ? _c("span", [_vm._v(" No limit will be enforced. ")])
          : _vm.isHardLimit
          ? _c("span", [
              _vm._v(" Users will be "),
              _c("span", { staticClass: "error-copy" }, [
                _vm._v("prevented from proceeding"),
              ]),
              _vm._v(
                " further if their entered amount " +
                  _vm._s(_vm.limitVerbiage) +
                  " this amount. "
              ),
            ])
          : _c("span", [
              _vm._v(" Users will be "),
              _c("span", { staticClass: "warn-copy" }, [_vm._v("warned")]),
              _vm._v(
                " if their entered amount " +
                  _vm._s(_vm.limitVerbiage) +
                  " this amount. "
              ),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }