var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group mb-0" }, [
    _vm.title
      ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c("div", { staticClass: "input-group" }, [
      _c("input", {
        staticClass: "form-control",
        attrs: { type: _vm.isSecret ? "password" : "text", disabled: "" },
        domProps: { value: _vm.value },
      }),
      _c(
        "button",
        {
          directives: [
            {
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: _vm.value,
              expression: "value",
              arg: "copy",
            },
            {
              name: "clipboard",
              rawName: "v-clipboard:success",
              value: _vm.onCopy,
              expression: "onCopy",
              arg: "success",
            },
            {
              name: "clipboard",
              rawName: "v-clipboard:error",
              value: _vm.onError,
              expression: "onError",
              arg: "error",
            },
          ],
          staticClass: "btn btn-outline-secondary",
          attrs: { type: "button", disabled: _vm.disabled },
        },
        [
          _c("CopyIcon", {
            staticClass: "me-1 p-0 m-0",
            attrs: { size: "14" },
          }),
          _vm._v(" Copy "),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }