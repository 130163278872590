var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "log-wrapper", on: { click: _vm.toggleShowDetails } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _vm.event.category === "finish"
            ? _c("span", { staticClass: "badge bg-success-soft" }, [
                _vm._v("finish"),
              ])
            : _vm.event.category === "close"
            ? _c("span", { staticClass: "badge bg-warning-soft" }, [
                _vm._v("close"),
              ])
            : _vm.event.category === "interaction"
            ? _c("span", { staticClass: "badge bg-primary-soft" }, [
                _vm._v("interaction"),
              ])
            : _vm.event.category === "data-request"
            ? _c("span", { staticClass: "badge bg-warning-soft" }, [
                _vm._v("data request"),
              ])
            : _vm._e(),
          _c("br"),
          _vm.event.payload && _vm.event.payload.name
            ? _c("small", { staticClass: "text-secondary" }, [
                _vm._v(_vm._s(_vm.event.payload.name)),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.showDetails && _vm.event.payload
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "alert alert-light mt-3 mb-0",
                attrs: { role: "alert" },
              },
              [_c("pre", [_vm._v(_vm._s(_vm.event.payload))])]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }