export const DISTRIBUTION_TYPES = {
  TOTAL: 'total',
  PERCENT: 'percent',
  FIXED: 'fixed',
} as const

export type DistributionType = (typeof DISTRIBUTION_TYPES)[keyof typeof DISTRIBUTION_TYPES]

export const DISTRIBUTION_LIMIT_TYPES = {
  WARN: 'warn',
  ERROR: 'error',
}

export const DEEPLINK_STEPS = {
  SEARCH_COMPANY: 'search-company',
  SEARCH_PAYROLL: 'search-payroll',
  LOGIN_COMPANY: 'login-company',
  LOGIN_PAYROLL: 'login-payroll',
}

export const ENVIRONMENTS = {
  PRODUCTION: 'production',
  SANDBOX: 'sandbox',
  TEST: 'test',
  DEVELOPMENT: 'development',
}

export const TASK_TYPES = {
  TEST: 'test',
  LIVE: 'live',
}

export const ACTION_TYPES = {
  PAUSE: 'pause',
  CLOSE: 'close',
  RESUME: 'resume',
  UPDATE: 'update',
}

export const EMULATOR_CUSTOMIZATION_TABS = {
  PHRASES: 'Phrases',
}

export const EMULATOR_DATA_TYPES = {
  COLOR: 'color',
  IMAGE: 'image',
  PHRASE: 'phrase',
  STRING: 'string',
}

export const EMULATOR_EVENT_TYPES = {
  UPDATE_CUSTOMIZATION: 'update-customization',
  UPDATE_DARK_MODE: 'update-dark-mode',
  UPDATE_LANGUAGE: 'update-language',
  GET_DATA_FROM_VUEX: 'get-data-from-vuex',
  GET_SUPPORTED_FONTS: 'get-supported-fonts',
  GET_DEFAULT_ICON: 'get-default-icon',
}

export const BANNO_STATUSES = {
  ACTIVE: 'active', // the Banno feature is enabled
  INACTIVE: 'inactive', // the Banno feature is not enabled, but used to be and configuration is still saved
  DISABLED: 'disabled', // the Banno feature is not enabled and no configuration is saved
}

export const CUSTOMER_STAGES = {
  AWAITING_APPROVAL: 'awaiting-approval',
  DEVELOPMENT: 'development',
  LIVE: 'live',
  TESTING: 'testing',
}

export const CHART_TYPES = {
  LINE: 'Line',
  BAR: 'Bar',
  DOUGHNUT: 'Doughnut',
}

export const TRANSACT_EVENTS = {
  // Non-page events
  INITIALIZED_TRANSACT: 'Initialized Transact',
  SUBMITTED_CREDENTIALS: 'Clicked Button To Start Authentication',
  TASK_DONE: 'Task Done',
  // Pages
  WELCOME: 'Viewed Welcome Page',
  SEARCH_COMPANY: 'Viewed Search By Company Page',
  SEARCH_PAYROLL: 'Viewed Search By Payroll Page',
  LOGIN: 'Viewed Login Page',
  DISTRIBUTION_CONFIRMATION: 'Viewed Distribution Confirmation Page',
  MFA: 'Viewed MFA Page',
  LOGIN_HELP: 'Viewed Login Help Page',
  DEPOSIT_OPTIONS: 'Viewed Select From Deposit Options Page',
  AUTHENTICATION_PAUSED: 'Viewed Authentication Paused Page',
  AUTHENTICATION_SUCCESS: 'Viewed Authentication Success Page',
  AUTHENTICATION_FAILED: 'Viewed Authentication Failed Page',
  MULTIPLE_ACCOUNTS: 'Viewed Select From Multiple Accounts Page',
  SEARCH_CONFIGURABLE_CONNECTOR: 'Viewed Search By Configurable Connector Page',
  SEARCH_CONFIGURABLE_CONNECTOR_TYPEAHEAD: 'Typeahead Search By Configurable Connector Page',
  MULTIPLE_PAYROLL_PROVIDERS: 'Viewed Select From Multiple Payroll Providers Page',
  ADP_INTERSTITIAL: 'Viewed Select From ADP Login Options Page',
  MAINTENANCE: 'Viewed Under Maintenance Page',
  LOGIN_RECOVERY: 'Viewed Login Recovery Page',
  FIXED_DEPOSIT: 'Viewed Fixed Deposit Amount Page',
  PERCENT_DEPOSIT: 'Viewed Percentage Deposit Amount Page',
  TERMS_REQUIRED: 'Viewed Terms And Conditions Are Required Page',
  TERMS_CONDITIONS: 'Viewed Terms And Conditions Page',
  EXPIRED_TOKEN: 'Viewed Expired Token Page',
  ACCESS_UNAUTHORIZED: 'Viewed Access Unauthorized Page',
  TASK_UNAUTHORIZED: 'Viewed Task Unauthorized Page',
  NETWORK_ERROR: 'Viewed Network Error Page',
  FAILED_TIMEOUT: 'Viewed Timeout Failure Page',
  INVALID_SDK: 'Viewed Invalid SDK Parameters Page',
  NO_CONNECTION: 'Viewed No Internet Connection Page',
  YOU_ARE_PROTECTED: 'Viewed Learn How You Are Protected Page',
  UPDATED_DEPOSIT_EXPERIENCE: 'Viewed Updated Deposit Experience',
  TASK_FAILED: 'Viewed Task Failed Page',
  FRACTIONAL_ERROR: 'Viewed Fractional Deposit Error Page',
  MANUAL_DEPOSIT_INSTRUCTIONS: 'Viewed Manual Deposit Instructions Page',
  HIGH_LATENCY: 'Viewed High Latency Page',
  TASK_PROGRESS: 'Viewed Task Progress Page',
  TASK_COMPLETED: 'Viewed Task Completed Page',
  EXIT_CONFIRMATION: 'Viewed Exit Confirmation Page',
}

export const PAYROLL_DATA_SNAPSHOT_TYPES = {
  DEPOSIT_ACCOUNTS: 'deposit-accounts',
  EMPLOYMENT: 'employment',
  IDENTITY: 'identity',
  INCOME: 'income',
  STATEMENTS: 'statements',
  TAXES: 'taxes',
  TIMESHEETS: 'timesheets',
}
