var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    { staticClass: "text-center" },
    [
      _vm.hasPermission
        ? _c("CheckIcon", { staticClass: "has-permission" })
        : _c("XIcon", { staticClass: "does-not-have-permission" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }