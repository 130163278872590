var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body text-center" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-xl-10" },
          [
            _c("img", {
              staticClass: "img-fluid mb-4",
              staticStyle: { "max-width": "320px" },
              attrs: {
                src: "/images/illustrations/dashboard-offstate.svg",
                alt: "...",
              },
            }),
            _c("h2", [_vm._v("Welcome to Atomic")]),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v("It looks like you're new. Ready to get started?"),
            ]),
            _vm.canViewCredentials
              ? _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary lift me-3",
                    attrs: { to: "/settings/credentials" },
                  },
                  [_vm._v("Get API keys")]
                )
              : _vm._e(),
            _vm.canUseEmulator
              ? _c(
                  "router-link",
                  {
                    staticClass: "btn btn-outline lift btn-outline-primary",
                    attrs: { to: "/emulator" },
                  },
                  [_vm._v("Try the Emulator")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }