var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ModalForm",
    {
      attrs: {
        id: "select-environment-modal",
        title: "Select Environment",
        onSubmit: _vm.submit,
        submitButtonText: "Publish",
      },
      on: { hidden: _vm.onHide },
    },
    [
      _c(
        "validation-provider",
        { attrs: { name: "Select Environment", rules: "required" } },
        _vm._l(_vm.options, function (option) {
          return _c(
            "div",
            { key: option, staticClass: "form-group form-check" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.environmentsToUpdate,
                    expression: "environmentsToUpdate",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", id: "select-environment-" + option },
                domProps: {
                  value: option,
                  checked: Array.isArray(_vm.environmentsToUpdate)
                    ? _vm._i(_vm.environmentsToUpdate, option) > -1
                    : _vm.environmentsToUpdate,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.environmentsToUpdate,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = option,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.environmentsToUpdate = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.environmentsToUpdate = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.environmentsToUpdate = $$c
                    }
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "form-check-label text-capitalize",
                  attrs: { for: "select-environment-" + option },
                },
                [_vm._v(_vm._s(option))]
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }