var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EmulatorSectionHeader", { attrs: { title: "Fonts" } }),
      _vm._l(_vm.fontsToCustomize, function (font) {
        return _c("EmulatorCustomizationDropdown", {
          key: font.path,
          attrs: {
            label: font.label,
            namespace: font.namespace,
            path: font.path,
            category: font.label + " Font",
            options: _vm.fontNameList,
            selectionChangedHandler: _vm.onFontCustomization,
            dataType: "string",
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }