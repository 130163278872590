<template>
  <MiniMetric
    title="New Monitored Accounts"
    :value="monitoredAccounts.toLocaleString()"
    :isLoading="!isDataReady"
  >
    <template #visual v-if="isDataReady && increasePercentage">
      <span class="badge bg-success-soft mt-n1">+{{ increasePercentage }}%</span>
    </template>
    <template #icon>
      <ActivityIcon size="20" class="h2 text-muted mb-0" />
    </template>
  </MiniMetric>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ActivityIcon } from 'vue-feather-icons'
import MiniMetric from '@/components/Modules/Activity/Elements/MiniMetric'

export default {
  name: 'MonitoredAccounts',
  components: {
    ActivityIcon,
    MiniMetric,
  },
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('activity', ['linkedAccounts', 'activeDateRangeOption', 'sampleDataMode']),
    monitoredAccounts() {
      if (this.sampleDataMode) return 11592
      if (this.isLoading || !this.linkedAccounts?.withinRange) return 0
      return this.linkedAccounts?.withinRange
    },
    increasePercentage() {
      if (this.sampleDataMode) return 42
      if (this.isLoading || !this.linkedAccounts.total) return
      return Math.ceil((this.linkedAccounts.withinRange / this.linkedAccounts.total) * 100)
    },
    isDataReady() {
      return !!(!this.isLoading || this.sampleDataMode)
    },
  },
  methods: {
    ...mapActions('activity', ['getLinkedAccounts']),
    async getData() {
      this.isLoading = true
      await this.getLinkedAccounts()
      this.isLoading = false
    },
  },
  mounted() {
    this.getData()
  },
  data: () => ({
    isLoading: false,
  }),
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.getData()
    },
  },
}
</script>
