var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-footer d-flex justify-content-between align-items-center flex-wrap",
    },
    [
      _c(
        "div",
        { staticClass: "tasks-count mb-2 mb-md-0" },
        [
          _vm.totalResults === 0
            ? [_vm._v(" 0 tasks ")]
            : [
                _vm._v(
                  " " +
                    _vm._s(_vm.startTaskIndex) +
                    "-" +
                    _vm._s(_vm.endTaskIndex) +
                    " of " +
                    _vm._s(_vm.totalResults) +
                    " tasks "
                ),
              ],
        ],
        2
      ),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.pages.length > 0
          ? _c(
              "ul",
              {
                staticClass:
                  "list-pagination-prev pagination pagination-tabs card-pagination mb-0",
              },
              [
                _c("li", { staticClass: "page-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link ps-2 pe-2",
                      class: { disabled: _vm.isFirstPage },
                      attrs: { "data-test-id": "data-pager-prev", href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.previousPage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("ArrowLeftIcon", {
                        staticClass: "me-1",
                        attrs: { size: "16" },
                      }),
                      _vm._v(" Prev "),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm.showPages
          ? _c(
              "ul",
              {
                staticClass:
                  "list-pagination pagination pagination-tabs card-pagination mb-0",
              },
              _vm._l(_vm.pages, function (page) {
                return _c(
                  "li",
                  {
                    key: page,
                    staticClass: "page-item",
                    class: { active: page === _vm.currentPage },
                    attrs: { "data-test-id": "data-pager-page-" + page },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.gotoPage(page)
                          },
                        },
                      },
                      [_vm._v(_vm._s(page))]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm.pages.length > 0
          ? _c(
              "ul",
              {
                staticClass:
                  "list-pagination-next pagination pagination-tabs card-pagination mb-0",
              },
              [
                _c("li", { staticClass: "page-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link ps-2 pe-0",
                      class: { disabled: _vm.isLastPage },
                      attrs: { "data-test-id": "data-pager-next", href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.nextPage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(" Next "),
                      _c("ArrowRightIcon", {
                        staticClass: "ms-1",
                        attrs: { size: "16" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "tasks-per-page ms-4" }, [
          _c(
            "label",
            {
              staticClass: "visually-hidden",
              attrs: { for: "tasks-per-page" },
            },
            [_vm._v("Tasks per page")]
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.localItemsPerPage,
                  expression: "localItemsPerPage",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-select form-select-sm",
              attrs: { id: "tasks-per-page", disabled: _vm.loading },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.localItemsPerPage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onItemsPerPageChange,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "25" } }, [_vm._v("25 per page")]),
              _c("option", { attrs: { value: "50" } }, [_vm._v("50 per page")]),
              _c("option", { attrs: { value: "100" } }, [
                _vm._v("100 per page"),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }