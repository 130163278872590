var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fields" },
    _vm._l(_vm.fieldDefinitions, function (ref) {
      var keyLevel0 = ref.key
      var label = ref.label
      var fieldsLevel1 = ref.fields
      var helperText = ref.helperText
      var disabled = ref.disabled
      return _c(
        "div",
        { key: keyLevel0 },
        [
          _c("Checkbox", {
            attrs: {
              label: _vm.getLabel(label, keyLevel0),
              value: _vm.fieldIsSelected(keyLevel0),
              helperText: helperText,
              disabled:
                disabled || _vm.disableAll || _vm.fieldIsRequired(keyLevel0),
              required: _vm.fieldIsRequired(keyLevel0),
            },
            on: {
              input: function ($event) {
                fieldsLevel1
                  ? _vm.toggleAllFields($event, keyLevel0)
                  : _vm.toggleField($event, keyLevel0)
              },
            },
          }),
          _vm._l(fieldsLevel1, function (ref) {
            var keyLevel1 = ref.key
            var label = ref.label
            var fieldsLevel2 = ref.fields
            var helperText = ref.helperText
            var disabled = ref.disabled
            return _c(
              "div",
              { key: keyLevel1 },
              [
                _c("Checkbox", {
                  key: keyLevel1,
                  staticClass: "nested-level-1",
                  attrs: {
                    label: _vm.getLabel(label, keyLevel1),
                    value: _vm.fieldIsSelected([keyLevel0, keyLevel1]),
                    helperText: helperText,
                    disabled:
                      disabled ||
                      _vm.disableAll ||
                      _vm.fieldIsRequired([keyLevel0, keyLevel1]),
                    required: _vm.fieldIsRequired([keyLevel0, keyLevel1]),
                  },
                  on: {
                    input: function ($event) {
                      fieldsLevel2
                        ? _vm.toggleAllFields($event, [keyLevel0, keyLevel1])
                        : _vm.toggleField($event, [keyLevel0, keyLevel1])
                    },
                  },
                }),
                fieldsLevel2
                  ? _c(
                      "div",
                      _vm._l(fieldsLevel2, function (ref) {
                        var keyLevel2 = ref.key
                        var label = ref.label
                        var fieldsLevel3 = ref.fields
                        var helperText = ref.helperText
                        var disabled = ref.disabled
                        return _c("Checkbox", {
                          key: keyLevel2,
                          staticClass: "nested-level-2",
                          attrs: {
                            label: _vm.getLabel(label, keyLevel2),
                            value: _vm.fieldIsSelected([
                              keyLevel0,
                              keyLevel1,
                              keyLevel2,
                            ]),
                            helperText: helperText,
                            disabled:
                              disabled ||
                              _vm.disableAll ||
                              _vm.fieldIsRequired([
                                keyLevel0,
                                keyLevel1,
                                keyLevel2,
                              ]),
                            required: _vm.fieldIsRequired([
                              keyLevel0,
                              keyLevel1,
                              keyLevel2,
                            ]),
                          },
                          on: {
                            input: function ($event) {
                              fieldsLevel3
                                ? _vm.toggleAllFields($event, [
                                    keyLevel0,
                                    keyLevel1,
                                    keyLevel2,
                                  ])
                                : _vm.toggleField($event, [
                                    keyLevel0,
                                    keyLevel1,
                                    keyLevel2,
                                  ])
                            },
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }