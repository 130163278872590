<template>
  <div>
    <div v-if="isLoading" class="col-12 text-center mt-3 mb-3">
      <div class="spinner-border text-primary" role="status" />
    </div>
    <div v-else>
      <div class="card">
        <div class="card-header">
          <h4 class="card-header-title">API Key</h4>
        </div>
        <div class="card-body">
          <CopyableInput :value="activeCustomer.credentials.key" @copy="onApiKeyCopy" />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-header-title">Secrets</h4>
          <button
            type="button"
            v-b-modal="'add-secret-modal'"
            class="btn btn-primary btn-sm"
            :disabled="!canAddSecret"
            :title="canAddSecret ? undefined : `You may only add up to ${MAX_SECRETS} secrets.`"
          >
            <PlusIcon size="14" class="me-1 p-0 m-0" />
            Add
          </button>
        </div>
        <div class="card-body">
          <div
            v-for="(secret, idx) in activeCustomer.credentials.secrets"
            class="row align-items-center"
            :class="{ 'mb-5': idx < activeCustomer.credentials.secrets.length - 1 }"
            :key="secret._id"
          >
            <div v-if="accessNotEnabled" class="col">
              <span class="badge bg-warning-soft">Production access required</span>
            </div>
            <div v-if="!accessNotEnabled" class="col-2">
              <span>{{ getSecretName(secret) }}</span>
            </div>
            <div v-if="!accessNotEnabled" class="col">
              <CopyableInput
                :value="secret.token"
                isSecret="true"
                :disabled="accessNotEnabled"
                @copy="() => onApiSecretCopy(secret)"
              />
            </div>
            <div
              v-if="!accessNotEnabled && activeCustomer.credentials.secrets.length > 1"
              class="col-auto"
            >
              <button
                type="button"
                v-b-modal="'remove-secret-modal'"
                @click="openDeleteSecret(secret)"
                class="btn btn-light"
              >
                <TrashIcon size="14" class="p-0 m-0" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <p class="text-muted mb-4">
        Need help? See the <a href="https://docs.atomicfi.com" target="_blank">documentation</a> to
        get started on your integration, or
        <a href="mailto:support@atomicfi.com">send us a message</a>.
      </p>

      <SettingsCredentialsAddSecretModal />
      <SettingsCredentialsRemoveSecretModal :secretToRemove="secretToRemove" />
    </div>
  </div>
</template>

<script>
import CopyableInput from '@/components/MainContent/CopyableInput'
import SettingsBase from './SettingsBase'
import SettingsCredentialsAddSecretModal from './SettingsCredentialsAddSecretModal'
import SettingsCredentialsRemoveSecretModal from './SettingsCredentialsRemoveSecretModal'
import { mapActions, mapGetters } from 'vuex'
import { PlusIcon, TrashIcon } from 'vue-feather-icons'

const MAX_SECRETS = 10

export default {
  name: 'SettingsCredentials',
  extends: SettingsBase,
  components: {
    CopyableInput,
    PlusIcon,
    SettingsCredentialsAddSecretModal,
    SettingsCredentialsRemoveSecretModal,
    TrashIcon,
  },
  data: () => ({
    isLoading: true,
    MAX_SECRETS,
    secretToRemove: undefined,
  }),
  computed: {
    ...mapGetters('environment', ['accessNotEnabled']),
    canAddSecret() {
      return this.activeCustomer.credentials.secrets.length < MAX_SECRETS
    },
  },
  methods: {
    ...mapActions('customer', ['getCustomerCredentials']),
    getSecretName(secret) {
      return secret.name ?? 'Default'
    },
    onApiKeyCopy() {
      this.$analytics.track({
        event: 'Copy API Key',
      })
    },
    onApiSecretCopy(secret) {
      this.$analytics.track({
        event: 'Copy API Secret',
        payload: {
          secretName: this.getSecretName(secret),
        },
      })
    },
    openDeleteSecret(secret) {
      this.secretToRemove = secret
    },
  },
  async mounted() {
    await this.getCustomerCredentials({ includeKey: true, includeToken: true })
    this.isLoading = false
  },
}
</script>

<style scoped>
[disabled] {
  cursor: not-allowed;
}
</style>
