var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "card text-left",
      class: { active: _vm.active },
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.onSelect(_vm.scopeOption)
        },
      },
    },
    [
      _vm.toggleable
        ? _c(
            "div",
            {
              staticClass:
                "avatar avatar-xs select-indicator d-flex align-items-center justify-content-center",
            },
            [
              _c("CheckIcon", {
                staticClass: "text-white",
                attrs: { size: "14" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          !_vm.hideIcon
            ? _c("div", { staticClass: "col-auto" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "avatar d-flex align-items-center justify-content-center",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "/images/emulator/" + _vm.scopeOption.name + ".svg",
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "col ms-n2" },
            [
              _c("h4", { staticClass: "mb-3" }, [
                _vm._v(_vm._s(_vm.scopeOption.label)),
              ]),
              _vm._l(_vm.scopeOption.useCases, function (useCase) {
                return _c(
                  "span",
                  {
                    key: useCase,
                    staticClass: "badge bg-secondary-soft m-2 ms-0",
                  },
                  [_vm._v(_vm._s(useCase))]
                )
              }),
            ],
            2
          ),
          !_vm.toggleable
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("ArrowRightIcon", {
                    staticClass: "ms-sm-2 text-muted scope-button-icon",
                    attrs: { size: "15" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }