exports.failReasons = {
  'account-lockout': {
    description:
      'The account is locked out, most likely the end user has had too many failed attempts.',
    miniTitle: 'Lockout',
    class: 'primary',
  },
  'account-setup-incomplete': {
    description:
      "The user's account setup is not complete and will require additional information from the user.",
    miniTitle: 'Not Setup',
    class: 'primary',
  },
  'account-swapping-detected': {
    description: 'We detected account swapping activity from the user.',
    miniTitle: 'Fraud',
    class: 'primary',
    includeInForm: false,
  },
  'account-unusable': {
    description: "The user's bank account is unusable for the selected product or use case.",
    miniTitle: 'Unusable',
    class: 'primary',
  },
  'bad-credentials': {
    description: 'Either the username or password was incorrect.',
    miniTitle: 'Bad Credentials',
    class: 'primary',
  },
  'connection-error': {
    description:
      'A network error occurred which caused the connection between our system and the payroll system to be lost.',
    miniTitle: 'Network Failure',
    class: 'primary',
    requiresDescription: true,
  },
  'distribution-not-supported': {
    description:
      'The payroll system did not support the type of distribution requested. For example, the user attempted to allocate a percentage of their paycheck, but is only eligible for fixed amounts and remainder/net balance.',
    miniTitle: 'Unsupported Distribution',
    class: 'primary',
    requiresDescription: true,
  },
  'enrolled-in-paycard': {
    description:
      'The user is enrolled in a paycard program instead of direct deposit via their bank.',
    miniTitle: 'Paycard Enrollment',
    class: 'primary',
  },
  'high-latency': {
    description:
      'When the user’s device is experiencing high latency likely due to a poor internet connection.',
    miniTitle: 'High Latency',
    class: 'primary',
  },
  'no-data-found': {
    description: 'No verify data was found for the user.',
    miniTitle: 'No Data Found',
    class: 'primary',
  },
  expired: {
    description: "The user's password has expired and they must create a new one.",
    miniTitle: 'Password Expired',
    class: 'primary',
  },
  'product-not-supported': {
    description:
      'The payroll system did not allow the action to be taken. Many payroll systems allow HR to customize what is allowed in their system. Direct Deposit may be allowed by a payroll system, but may be disallowed by a specific employer. Therefore, when an employee of that company goes to set up Direct Deposit it is rejected, resulting in this error code.',
    miniTitle: 'Unsupported Product',
    class: 'primary',
    requiresDescription: true,
  },
  'routing-number-not-supported': {
    description: 'The account did not support the routing number entered.',
    miniTitle: 'Routing Number Issue',
    class: 'primary',
  },
  'session-timeout': {
    description: 'The auth session within the payroll system timed out.',
    miniTitle: 'Session Timeout',
    class: 'secondary',
  },
  'system-unavailable': {
    description:
      'The system was unavailable. For example, the site is undergoing maintenance or it is outside the window of scheduled availability for the site.',
    miniTitle: 'System Unavailable',
    class: 'primary',
    requiresDescription: true,
  },
  'transaction-pending': {
    description:
      'There is a deposit allocation already in progress and additional updates cannot be made at this time. For example, if an employer has an approval process in place, they may disallow modifications until the update has been processed.',
    miniTitle: 'Transaction Pending',
    class: 'primary',
  },
  'unknown-failure': {
    description: 'We encountered an unexpected error.',
    miniTitle: 'Unknown Failure',
    class: 'primary',
    requiresDescription: true,
  },
  'user-abandon': {
    description: 'The user was asked an MFA question, but did not answer the question.',
    miniTitle: 'User Abandon',
    class: 'primary',
  },
  'work-status-terminated': {
    description:
      "The task could not be completed because the user's employment has been terminated.",
    miniTitle: 'Work Status Terminated',
    class: 'primary',
  },
}
