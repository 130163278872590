import { DistributionType } from '@/utils/constants'
import { WEBHOOK_EVENT_TYPES, WebhookEventType, WEBHOOK_EVENTS } from '@atomicfi/webhook-shared'
import { Product, PRODUCTS, Scope } from '@atomicfi/constants-shared'

const { test, ...relevantWebhookEvents } = WEBHOOK_EVENTS

/** Excludes webhook events that are irrelevant to customers. */
export const webhookEvents = relevantWebhookEvents

type WebhookEvents = typeof webhookEvents

export function filterWebhookEventsByScopes(
  webhookEvents: Partial<WebhookEvents>,
  scopes?: Scope[]
): Partial<WebhookEvents> {
  if (!scopes || !scopes.length) {
    return webhookEvents
  }

  const entries = Object.entries(webhookEvents)

  const filteredByScopes = entries.filter(([, webhookEventProperties]) =>
    webhookEventProperties.scopes.some((scope) => scopes.includes(scope))
  )

  return Object.fromEntries(filteredByScopes)
}

interface CreateSampleEventPayloadParams {
  eventType: WebhookEventType
  productType: Product
  distributionType: DistributionType
}

export function createSampleEventPayload({
  eventType,
  productType,
  distributionType,
}: CreateSampleEventPayloadParams) {
  const data =
    eventType === WEBHOOK_EVENT_TYPES.TASK_STATUS_UPDATED
      ? {
          previousStatus: 'processing',
          status: 'completed',
          ...(productType === PRODUCTS.DEPOSIT ? { distributionType: distributionType } : {}),
          ...(productType === PRODUCTS.DEPOSIT && distributionType !== 'total'
            ? { distributionAmount: 25 }
            : {}),
        }
      : null

  const product = [
    WEBHOOK_EVENT_TYPES.TASK_STATUS_UPDATED,
    WEBHOOK_EVENT_TYPES.TASK_AUTHENTICATION_STATUS_UPDATED,
  ].includes(eventType as any)
    ? productType
    : null

  return WEBHOOK_EVENTS[eventType].samplePayload({
    ...(product && { product }),
    ...(data && { data }),
  })
}

/**
 * Sorts webhook event types to match the arbitrary order of
 * `WEBHOOK_EVENT_TYPES` from `@atomicfi/constants-shared`.
 */
export function sortWebhookEventTypesCanonically(webhookEventTypes: WebhookEventType[]) {
  return [...webhookEventTypes].sort((a, b) => {
    const canonicalWebhookEventTypes = Object.values(WEBHOOK_EVENT_TYPES)
    return canonicalWebhookEventTypes.indexOf(a) - canonicalWebhookEventTypes.indexOf(b)
  })
}
