<template>
  <Wrapper :fullscreen="true">
    <Conversion />
  </Wrapper>
</template>

<script>
import Conversion from '@/components/Modules/Activity/Conversion'

export default {
  name: 'Activity',
  data() {
    return {
      profile: this.$auth.user,
    }
  },
  methods: {},
  components: { Conversion },
}
</script>
