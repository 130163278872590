<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="avatar avatar-md">
            <img :src="user.picture" alt="..." class="avatar-img rounded-circle" />
          </div>
        </div>
        <div class="col ms--2">
          <h4 class="card-title mb-1">
            <span class="text-capitalize">{{ user.givenName }} {{ user.familyName }}</span>
            <span class="text-muted ms-1" v-if="isAuthenticatedUser"> (You)</span>
          </h4>
          <p class="card-text small mb-1">{{ userRole }}</p>
        </div>
        <div class="col-auto text-end">
          <span class="badge bg-success-soft" v-if="user.emailVerified">Verified</span>
          <span class="badge bg-secondary-soft" v-else>Invited</span>
        </div>
        <div class="col-1">
          <div class="dropdown text-center">
            <a
              href="#!"
              class="dropdown-ellipses dropdown-toggle"
              role="button"
              data-toggle="dropdown"
            >
              <MoreVerticalIcon class="vertical-icon" size="16" />
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a
                @click="$emit('viewRolePermissions', user)"
                href="javascript:"
                class="dropdown-item"
              >
                View Permissions
              </a>
              <a
                v-if="canEditRole"
                @click="$emit('editRole', user)"
                href="javascript:"
                class="dropdown-item"
              >
                Edit Role
              </a>
              <a
                v-if="canDeleteUser"
                @click="$emit('delete', user)"
                href="javascript:"
                class="dropdown-item text-danger"
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentUserMaxRoleLevel, getUserRoleLevel, hasPermissions } from '@/lib/authorization'
import { PERMISSIONS } from '@/lib/permissions'
import { MoreVerticalIcon } from 'vue-feather-icons'
import { startCase } from 'lodash-es'
import { mapGetters } from 'vuex'

export default {
  name: 'UserCard',
  components: { MoreVerticalIcon },
  props: ['user'],
  computed: {
    ...mapGetters('customer', ['managesRolesInIdp']),
    currentUserMaxRoleLevel() {
      return getCurrentUserMaxRoleLevel()
    },
    userRole() {
      return startCase(this.user.roles[0])
    },
    canDeleteUser() {
      return hasPermissions(PERMISSIONS.DELETE_USERS) && !this.isAuthenticatedUser
    },
    canEditRole() {
      return (
        hasPermissions(PERMISSIONS.EDIT_USER_ROLES) &&
        this.currentUserMaxRoleLevel >= getUserRoleLevel(this.user.roles[0]) &&
        !this.isAuthenticatedUser &&
        !this.managesRolesInIdp
      )
    },
    isAuthenticatedUser() {
      return this.$auth.user.sub === this.user.userId
    },
  },
}
</script>

<style scoped lang="scss">
.vertical-icon {
  color: var(--bs-primary);
}

.text-danger:hover {
  color: #b82c44 !important;
}
</style>
