var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading
      ? _c("div", { staticClass: "col-12 text-center mt-3 mb-3" }, [
          _c("div", {
            staticClass: "spinner-border text-primary",
            attrs: { role: "status" },
          }),
        ])
      : _c(
          "div",
          [
            _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("CopyableInput", {
                    attrs: { value: _vm.activeCustomer.credentials.key },
                    on: { copy: _vm.onApiKeyCopy },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("h4", { staticClass: "card-header-title" }, [
                  _vm._v("Secrets"),
                ]),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal",
                        value: "add-secret-modal",
                        expression: "'add-secret-modal'",
                      },
                    ],
                    staticClass: "btn btn-primary btn-sm",
                    attrs: {
                      type: "button",
                      disabled: !_vm.canAddSecret,
                      title: _vm.canAddSecret
                        ? undefined
                        : "You may only add up to " +
                          _vm.MAX_SECRETS +
                          " secrets.",
                    },
                  },
                  [
                    _c("PlusIcon", {
                      staticClass: "me-1 p-0 m-0",
                      attrs: { size: "14" },
                    }),
                    _vm._v(" Add "),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                _vm._l(
                  _vm.activeCustomer.credentials.secrets,
                  function (secret, idx) {
                    return _c(
                      "div",
                      {
                        key: secret._id,
                        staticClass: "row align-items-center",
                        class: {
                          "mb-5":
                            idx <
                            _vm.activeCustomer.credentials.secrets.length - 1,
                        },
                      },
                      [
                        _vm.accessNotEnabled
                          ? _c("div", { staticClass: "col" }, [
                              _c(
                                "span",
                                { staticClass: "badge bg-warning-soft" },
                                [_vm._v("Production access required")]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.accessNotEnabled
                          ? _c("div", { staticClass: "col-2" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getSecretName(secret))),
                              ]),
                            ])
                          : _vm._e(),
                        !_vm.accessNotEnabled
                          ? _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("CopyableInput", {
                                  attrs: {
                                    value: secret.token,
                                    isSecret: "true",
                                    disabled: _vm.accessNotEnabled,
                                  },
                                  on: {
                                    copy: function () {
                                      return _vm.onApiSecretCopy(secret)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.accessNotEnabled &&
                        _vm.activeCustomer.credentials.secrets.length > 1
                          ? _c("div", { staticClass: "col-auto" }, [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal",
                                      value: "remove-secret-modal",
                                      expression: "'remove-secret-modal'",
                                    },
                                  ],
                                  staticClass: "btn btn-light",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDeleteSecret(secret)
                                    },
                                  },
                                },
                                [
                                  _c("TrashIcon", {
                                    staticClass: "p-0 m-0",
                                    attrs: { size: "14" },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                0
              ),
            ]),
            _vm._m(1),
            _c("SettingsCredentialsAddSecretModal"),
            _c("SettingsCredentialsRemoveSecretModal", {
              attrs: { secretToRemove: _vm.secretToRemove },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-header-title" }, [_vm._v("API Key")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-muted mb-4" }, [
      _vm._v(" Need help? See the "),
      _c(
        "a",
        { attrs: { href: "https://docs.atomicfi.com", target: "_blank" } },
        [_vm._v("documentation")]
      ),
      _vm._v(" to get started on your integration, or "),
      _c("a", { attrs: { href: "mailto:support@atomicfi.com" } }, [
        _vm._v("send us a message"),
      ]),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }