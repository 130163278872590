<template>
  <div>
    <h6 class="header-pretitle mb-3">{{ label }}</h6>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmulatorCustomizationInputWrapper',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
