import { getInstance } from '@/plugins/auth'
import store from '@/store'

import { Permission, PERMISSIONS, PRODUCTION_ONLY_PERMISSIONS } from './permissions'
import { Scope } from '@atomicfi/constants-shared'
import { max } from 'lodash-es'

type Role = 'admin' | 'production_developer' | 'sandbox_developer' | 'analyst' | 'designer'

interface RoleConfig {
  value: Role
  label: string
  level: number
  permissions: {
    production: Permission[]
    sandbox: Permission[]
  }
}

export const roles: RoleConfig[] = [
  {
    value: 'admin',
    label: 'Admin',
    level: 5,
    permissions: {
      production: Object.values(PERMISSIONS),
      sandbox: Object.values(PERMISSIONS).filter(
        (permission) => !PRODUCTION_ONLY_PERMISSIONS.includes(permission as any)
      ),
    },
  },
  {
    value: 'production_developer',
    label: 'Production Developer',
    level: 4,
    permissions: {
      production: [
        PERMISSIONS.SAVE_TRANSACT_CUSTOMIZATION,
        PERMISSIONS.SEARCH_TASKS,
        PERMISSIONS.USE_EMULATOR,
        PERMISSIONS.VIEW_DESIGNS,
        PERMISSIONS.VIEW_TASK,
        PERMISSIONS.MANAGE_CREDENTIALS,
        PERMISSIONS.MANAGE_WEBHOOKS,
      ],
      sandbox: [
        PERMISSIONS.ADD_USERS,
        PERMISSIONS.SAVE_TRANSACT_CUSTOMIZATION,
        PERMISSIONS.EDIT_USER_ROLES,
        PERMISSIONS.MANAGE_CREDENTIALS,
        PERMISSIONS.MANAGE_FEATURES,
        PERMISSIONS.MANAGE_INSTITUTION_SETTINGS,
        PERMISSIONS.MANAGE_WEBHOOKS,
        PERMISSIONS.SEARCH_TASKS,
        PERMISSIONS.USE_EMULATOR,
        PERMISSIONS.VIEW_DESIGNS,
        PERMISSIONS.VIEW_TASK,
      ],
    },
  },
  {
    value: 'sandbox_developer',
    label: 'Sandbox Developer',
    level: 3,
    permissions: {
      production: [],
      sandbox: [
        PERMISSIONS.ADD_USERS,
        PERMISSIONS.EDIT_USER_ROLES,
        PERMISSIONS.MANAGE_CREDENTIALS,
        PERMISSIONS.MANAGE_FEATURES,
        PERMISSIONS.MANAGE_INSTITUTION_SETTINGS,
        PERMISSIONS.MANAGE_WEBHOOKS,
        PERMISSIONS.SAVE_TRANSACT_CUSTOMIZATION,
        PERMISSIONS.SEARCH_TASKS,
        PERMISSIONS.USE_EMULATOR,
        PERMISSIONS.VIEW_DESIGNS,
        PERMISSIONS.VIEW_TASK,
      ],
    },
  },
  {
    value: 'analyst',
    label: 'Analyst',
    level: 2,
    permissions: {
      production: [
        PERMISSIONS.SEARCH_TASKS,
        PERMISSIONS.USE_EMULATOR,
        PERMISSIONS.VIEW_DESIGNS,
        PERMISSIONS.VIEW_TASK,
      ],
      sandbox: [
        PERMISSIONS.SEARCH_TASKS,
        PERMISSIONS.USE_EMULATOR,
        PERMISSIONS.VIEW_DESIGNS,
        PERMISSIONS.VIEW_TASK,
      ],
    },
  },
  {
    value: 'designer',
    label: 'Designer',
    level: 1,
    permissions: {
      production: [PERMISSIONS.USE_EMULATOR, PERMISSIONS.VIEW_DESIGNS],
      sandbox: [PERMISSIONS.USE_EMULATOR, PERMISSIONS.VIEW_DESIGNS],
    },
  },
]

export function getAvailableUserRoles(level: number) {
  const currentUserMaxRoleLevel = getCurrentUserMaxRoleLevel()

  if (!currentUserMaxRoleLevel) return []

  if (level) {
    return roles.filter((role) => {
      return role.level <= currentUserMaxRoleLevel && role.level !== level
    })
  }
  return roles.filter((role) => role.level <= currentUserMaxRoleLevel)
}

export function getCurrentUserMaxRoleLevel() {
  return max(getUserRoleDetails().map(({ level }) => level))
}

export function getUserRoleDetails() {
  const authService = getInstance()
  const userRoles = authService.getRoles() || []

  return roles.filter((role) => userRoles.includes(role.value))
}

export function getUserRoleLevel(userRole: Role) {
  return roles.filter((role) => role.value === userRole).map(({ level }) => level)[0]
}

export function hasScope(scope: Scope) {
  return store.getters['customer/scopes'].includes(scope)
}

export function hasFinishedOnboarding() {
  return !!store.getters['customer/scopes']?.length
}

export function hasPermissions(...permissions: Permission[]): boolean {
  const userPermissions = _getUserPermissions()
  return permissions.every((p) => userPermissions.includes(p))
}

export function hasAPermission(...permissions: Permission[]): boolean {
  const userPermissions = _getUserPermissions()
  return permissions.some((p) => userPermissions.includes(p))
}

export function hasProductionAccess() {
  return getUserRoleDetails().some((role) => role.permissions.production.length > 0)
}

function _getUserPermissions() {
  return getUserRoleDetails().flatMap(
    (role) =>
      role.permissions[store.state.environment.activeEnvironment as keyof RoleConfig['permissions']]
  )
}
