<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Security</h4>
    </div>
    <div class="card-body">
      <h4>Signature verification</h4>
      <p class="mb-5">
        To validate a webhook request came from Atomic, we suggest verifying the payloads as stated
        in the
        <a
          href="https://docs.atomicfi.com/guides/webhooks/how-to-secure-webhook-endpoints"
          target="_blank"
          >documentation</a
        >.
      </p>
      <h4>IP Allowlisting</h4>
      <p>
        You may additionally only allow traffic into your webhook endpoints from the following IP
        addresses:
      </p>
      <div class="row mb-6">
        <div class="col-12 col-lg-6 mb-3">
          <h5>Production</h5>
          <pre>
34.224.55.47
54.160.71.206
</pre
          >
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <h5>Sandbox</h5>
          <pre class="mb-0">
34.203.158.15
52.205.207.85
</pre
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SettingsWebhookSecurity',
}
</script>

<style scoped lang="scss">
.added,
.removed {
  color: white;
  display: inline-block;
  padding: 0 0.25em;

  &::before {
    margin-right: 0.25em;
  }
}

.added {
  background-color: var(--bs-success);

  &::before {
    content: '+';
  }
}

.removed {
  background-color: var(--bs-danger);

  &::before {
    content: '-';
  }
}

.arrow {
  display: inline-block;
  font-size: 24px;
  margin: 1em 0;

  @media (min-width: 992px) {
    margin: 0;
    transform: rotate(-90deg);
  }
}

pre {
  margin: 0;
}
</style>
