<template>
  <b-modal id="role-permissions-modal" title="Role Permissions" v-model="isVisible">
    <RolePermissionsTable :userRole="userRole" />
    <template #modal-footer>
      <button type="button" class="btn btn-light" @click="closeModal">Close</button>
    </template>
  </b-modal>
</template>

<script>
import RolePermissionsTable from '@/components/Modules/Settings/RolePermissions/RolePermissionsTable'

export default {
  name: 'RolePermissionsModal',
  components: {
    RolePermissionsTable,
  },
  props: {
    userRole: {
      type: Object,
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    closeModal() {
      this.isVisible = false
    },
  },
}
</script>
