import TaskService from '@/services/TaskService'
import { getField, updateField } from 'vuex-map-fields'
import { uniqueId } from 'lodash-es'
import { TASK_FILTER_PRODUCT_OPTIONS } from '@/lib/task-filter-product-options'

const defaultNewTask = () => ({
  company: undefined,
  product: undefined,
  additionalProduct: undefined,
  inputs: {},
  sendUserInvite: true,
  settings: { transfer: { amount: undefined }, deposit: {} },
  user: {
    sendUserInvite: true,
    identifier: undefined,
    phone: undefined,
    email: undefined,
    names: [
      {
        firstName: undefined,
        lastName: undefined,
      },
    ],
    addresses: [
      {
        recipient: undefined,
        line1: undefined,
        line2: undefined,
        city: undefined,
        state: undefined,
        zipcode: undefined,
        country: undefined,
      },
    ],
    accounts: [
      {
        accountNumber: undefined,
        routingNumber: undefined,
        type: undefined,
        title: undefined,
      },
    ],
  },
})

export default {
  namespaced: true,
  state: {
    loadingTasks: false,
    filters: {
      externalStatus: ['completed', 'processing', 'failed'],
      product: Object.values(TASK_FILTER_PRODUCT_OPTIONS),
      query: undefined,
    },
    tasks: [],
    task: {},
    newTask: defaultNewTask(),
    searchQueue: [],
    resolvedSearchRequestId: '0',
  },
  mutations: {
    updateField,
    setTasks(state, tasks) {
      state.tasks = [...tasks]
    },
    setTask(state, task) {
      state.task = task
    },
    resetTask(state) {
      state.task = {}
    },
    resetNewTask(state) {
      state.newTask = defaultNewTask()
    },
    setFilterProduct(state, product) {
      state.filters.product = product
    },
    setFilterStatus(state, status) {
      state.filters.externalStatus = status
    },
    setFilterQuery(state, query) {
      state.filters.query = query
    },
    setLoadingTasks(state, loading) {
      state.loadingTasks = loading
    },
    setResolvedSearchRequestId(state, resolvedSearchRequestId) {
      state.resolvedSearchRequestId = resolvedSearchRequestId
    },
    setSearchQueue(state, searchQueue) {
      state.searchQueue = searchQueue
    },
  },
  getters: {
    getField,
    searchRequestsWaitingInQueue(state) {
      return state.searchQueue.length > 0
    },
  },
  actions: {
    addSearchRequestToQueue({ commit, state }, requestId) {
      commit('setSearchQueue', [...state.searchQueue, requestId])
    },
    async loadMoreTasks({ commit, state }) {
      commit('setLoadingTasks', true)
      const response = await TaskService.list({
        filters: { ...state.filters },
        skip: state.tasks.length,
      })
      const tasks = [...state.tasks, ...response.body.data]
      commit('setTasks', tasks)
      commit('setLoadingTasks', false)
    },
    async getTaskList({ commit, dispatch, getters, state }) {
      commit('setLoadingTasks', true)

      const requestId = uniqueId()

      dispatch('addSearchRequestToQueue', requestId)

      try {
        const response = await TaskService.list({
          filters: { ...state.filters },
        })

        if (requestId > state.resolvedSearchRequestId) {
          commit('setResolvedSearchRequestId', requestId)
          commit('setTasks', response.body.data)
        }
      } finally {
        dispatch('removeSearchRequestFromQueue', requestId)

        if (!getters.searchRequestsWaitingInQueue) {
          commit('setLoadingTasks', false)
        }
      }
    },
    async getTask({ commit }, taskId) {
      const response = await TaskService.get(taskId)
      commit('setTask', response.body.data)
    },
    removeSearchRequestFromQueue({ commit, state }, requestId) {
      commit(
        'setSearchQueue',
        state.searchQueue.filter((id) => id !== requestId)
      )
    },
    syncQueryFilterToURL({}, query) {
      const url = new URL(window.location.href)

      if (query) {
        url.searchParams.set('query', query)
      } else {
        url.searchParams.delete('query')
      }

      history.replaceState({}, '', url.href)
    },
    async toggleProductFilter({ commit, state, dispatch }, productToToggle) {
      if (state.filters.product.includes(productToToggle)) {
        commit(
          'setFilterProduct',
          state.filters.product.filter((product) => product !== productToToggle)
        )
      } else {
        commit('setFilterProduct', [...state.filters.product, productToToggle])
      }
      dispatch('getTaskList')
    },
    async toggleStatusFilter({ commit, state, dispatch }, statusToToggle) {
      if (state.filters.externalStatus.includes(statusToToggle)) {
        commit(
          'setFilterStatus',
          state.filters.externalStatus.filter((status) => status !== statusToToggle)
        )
      } else {
        commit('setFilterStatus', [...state.filters.externalStatus, statusToToggle])
      }
      dispatch('getTaskList')
    },
    async updateQueryFilter({ commit, dispatch }, query) {
      commit('setFilterQuery', query)

      dispatch('syncQueryFilterToURL', query)
      await dispatch('getTaskList')
    },
    async getTaskEvents(_, { taskId }) {
      const response = await TaskService.getTaskEvents({
        taskId,
      })
      return response.data.data
    },
  },
}
