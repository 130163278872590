<template>
  <td class="text-center">
    <CheckIcon v-if="hasPermission" class="has-permission" />
    <XIcon v-else class="does-not-have-permission" />
  </td>
</template>

<script>
import { CheckIcon, XIcon } from 'vue-feather-icons'

export default {
  name: 'PermissionCell',
  props: {
    hasPermission: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CheckIcon,
    XIcon,
  },
}
</script>

<style scoped>
.has-permission {
  color: green;
}

.does-not-have-permission {
  color: #ccc;
}
</style>
