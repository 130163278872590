<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">Endpoints</h4>
      </div>
      <div v-if="hasWebhooks" class="card-body">
        <SettingsWebhooksEndpoint
          v-for="(endpoint, index) in activeCustomer.webhook.endpoints"
          :key="index"
          :endpoint="endpoint"
          @remove="remove"
        />
      </div>
      <div class="card-footer">
        <div class="form-group mb-0">
          <label>&nbsp;</label>
          <button v-b-modal="'add-endpoint-modal'" class="btn btn-primary mt-0">
            Add endpoint
          </button>
        </div>
      </div>
    </div>
    <SettingsTestWebhooks />
    <SettingsWebhookSecurity />
    <SettingsWebhooksAddEndpointModal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SettingsBase from './SettingsBase'
import SettingsTestWebhooks from '@/components/Modules/Settings/SettingsTestWebhooks'
import SettingsWebhooksAddEndpointModal from '@/components/Modules/Settings/SettingsWebhooksAddEndpointModal'
import SettingsWebhookSecurity from '@/components/Modules/Settings/SettingsWebhookSecurity'
import SettingsWebhooksEndpoint from '@/components/Modules/Settings/SettingsWebhooksEndpoint'

export default {
  name: 'SettingsWebhooks',
  extends: SettingsBase,
  components: {
    SettingsWebhooksEndpoint,
    SettingsTestWebhooks,
    SettingsWebhooksAddEndpointModal,
    SettingsWebhookSecurity,
  },
  computed: {
    ...mapGetters('customer', ['hasWebhooks']),
  },
  methods: {
    ...mapActions('customer', ['updateCustomer']),
    async remove(webhookEndpointId) {
      try {
        this.$toasted.info('Removing endpoint')

        await this.updateCustomer({
          payload: webhookEndpointId,
          serviceFn: 'removeWebhookEndpoint',
        })

        this.$analytics.track({
          event: 'Remove Webhook Endpoint',
        })

        this.$toasted.success('Endpoint removed')
      } catch (error) {
        this.$toasted.error('Error removing endpoint')
      }
    },
  },
}
</script>
