var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MiniMetric", {
    attrs: {
      title: "Authentication Rate",
      value: _vm.readableAuthenticationRate,
      isLoading: !_vm.isDataReady,
    },
    scopedSlots: _vm._u([
      {
        key: "visual",
        fn: function () {
          return [
            _c("div", { staticClass: "progress progress-sm me-4" }, [
              _c("div", {
                staticClass: "progress-bar",
                style: "width: " + _vm.readableAuthenticationRate,
                attrs: { role: "progressbar" },
              }),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "icon",
        fn: function () {
          return [
            _c("UserCheckIcon", {
              staticClass: "h2 text-muted mb-0",
              attrs: { size: "20" },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }