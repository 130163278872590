<template>
  <Card class="mt-4 mb-0">
    <div class="wrapper">
      <div v-if="conversionOptimization.screenshotUrl" class="screenshot-wrapper">
        <img
          @click="toggleScreenshotLightbox"
          :src="conversionOptimization.screenshotUrl"
          :alt="`${conversionOptimization.name} screenshot}`"
          class="cursor-zoom mw-100"
        />
      </div>
      <div class="content-wrapper">
        <div v-if="conversionOptimization.scopes.length" class="mb-3">
          <span
            v-for="scope in conversionOptimization.scopes"
            :key="scope"
            class="badge bg-primary-soft text-uppercase me-2"
            >{{ scope }}</span
          >
        </div>
        <div class="d-flex justify-content-between align-items-center my-3">
          <h5 class="name mb-0">{{ conversionOptimization.name }}</h5>
          <div>
            <CustomSwitch
              v-if="showToggle"
              :showLabelText="false"
              :formGroupWrap="false"
              :label="`Toggle ${conversionOptimization.name}`"
              v-model="isEnabled"
              :disabled="state === 'toggling'"
            />
            <GitCommitIcon
              v-if="showMiddleOfFunnelIcon"
              class="middle-of-funnel-icon cursor-help"
              v-tooltip="`Middle-of-funnel ${conversionOptimization.type}`"
            />
            <TrendingUpIcon
              v-if="showImplementedIcon"
              class="text-success cursor-help"
              v-tooltip="'Implemented in Transact'"
            />
            <TrendingDownIcon
              v-if="showUnimplementedIcon"
              class="text-warning cursor-help"
              v-tooltip="'Not implemented in Transact'"
            />
          </div>
        </div>
        <div class="metadata my-3 d-flex">
          <div
            v-if="conversionOptimization.startDate || conversionOptimization.endDate"
            class="pe-4 d-flex align-items-center"
          >
            <CalendarIcon class="me-2" size="1x" />
            <time
              v-if="conversionOptimization.startDate"
              :datetime="conversionOptimization.startDate"
              >{{ $moment.utc(conversionOptimization.startDate).format('MMM Do') }}</time
            >
            <span
              v-if="conversionOptimization.startDate && conversionOptimization.endDate"
              class="mx-1"
              >&#8212;</span
            >
            <time
              v-if="conversionOptimization.endDate"
              :datetime="conversionOptimization.endDate"
              >{{ $moment.utc(conversionOptimization.endDate).format('MMM Do') }}</time
            >
          </div>
          <div class="d-flex align-items-center">
            <UsersIcon class="me-2" size="1x" />
            <span>{{ conversionOptimization.trafficSplitTesting }}% Testing</span>
            <span class="mx-1">/</span>
            <span>{{ conversionOptimization.trafficSplitControl }}% Control</span>
          </div>
        </div>
        <p class="mb-0">
          {{ isCompleted ? conversionOptimization.results : conversionOptimization.background }}
        </p>
      </div>
    </div>
    <vue-easy-lightbox
      :visible="screenshotLightboxVisible"
      :imgs="conversionOptimization.screenshotUrl"
      @hide="toggleScreenshotLightbox"
    ></vue-easy-lightbox>
  </Card>
</template>

<script>
import {
  CONVERSION_OPTIMIZATION_STATUSES,
  CONVERSION_OPTIMIZATION_TYPES,
} from '@atomicfi/constants-shared'
import {
  CalendarIcon,
  GitCommitIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  UsersIcon,
} from 'vue-feather-icons'
import { get, startCase } from 'lodash-es'
import { mapMutations, mapState } from 'vuex'
import Card from '@/components/MainContent/Card'
import ConversionOptimizationService from '@/services/ConversionOptimizationService'
import CustomSwitch from '@/components/MainContent/CustomSwitch'

export default {
  name: 'ConversionOptimizationCard',
  components: {
    CalendarIcon,
    Card,
    CustomSwitch,
    GitCommitIcon,
    TrendingDownIcon,
    TrendingUpIcon,
    UsersIcon,
  },
  data() {
    return {
      screenshotLightboxVisible: false,
      state: 'idle',
    }
  },
  props: {
    conversionOptimization: {
      type: Object,
    },
  },
  computed: {
    ...mapState('customer', ['activeCustomer']),
    conversionOptimizationSettingsPath() {
      return `${this.customerPathToConversionOptimizationSettings}.${this.conversionOptimization.type}s`
    },
    customerPathToConversionOptimizationSettings() {
      return `features.transact${startCase(this.conversionOptimization.type)}s`
    },
    globalToggleSettingPath() {
      return this.customerPathToConversionOptimizationSettings + '.enabled'
    },
    isCompleted() {
      return this.conversionOptimization.status === CONVERSION_OPTIMIZATION_STATUSES.COMPLETED
    },
    isEnabled: {
      get() {
        return !!get(this.activeCustomer, this.conversionOptimizationSettingsPath, []).find(
          ({ _id }) => _id === this.conversionOptimization._id
        )
      },
      async set(enabled) {
        let updatingToast

        try {
          this.state = 'toggling'

          updatingToast = this.$toasted.show('Updating...', { duration: null })

          const result = await ConversionOptimizationService.toggle(
            this.conversionOptimization._id,
            {
              enabled,
            }
          )

          this.$analytics.track({
            event: `Toggled ${startCase(this.conversionOptimization.type)}`,
            payload: {
              name: this.conversionOptimization.name,
              enabled,
            },
          })

          updatingToast.goAway(0)
          this.$toasted.success(
            `${startCase(this.conversionOptimization.type)} ${enabled ? 'enabled' : 'disabled'}`
          )
          this.setActiveCustomer(result.body.data)
        } catch (error) {
          updatingToast.goAway(0)
          this.$toasted.error(`Error toggling ${this.conversionOptimization.type}`)
        } finally {
          this.state = 'idle'
        }
      },
    },
    isExperiment() {
      return this.conversionOptimization.type === CONVERSION_OPTIMIZATION_TYPES.EXPERIMENT
    },
    showImplementedIcon() {
      return (
        this.isCompleted && this.conversionOptimization.implementedInProduct && this.isExperiment
      )
    },
    showMiddleOfFunnelIcon() {
      return !this.isCompleted && !this.conversionOptimization.isCustomerControlled
    },
    showToggle() {
      return (
        !this.isCompleted &&
        this.conversionOptimization.isCustomerControlled &&
        !get(this.activeCustomer, this.globalToggleSettingPath)
      )
    },
    showUnimplementedIcon() {
      return (
        this.isCompleted && !this.conversionOptimization.implementedInProduct && this.isExperiment
      )
    },
  },
  methods: {
    ...mapMutations('customer', ['setActiveCustomer']),
    toggleScreenshotLightbox() {
      this.screenshotLightboxVisible = !this.screenshotLightboxVisible
    },
  },
}
</script>

<style scoped lang="scss">
.name {
  color: #21253d;
  font-size: 1em;
}

.wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }
}

.content-wrapper {
  @media screen and (min-width: 768px) {
    flex-basis: 62%;
    flex-grow: 1;
  }
}

.screenshot-wrapper {
  background-color: #fafbff;
  border-radius: 4px;
  border: 1px solid #edeff5;
  margin-bottom: 1.5rem;
  padding: 0.5em;

  @media screen and (min-width: 768px) {
    height: max-content;
    margin-left: 5%;
    width: 33%;
  }
}

.metadata,
.middle-of-funnel-icon {
  color: #696f8c;
}

.cursor-help {
  cursor: help;
}

.cursor-zoom {
  cursor: zoom-in;
}
</style>
