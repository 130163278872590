<template>
  <MiniMetric
    title="Authentication Rate"
    :value="readableAuthenticationRate"
    :isLoading="!isDataReady"
  >
    <template #visual>
      <div class="progress progress-sm me-4">
        <div
          class="progress-bar"
          role="progressbar"
          :style="`width: ${readableAuthenticationRate}`"
        ></div>
      </div>
    </template>
    <template #icon>
      <UserCheckIcon size="20" class="h2 text-muted mb-0" />
    </template>
  </MiniMetric>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { UserCheckIcon } from 'vue-feather-icons'
import MiniMetric from '@/components/Modules/Activity/Elements/MiniMetric'

export default {
  name: 'AuthenticationRate',
  components: {
    MiniMetric,
    UserCheckIcon,
  },
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('activity', ['authenticationRate', 'activeDateRangeOption', 'sampleDataMode']),
    readableAuthenticationRate() {
      if (this.sampleDataMode) return '64%'
      if (this.isLoading) return 0
      if (!this.authenticationRate?.authenticated) return '0%'
      return `${Math.ceil(
        (this.authenticationRate?.authenticated /
          (this.authenticationRate?.authenticated + this.authenticationRate?.not_authenticated)) *
          100
      )}%`
    },
    isDataReady() {
      return !!(!this.isLoading || this.sampleDataMode)
    },
  },
  methods: {
    ...mapActions('activity', ['getAuthenticationRate']),
    async getData() {
      this.isLoading = true
      await this.getAuthenticationRate()
      this.isLoading = false
    },
  },
  mounted() {
    this.getData()
  },
  data: () => ({
    isLoading: false,
  }),
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.getData()
    },
  },
}
</script>
