<template>
  <div>
    <div v-if="hasEventRequestsData" data-test-id="event-requests">
      <div class="header-body">
        <h4 class="header-title">Sent Events</h4>
      </div>
      <Alert v-if="!hasWebhooks && !hasUnsentEventsData" class="mb-3" type="info">
        <span>
          Webhook endpoints are not currently configured.
          <span v-if="canManageWebhooks">
            To change your webhooks settings, go to the
            <router-link :to="{ name: 'settings-webhooks' }" class="alert-link">
              Webhooks page</router-link
            >.</span
          >
        </span>
      </Alert>
      <EventRequest
        v-for="event in eventRequests"
        :key="event._id"
        :event="event"
        :displayEventType="true"
      />
    </div>
    <div v-if="hasUnsentEventsData" data-test-id="unsent-events">
      <div class="header-body">
        <h4 class="header-title">Unsent Events</h4>
      </div>
      <Alert class="mb-3" type="info">
        <div>
          <p>
            <span v-if="!hasWebhooks">Webhook endpoints are not currently configured. </span>
            <span>
              When the task was run, you were not subscribed to all task events. Below are the
              events you would have received if you were subscribed to all events.
            </span>
          </p>
          <span v-if="canManageWebhooks">
            To change your webhooks settings, go to the
            <router-link :to="{ name: 'settings-webhooks' }" class="alert-link"
              >Webhooks page</router-link
            >.
          </span>
        </div>
      </Alert>
      <EventRequest
        v-for="event in unsentEvents"
        :key="event._id"
        :event="event"
        :displayEventType="true"
      />
    </div>
  </div>
</template>

<script>
import Alert from '@/components/MainContent/Alert'
import EventRequest from '@/components/Modules/Event/EventRequest'
import { hasPermissions } from '@/lib/authorization'
import { PERMISSIONS } from '@/lib/permissions'
import { mapGetters } from 'vuex'

export default {
  name: 'TaskEvents',
  components: { Alert, EventRequest },
  props: {
    eventRequests: { type: Array },
    unsentEvents: { type: Array },
  },
  computed: {
    ...mapGetters('customer', ['hasWebhooks']),
    hasUnsentEventsData() {
      return this.unsentEvents.length
    },
    hasEventRequestsData() {
      return this.eventRequests.length
    },
    canManageWebhooks() {
      return hasPermissions(PERMISSIONS.MANAGE_WEBHOOKS)
    },
  },
}
</script>
