import Vue from 'vue'
import store from '@/store'
import { getAtomicCustomerAPIFromEnvironment } from '@/utils/environment'
import urlGenerator from '@/lib/url-generator'
import { ENVIRONMENTS } from '@/utils/constants'

export default {
  get(specificEnvironment) {
    return Vue.http.get(`${_getEndpointForSpecificEnvironment(specificEnvironment)}/customer`)
  },
  getChildCustomer(specificEnvironment) {
    return Vue.http.get(
      `${_getEndpointForSpecificEnvironment(specificEnvironment)}/customer/child-customer`
    )
  },
  getAccessToken({ queryStringParams = {} }) {
    return Vue.http.get(
      urlGenerator(
        `${store.getters['environment/atomicCustomerAPI']}/customer/access-token`,
        queryStringParams
      )
    )
  },
  children({ queryStringParams = {}, specificEnvironment } = {}) {
    return Vue.http.get(
      urlGenerator(
        `${_getEndpointForSpecificEnvironment(specificEnvironment)}/customer/children`,
        queryStringParams
      )
    )
  },
  update(payload, syncToSandbox) {
    let config = {}
    if (syncToSandbox) {
      config = {
        headers: { 'x-sync-to-sandbox': syncToSandbox ? 'true' : 'false' },
      }
    }

    return Vue.http.put(
      `${store.getters['environment/atomicCustomerAPI']}/customer`,
      payload,
      config
    )
  },
  updateTransactConfiguration({ payload, specificEnvironment }) {
    return Vue.http.put(
      `${_getEndpointForSpecificEnvironment(specificEnvironment)}/customer/transact-configuration`,
      payload
    )
  },
  testWebhooks(payload) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/customer/test-webhooks`,
      payload
    )
  },
  addSecret(payload) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/customer/secret`,
      payload
    )
  },
  removeSecret(payload) {
    return Vue.http.delete(
      `${store.getters['environment/atomicCustomerAPI']}/customer/secret/${payload.secretId}`,
      payload
    )
  },
  getCredentials({ queryStringParams }) {
    return Vue.http.get(
      urlGenerator(
        `${store.getters['environment/atomicCustomerAPI']}/customer/credentials`,
        queryStringParams
      )
    )
  },
  createChildCustomer(payload) {
    return Vue.http.post(`${store.getters['environment/atomicCustomerAPI']}/customer`, payload)
  },
  addWebhookEndpoint(payload) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/customer/webhook-endpoints`,
      payload
    )
  },
  removeWebhookEndpoint(webhookEndpointId) {
    return Vue.http.delete(
      `${store.getters['environment/atomicCustomerAPI']}/customer/webhook-endpoints/${webhookEndpointId}`
    )
  },
  signup(payload) {
    return Vue.http.post(
      `${getAtomicCustomerAPIFromEnvironment(ENVIRONMENTS.PRODUCTION)}/customer/signup`,
      payload
    )
  },
  updateWebhookEndpoint({ _id, ...payload }) {
    return Vue.http.put(
      `${store.getters['environment/atomicCustomerAPI']}/customer/webhook-endpoints/${_id}`,
      payload
    )
  },
}

function _getEndpointForSpecificEnvironment(specificEnvironment) {
  return specificEnvironment
    ? getAtomicCustomerAPIFromEnvironment(specificEnvironment)
    : store.getters['environment/atomicCustomerAPI']
}
