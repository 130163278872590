var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "card" }, [
        _vm._m(0),
        _vm.hasWebhooks
          ? _c(
              "div",
              { staticClass: "card-body" },
              _vm._l(
                _vm.activeCustomer.webhook.endpoints,
                function (endpoint, index) {
                  return _c("SettingsWebhooksEndpoint", {
                    key: index,
                    attrs: { endpoint: endpoint },
                    on: { remove: _vm.remove },
                  })
                }
              ),
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "form-group mb-0" }, [
            _c("label", [_vm._v(" ")]),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal",
                    value: "add-endpoint-modal",
                    expression: "'add-endpoint-modal'",
                  },
                ],
                staticClass: "btn btn-primary mt-0",
              },
              [_vm._v(" Add endpoint ")]
            ),
          ]),
        ]),
      ]),
      _c("SettingsTestWebhooks"),
      _c("SettingsWebhookSecurity"),
      _c("SettingsWebhooksAddEndpointModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-header-title" }, [_vm._v("Endpoints")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }