<template>
  <div>
    <EmulatorSectionHeader :title="title">
      <LanguageSelectorDropdown />
    </EmulatorSectionHeader>

    <div v-if="shouldRenderTabs" class="">
      <div class="row">
        <div class="col">
          <ul class="nav nav-tabs nav-tabs-sm">
            <li
              class="nav-item"
              v-for="(customization, index) in customizations"
              :key="customization.tab"
            >
              <button
                :id="`${customization.tab}-tab`"
                class="nav-link"
                @click="() => setActiveTabIndex(index)"
                :class="{ active: activeTabIndex === index }"
              >
                {{ customization.tab }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="tab-content mt-4">
      <div
        v-for="(customization, index) in customizations"
        :id="customization.tab"
        role="tabpanel"
        :key="customization.tab"
        class="tab-pane"
        :class="{ active: activeTabIndex === index }"
        :aria-labelledby="`${customization.tab}-tab`"
      >
        <div v-if="customization.tab === EMULATOR_CUSTOMIZATION_TABS.PHRASES">
          <EmulatorCustomizationTextBox
            v-for="phrase in customization.phrases"
            :key="phrase.label"
            :label="phrase.label"
            :category="title"
            namespace="i18n"
            :path="`${settings.language}.${phrase.phraseNamespace}.${phrase.phraseKey}`"
            dataType="phrase"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { EMULATOR_CUSTOMIZATION_TABS, EMULATOR_EVENT_TYPES } from '@/utils/constants'
import EmulatorSectionHeader from '@/components/Modules/Emulator/EmulatorSectionHeader'
import EmulatorCustomizationTextBox from '@/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationTextBox'
import LanguageSelectorDropdown from '@/components/Modules/Emulator/LanguageSelectorDropdown.vue'

export default {
  name: 'EmulatorScreenCustomization',
  data: () => ({
    EMULATOR_CUSTOMIZATION_TABS,
    EMULATOR_EVENT_TYPES,
  }),
  props: ['name'],
  components: {
    EmulatorCustomizationTextBox,
    EmulatorSectionHeader,
    LanguageSelectorDropdown,
  },
  computed: {
    ...mapState('emulator', ['activeTabIndex', 'settings']),
    ...mapGetters('emulator', ['screensToCustomize']),
    customizations() {
      return this.screenConfig.customizations ?? []
    },
    shouldRenderTabs() {
      return this.customizations.length > 1
    },
    screenConfig() {
      return this.screensToCustomize.find((screen) => this.name === screen.name) ?? {}
    },
    title() {
      return this.screenConfig.title ?? 'No customizations found'
    },
  },
  methods: {
    ...mapMutations('emulator', ['setActiveTabIndex']),
  },
  destroyed() {
    this.setActiveTabIndex(0)
  },
}
</script>

<style lang="scss" scoped>
h5 {
  font-weight: normal;
  margin-bottom: 1rem;
}

.tab-nav {
  margin-bottom: 1rem;
}

.nav-tabs-sm .nav-link {
  padding-top: 0;
}

.nav-link {
  padding-bottom: 5px;
}
</style>
