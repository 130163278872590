<template>
  <div>
    <div
      class="row flex-nowrap"
      v-for="(
        { routingNumber, accountNumber, distributionType, distributionAmount }, index
      ) in accounts"
      :key="accountNumber"
      :class="{ 'mt-n3': index !== 0 }"
    >
      <div class="col" v-if="hasMultipleAccounts">
        <div class="distribution-indicator" :class="{ 'distribution-secondary': index !== 0 }">
          <img
            :src="index !== 0 ? '/images/distribution-next.svg' : '/images/distribution-first.svg'"
          />
        </div>
      </div>

      <div :class="{ 'col-11': hasMultipleAccounts, 'col-12': !hasMultipleAccounts }">
        <div class="card card-body d-flex flex-row justify-content-between">
          <div class="d-flex flex-row mt-n2">
            <div class="me-4">
              <h6 class="text-muted text-uppercase m-0">routing</h6>
              <div>
                {{ routingNumber }}
              </div>
            </div>

            <div>
              <h6 class="text-muted text-uppercase m-0">account</h6>
              <div>
                {{ accountNumber }}
              </div>
            </div>
          </div>

          <div>
            <span v-if="distributionType === 'total'">100%</span>
            <span v-if="distributionType === 'percent'">{{ distributionAmount }}%</span>
            <span v-if="distributionType === 'fixed'">{{ distributionAmount | currency }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskDistrubutions',
  props: ['accounts'],
  data() {
    return {
      loading: false,
      accountsWithBankNames: undefined,
    }
  },
  computed: {
    hasMultipleAccounts() {
      return this.accounts.length > 1
    },
  },
}
</script>
<style lang="scss" scoped>
.align-middle {
  margin-top: auto;
  margin-bottom: auto;
}
.distribution-indicator {
  img {
    width: 33px;
  }
  height: 30px;
  position: relative;
  &.distribution-secondary {
    top: -67px;
  }
}
</style>
