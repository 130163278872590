<template>
  <MiniMetric
    title="Completed Tasks"
    :value="completedTasks.toLocaleString()"
    :isLoading="!isDataReady"
  >
    <template #icon>
      <CheckIcon size="20" class="h2 text-muted mb-0" />
    </template>
  </MiniMetric>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { CheckIcon } from 'vue-feather-icons'
import MiniMetric from '@/components/Modules/Activity/Elements/MiniMetric'

export default {
  name: 'CompletedTasks',
  components: {
    MiniMetric,
    CheckIcon,
  },
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('activity', ['taskCounts', 'activeDateRangeOption', 'sampleDataMode']),
    completedTasks() {
      if (this.sampleDataMode) return 9281
      if (this.isLoading || !this.taskCounts?.length) return 0
      return this.taskCounts
        .filter((item) => item.status === 'completed')
        .reduce((accumulator, object) => {
          return accumulator + object.count
        }, 0)
    },
    isDataReady() {
      return !!(!this.isLoading || this.sampleDataMode)
    },
  },
  methods: {
    ...mapActions('activity', ['getTaskCounts']),
    async getData() {
      this.isLoading = true
      await this.getTaskCounts()
      this.isLoading = false
    },
  },
  mounted() {
    this.getData()
  },
  data: () => ({
    isLoading: false,
  }),
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.getData()
    },
  },
}
</script>
