<template>
  <div class="row">
    <div class="col-12">
      <div class="glassmorphic card">
        <div class="card-body">
          <div class="d-flex flex-nowrap justify-content-center align-middle" v-if="!isDataReady">
            <div
              v-for="index in this.maximumItems"
              :key="index"
              class="connection justify-content-center align-middle"
            >
              <VueSkeletonLoader
                type="rect"
                :width="64"
                :height="64"
                color="rgba(227,236,248,0.6)"
                :rounded="true"
                animation="fade"
              />
              <div class="text-center mt-2 text-secondary">
                <VueSkeletonLoader
                  class="d-inline-block"
                  type="rect"
                  :width="32"
                  :height="24"
                  color="rgba(227,236,248,0.3)"
                  :rounded="true"
                  animation="fade"
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-nowrap justify-content-center align-middle" v-else>
            <div
              class="connection justify-content-center align-middle"
              v-for="connection in resizedTopConnections"
              :key="connection.name"
            >
              <div @mouseover="hoverTopConnection(connection.name)">
                <Logo
                  :branding="connection.cache.branding"
                  :name="connection.name"
                  size="lg"
                  v-tooltip="connection.name"
                />
              </div>
              <div class="text-center mt-2 text-secondary">
                {{ connection.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Logo from '@/components/MainContent/Logo'
import VueSkeletonLoader from 'skeleton-loader-vue'
import { topConnectionsSampleData } from '@/utils/activity'

export default {
  name: 'TopConnections',
  components: {
    Logo,
    VueSkeletonLoader,
  },
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('activity', ['topConnections', 'activeDateRangeOption', 'sampleDataMode']),
    isDataReady() {
      return !!(!this.isLoading || this.sampleDataMode)
    },
    resizedTopConnections() {
      const topConnections = this.topConnections?.length
        ? this.topConnections
        : topConnectionsSampleData()
      return topConnections.filter((connection) => connection?.cache).slice(0, this.maximumItems)
    },
  },
  methods: {
    ...mapActions('activity', ['getTopConnections']),
    ...mapMutations('activity', ['setTopConnections']),
    async getData() {
      this.isLoading = true
      await this.getTopConnections()
      this.isLoading = false
    },
    hoverTopConnection(connectionName) {
      this.$analytics.track({
        event: 'Hovered Top Connection',
        payload: {
          connectionName,
        },
      })
    },
    setMaximumItems() {
      const container = document.querySelector('.glassmorphic')
      const width = container.offsetWidth
      this.maximumItems = Math.floor(width / 115)
    },
    onResize() {
      this.setMaximumItems()
    },
  },
  mounted() {
    this.getData()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  data: () => ({
    isLoading: false,
    maximumItems: 8,
  }),
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.getData()
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  min-height: 170px;
}
.connection {
  margin: 1.6rem !important;
}
.glassmorphic {
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 6px 21px 0 rgb(31 38 135 / 7%);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  .card-body {
    padding: 0.8rem;
    padding-bottom: 0.4rem;
  }
}
</style>
