<template>
  <HasPermissionTable
    :label="userRole.label"
    :rowData="availablePermissions"
    :hasPermissionFunction="userRoleHasPermission"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import HasPermissionTable from '@/components/Modules/Settings/RolePermissions/HasPermissionTable'

export default {
  name: 'RolePermissionsTable',
  components: {
    HasPermissionTable,
  },
  props: {
    userRole: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('customer', ['availablePermissions']),
  },
  methods: {
    userRoleHasPermission(environment, permission) {
      return this.userRole.permissions[environment].includes(permission)
    },
  },
}
</script>
