import Vue from 'vue'
import store from '@/store'

export default {
  funnel(query) {
    return Vue.http.post(`${store.getters['environment/atomicCustomerAPI']}/activity/funnel`, query)
  },
  topConnections(query) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/activity/top-connections`,
      query
    )
  },
  authenticationRate(query) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/activity/metric/authentication-rate`,
      query
    )
  },
  linkedAccounts(query) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/activity/metric/linked-accounts`,
      query
    )
  },
  taskCounts(query) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/activity/metric/task-counts`,
      query
    )
  },
  uniqueUsers(query) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/activity/metric/unique-users`,
      query
    )
  },
  transactionVolume(query) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/activity/transaction-volume`,
      query
    )
  },
  failureReasons(query) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/activity/failure-reasons`,
      query
    )
  },
}
