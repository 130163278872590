<template>
  <div>
    <div class="row align-items-center">
      <div class="col">
        <ul class="nav nav-tabs nav-tabs-sm mt-0">
          <li
            class="nav-item"
            v-for="year in yearsOfTimesheetData"
            :key="year"
            @click="onYearSelect(year)"
          >
            <a href="javascript:;" class="nav-link" :class="{ active: year === selectedYear }">
              {{ year }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <ul class="nav nav-tabs nav-tabs-sm mt-0">
          <li
            class="nav-item"
            v-for="month in monthsOfTimesheetData"
            :key="month"
            @click="onMonthSelect(month)"
          >
            <a href="javascript:;" class="nav-link" :class="{ active: month === selectedMonth }">
              {{ monthsShort[month] }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="card mt-4" v-if="timesheets.length > 0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">
          {{ `${selectedView} Hours Worked` }}
        </h4>

        <!-- Caption -->
        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
          <li class="nav-item" v-for="view in views" :key="view">
            <a
              href="javascript:;"
              class="nav-link"
              :class="{ active: view === selectedView }"
              data-bs-toggle="tab"
              @click="() => onViewSelect(view)"
            >
              {{ view }}
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <BasicBarChart
          :chart-data="selectedView === 'Daily' ? dailyChartData : monthlyChartData"
          :y-axis-callback="(v) => v"
          :tooltip-label-callback="(items) => `${items.value} hours`"
          :display-legend="true"
        ></BasicBarChart>
        <div class="table-sm mb-n3">
          <dl class="d-flex">
            <dt class="col-1"><span class="dot" :style="{ color: red }">•</span></dt>
            <dt class="col-2">Total Unpaid Hours</dt>
            <dt class="col-1 text-right">{{ totalUnpaidHours }}</dt>
          </dl>
          <dl class="d-flex">
            <dt class="col-1"><span class="dot" :style="{ color: yellow }">•</span></dt>
            <dt class="col-2">Total Scheduled Hours</dt>
            <dt class="col-1 text-right">{{ totalScheduledHours }}</dt>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicBarChart from '@/components/Charts/BasicBarChart'
import { getObjectsGroupedByYearAndMonth } from '@/utils/task'
import { round, max, sumBy } from 'lodash-es'
import Colors from '@/lib/chart/colors.js'

export default {
  name: 'TaskTimesheetsChart',
  components: {
    BasicBarChart,
  },
  props: ['timesheets'],
  data() {
    return {
      month: undefined,
      year: undefined,
      gridLines: {
        beginAtZero: false,
      },
      views: ['Daily', 'Monthly'],
      selectedView: 'Daily',
      red: Colors.red,
      yellow: Colors.yellow,
    }
  },
  computed: {
    timesheetsGroupedByYearAndMonth() {
      return getObjectsGroupedByYearAndMonth(this.timesheets)
    },
    selectedYear() {
      return this.year || Number(max(Object.keys(this.timesheetsGroupedByYearAndMonth)))
    },
    selectedMonth() {
      if (this.month !== undefined) return this.month
      else return Number(max(Object.keys(this.timesheetsGroupedByYearAndMonth[this.selectedYear])))
    },
    yearsOfTimesheetData() {
      return Object.keys(this.timesheetsGroupedByYearAndMonth).map((str) => Number(str))
    },
    monthsOfTimesheetData() {
      return Object.keys(this.timesheetsGroupedByYearAndMonth[this.selectedYear]).map((str) =>
        Number(str)
      )
    },
    monthsShort() {
      return this.$moment.monthsShort()
    },
    totalUnpaidHours() {
      return round(
        sumBy(
          this.timesheets.filter(({ type }) => type === 'unpaid'),
          'duration'
        ) / 60,
        2
      )
    },
    totalScheduledHours() {
      return round(
        sumBy(
          this.timesheets.filter(({ type }) => type === 'scheduled'),
          'duration'
        ) / 60,
        2
      )
    },
    dailyChartData() {
      const displayedDays = Array.from(
        {
          length: this.$moment(
            new Date(`${this.selectedYear}-${this.selectedMonth + 1}`)
          ).daysInMonth(),
        },
        (_, i) => i + 1
      )

      const paidHours = Array(displayedDays.length).fill(0)
      const unpaidHours = Array(displayedDays.length).fill(0)
      const scheduledHours = Array(displayedDays.length).fill(0)

      this.timesheetsGroupedByYearAndMonth[this.selectedYear][this.selectedMonth].forEach(
        ({ date, duration, type }) => {
          switch (type) {
            case 'paid':
              paidHours[new Date(date).getUTCDate() - 1] = round(duration / 60, 2)
              break
            case 'unpaid':
              unpaidHours[new Date(date).getUTCDate() - 1] = round(duration / 60, 2)
              break
            case 'scheduled':
              scheduledHours[new Date(date).getUTCDate() - 1] = round(duration / 60, 2)
              break
          }
        }
      )

      return {
        labels: displayedDays,
        datasets: [
          {
            label: 'Paid',
            data: paidHours,
            backgroundColor: Colors.indigo,
          },
          {
            label: 'Unpaid',
            data: unpaidHours,
            backgroundColor: Colors.red,
          },
          {
            label: 'Scheduled',
            data: scheduledHours,
            backgroundColor: Colors.yellow,
          },
        ],
      }
    },
    monthlyChartData() {
      const paidHours = [0, 0, 0]
      const unpaidHours = [0, 0, 0]
      const scheduledHours = [0, 0, 0]

      this.timesheetsGroupedByYearAndMonth[this.selectedYear][this.selectedMonth].forEach(
        ({ duration, type }) => {
          switch (type) {
            case 'paid':
              paidHours[0] += duration
              break
            case 'unpaid':
              unpaidHours[1] += duration
              break
            case 'scheduled':
              scheduledHours[2] += duration
              break
          }
        }
      )

      paidHours[0] = round(paidHours[0] / 60, 2)
      unpaidHours[1] = round(unpaidHours[1] / 60, 2)
      scheduledHours[2] = round(scheduledHours[2] / 60, 2)

      return {
        labels: ['Paid', 'Unpaid', 'Scheduled'],
        datasets: [
          {
            label: 'Paid',
            data: paidHours,
            backgroundColor: Colors.indigo,
          },
          {
            label: 'Unpaid',
            data: unpaidHours,
            backgroundColor: Colors.red,
          },
          {
            label: 'Scheduled',
            data: scheduledHours,
            backgroundColor: Colors.yellow,
          },
        ],
      }
    },
  },
  methods: {
    onYearSelect(year) {
      this.year = year
    },
    onMonthSelect(month) {
      this.month = month
    },
    onViewSelect(view) {
      this.selectedView = view
    },
  },
}
</script>
<style scoped lang="scss">
.dot {
  position: relative;
  top: 9px;
  font-size: 3rem;
  line-height: 0.1rem;
}

dt {
  border: none !important;
  padding: 0.2rem;
  font-weight: normal;
}

dl {
  margin-bottom: 0;
}
</style>
