export default {
  namespaced: true,
  state: {
    distributionType: undefined,
    distributionAmount: undefined,
  },
  mutations: {
    setDistributionType(state, distributionType) {
      state.distributionType = distributionType
    },
    setCurrentAccountsOnFile(state, currentAccountsOnFile) {
      state.currentAccountsOnFile = currentAccountsOnFile
    },
    setDistributionAmount(state, distributionAmount) {
      state.distributionAmount = distributionAmount
    },
  },
  actions: {
    updateDistributionType({ commit }, distributionType) {
      commit('setDistributionType', distributionType)
    },
    updateDistributionAmount({ commit }, distributionAmount) {
      commit('setDistributionAmount', distributionAmount)
    },
    resetScenario({ commit }) {
      commit('setDistributionType', undefined)
      commit('setDistributionAmount', undefined)
    },
  },
}
