var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EmulatorSectionHeader", { attrs: { title: "Styles" } }),
      _c("EmulatorCustomizationTextBox", {
        attrs: {
          category: "Brand",
          label: "Brand Color",
          namespace: "theme",
          path: "colors.brand",
          dataType: "color",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var value = ref.value
              return [
                _c(
                  "div",
                  { staticClass: "col-auto" },
                  [_c("ColorSwatch", { attrs: { color: value } })],
                  1
                ),
              ]
            },
          },
          {
            key: "postInput",
            fn: function () {
              return [
                _c("Alert", { staticClass: "mb-4", attrs: { type: "info" } }, [
                  _vm._v(
                    " Brand color can be saved as your default to the database or overridden with the "
                  ),
                  _c("code", { staticClass: "me-2" }, [
                    _vm._v("theme.brandColor"),
                  ]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://docs.atomicfi.com/reference/transact-sdk#parameters--optional-properties",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Transact SDK parameter")]
                  ),
                  _vm._v(". "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("EmulatorCustomizationTextBox", {
        attrs: {
          category: "Button Styles",
          label: "Button Border Radius",
          namespace: "theme",
          path: "buttons.borderRadius",
          dataType: "string",
        },
      }),
      _vm.customCssEnabled
        ? _c("EmulatorCustomizationTextBox", {
            attrs: {
              category: "Custom Styles",
              label: "Custom CSS",
              namespace: "theme",
              path: "customCSS",
              dataType: "string",
              inputType: "textarea",
              placeholder: "Paste or type your CSS here",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "postInput",
                  fn: function () {
                    return [
                      _c(
                        "Alert",
                        { staticClass: "mb-4", attrs: { type: "warning" } },
                        [
                          _c("p", [
                            _vm._v(
                              "We cannot guarantee your custom CSS will work as expected."
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " Custom CSS cannot override certain content, including references to Atomic. Some references to Atomic will be automatically applied if you use this feature. "
                            ),
                          ]),
                          _c("p", { staticClass: "disclaimer" }, [
                            _vm._v(
                              " Disclaimer: Our support agreement does not include assistance with modifying or debugging your custom CSS. "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              490681734
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }