var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.chartType === _vm.CHART_TYPES.LINE
        ? _c("LineChart", {
            staticClass: "chart",
            class: { "chart-sparkline": _vm.spark },
            attrs: {
              chartData: _vm.chartData,
              options: _vm.chartOptions,
              useDummyData: _vm.useDummyData,
              spark: _vm.spark,
            },
          })
        : _vm.chartType === _vm.CHART_TYPES.DOUGHNUT
        ? _c("DoughnutChart", {
            staticClass: "chart",
            attrs: {
              chartData: _vm.chartData,
              options: _vm.chartOptions,
              useDummyData: _vm.useDummyData,
            },
          })
        : _vm.chartType === _vm.CHART_TYPES.BAR
        ? _c("BarChart", {
            staticClass: "chart",
            attrs: {
              chartData: _vm.chartData,
              options: _vm.chartOptions,
              useDummyData: _vm.useDummyData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }