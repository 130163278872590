<template>
  <div class="p-6 text-center justify-content-center">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
}
</script>
