<template>
  <div class="form-group mb-0">
    <label v-if="title" class="form-label">{{ title }}</label>
    <div class="input-group">
      <input :type="isSecret ? 'password' : 'text'" disabled class="form-control" :value="value" />
      <button
        class="btn btn-outline-secondary"
        type="button"
        v-clipboard:copy="value"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        :disabled="disabled"
      >
        <CopyIcon size="14" class="me-1 p-0 m-0" /> Copy
      </button>
    </div>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons'
export default {
  name: 'CopyableInput',
  props: ['title', 'value', 'isSecret', 'disabled'],
  emits: ['copy'],
  components: { CopyIcon },
  methods: {
    onCopy() {
      this.$emit('copy')
      this.$toasted.success('Copied to clipboard.')
    },
    onError() {
      this.$toasted.error('Error copying to clipboard.')
    },
  },
}
</script>
