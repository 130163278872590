var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.usesSso
        ? _c("Alert", { staticClass: "mb-3", attrs: { type: "info" } }, [
            _vm._v(" Adding new team members "),
            _vm.managesRolesInIdp
              ? _c("span", [_vm._v("and editing their role")])
              : _vm._e(),
            _vm._v(" is done via your organization's SSO provider. "),
          ])
        : _vm._e(),
      _vm.isLoading
        ? _c("div", { staticClass: "col-12 text-center mt-3 mb-3" }, [
            _c("div", {
              staticClass: "spinner-border text-primary",
              attrs: { role: "status" },
            }),
          ])
        : _vm.users
        ? _c(
            "div",
            { staticClass: "user-list" },
            _vm._l(_vm.sortedUsers, function (user) {
              return _c("UserCard", {
                key: user.user_id,
                attrs: { user: user },
                on: {
                  delete: _vm.openDeleteUserModal,
                  editRole: _vm.openEditModal,
                  viewRolePermissions: _vm.openRolePermissionsModal,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _c("UserEditRoleModal", {
        attrs: { user: _vm.userToEdit },
        on: {
          onSuccess: function () {
            return _vm.populateUsers({ withLoadingState: false })
          },
        },
      }),
      _c("SettingsTeamDeleteUserModal", {
        attrs: { user: _vm.userToDelete },
        on: {
          onSuccess: function () {
            return _vm.populateUsers({ withLoadingState: false })
          },
        },
      }),
      _c("RolePermissionsModal", { attrs: { userRole: _vm.userRole } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }