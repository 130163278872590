<template>
  <ConversionOptimizationManager
    :conversionOptimizationType="CONVERSION_OPTIMIZATION_TYPES.SURVEY"
  />
</template>

<script>
import { CONVERSION_OPTIMIZATION_TYPES } from '@atomicfi/constants-shared'
import ConversionOptimizationManager from '@/components/Modules/ConversionOptimization/ConversionOptimizationManager'

export default {
  name: 'SettingsSurveys',
  components: {
    ConversionOptimizationManager,
  },
  data() {
    return {
      CONVERSION_OPTIMIZATION_TYPES,
    }
  },
}
</script>
