var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-white dropdown-toggle",
        attrs: {
          type: "button",
          id: "language-selector",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [
        _vm._v(
          " " +
            _vm._s(_vm.selectedLanguage.flag) +
            " " +
            _vm._s(_vm.selectedLanguage.title) +
            " "
        ),
        _c("ChevronDownIcon", { attrs: { size: "14" } }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dropdown-menu dropdown-menu-right",
        attrs: { "aria-labelledby": "language-selector" },
      },
      _vm._l(_vm.languages, function (language) {
        return _c(
          "button",
          {
            key: language.code,
            staticClass: "dropdown-item",
            attrs: { type: "button", value: language.code },
            on: {
              click: function () {
                return _vm.selectLanguage(language.code)
              },
            },
          },
          [
            _vm._v(
              " " + _vm._s(language.flag) + " " + _vm._s(language.title) + " "
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }