var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row align-items-center" }, [
    _c(
      "nav",
      {
        staticClass: "navbar navbar-vertical navbar-light",
        staticStyle: {
          position: "relative",
          "border-width": "0",
          "background-color": "inherit",
        },
      },
      [
        _c(
          "ul",
          { staticClass: "navbar-nav mb-md-3" },
          [
            _vm._m(0),
            _c("EmulatorNavigationItem", {
              attrs: { name: "initialization", icon: _vm.PlayCircleIcon },
            }),
            _vm.demoIsAvailable
              ? _c("EmulatorNavigationItem", {
                  attrs: { name: "demo", icon: _vm.ZapIcon },
                })
              : _vm._e(),
            _vm._m(1),
            _c("EmulatorNavigationItem", {
              attrs: {
                name: "styles",
                icon: _vm.EyeIcon,
                requiresInitialization: true,
              },
            }),
            _c("EmulatorNavigationItem", {
              attrs: {
                name: "fonts",
                icon: _vm.TypeIcon,
                requiresInitialization: true,
              },
            }),
            _vm.screensToCustomize.length
              ? _c("li", { staticClass: "mt-4" }, [
                  _c("h6", { staticClass: "header-pretitle" }, [
                    _vm._v("Screens"),
                  ]),
                ])
              : _vm._e(),
            _vm._l(_vm.screensToCustomize, function (screen) {
              return _c("EmulatorNavigationItem", {
                key: screen.name,
                attrs: {
                  name: screen.name,
                  icon: screen.icon,
                  requiresInitialization: true,
                },
              })
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("h6", { staticClass: "header-pretitle" }, [_vm._v("General")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mt-4" }, [
      _c("h6", { staticClass: "header-pretitle" }, [_vm._v("Theme")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }