var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "list-group" },
        _vm._l(_vm.outputs, function (ref) {
          var label = ref.label
          var value = ref.value
          return _c("div", { key: value, staticClass: "list-group-item" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("small", { staticClass: "col text-uppercase text-muted" }, [
                _vm._v(" " + _vm._s(label) + " "),
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v(" " + _vm._s(value) + " "),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }