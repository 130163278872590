export const exceptions = {
  walmart: {
    onlyTwoAccountsAllowed: {
      explanation:
        'Walmart only allows two accounts, so this would fail for distribution not supported.',
    },
  },
  gusto: {
    mixedDistributionsNotAllowed: {
      explanation:
        'Gusto does not allow mixed distributions, in this case a user would be prompted to adjust their selections.',
    },
  },
}
