var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3 p-4" },
    [
      _c("h2", {}, [_vm._v("Transact SDK Emulator")]),
      _c("p", { staticClass: "text-secondary mb-5" }, [
        _vm._v("Choose a solution to kick this off 🚀"),
      ]),
      _vm._l(_vm.filteredScopeOptions, function (scopeOption) {
        return _c("ScopeOption", {
          key: scopeOption.name,
          attrs: { hideIcon: true, scopeOption: scopeOption },
          on: { selected: _vm.selectScope },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }