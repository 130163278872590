var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-observer", {
    attrs: { tag: "div" },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var handleSubmit = ref.handleSubmit
            var invalid = ref.invalid
            return [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return handleSubmit(_vm.submit)
                    },
                  },
                },
                [
                  _c(
                    "Card",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _vm.title
                                  ? _c(
                                      "h4",
                                      { staticClass: "card-header-title" },
                                      [_vm._v(_vm._s(_vm.title))]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "button",
                                  {
                                    class: "btn btn-" + _vm.submitButtonVariant,
                                    attrs: {
                                      type: "submit",
                                      disabled: _vm.isProcessing || invalid,
                                    },
                                  },
                                  [
                                    _vm.isProcessing
                                      ? _c("span", {
                                          staticClass:
                                            "spinner-border spinner-border-sm",
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " + _vm._s(_vm.submitButtonText) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_vm._t("default", null, { disabled: _vm.isProcessing })],
                    2
                  ),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }