var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-observer", {
    attrs: { tag: "div" },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var handleSubmit = ref.handleSubmit
            var invalid = ref.invalid
            return [
              _c(
                "b-modal",
                {
                  ref: _vm.id,
                  attrs: {
                    id: _vm.id,
                    title: _vm.title,
                    "no-close-on-backdrop": "",
                  },
                  on: {
                    close: function ($event) {
                      return _vm.$emit("close")
                    },
                    hidden: function ($event) {
                      return _vm.$emit("hidden")
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "modal-footer",
                        fn: function () {
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isProcessing,
                                },
                                on: { click: _vm.onCancel },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            !_vm.submitButtonHidden
                              ? _c(
                                  "button",
                                  {
                                    class: "btn btn-" + _vm.submitButtonVariant,
                                    attrs: {
                                      type: "submit",
                                      disabled: _vm.isProcessing || invalid,
                                    },
                                    on: { click: _vm.submit },
                                  },
                                  [
                                    _vm.isProcessing
                                      ? _c("span", {
                                          staticClass:
                                            "spinner-border spinner-border-sm",
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " + _vm._s(_vm.submitButtonText) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.isVisible,
                    callback: function ($$v) {
                      _vm.isVisible = $$v
                    },
                    expression: "isVisible",
                  },
                },
                [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return handleSubmit(_vm.submit)
                        },
                      },
                    },
                    [_vm._t("default", null, { disabled: _vm.isProcessing })],
                    2
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }