var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltip,
          expression: "tooltip",
        },
      ],
      staticClass: "nav-item",
      class: { "not-allowed": _vm.isDisabled },
      attrs: { tabindex: "0" },
    },
    [
      _c(
        "button",
        {
          staticClass: "nav-link btn btn-link",
          class: { active: _vm.activeNavItem === _vm.name },
          attrs: { disabled: _vm.isDisabled },
          on: {
            click: function () {
              return _vm.onClick(_vm.name)
            },
          },
        },
        [
          _c(_vm.icon, {
            tag: "component",
            staticClass: "me-3 mt-1",
            attrs: { size: "18" },
          }),
          _vm._v(" " + _vm._s(_vm.formattedName) + " "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }