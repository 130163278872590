import Vue from 'vue'
import SlideFadeTransition from './Transitions/SlideFadeTransition'
import FadeTransition from './Transitions/FadeTransition'
import Wrapper from './MainContent/Wrapper'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { email, required, is } from 'vee-validate/dist/rules'

export function registerValidation() {
  // Install vee-validate rules
  Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule])
  })

  extend('email', {
    ...email,
    message: 'Please enter a valid email address.',
  })

  extend('required', {
    ...required,
    params: ['message'],
    message: (_, { message }) => message || 'This field is required.',
  })

  extend('is', {
    ...is,
    message: (_, placeholders) => `You must enter ${placeholders.other}.`,
  })

  extend('url', {
    validate(value) {
      if (value) {
        return /^https?:\/\/(.*)$/.test(value)
      }

      return false
    },
    message: 'Please enter a valid URL.',
  })

  // Set up vee-validate classes
  configure({
    classes: {
      valid: '',
      invalid: 'is-invalid',
      dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
    },
  })
}

/**
 * Registers vue components globally
 */
export function registerGlobal() {
  Vue.component('v-select', vSelect)
  Vue.component('validation-observer', ValidationObserver)
  Vue.component('validation-provider', ValidationProvider)
  Vue.component('SlideFadeTransition', SlideFadeTransition)
  Vue.component('FadeTransition', FadeTransition)
  Vue.component('Wrapper', Wrapper)
}
