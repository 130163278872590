<template>
  <div
    class="card-body review-item d-flex align-items-center justify-content-center"
    v-tooltip="'Click to edit'"
    @click="editPendingMutation"
  >
    <slot name="before"></slot>
    <ArrowRightIcon size="16" class="mx-3 flex-shrink-0" :class="arrowClass" />
    <slot name="after"></slot>
  </div>
</template>

<script>
import { ArrowRightIcon } from 'vue-feather-icons'
import { mapMutations } from 'vuex'

export default {
  name: 'EmulatorMutationPreview',
  props: {
    arrowClass: {
      type: String,
      default: '',
    },
    mutation: {
      type: Object,
      required: true,
    },
  },
  components: {
    ArrowRightIcon,
  },
  methods: {
    ...mapMutations('emulator', ['setActiveNavItem', 'setActiveTabIndex', 'setLanguage']),
    editPendingMutation() {
      this.setActiveNavItem(this.mutation.navItem)
      this.setActiveTabIndex(this.mutation.tabIndex)
      this.setLanguage(this.mutation.language)
    },
  },
}
</script>

<style scoped lang="scss">
.review-item {
  border: 1px solid var(--bs-white);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--bs-light);
  }
}
</style>
