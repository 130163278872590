<template>
  <button type="button" class="card text-left" @click="onSelect(scopeOption)" :class="{ active }">
    <div
      class="avatar avatar-xs select-indicator d-flex align-items-center justify-content-center"
      v-if="toggleable"
    >
      <CheckIcon size="14" class="text-white" />
    </div>
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto" v-if="!hideIcon">
          <div class="avatar d-flex align-items-center justify-content-center">
            <img :src="`/images/emulator/${scopeOption.name}.svg`" />
          </div>
        </div>
        <div class="col ms-n2">
          <h4 class="mb-3">{{ scopeOption.label }}</h4>
          <span
            class="badge bg-secondary-soft m-2 ms-0"
            v-for="useCase in scopeOption.useCases"
            :key="useCase"
            >{{ useCase }}</span
          >
        </div>
        <div class="col-auto" v-if="!toggleable">
          <ArrowRightIcon size="15" class="ms-sm-2 text-muted scope-button-icon" />
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { ArrowRightIcon, CheckIcon } from 'vue-feather-icons'

export default {
  name: 'ScopeOption',
  props: ['scopeOption', 'active', 'toggleable', 'hideIcon'],
  emits: ['selected'],
  components: {
    CheckIcon,
    ArrowRightIcon,
  },
  methods: {
    onSelect(scopeOption) {
      this.$emit('selected', scopeOption)
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  border-radius: 50px;
  img {
    height: 45px;
  }
}
.card {
  border-width: 2px;
  cursor: pointer;
  user-select: none;
  box-shadow: none;
  position: relative;
  .select-indicator {
    width: 20px;
    height: 20px;
    position: absolute;
    border: 1px solid #edeff5;
    top: 15px;
    right: 15px;
  }
  &:hover {
    transition: 600ms border-color;
    border-color: #b0abff;
    border-width: 2px;
  }
  &.active {
    .select-indicator {
      background-color: var(--bs-success);
      border: none;
      svg {
        display: block;
      }
    }
    border-color: var(--bs-primary);
    border-width: 2px;
  }
}
.badge.bg-secondary-soft {
  background-color: #f1f6ff !important;
}
</style>
