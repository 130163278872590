<template>
  <div class="fields">
    <div
      v-for="{
        key: keyLevel0,
        label,
        fields: fieldsLevel1,
        helperText,
        disabled,
      } in fieldDefinitions"
      :key="keyLevel0"
    >
      <Checkbox
        :label="getLabel(label, keyLevel0)"
        :value="fieldIsSelected(keyLevel0)"
        @input="fieldsLevel1 ? toggleAllFields($event, keyLevel0) : toggleField($event, keyLevel0)"
        :helperText="helperText"
        :disabled="disabled || disableAll || fieldIsRequired(keyLevel0)"
        :required="fieldIsRequired(keyLevel0)"
      />
      <div
        v-for="{
          key: keyLevel1,
          label,
          fields: fieldsLevel2,
          helperText,
          disabled,
        } in fieldsLevel1"
        :key="keyLevel1"
      >
        <Checkbox
          :label="getLabel(label, keyLevel1)"
          :value="fieldIsSelected([keyLevel0, keyLevel1])"
          @input="
            fieldsLevel2
              ? toggleAllFields($event, [keyLevel0, keyLevel1])
              : toggleField($event, [keyLevel0, keyLevel1])
          "
          :key="keyLevel1"
          :helperText="helperText"
          :disabled="disabled || disableAll || fieldIsRequired([keyLevel0, keyLevel1])"
          :required="fieldIsRequired([keyLevel0, keyLevel1])"
          class="nested-level-1"
        />
        <div v-if="fieldsLevel2">
          <Checkbox
            v-for="{
              key: keyLevel2,
              label,
              fields: fieldsLevel3,
              helperText,
              disabled,
            } in fieldsLevel2"
            :label="getLabel(label, keyLevel2)"
            :value="fieldIsSelected([keyLevel0, keyLevel1, keyLevel2])"
            @input="
              fieldsLevel3
                ? toggleAllFields($event, [keyLevel0, keyLevel1, keyLevel2])
                : toggleField($event, [keyLevel0, keyLevel1, keyLevel2])
            "
            :key="keyLevel2"
            :helperText="helperText"
            :disabled="disabled || disableAll || fieldIsRequired([keyLevel0, keyLevel1, keyLevel2])"
            :required="fieldIsRequired([keyLevel0, keyLevel1, keyLevel2])"
            class="nested-level-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from './Checkbox'
import { cloneDeep, set, get, startCase } from 'lodash-es'

function _fieldIsTrue(fields, path, key) {
  const value = get(fields, path)

  if (typeof value[key] === 'boolean') {
    return value[key]
  } else if (typeof value === 'object') {
    return Object.keys(value).some((subPath) => _fieldIsTrue(value, subPath, key))
  } else {
    return false
  }
}

function _setAllBooleanValues(fields, newValue) {
  Object.entries(fields).forEach(([key, value]) => {
    if (typeof value.isIncluded === 'boolean') {
      fields[key].isIncluded = newValue
    } else {
      _setAllBooleanValues(value, newValue)
    }
  })
}

export default {
  name: 'FieldSelectionForm',
  components: {
    Checkbox,
  },
  props: {
    disableAll: Boolean,
    fieldDefinitions: Array,
    value: Object,
  },
  methods: {
    getLabel(label, key) {
      return label || startCase(key)
    },
    fieldIsSelected(path) {
      return _fieldIsTrue(this.value, path, 'isIncluded')
    },
    fieldIsRequired(path) {
      return _fieldIsTrue(this.value, path, 'isRequired')
    },
    toggleField(checked, path) {
      const fieldsCopy = cloneDeep(this.value)

      set(fieldsCopy, path + '.isIncluded', checked)

      this.$emit('input', fieldsCopy)
    },
    toggleAllFields(checked, path) {
      const fieldsCopy = cloneDeep(this.value)
      _setAllBooleanValues(get(fieldsCopy, path), checked)

      this.$emit('input', fieldsCopy)
    },
  },
}
</script>

<style scoped lang="scss">
.fields {
  margin-top: 16px;
  margin-left: 16px;
}
.nested-level-1 {
  margin-left: 32px;
}
.nested-level-2 {
  margin-left: 64px;
}
</style>
