var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$auth.loading
    ? _c("div", { attrs: { id: "app" } }, [
        _vm.$route.meta.public
          ? _c("div", [_c("router-view")], 1)
          : _c(
              "div",
              { key: _vm.activeCustomer ? _vm.activeCustomer._id : null },
              [
                !_vm.initialized ? _c("FullLoader") : _vm._e(),
                _vm.initialized ? _c("router-view") : _vm._e(),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }