var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MiniMetric", {
    attrs: {
      title: "Completed Tasks",
      value: _vm.completedTasks.toLocaleString(),
      isLoading: !_vm.isDataReady,
    },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function () {
          return [
            _c("CheckIcon", {
              staticClass: "h2 text-muted mb-0",
              attrs: { size: "20" },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }