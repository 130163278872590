import { ADD_FIXED } from '@/lib/simulation-scenarios/add-fixed'
import { ADD_TOTAL } from '@/lib/simulation-scenarios/add-total'
import { ADD_PERCENT } from '@/lib/simulation-scenarios/add-percent'
import { analyticsEvents } from '@/lib/simulation-scenarios/analytics-events'

export const SIMULATION_SCENARIOS = {
  prompt: 'What type of account is being added?',
  options: {
    total: ADD_TOTAL,
    fixed: ADD_FIXED,
    percent: ADD_PERCENT,
  },
  analyticsEvents
}
