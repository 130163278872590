import Ably from 'ably'
import ConfigService from '@/services/ConfigService'

let ablyInstance

export default {
  install: function (Vue) {
    ablyInstance = new Ably.Realtime.Promise({
      log: {
        level: process.env.NODE_ENV === 'production' ? 0 : 4,
      },
      authCallback: async (tokenParams, callback) => {
        try {
          const response = await ConfigService.getAblyToken()

          callback(null, response.data)
        } catch (error) {
          callback(error, null)
        }
      },
      echoMessages: false,
    })
    ablyInstance.connection.on('connected', () => console.log('Ably is connected'))
    ablyInstance.connection.on('disconnected', (error) =>
      console.log('Ably is disconnected', error)
    )
    ablyInstance.connection.on('failed', () => console.log('Ably is failed'))

    Vue.prototype.$ably = ablyInstance
  },
}
