var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.form1099s, function (ref) {
      var totalWages = ref.totalWages
      var year = ref.year
      var form = ref.form
      return _c("div", { key: year + totalWages, staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("div", { staticClass: "d-flex flex-row mt-n2" }, [
                _vm._m(0, true),
                _c("div", { staticClass: "me-4" }, [
                  _c("h6", { staticClass: "text-muted text-uppercase m-0" }, [
                    _vm._v("year"),
                  ]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm._f("moment")(year, "YYYY")) + " "),
                  ]),
                ]),
                _c("div", [
                  _c("h6", { staticClass: "text-muted text-uppercase m-0" }, [
                    _vm._v("total wages"),
                  ]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm._f("currency")(totalWages)) + " "),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col text-right align-self-center" },
              [
                _c(
                  "ViewTaskOutputFileButton",
                  { attrs: { taskId: _vm.taskId, fileId: form } },
                  [_vm._v("View Form")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "me-4" }, [
      _c("h6", { staticClass: "text-muted text-uppercase m-0" }, [
        _vm._v("type"),
      ]),
      _c("div", [_vm._v(" 1099 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }