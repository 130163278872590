<template>
  <Card>
    <template #header>
      <h4 class="card-header-title">{{ conversionOptimizationType | startCase }}s</h4>
    </template>
    <p>
      To improve the Atomic experience and increase conversion rate, our team periodically runs
      {{ conversionOptimizationType }}s to make data driven decisions around modifications. Please
      opt in to help us make our product better for our shared customers.
    </p>
    <slot name="introduction"></slot>
    <div class="global-toggle-wrapper bg-secondary-light">
      <div>
        <p class="global-toggle-label">Enable all {{ conversionOptimizationType }}s</p>
        <span class="global-toggle-helper-text">{{
          globalToggleSetting
            ? `You will be opted in to all ${CONVERSION_OPTIMIZATION_STATUSES.ACTIVE} and ${CONVERSION_OPTIMIZATION_STATUSES.UPCOMING} ${conversionOptimizationType}s.`
            : `You will need to opt in to ${CONVERSION_OPTIMIZATION_STATUSES.ACTIVE} and ${CONVERSION_OPTIMIZATION_STATUSES.UPCOMING} ${conversionOptimizationType}s individually.`
        }}</span>
      </div>
      <CustomSwitch
        :value="globalToggleSetting"
        :label="`Global opt-in for ${conversionOptimizationType}s`"
        @input="onGlobalToggleChange"
        :showLabelText="false"
        :formGroupWrap="false"
      />
    </div>
    <ul class="nav nav-tabs">
      <li v-for="tab in tabs" :key="tab" class="nav-item">
        <a
          href="#"
          class="nav-link"
          :class="{ active: currentTab === tab }"
          @click.prevent="() => setCurrentTab(tab)"
        >
          {{ tab | startCase }}
        </a>
      </li>
    </ul>
    <ContentLoader :contentLoaded="state === 'loaded'" :contentLoading="state === 'loading'">
      <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
          <EmptyState v-if="!conversionOptimizations.length" class="mt-4 mb-0">
            <div class="d-block mb-3">
              <div class="d-inline-block mx-auto p-3 bg-secondary-light rounded">
                <LayersIcon />
              </div>
            </div>
            No {{ currentTab }} {{ conversionOptimizationType }}s.
          </EmptyState>
          <ConversionOptimizationCard
            v-for="conversionOptimization in conversionOptimizations"
            :conversionOptimization="conversionOptimization"
            :key="conversionOptimization._id"
          />
        </div>
      </div>
    </ContentLoader>
  </Card>
</template>

<script>
import { CONVERSION_OPTIMIZATION_STATUSES } from '@atomicfi/constants-shared'
import { get, startCase } from 'lodash-es'
import { getCustomerUpdater } from '@/lib/get-customer-updater'
import { LayersIcon } from 'vue-feather-icons'
import { mapActions, mapState } from 'vuex'
import Card from '@/components/MainContent/Card'
import ContentLoader from '@/components/MainContent/ContentLoader'
import ConversionOptimizationCard from '@/components/Modules/ConversionOptimization/ConversionOptimizationCard'
import ConversionOptimizationService from '@/services/ConversionOptimizationService'
import CustomSwitch from '@/components/MainContent/CustomSwitch'
import EmptyState from '@/components/MainContent/EmptyState.vue'

export default {
  name: 'ConversionOptimizationManager',
  components: {
    Card,
    ContentLoader,
    ConversionOptimizationCard,
    CustomSwitch,
    EmptyState,
    LayersIcon,
  },
  props: {
    conversionOptimizationType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      CONVERSION_OPTIMIZATION_STATUSES,
      conversionOptimizations: {
        docs: [],
      },
      currentTab: CONVERSION_OPTIMIZATION_STATUSES.ACTIVE,
      state: 'idle',
      tabs: [
        CONVERSION_OPTIMIZATION_STATUSES.ACTIVE,
        CONVERSION_OPTIMIZATION_STATUSES.UPCOMING,
        CONVERSION_OPTIMIZATION_STATUSES.COMPLETED,
      ],
    }
  },
  computed: {
    ...mapState('customer', ['activeCustomer']),
    customerPathToConversionOptimizationSettings() {
      return `features.transact${startCase(this.conversionOptimizationType)}s`
    },
    globalToggleSettingPath() {
      return this.customerPathToConversionOptimizationSettings + '.enabled'
    },
    globalToggleSetting() {
      return get(this.activeCustomer, this.globalToggleSettingPath)
    },
  },
  methods: {
    ...mapActions('customer', ['updateCustomer']),
    async getConversionOptimizations() {
      this.state = 'loading'

      const response = await ConversionOptimizationService.list({
        status: this.currentTab,
        type: this.conversionOptimizationType,
      })

      this.conversionOptimizations = response.body.data.docs

      this.state = 'loaded'
    },
    async setCurrentTab(tab) {
      this.currentTab = tab

      await this.getConversionOptimizations()
    },
    async onGlobalToggleChange(enabled) {
      const customerUpdate = getCustomerUpdater({
        getUpdates: () => ({
          [this.globalToggleSettingPath]: enabled,
        }),
        localStateKey: 'globalToggleSetting',
      }).bind(this)

      await customerUpdate()
    },
  },
  async mounted() {
    await this.getConversionOptimizations()
  },
}
</script>

<style scoped>
.bg-secondary-light {
  background-color: #edeff5;
}

.global-toggle-wrapper {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.global-toggle-label {
  color: #21253d;
  font-weight: 500;
  margin-bottom: 0.25em;
}

.global-toggle-helper-text {
  color: #696f8c;
  font-size: 0.933333rem;
}
</style>
