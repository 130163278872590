o
<template>
  <div class="funnel-settings">
    <!-- Open Sidebar Button -->
    <button @click="toggleSettings" class="btn btn-light" title="Explore">Funnel Builder</button>

    <!-- Sidebar -->
    <b-sidebar
      v-model="showSettings"
      bg-variant="dark"
      :backdrop="true"
      :no-header="true"
      width="35em"
      shadow="lg"
      right
      v-scroll-lock="showSettings"
    >
      <div class="offcanvas-body">
        <div class="mb-4">
          <!-- Title -->
          <h1 class="header-title">Funnel Builder</h1>

          <!-- Subtitle -->
          <p class="header-subtitle">
            Construct your own custom funnel to learn more about how your users behave within
            Atomic's SDK. To learn more about which events you'd like to use, see our
            <a
              target="_blank"
              href="https://docs.atomicfi.com/reference/transact-sdk#event-listeners__interaction-events"
              class="text-white"
              >interaction event documentation</a
            >.
          </p>
        </div>

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">Build a funnel</h4>
            <a href="#" @click="addEmptyStep"><PlusIcon size="15" class="text-white" /></a>
          </div>
          <div class="card-body">
            <draggable
              v-model="events"
              tag="ul"
              class="list-group list-group-flush steps-list"
              @change="eventOrderChanged"
            >
              <li class="list-group-item" v-for="(event, index) in events" :key="index">
                <div class="row">
                  <div class="col-auto move-icon mt-2">
                    <MenuIcon size="10" class="text-muted" />
                  </div>
                  <div class="col-auto badge-container mt-2">
                    <span class="badge bg-primary-soft merge-into-header">{{ index + 1 }}</span>
                  </div>
                  <div class="col event-name">
                    <div class="event-select">
                      <v-select
                        class="vs__dark"
                        v-model="events[index]"
                        :value="getFriendlyEventName(events[index])"
                        :options="lexicon"
                        :clearable="false"
                        @option:selected="eventNameChanged"
                      />
                      <div class="actions btn-group">
                        <button
                          @click="addBlankFilter(index)"
                          class="btn btn-sm btn-outline-dark"
                          v-if="getEventFilters(event).length && event"
                        >
                          <FilterIcon size="14" class="text-muted" />
                        </button>
                        <button @click="removeEvent(index)" class="btn btn-sm btn-outline-dark">
                          <XIcon size="14" class="text-muted" />
                        </button>
                      </div>
                    </div>
                    <div class="filters list-group" v-if="filters[index]">
                      <li
                        class="list-group-item"
                        v-for="(filter, filterIndex) in filters[index]"
                        :key="filterIndex"
                      >
                        <div class="actions btn-group">
                          <button
                            @click="removeFilter(index, filterIndex)"
                            class="btn btn-sm btn-outline-dark"
                          >
                            <XIcon size="14" class="text-muted" />
                          </button>
                        </div>
                        <div class="row ms-0 me-0 mb-0">
                          <div
                            class="col-auto ps-0 pe-0 d-flex align-items-center justify-content-center"
                          >
                            <FilterIcon size="14" class="text-muted" />
                          </div>
                          <div class="col pe-0">
                            <v-select
                              v-model="filters[index][filterIndex].property"
                              :options="getEventFilters(event)"
                              :ref="`${index}-${filterIndex}`"
                              @input="selectedFilter(index, filterIndex)"
                              :clearable="false"
                              class="vs__dark vs__transparent vs__small"
                              :dropdown-should-open="
                                filters[index][filterIndex].property
                                  ? undefined
                                  : filterDropdownShouldOpen(index, filterIndex)
                              "
                            />
                          </div>
                          <div
                            class="row ms-0 me-0 mb-0 pe-0"
                            v-if="filters[index][filterIndex].property"
                          >
                            <div class="col-1" />
                            <div class="col-1 text-secondary d-flex align-items-center">
                              <span>
                                <small>{{ filter.operator }}</small>
                              </span>
                            </div>
                            <div class="col-10 pe-0">
                              <v-select
                                v-model="filters[index][filterIndex].value"
                                :ref="`${index}-${filterIndex}-value`"
                                @input="selectedFilterValue(index, filterIndex)"
                                :options="
                                  getEventFilterOptions(filters[index][filterIndex].property)
                                "
                                :dropdown-should-open="
                                  filters[index][filterIndex].value
                                    ? undefined
                                    : filterOptionDropdownShouldOpen(index, filterIndex)
                                "
                                :clearable="false"
                                class="vs__dark vs__small"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </li>
            </draggable>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">Suggested funnels</h4>
          </div>
          <div class="card-body">
            <!-- List group -->
            <div class="list-group list-group-flush my-n3">
              <div
                v-for="suggestion in suggestions"
                class="list-group-item"
                :key="suggestion.title"
                @click="setActiveSuggestion(suggestion)"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <a href="#" class="avatar">
                      <img :src="suggestion.image" alt="..." class="avatar-img rounded" />
                    </a>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="mb-1">
                      <a href="#">{{ suggestion.title }}</a>
                    </h4>

                    <small class="text-muted"> {{ suggestion.description }} </small>
                  </div>
                  <div class="col-auto">
                    <CheckIcon
                      size="20"
                      v-show="activeSuggestion === suggestion.title"
                      class="h2 text-success mb-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer="{ hide }">
        <div class="p-3">
          <button
            type="submit"
            class="btn btn-lg w-100 btn-primary mt-auto"
            @click="applySettings(hide)"
          >
            Apply Settings
          </button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { getFriendlyEventName, funnelSuggestions, TRANSACT_EVENT_FILTERS } from '@/utils/activity'
import { FilterIcon, MenuIcon, CheckIcon, PlusIcon, XIcon } from 'vue-feather-icons'
import draggable from 'vuedraggable'
import { TRANSACT_EVENTS } from '@/utils/constants'

export default {
  name: 'FunnelSettings',
  computed: {
    ...mapState('activity', ['funnel']),
  },
  components: { draggable, CheckIcon, FilterIcon, MenuIcon, PlusIcon, XIcon },
  data() {
    return {
      showSettings: false,
      state: 'idle',
      events: [],
      filters: {},
      activeSuggestion: undefined,
      lexicon: Object.keys(TRANSACT_EVENTS).map((key) => {
        return TRANSACT_EVENTS[key]
      }),
      eventFilters: Object.keys(TRANSACT_EVENT_FILTERS),
      suggestions: funnelSuggestions(),
    }
  },
  methods: {
    ...mapMutations('activity', ['setFunnelSteps', 'setFunnelFilters', 'setFunnelIsLoading']),
    ...mapActions('activity', ['getFunnel']),
    async applySettings(closeSidebar) {
      this.setFunnelSteps([...this.events])
      this.setFunnelFilters(this.filters)
      this.setFunnelIsLoading(true)
      closeSidebar()
      await this.getFunnel()
      this.setFunnelIsLoading(false)
      this.$analytics.track({
        event: 'Applied Funnel Settings',
      })
    },
    addBlankFilter(index) {
      if (!this.filters[index]) {
        this.$set(this.filters, index, [])
      }

      this.$set(this.filters, index, [
        ...this.filters[index],
        {
          property: undefined,
          operator: 'is',
          value: undefined,
        },
      ])
    },
    eventNameChanged(funnelStepName) {
      this.$analytics.track({
        event: 'Changed Funnel Step Name',
        payload: {
          funnelStepName,
        },
      })
    },
    eventOrderChanged({ moved }) {
      if (moved) {
        this.$analytics.track({
          event: 'Moved Funnel Steps',
          payload: {
            funnelStepMoved: moved.element,
            funnelStepOldIndex: moved.oldIndex,
            funnelStepNewIndex: moved.newIndex,
          },
        })
      }
    },
    removeFilter(eventIndex, filterIndex) {
      this.filters[eventIndex].splice(filterIndex, 1)
    },
    removeEvent(index) {
      const funnelStepRemoved = this.events[index]

      this.$analytics.track({
        event: 'Removed Funnel Step',
        payload: {
          funnelStepRemoved,
        },
      })

      this.events.splice(index, 1)
      delete this.filters[index]
    },
    getEventFilters(event) {
      return Object.keys(TRANSACT_EVENT_FILTERS).filter((key) => {
        return (
          TRANSACT_EVENT_FILTERS[key].events?.includes(event) || !TRANSACT_EVENT_FILTERS[key].events
        )
      })
    },
    getEventFilterOptions(property) {
      return TRANSACT_EVENT_FILTERS[property].options
    },
    addEmptyStep() {
      this.events.push('')
      this.$analytics.track({
        event: 'Added Funnel Step',
      })
    },
    setActiveSuggestion(suggestion) {
      this.events = suggestion.steps
      this.filters = suggestion.filters
      this.activeSuggestion = suggestion.title
      this.$analytics.track({
        event: 'Selected Suggested Funnel',
        payload: {
          suggestedFunnelTitle: suggestion.title,
        },
      })
    },
    selectedFilter(index, filterIndex) {
      setTimeout(() => {
        this.$refs?.[`${index}-${filterIndex}`][0].closeSearchOptions()
      }, 1)
      this.filters[index][filterIndex].value = undefined
    },
    selectedFilterValue(index, filterIndex) {
      setTimeout(() => {
        this.$refs?.[`${index}-${filterIndex}-value`][0].closeSearchOptions()
      }, 1)
    },
    getFriendlyEventName,
    toggleSettings() {
      this.showSettings = !this.showSwitcher
      this.$analytics.track({
        event: 'Toggle Funnel Settings',
        payload: {
          showSettings: this.showSettings,
        },
      })
    },
    filterDropdownShouldOpen(index, filterIndex) {
      let hasValue = this.filters[index][filterIndex].property ? false : true
      return function () {
        return hasValue
      }
    },
    filterOptionDropdownShouldOpen(index, filterIndex) {
      let hasValue = this.filters[index][filterIndex].value ? false : true
      return function () {
        return hasValue
      }
    },
  },
  mounted() {
    this.events = Array.from(this.funnel.steps)
    this.filters = this.funnel.filters
  },
}
</script>

<style lang="scss" scoped>
.funnel-settings {
  $spacing: 1.5rem;

  background-color: #282c34;

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgb(2 14 32) !important;
  }

  .bg-primary-soft {
    background-color: #143767 !important;
    color: #2c7be5;
  }

  .actions {
    display: none;
    position: absolute;
    z-index: 10;
  }

  .event-select {
    position: relative;
    .actions {
      right: 34px;
      top: 5.5px;
    }
    &:hover {
      .actions {
        display: block;
      }
    }
  }

  h1,
  h4 {
    color: #fff;
  }

  .list-group-item {
    background-color: transparent;
    border-color: #1e3a5c;
  }

  .steps-list {
    .list-group-item {
      .event-select {
        min-height: 39px;
      }
    }
  }

  .filters {
    padding: 0px;
    margin: 0px;
    .list-group-item {
      .actions {
        right: 34px;
        top: 18px;
      }
      padding: 7px 0px 0px 0px;
      border: none;
      &:hover {
        .actions {
          display: block;
        }
      }
    }
  }

  .list-group {
    border-radius: 0.375rem;
  }

  .list-group-flush {
    border-radius: 0px;
  }

  .badge-container {
    padding-left: 5px;
    padding-right: 4px;
  }

  .list-group-item {
    .move-icon {
      width: 15px;
      padding-left: 2px;
      visibility: hidden;

      &:hover {
        cursor: grab;
      }
      &:active {
        cursor: grabbing;
      }
    }

    &:hover {
      .move-icon {
        visibility: visible;
      }
    }
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $spacing;
  }

  search-container {
    flex: 0 1 auto;
  }

  .list-container {
    flex: 1 1 auto;
    margin-left: -$spacing;
    margin-right: -$spacing;
    overflow-y: auto;
    padding-left: $spacing;
    padding-right: $spacing;
  }

  .switcher-toggle {
    background: transparent;
    border: none;
  }
  .card {
    background-color: #152e4d;
    border: 1px solid #1e3a5c;
    .card-header {
      border-bottom: 1px solid #1e3a5c;
    }
    .card-body {
      padding: 0px;
      .row {
        margin: 10px;
      }
    }
  }
}

.btn-light {
  background-color: #152e4d;
  border-color: #244166;
  color: #fff;
  &:hover,
  &:active,
  &:focus {
    background-color: #12263f;
    border-color: #1e3a5c;
    color: #fff;
  }
}
</style>
