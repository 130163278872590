var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "card col-12" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm.company
            ? _c("Logo", {
                staticClass: "card-avatar card-avatar-top",
                attrs: {
                  branding: _vm.company.branding,
                  name: _vm.company.name,
                  size: "lg",
                },
              })
            : _vm._e(),
          _vm.company
            ? _c("h2", { staticClass: "card-title text-center" }, [
                _vm._v(_vm._s(_vm.company.name)),
              ])
            : _vm._e(),
          _vm.jobTitle
            ? _c("p", { staticClass: "small text-center text-muted mb-1" }, [
                _vm._v(" " + _vm._s(_vm._f("startCase")(_vm.jobTitle)) + " "),
              ])
            : _vm._e(),
          _c("p", { staticClass: "card-text text-center" }, [
            _c("span", { staticClass: "badge bg-secondary-soft" }, [
              _vm._v(_vm._s(_vm._f("startCase")(_vm.employeeType))),
            ]),
          ]),
          _c("div", { staticClass: "row g-0 border-top border-bottom" }, [
            _vm.hasStatements
              ? _c(
                  "div",
                  { staticClass: "col-auto py-4 text-center flex-fill" },
                  [
                    _c("h6", { staticClass: "text-uppercase text-muted" }, [
                      _vm._v("Total Earnings"),
                    ]),
                    _c("h2", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.totalEarnings))),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.startDate
              ? _c(
                  "div",
                  { staticClass: "col-auto py-4 text-center flex-fill" },
                  [
                    _c("h6", { staticClass: "text-uppercase text-muted" }, [
                      _vm._v("Hire Date"),
                    ]),
                    _c("h2", { staticClass: "mb-0" }, [
                      _vm._v(
                        _vm._s(_vm._f("moment")(_vm.startDate, "M/DD/YYYY"))
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.minimumMonthsOfEmployment
              ? _c(
                  "div",
                  { staticClass: "col-auto py-4 text-center flex-fill" },
                  [
                    _c("h6", { staticClass: "text-uppercase text-muted" }, [
                      _vm._v("Employed at least"),
                    ]),
                    _c("h2", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.yearsAndMonthsEmployed)),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "list-group list-group-flush" }, [
            _c("div", { staticClass: "list-group-item" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-auto" }, [
                  _c("div", { staticClass: "identifier" }, [
                    _c("small", [_vm._v(_vm._s(_vm.user.identifier))]),
                  ]),
                ]),
              ]),
            ]),
            _vm.employerName
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-auto" }, [
                      _c("small", [_vm._v(_vm._s(_vm.employerName))]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.employmentStatus
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _vm.isActivelyEmployed
                          ? _c("CheckCircleIcon", {
                              staticClass: "text-success",
                              attrs: { size: "16" },
                            })
                          : _c("XCircleIcon", {
                              staticClass: "text-danger",
                              attrs: { size: "16" },
                            }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.hasStatements
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _vm.hasRecentStatements
                          ? _c("CheckCircleIcon", {
                              staticClass: "text-success",
                              attrs: { size: "16" },
                            })
                          : _c("XCircleIcon", {
                              staticClass: "text-danger",
                              attrs: { size: "16" },
                            }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.product === "deposit" || _vm.status !== "completed"
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm.status === "completed"
                        ? _c("div", { staticClass: "text-success" }, [
                            _c(
                              "span",
                              { staticClass: "badge bg-success-soft" },
                              [_vm._v("Completed")]
                            ),
                          ])
                        : _vm.status === "failed"
                        ? _c("div", [
                            _c(
                              "span",
                              { staticClass: "badge bg-danger-soft" },
                              [_vm._v("Failed")]
                            ),
                          ])
                        : _c("div", [
                            _c(
                              "span",
                              { staticClass: "badge bg-primary-soft" },
                              [_vm._v("Processing")]
                            ),
                          ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.failReason
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "col-auto" }, [
                      _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _c("span", { staticClass: "badge bg-light" }, [
                          _vm._v(_vm._s(_vm.failReason)),
                        ]),
                      ]),
                      _c("small", [
                        _vm._v(
                          _vm._s(_vm.failReasons[_vm.failReason].description)
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.deposit &&
            _vm.deposit.distributionAction &&
            _vm.product === "deposit"
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(6),
                    _c("div", { staticClass: "col-auto" }, [
                      _c("span", { staticClass: "badge bg-light capitalize" }, [
                        _vm._v(_vm._s(_vm.deposit.distributionAction)),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.deposit &&
            _vm.deposit.distributionType &&
            _vm.product === "deposit"
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(7),
                    _c("div", { staticClass: "col-auto" }, [
                      _c("span", { staticClass: "capitalize" }, [
                        _vm.deposit.distributionType === "total"
                          ? _c("span", { staticClass: "badge bg-light" }, [
                              _vm._v(" Entire balance "),
                            ])
                          : _vm._e(),
                        _vm.deposit.distributionType === "percent"
                          ? _c("span", { staticClass: "badge bg-light" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.deposit.distributionAmount) +
                                  "% "
                              ),
                            ])
                          : _vm._e(),
                        _vm.deposit.distributionType === "fixed"
                          ? _c("span", { staticClass: "badge bg-light" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.deposit.distributionAmount
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.deposit &&
            _vm.deposit.routingNumber &&
            _vm.product === "deposit"
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(8),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(" " + _vm._s(_vm.deposit.routingNumber) + " "),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.deposit &&
            _vm.deposit.accountNumber &&
            _vm.product === "deposit"
              ? _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(9),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        " ******" +
                          _vm._s(
                            _vm.deposit.accountNumber.substring(
                              _vm.deposit.accountNumber.length - 4
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm.product === "withhold"
            ? _c("div", { staticClass: "list-group list-group-flush" }, [
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(10),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formVersionMap[_vm.withhold.formVersion]) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(11),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.filingStatusMap[_vm.withhold.filingStatus]
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(12),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.withhold.multipleJobsOrSpouseWorks
                              ? "Yes"
                              : "No"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(13),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        _vm._s(_vm.withhold.nonResidentAlien ? "Yes" : "No")
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(14),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(_vm._s(_vm.withhold.exempt ? "Yes" : "No")),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(15),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        _vm._s(
                          _vm.withhold.dependentTaxCreditEligible ? "Yes" : "No"
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(16),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(_vm._s(_vm.withhold.dependentsUnder17)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(17),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.withhold.dependentsUnder17Amount
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(18),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(_vm._s(_vm.withhold.otherDependents)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(19),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.withhold.otherDependentsAmount)
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(20),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.withhold.dependentAmount))
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(21),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.withhold.otherIncomeNotFromJobsAmount
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(22),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.withhold.deductionsAmount)
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-group-item" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _vm._m(23),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.withhold.extraWithholdingPerPayPeriodAmount
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Identifier")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Employer name in payroll system")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Actively employed")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Recent pay statements")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("small", [_vm._v("Status")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Fail reason")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("small", [_vm._v("Action")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("small", [_vm._v("Amount")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Routing number")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Account number")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Form Version")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Filing Status")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Multiple Jobs or Spouse Works")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Non-Resident Alien")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("small", [_vm._v("Exempt")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Dependent Tax Credit Eligible")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Dependents Under 17")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Dependents Under 17 Amount")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Other Dependents")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Other Dependents Amount")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Dependent Total Amount")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Other Income Not From Jobs")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Deductions")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("small", [_vm._v("Extra Withholding Per Pay Period")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }