<template>
  <div>
    <div class="row align-items-center">
      <div class="col">
        <ul class="nav nav-tabs nav-tabs-sm mt-0">
          <li
            class="nav-item"
            v-for="year in yearsOfPaymentHistory"
            :key="year"
            @click="onYearSelect(year)"
          >
            <a href="javascript:;" class="nav-link" :class="{ active: year === selectedYear }">
              {{ year }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col">
        <!-- Nav -->
        <ul class="nav nav-tabs nav-tabs-sm">
          <li
            class="nav-item"
            v-for="month in monthsOfPaymentHistory"
            :key="month"
            @click="onMonthSelect(month)"
          >
            <a href="javascript:;" class="nav-link" :class="{ active: month === selectedMonth }">
              {{ months[month] }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row align-items-center">
      <small>
        <div class="col">
          <!-- Nav -->
          <ul class="nav nav-tabs header-tabs">
            <li class="nav-item" v-for="statement in statementsToSelect" :key="statement.date+statement.grossAmount">
              <button
                class="btn btn-sm btn-rounded-circle"
                :class="{
                  'btn-primary': statement === selectedStatement,
                  'btn-white': statement !== selectedStatement,
                }"
                @click="onStatementSelect(statement)"
              >
                {{ new Date(statement.date).getUTCDate() }}
              </button>
            </li>
          </ul>
        </div>
      </small>
    </div>
    <TaskStatementBreakdown
      v-if="selectedStatement"
      :taskId="taskId"
      :statement="selectedStatement"
    />
  </div>
</template>

<script>
import TaskStatementBreakdown from '@/components/Modules/Task/TaskElement/TaskStatementBreakdown'
import { getObjectsGroupedByYearAndMonth, getYearsOfPaymentHistory } from '@/utils/task'
import { maxBy, sortBy } from 'lodash-es'

const getMostRecentStatement = (statements) => maxBy(statements, ({ date }) => date)

export default {
  name: 'TaskPaystubs',
  components: { TaskStatementBreakdown },
  props: {
    taskId: String,
    statements: { type: Array, default: () => [] },
  },
  data() {
    return {
      year: undefined,
      month: undefined,
      statement: undefined,
    }
  },
  computed: {
    statementsGroupedByYearAndMonth() {
      return getObjectsGroupedByYearAndMonth(this.statements)
    },
    yearsOfPaymentHistory() {
      return getYearsOfPaymentHistory(this.statements)
    },
    months() {
      return this.$moment.monthsShort()
    },
    selectedYear() {
      return (
        this.year ||
        new Date(getMostRecentStatement(this.statements)?.date).getUTCFullYear().toString()
      )
    },
    selectedMonth() {
      return (
        this.month ||
        new Date(getMostRecentStatement(this.statements)?.date).getUTCMonth().toString()
      )
    },
    selectedStatement() {
      return this.statement || getMostRecentStatement(this.statements)
    },
    monthsOfPaymentHistory() {
      return Object.keys(this.statementsGroupedByYearAndMonth[this.selectedYear] || {})
    },
    statementsToSelect() {
      return sortBy(
        this.statementsGroupedByYearAndMonth[this.selectedYear]?.[this.selectedMonth],
        'date'
      )
    },
  },
  methods: {
    onYearSelect(year) {
      this.year = year
      this.month = this.monthsOfPaymentHistory[0]
      this.statement = this.statementsToSelect[0]
    },
    onMonthSelect(month) {
      this.month = month
      this.statement = this.statementsToSelect[0]
    },
    onStatementSelect(statement) {
      this.statement = statement
    },
  },
}
</script>

<style scoped lang="scss">
.header-tabs .nav-link {
  padding-top: 0rem;
  padding-bottom: 0.5rem;
}
.header-tabs {
  margin-bottom: 0;
  border-bottom-width: 0;
}
.nav {
  flex-wrap: nowrap;
}
</style>
