var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col" }, [
          _vm.displayEventType
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "badge bg-light mb-2",
                    staticStyle: { "font-size": "14px" },
                  },
                  [
                    _c("span", { staticClass: "fw-bold" }, [
                      _vm._v(_vm._s(_vm.event.payload.eventType)),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c("div", [
            !_vm.displayEventType
              ? _c(
                  "span",
                  {
                    staticClass: "badge bg-light me-3",
                    staticStyle: { "font-size": "12px" },
                  },
                  [_c("span", { staticClass: "fw-bold" }, [_vm._v("POST")])]
                )
              : _vm._e(),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.event.createdAt)),
            ]),
          ]),
          _c("div", { staticClass: "mt-1" }, [
            _c("code", { staticClass: "text-secondary" }, [
              _vm._v(
                _vm._s(
                  _vm.event.endpointUrl ||
                    "https://your-domain.com/api/webhooks/atomic"
                )
              ),
            ]),
          ]),
        ]),
        _vm.hasResponse
          ? _c("div", { staticClass: "col-auto capitalize" }, [
              _c(
                "span",
                {
                  staticClass: "badge bg-success-soft",
                  class: _vm.eventStatus.class,
                },
                [
                  _c("span", { staticClass: "event-status" }, [
                    _vm._v(" " + _vm._s(_vm.event.status) + " "),
                  ]),
                  _c(_vm.eventStatus.component, {
                    tag: "component",
                    attrs: { size: "1x" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "col-auto" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: _vm.event._id,
                  expression: "event._id",
                },
              ],
            },
            [_c("ChevronDownIcon", { staticClass: "toggle-icon" })],
            1
          ),
        ]),
      ]),
      _c("b-collapse", { attrs: { id: _vm.event._id } }, [
        _c("div", { staticClass: "mt-4" }, [
          _c("h5", [_vm._v("Request Headers")]),
          _c("div", { staticClass: "card mb-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("pre", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.event.headers)),
              ]),
            ]),
          ]),
          _c("h5", { staticClass: "mt-4" }, [_vm._v("Request Body")]),
          _c("div", { staticClass: "card mb-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("pre", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.event.payload)),
              ]),
            ]),
          ]),
          _vm.hasResponse
            ? _c("div", [
                _c("hr", { staticClass: "my-4" }),
                _c("h5", [_vm._v("Response Headers")]),
                _c("div", { staticClass: "card mb-3" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("pre", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.event.responses[0].headers)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row align-items-center mt-4 mb-1" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h5", { staticClass: "m-0" }, [_vm._v("Response Body")]),
                  ]),
                  _c("div", { staticClass: "col text-end" }, [
                    _c(
                      "span",
                      {
                        staticClass: "badge",
                        class: _vm.eventResponseStatus.class,
                      },
                      [
                        _vm._v(
                          " Status: " +
                            _vm._s(_vm.event.responses[0].status) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "card mb-3" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("pre", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.event.responses[0].data)),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }