<template>
  <div>
    <div class="row">
      <div class="col min-col-3" v-if="shouldShowIncome">
        <TaskIncomeChartCard
          header="Income"
          :text="income | currency"
          :textQualifier="incomeType"
          :isDerived="derivedOutputMetadata.income || derivedOutputMetadata.incomeType"
        />
      </div>
      <div class="col min-col-3" v-if="annualIncome">
        <TaskIncomeChartCard
          header="Annual Income"
          :text="annualIncome | currency"
          :isDerived="derivedOutputMetadata.annualIncome"
        />
      </div>
      <div class="col min-col-3" v-if="hourlyIncome">
        <TaskIncomeChartCard
          header="Hourly Income"
          :text="hourlyIncome | currency"
          :isDerived="derivedOutputMetadata.hourlyIncome"
        />
      </div>
      <div class="col min-col-3" v-if="payCycle">
        <TaskIncomeChartCard
          header="Pay Cycle"
          :text="payCycle | startCase"
          :isDerived="derivedOutputMetadata.payCycle"
        />
      </div>
      <div class="col min-col-3" v-if="nextExpectedPayDate">
        <TaskIncomeChartCard
          header="Next Expected Pay Date"
          :text="nextExpectedPayDate | moment('MM/DD/YYYY')"
          :isDerived="derivedOutputMetadata.nextExpectedPayDate"
        />
      </div>
    </div>
    <div class="card" v-if="hasStatements">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Monthly Comparison</h4>

        <!-- Caption -->
        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
          <li class="nav-item" v-for="year in yearsOfPaymentHistory" :key="year">
            <a
              href="javascript:;"
              class="nav-link"
              :class="{ active: year === selectedYear }"
              data-bs-toggle="tab"
              @click="onYearSelect(year)"
            >
              {{ year }}
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <BasicBarChart :chartData="chartData"></BasicBarChart>
      </div>
    </div>
  </div>
</template>

<script>
import BasicBarChart from '@/components/Charts/BasicBarChart'
import TaskIncomeChartCard from '@/components/Modules/Task/TaskElement/TaskIncomeChartCard.vue'
import sumOfDecimals from '@/lib/sum-of-decimals'
import { getObjectsGroupedByYearAndMonth, getYearsOfPaymentHistory } from '@/utils/task'
import { dropRightWhile } from 'lodash-es'

export default {
  name: 'TaskIncomeChart',
  components: {
    BasicBarChart,
    TaskIncomeChartCard,
  },
  props: {
    income: Number,
    incomeType: String,
    annualIncome: Number,
    hourlyIncome: Number,
    payCycle: String,
    nextExpectedPayDate: String,
    derivedOutputMetadata: { type: Object, default: () => {} },
    statements: { type: Array, default: () => [] },
  },
  data() {
    return {
      year: undefined,
      gridLines: {
        beginAtZero: false,
      },
    }
  },
  computed: {
    getStatementsGroupedByYearAndMonth() {
      return getObjectsGroupedByYearAndMonth(this.statements)
    },
    yearsOfPaymentHistory() {
      return getYearsOfPaymentHistory(this.statements)
    },
    hasStatements() {
      return this.statements.length > 0
    },
    selectedYear() {
      return this.year ?? this.hasStatements
        ? new Date(this.statements[0].date).getUTCFullYear().toString()
        : undefined
    },
    shouldShowIncome() {
      const nonStandardType =
        this.incomeType && this.incomeType !== 'hourly' && this.incomeType !== 'yearly'
      const showHourly = !this.hourlyIncome && this.incomeType === 'hourly'
      const showYearly = !this.annualIncome && this.incomeType === 'yearly'
      return this.income && (nonStandardType || showHourly || showYearly)
    },
    chartData() {
      if (!this.hasStatements) return undefined

      const allMonths = this.$moment.monthsShort()
      const thisMonth = this.$moment().format('MMM')
      const thisYear = this.$moment().format('YYYY')

      const displayedMonths =
        this.selectedYear === thisYear
          ? dropRightWhile(allMonths, (month) => month !== thisMonth)
          : allMonths

      const grossAmontPerMonth = Array(displayedMonths.length).fill(0)

      Object.entries(this.getStatementsGroupedByYearAndMonth[this.selectedYear]).map(
        ([month, statements]) => {
          grossAmontPerMonth[month] = sumOfDecimals(
            statements.map(({ grossAmount }) => grossAmount)
          )
        }
      )

      return {
        labels: displayedMonths,
        datasets: [
          {
            label: 'Income',
            data: grossAmontPerMonth,
          },
        ],
      }
    },
  },
  methods: {
    onYearSelect(year) {
      this.year = year
    },
  },
}
</script>

<style lang="scss" scoped>
.min-col-3 {
  min-width: 33.3333%;
}
</style>
