var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.task._id
    ? _c(
        "Wrapper",
        {
          attrs: {
            title:
              _vm.productTitle[0].toUpperCase() + _vm.productTitle.substring(1),
            pretitle: _vm.task.user.identifier,
            image: "/images/icon-" + _vm.task.product + ".svg",
            imageTitle: _vm.task.product,
          },
        },
        [_c("TaskOverview")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }