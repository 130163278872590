<template>
  <div class="card card-inactive">
    <div class="card-body">
      <div class="row justify-content-center align-items-center">
        <div class="col">
          <h4 class="mb-1">
            <button
              class="btn btn-link btn-sm p-0"
              type="button"
              v-clipboard:copy="endpoint.url"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              title="Copy URL"
              v-tooltip="'Copy URL'"
            >
              <CopyIcon size="14" />
            </button>
            <span class="m-2">{{ endpoint.url }}</span>
          </h4>
          <div class="mb-1 d-flex align-items-center">
            <KeyIcon size="14" v-tooltip="'Secret'" title="Secret" />
            <div class="ms-2 text-secondary fs-4 fw-bold">
              <span v-if="endpointSecretName">
                {{ endpointSecretName }}
              </span>
              <span v-else class="badge bg-warning-soft">NO SECRET ASSIGNED</span>
            </div>
          </div>
          <span>
            <span v-for="eventType in endpoint.eventTypes" :key="eventType">
              <span class="badge bg-light">{{ eventType }}</span>
              <span>&nbsp;</span>
            </span>
          </span>
        </div>
        <div class="col-auto">
          <div class="col-auto"></div>
          <button
            class="btn btn-rounded-circle btn-white btn-sm me-2"
            v-b-modal="`update-endpoint-modal-${endpoint._id}`"
            title="Update endpoint"
          >
            <EditIcon size="14" />
          </button>
          <button
            class="btn btn-rounded-circle btn-white btn-sm"
            v-b-modal="`remove-endpoint-modal-${endpoint._id}`"
            title="Remove endpoint"
          >
            <TrashIcon size="14" />
          </button>
        </div>
      </div>
      <ConfirmActionModal
        :id="`remove-endpoint-modal-${endpoint._id}`"
        @action-confirm="$emit('remove', endpoint._id)"
        title="Remove endpoint"
        ok-variant="danger"
        ok-title="Remove"
      >
        Are you sure you want to remove this endpoint?
      </ConfirmActionModal>
      <!--
        Incorrect endpoint data was shown in the modal when we had a single instance of the endpoint instead of one per endpoint.
        This happened because the modal would render before the state was ready. We'd have to put a deep watch on the modal to fix it.
        And that just felt dirty.
      -->
      <SettingsWebhooksUpdateEndpointModal :endpoint="endpoint" />
    </div>
  </div>
</template>

<script>
import { CopyIcon, EditIcon, TrashIcon, KeyIcon } from 'vue-feather-icons'
import ConfirmActionModal from '@/components/MainContent/ConfirmActionModal'
import SettingsWebhooksUpdateEndpointModal from '@/components/Modules/Settings/SettingsWebhooksUpdateEndpointModal'
import SettingsBase from './SettingsBase'

export default {
  name: 'SettingsWebhooksEndpoint',
  extends: SettingsBase,
  props: ['endpoint'],
  components: {
    ConfirmActionModal,
    CopyIcon,
    EditIcon,
    SettingsWebhooksUpdateEndpointModal,
    TrashIcon,
    KeyIcon,
  },
  methods: {
    onCopy() {
      this.$toasted.success('URL copied to clipboard.')
    },
    onError() {
      this.$toasted.error('Error copying to clipboard.')
    },
  },
  computed: {
    endpointSecretName() {
      const secret = this.activeCustomer.credentials.secrets.find(
        ({ _id }) => _id === this.endpoint.secretId
      )

      return secret ? secret.name ?? 'Default' : undefined
    },
  },
}
</script>
