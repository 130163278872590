<template>
  <b-modal @ok="confirmAction" v-bind="[$props, $attrs]">
    <template #modal-header>
      <h3>{{ title }}</h3>
    </template>
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmActionModal',
  props: {
    title: String,
  },
  methods: {
    confirmAction() {
      this.$emit('action-confirm')
    },
  },
}
</script>
