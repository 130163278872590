import { render, staticRenderFns } from "./SettingsFeaturesPayLink.vue?vue&type=template&id=784da824&scoped=true"
import script from "./SettingsFeaturesPayLink.vue?vue&type=script&lang=js"
export * from "./SettingsFeaturesPayLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784da824",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src525800907/src/console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('784da824')) {
      api.createRecord('784da824', component.options)
    } else {
      api.reload('784da824', component.options)
    }
    module.hot.accept("./SettingsFeaturesPayLink.vue?vue&type=template&id=784da824&scoped=true", function () {
      api.rerender('784da824', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Modules/Settings/SettingsFeaturesPayLink.vue"
export default component.exports