<template>
  <div :style="`height: ${height + 100}px;`" class="funnel-container">
    <div v-if="!isDataReady" class="centered">
      <div
        data-test-id="search-spinner"
        class="spinner-border spinner-border-md text-muted spinner"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div v-if="isDataReady && insufficientData" class="centered alert alert-secondary">
      Not enough activity.
    </div>
    <vue-funnel-graph
      :class="{ loading: !isDataReady, 'insufficient-data': insufficientData }"
      :width="documentWidth"
      :height="height"
      :labels="labels"
      :values="values"
      :colors="colors"
      :sub-labels="subLabels"
      :direction="direction"
      :gradient-direction="gradientDirection"
      :animated="true"
      :display-percentage="true"
      :key="documentWidth"
    ></vue-funnel-graph>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { VueFunnelGraph } from '@atomicfi/vue-funnel-graph-js'
import { getFriendlyEventName } from '@/utils/activity'

export default {
  name: 'Funnel',
  components: {
    VueFunnelGraph,
  },
  data() {
    return {
      subLabels: [],
      colors: ['#12263f', '#2D8AE2', '#4BDEBA'],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: 300,
      width: 800,
      documentWidth: document.documentElement.clientWidth,
    }
  },
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('activity', ['funnel', 'activeDateRangeOption', 'sampleDataMode']),
    labels() {
      return this.funnel.steps.map((step) => {
        return this.getFriendlyEventName(step)
      })
    },
    values() {
      if (this.funnel.data && this.funnel.data[this.funnel.steps[0]] && !this.sampleDataMode)
        return Object.values(this.funnel.data)
      return [24903, 12502, 9281]
    },
    isDataReady() {
      return !!(!this.funnel.isLoading || this.sampleDataMode)
    },
    insufficientData() {
      if (this.sampleDataMode) return false
      if (this.funnel.data && !this.funnel.data[this.funnel.steps[0]]) return true
      return false
    },
  },
  methods: {
    ...mapMutations('activity', ['setFunnelIsLoading']),
    ...mapActions('activity', ['getFunnel']),
    getFriendlyEventName,
    onResize() {
      this.documentWidth = document.documentElement.clientWidth
    },
    async getData() {
      this.setFunnelIsLoading(true)
      await this.getFunnel()
      this.setFunnelIsLoading(false)
    },
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
    this.getData()
  },
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.getData()
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style lang="scss">
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
  border-image: linear-gradient(to bottom, #283b52, #12263f) 1 100%;
}
.svg-funnel-js {
  position: relative;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
  color: #fff;
  font-size: 1.1rem;
  position: absolute;
  top: 215px;
  opacity: 0.8;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  color: #fff;
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 2px;
}
.svg-funnel-js {
  .svg-funnel-js__container {
    margin-top: 1rem;
  }
  transition: opacity 0.3s;
  &.loading,
  &.insufficient-data {
    opacity: 0.2;
    .label__value {
      display: none;
    }
    .label__percentage {
      display: none;
    }
    .label__title {
      display: none;
    }
  }
}
.funnel-container {
  border-top: 1px solid rgba(227, 235, 246, 0.1);
  position: relative;
  .alert {
    opacity: 0.8;
    margin: none !important;
    background-color: #2c3541;
    border: none;
  }
}

.label__value {
  display: none;
}
.label__title {
  margin-top: 1rem;
}
</style>
