var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Form", {
    attrs: { onSubmit: _vm.addUser, submitButtonText: "Save" },
    on: { success: _vm.onSuccess, error: _vm.onError },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var disabled = ref.disabled
          return [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "user-first-name" } }, [
                _vm._v("First Name"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.givenName,
                    expression: "user.givenName",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "user-first-name",
                  type: "text",
                  placeholder: "Johnny",
                  required: "",
                  disabled: disabled,
                },
                domProps: { value: _vm.user.givenName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "givenName", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "user-last-name" } }, [
                _vm._v("Last Name"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.familyName,
                    expression: "user.familyName",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "user-last-name",
                  type: "text",
                  placeholder: "Appleseed",
                  required: "",
                  disabled: disabled,
                },
                domProps: { value: _vm.user.familyName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "familyName", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "user-email" } }, [_vm._v("Email")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.email,
                    expression: "user.email",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "user-email",
                  type: "email",
                  placeholder: "jappleseed@example.org",
                  required: "",
                  disabled: disabled,
                },
                domProps: { value: _vm.user.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "email", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "user-phone-number" } }, [
                _vm._v("Phone Number (optional)"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.phoneNumber,
                    expression: "user.phoneNumber",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "user-phone-number",
                  type: "phone",
                  placeholder: "1234567890",
                  disabled: disabled,
                },
                domProps: { value: _vm.user.phoneNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "phoneNumber", $event.target.value)
                  },
                },
              }),
            ]),
            _c("UserRoleSelector", {
              attrs: { options: _vm.availableRoles },
              on: { selectionChanged: _vm.updateUserRole },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }