import TaskService from '@/services/TaskService'
import { getField, updateField } from 'vuex-map-fields'
import { uniqueId } from 'lodash-es'
import { TASK_FILTER_PRODUCT_OPTIONS } from '@/lib/task-filter-product-options'
import moment from 'moment'

const defaultNewTask = () => ({
  company: undefined,
  product: undefined,
  additionalProduct: undefined,
  inputs: {},
  sendUserInvite: true,
  settings: { transfer: { amount: undefined }, deposit: {} },
  user: {
    sendUserInvite: true,
    identifier: undefined,
    phone: undefined,
    email: undefined,
    names: [
      {
        firstName: undefined,
        lastName: undefined,
      },
    ],
    addresses: [
      {
        recipient: undefined,
        line1: undefined,
        line2: undefined,
        city: undefined,
        state: undefined,
        zipcode: undefined,
        country: undefined,
      },
    ],
    accounts: [
      {
        accountNumber: undefined,
        routingNumber: undefined,
        type: undefined,
        title: undefined,
      },
    ],
  },
})

export default {
  namespaced: true,
  state: {
    loadingTasks: false,
    filters: {
      externalStatus: ['completed', 'processing', 'failed'],
      product: [],
      query: undefined,
      startDate: undefined,
      endDate: undefined,
    },
    tasks: [],
    totalTasks: 0,
    task: {},
    newTask: defaultNewTask(),
    searchQueue: [],
    resolvedSearchRequestId: '0',
  },
  mutations: {
    updateField,
    setTasks(state, { tasks, total }) {
      state.tasks = tasks
      state.totalTasks = total
    },
    setTask(state, task) {
      state.task = task
    },
    resetTask(state) {
      state.task = {}
    },
    resetNewTask(state) {
      state.newTask = defaultNewTask()
    },
    resetFilters(state) {
      const defaultStartDate = moment().subtract(29, 'days').startOf('day').toISOString()
      const defaultEndDate = moment().endOf('day').toISOString()

      state.filters = {
        externalStatus: ['completed', 'processing', 'failed'],
        product: [state.filters.product?.[0] || 'DEPOSIT'],
        query: undefined,
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      }
    },
    setFilterProduct(state, product) {
      state.filters.product = Array.isArray(product) ? product : [product]
    },
    setFilterStatus(state, status) {
      state.filters.externalStatus = status
    },
    setFilterQuery(state, query) {
      state.filters.query = query
    },
    setResolvedSearchRequestId(state, resolvedSearchRequestId) {
      state.resolvedSearchRequestId = resolvedSearchRequestId
    },
    setSearchQueue(state, searchQueue) {
      state.searchQueue = searchQueue
    },
    setFilterDateRange(state, { startDate, endDate }) {
      state.filters.startDate = startDate
      state.filters.endDate = endDate
    },
    updateFilters(state, filters) {
      state.filters = { ...state.filters, ...filters }
    },
    setLoadingTasks(state, loading) {
      state.loadingTasks = loading
    },
  },
  getters: {
    getField,
    searchRequestsWaitingInQueue(state) {
      return state.searchQueue.length > 0
    },
  },
  actions: {
    addSearchRequestToQueue({ commit, state }, requestId) {
      commit('setSearchQueue', [...state.searchQueue, requestId])
    },
    async getTaskList({ commit, state }, { skip, limit, sortField, sortOrder } = {}) {
      const requestId = uniqueId()

      commit('setLoadingTasks', true)
      commit('setSearchQueue', [...state.searchQueue, requestId])

      try {
        const { tasks, total } = await TaskService.list({
          filters: { ...state.filters },
          skip,
          limit,
          sortField,
          sortOrder,
        })
        commit('setTasks', { tasks, total })
      } catch (error) {
        commit('setTasks', { tasks: [], total: 0 })
      } finally {
        const updatedQueue = state.searchQueue.filter((id) => id !== requestId)
        commit('setSearchQueue', updatedQueue)

        if (updatedQueue.length === 0) {
          commit('setLoadingTasks', false)
        }
      }
    },
    async getTask({ commit }, taskId) {
      const response = await TaskService.get(taskId)
      commit('setTask', response.body.data)
    },
    removeSearchRequestFromQueue({ commit, state }, requestId) {
      commit(
        'setSearchQueue',
        state.searchQueue.filter((id) => id !== requestId)
      )
    },
    syncQueryFilterToURL({}, query) {
      const url = new URL(window.location.href)
      if (query) {
        url.searchParams.set('query', query)
      } else {
        url.searchParams.delete('query')
      }
      history.replaceState({}, '', url.href)
    },
    async toggleProductFilter({ commit, dispatch, state }, productToToggle) {
      const newProducts = state.filters.product.includes(productToToggle)
        ? state.filters.product.filter((product) => product !== productToToggle)
        : [...state.filters.product, productToToggle]

      await commit('setFilterProduct', newProducts)
      return dispatch('getTaskList')
    },
    async toggleStatusFilter({ commit, dispatch, state }, statusToToggle) {
      const newStatuses = state.filters.externalStatus.includes(statusToToggle)
        ? state.filters.externalStatus.filter((status) => status !== statusToToggle)
        : [...state.filters.externalStatus, statusToToggle]

      await commit('setFilterStatus', newStatuses)
      return dispatch('getTaskList')
    },
    async updateDateRangeFilter({ commit, dispatch }, { startDate, endDate }) {
      const formattedStartDate = startDate ? new Date(startDate).toISOString() : undefined
      const formattedEndDate = endDate ? new Date(endDate).toISOString() : undefined

      await commit('setFilterDateRange', {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })
      return dispatch('getTaskList')
    },
    async updateQueryFilter({ commit, dispatch }, query) {
      await commit('setFilterQuery', query)
      dispatch('syncQueryFilterToURL', query)
      return dispatch('getTaskList')
    },
    async resetFilters({ commit, dispatch }) {
      await commit('resetFilters')
      return dispatch('getTaskList')
    },
    async getTaskEvents(_, { taskId }) {
      const response = await TaskService.getTaskEvents({
        taskId,
      })
      return response.data.data
    },
  },
}
