var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-outer" },
    [
      _c(
        "div",
        { staticClass: "icon-inner", class: { dark: _vm.settings.theme.dark } },
        [
          _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.value, alt: _vm.label },
          }),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }