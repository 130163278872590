var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn btn-outline-secondary",
      attrs: {
        type: "button",
        disabled: _vm.disabled,
        "v-tooltip": _vm.toolTip,
        "aria-label": _vm.toolTip,
      },
      on: { click: _vm.onClickHandler },
    },
    [_c("RotateCcwIcon", { attrs: { size: "14" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }