var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Wrapper",
    {
      class: { dark: _vm.settings.theme.dark, fullscreen: _vm.fullscreenMode },
      attrs: { title: "Emulator", fullscreen: true },
    },
    [
      _c("div", { staticClass: "card-group full-height row p-0 m-0" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-md-3 m-0 p-0 panel-config panel-scrollable shadow-lg",
            class: { fullscreen: _vm.fullscreenMode },
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                !_vm.scope
                  ? _c("EmulatorScopeSelector", {
                      staticClass: "align-self-center",
                      on: { selectedScope: _vm.getOrCreateAccessToken },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "panel-config-wrap" },
              [
                _vm.scope
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              name: _vm.activeNavItem
                                ? "push-right"
                                : "push-left",
                              mode: "out-in",
                            },
                          },
                          [
                            _vm.activeNavItem
                              ? _c(
                                  "div",
                                  { key: "back-button", staticClass: "mb-3" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "back-link",
                                        attrs: { href: "#" },
                                        on: { click: _vm.goBack },
                                      },
                                      [
                                        _c("ArrowLeftIcon", {
                                          staticClass:
                                            "mb-1 d-inline-block success",
                                          attrs: { size: "24" },
                                        }),
                                        _vm._v(" Transact SDK "),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { key: "header", staticClass: "mt-2" },
                                  [_c("h3", [_vm._v("Transact SDK")])]
                                ),
                          ]
                        ),
                        _c(
                          "transition",
                          {
                            attrs: {
                              name: _vm.activeNavItem
                                ? "push-right"
                                : "push-left",
                              mode: "out-in",
                            },
                          },
                          [
                            !_vm.activeNavItem
                              ? _c("EmulatorNavigation", { key: "nav" })
                              : _vm.activeNavItem === "initialization"
                              ? _c("EmulatorInitialization", {
                                  key: "initialization",
                                })
                              : _vm.activeNavItem === "styles"
                              ? _c("EmulatorStyles", { key: "theme" })
                              : _vm.activeNavItem === "fonts"
                              ? _c("EmulatorFonts", { key: "fonts" })
                              : _vm.activeNavItem === "demo" &&
                                _vm.demoIsAvailable
                              ? _c("EmulatorDemo", { key: "demo" })
                              : _vm.activeNavItem === "login"
                              ? _c("EmulatorLogin", { key: "login" })
                              : _vm.activeNavItem === "mutations"
                              ? _c("EmulatorMutationReview", {
                                  key: "mutations",
                                })
                              : _vm.scope.name === _vm.scopeOptions.USER_LINK
                              ? _c("EmulatorScreenCustomization", {
                                  key: _vm.activeNavItem,
                                  attrs: { name: _vm.activeNavItem },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "transition",
                  { attrs: { name: "push-up", mode: "out-in" } },
                  [
                    _vm.scope &&
                    (_vm.activeNavItem === "initialization" ||
                      !_vm.isInitialized)
                      ? _c(
                          "button",
                          {
                            key: "btn-initialize",
                            staticClass:
                              "btn btn-primary btn-large btn-fixed col-12 mt-3 action-button",
                            attrs: { disabled: _vm.disableInitialization },
                            on: { click: _vm.launchTransact },
                          },
                          [_vm._v(" Initialize ")]
                        )
                      : _vm.showReviewCustomizationsButton
                      ? _c(
                          "button",
                          {
                            key: "btn-review-customizations",
                            staticClass:
                              "btn btn-primary btn-large btn-fixed col-12 mt-3 action-button",
                            on: { click: _vm.reviewCustomizations },
                          },
                          [
                            _vm._v(" Review Customizations "),
                            _c("span", { staticClass: "badge bg-light" }, [
                              _vm._v(_vm._s(_vm.pendingMutations.length)),
                            ]),
                          ]
                        )
                      : _vm.showSaveChangesSandboxButton
                      ? _c(
                          "button",
                          {
                            key: "btn-save-changes-sandbox",
                            staticClass:
                              "btn btn-primary btn-large btn-fixed col-12 mt-3 action-button",
                            on: {
                              click: function ($event) {
                                return _vm.commitMutations([
                                  _vm.activeEnvironment,
                                ])
                              },
                            },
                          },
                          [_vm._v(" Publish to Sandbox ")]
                        )
                      : _vm.showSaveChangesButton
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal",
                                value: "select-environment-modal",
                                expression: "'select-environment-modal'",
                              },
                            ],
                            key: "btn-save-changes",
                            staticClass:
                              "btn btn-primary btn-large btn-fixed col-12 mt-3 action-button",
                          },
                          [_vm._v(" Publish ")]
                        )
                      : _vm._e(),
                  ]
                ),
                _c("EmulatorSelectEnvironmentModal", {
                  on: { submit: _vm.commitMutations },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-none d-md-block col-7 m-0 p-0 panel-viewport d-flex",
            class: {
              "panel-viewport-dark": _vm.settings.theme.dark,
              disabled: !_vm.scope,
              fullscreen: _vm.fullscreenMode,
            },
          },
          [
            _c(
              "div",
              { staticClass: "transact-container-wrapper text-center" },
              [
                _c("div", { staticClass: "transact-container" }, [
                  !_vm.session
                    ? _c(
                        "button",
                        {
                          staticClass: "session-empty-state",
                          attrs: { disabled: _vm.disableInitialization },
                          on: { click: _vm.launchTransact },
                        },
                        [
                          _c("img", {
                            attrs: { src: _vm.preLaunchMockupSvgUrl },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { attrs: { id: "transact-iframe" } }),
                ]),
                _c("EmulatorColorModeSelector", { staticClass: "mt-4" }),
                _c("div", { staticClass: "emulator-toggles" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            "Autopilot automatically navigates to the configuration options relevant to your current screen.",
                          expression:
                            "\n              'Autopilot automatically navigates to the configuration options relevant to your current screen.'\n            ",
                        },
                      ],
                      staticClass: "btn btn-sm mt-4",
                      class: {
                        "text-muted": !_vm.enableAssistance,
                        "btn-outline-white opacity-50": _vm.settings.theme.dark,
                        "btn-outline-secondary": !_vm.settings.theme.dark,
                      },
                      on: {
                        click: function ($event) {
                          _vm.enableAssistance = !_vm.enableAssistance
                        },
                      },
                    },
                    [
                      _vm.enableAssistance
                        ? _c("CheckIcon", {
                            staticClass: "mt-1",
                            attrs: { size: "14" },
                          })
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.enableAssistance
                              ? "Autopilot"
                              : "Autopilot disabled"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            "Hides the Console interface for better showcasing Transact.",
                          expression:
                            "'Hides the Console interface for better showcasing Transact.'",
                        },
                      ],
                      staticClass: "btn btn-sm mt-4",
                      class: {
                        "text-muted": !_vm.enableAssistance,
                        "btn-outline-white opacity-50": _vm.settings.theme.dark,
                        "btn-outline-secondary": !_vm.settings.theme.dark,
                      },
                      on: { click: _vm.toggleFullscreen },
                    },
                    [
                      _c("MaximizeIcon", {
                        staticClass: "mt-1",
                        attrs: { size: "14" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.fullscreenMode
                              ? "Exit Fullscreen"
                              : "Fullscreen"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-none d-xl-block col-3 m-0 p-0 event-list panel-events panel-scrollable",
            class: { disabled: !_vm.scope, fullscreen: _vm.fullscreenMode },
          },
          [
            _c("EmulatorEvents", {
              staticClass: "col-12 m-0 p-0 event-list",
              attrs: { events: _vm.events },
              on: { clear: _vm.clearEvents },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }