import { hasAPermission, hasFinishedOnboarding, hasScope } from '@/lib/authorization'
import store from '@/store'

export function checkRoutePermissions(requireOneOfPermissions) {
  return (to, from, next) => {
    if (hasAPermission(...requireOneOfPermissions)) {
      next()
    } else {
      next('/')
    }
  }
}

export function checkRouteScope(requiredScope) {
  return (to, from, next) => {
    if (hasScope(requiredScope)) {
      next()
    } else {
      next('/')
    }
  }
}
export function checkRouteFinishedOnboarding() {
  return (to, from, next) => {
    if (hasFinishedOnboarding()) {
      next()
    } else {
      next('/onboard')
    }
  }
}

export function checkRouteState(callback) {
  return (to, from, next) => {
    if (callback(store)) {
      next()
    } else {
      next('/')
    }
  }
}
