var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ModalForm", {
    attrs: {
      id: "delete-user-modal",
      title: "Delete User",
      onSubmit: _vm.deleteUser,
      submitButtonText: "Delete User",
    },
    on: { success: _vm.onSuccess, hidden: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var disabled = ref.disabled
          return [
            _c("validation-provider", {
              attrs: { rules: { is: _vm.userNameToDelete, required: true } },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var classes = ref.classes
                      var errors = ref.errors
                      var touched = ref.touched
                      return [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "deleteUserText" } }, [
                            _vm._v(
                              ' Please type "' +
                                _vm._s(_vm.userNameToDelete) +
                                '" to confirm you want to delete this User '
                            ),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.deleteUserText,
                                expression: "deleteUserText",
                              },
                            ],
                            staticClass: "form-control",
                            class: classes,
                            attrs: {
                              id: "deleteUserText",
                              type: "text",
                              placeholder: _vm.userNameToDelete,
                              disabled: disabled,
                            },
                            domProps: { value: _vm.deleteUserText },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.deleteUserText = $event.target.value
                              },
                            },
                          }),
                          touched
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(_vm._s(errors[0])),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }