var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mt-4" }, [
    _c(
      "div",
      { staticClass: "card-header" },
      [
        _c("div", { staticClass: "col ms-n3" }, [
          _c(
            "h6",
            { staticClass: "card-title text-uppercase text-muted mb-2" },
            [_vm._v("Gross Amount")]
          ),
          _c("h2", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm._f("currency")(_vm.statement.grossAmount))),
          ]),
        ]),
        _c(
          "ViewTaskOutputFileButton",
          { attrs: { taskId: _vm.taskId, fileId: _vm.statement.paystub } },
          [_vm._v("View PDF")]
        ),
      ],
      1
    ),
    _vm.hasDeductionData
      ? _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("BreakdownBar", {
              attrs: { sections: _vm.breakdownBarSections },
            }),
            _c("BreakdownTable", { attrs: { items: _vm.breakdownTableItems } }),
          ],
          1
        )
      : _vm._e(),
    _vm.statement.hours
      ? _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col" }, [
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(" " + _vm._s(_vm.statement.hours) + " hours worked"),
              ]),
            ]),
            _c("div", { staticClass: "col-auto" }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }