<template>
  <div class="alert d-flex align-items-start" :class="`alert-${type}`" role="alert">
    <div class="d-flex align-items-center">
      <component :is="icon" class="me-2" size="20" />
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { AlertCircleIcon, AlertTriangleIcon, CheckCircleIcon, InfoIcon } from 'vue-feather-icons'

export default {
  name: 'Alert',
  props: ['type'],
  computed: {
    icon() {
      return {
        danger: AlertCircleIcon,
        info: InfoIcon,
        success: CheckCircleIcon,
        warning: AlertTriangleIcon,
      }[this.type]
    },
  },
}
</script>
