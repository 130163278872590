/*eslint-env jquery*/

import Colors from './colors'

export const globalOptions = {
  responsive: true,
  maintainAspectRatio: false,
  defaultColor: Colors.primary[600],
  defaultFontColor: Colors.gray[600],
  defaultFontFamily: 'Cerebri Sans',
  defaultFontSize: 13,
  layout: {
    padding: 0,
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      usePointStyle: true,
      padding: 16,
    },
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: Colors.primary[700],
    },
    line: {
      tension: 0.4,
      borderWidth: 3,
      borderColor: Colors.primary[700],
      backgroundColor: Colors.transparent,
      borderCapStyle: 'rounded',
    },
    rectangle: {
      backgroundColor: Colors.primary[700],
    },
    arc: {
      borderWidth: 4,
      backgroundColor: Colors.primary[700],
    },
  },
  tooltips: {
    enabled: false,
    mode: 'index',
    intersect: false,
    custom: function (model) {
      // Get tooltip
      let $tooltip = $('#chart-tooltip')

      // Create tooltip on first render
      if (!$tooltip.length) {
        $tooltip = $('<div id="chart-tooltip" class="popover bs-popover-top" role="tooltip"></div>')

        // Append to body
        $('body').append($tooltip)
      }

      // Hide if no tooltip
      if (model.opacity === 0) {
        $tooltip.css('display', 'none')
        return
      }

      function getBody(bodyItem) {
        return bodyItem.lines
      }

      // Fill with content
      if (model.body) {
        const titleLines = model.title || []
        const bodyLines = model.body.map(getBody)
        let html = ''

        // Add arrow
        html += '<div class="arrow"></div>'

        // Add header
        titleLines.forEach(function (title) {
          html += '<h3 class="popover-header text-center">' + title + '</h3>'
        })

        // Add body
        bodyLines.forEach(function (body, i) {
          const colors = model.labelColors[i]
          const styles = 'background-color: ' + colors.backgroundColor
          const indicator = '<span class="popover-body-indicator" style="' + styles + '"></span>'
          const align = bodyLines.length > 1 ? 'justify-content-left' : 'justify-content-center'
          html +=
            '<div class="popover-body d-flex align-items-center ' +
            align +
            '">' +
            indicator +
            body +
            '</div>'
        })

        $tooltip.html(html)
      }

      // Get tooltip position
      const $canvas = $(this._chart.canvas)

      const canvasTop = $canvas.offset().top
      const canvasLeft = $canvas.offset().left

      const tooltipWidth = $tooltip.outerWidth()
      const tooltipHeight = $tooltip.outerHeight()

      const top = canvasTop + model.caretY - tooltipHeight - 16
      const left = canvasLeft + model.caretX - tooltipWidth / 2

      // Display tooltip
      $tooltip.css({
        top: top + 'px',
        left: left + 'px',
        display: 'block',
      })
    },
    callbacks: {
      label: function (item, data) {
        var label = data.datasets[item.datasetIndex].label || ''
        var yLabel = item.yLabel
        var content = ''

        if (data.datasets.length > 1) {
          content += '<span class="popover-body-label me-1">' + label + '</span>'
        }

        content += '<span class="popover-body-value ms-auto">' + yLabel + '</span>'
        return content
      },
    },
  },
}

export const doughnutOptions = {
  cutoutPercentage: 83,
  tooltips: {
    callbacks: {
      title: function (item, data) {
        return data.labels[item[0].index]
      },
      label: function (item, data) {
        const value = data.datasets[0].data[item.index]
        let content = ''

        content += '<span class="popover-body-value">' + value + '</span>'
        return content
      },
    },
  },
}
