var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    _vm._b(
      {
        on: { ok: _vm.confirmAction },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function () {
              return [_c("h3", [_vm._v(_vm._s(_vm.title))])]
            },
            proxy: true,
          },
        ]),
      },
      "b-modal",
      [_vm.$props, _vm.$attrs],
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }