var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-4" }, [
    _c(
      "ul",
      { staticClass: "nav nav-tabs nav-tabs-sm mb-4" },
      _vm._l(_vm.figmaPages, function (figmaPage) {
        return _c(
          "li",
          {
            key: figmaPage.node,
            staticClass: "nav-item",
            on: {
              click: function ($event) {
                return _vm.setFigmaNode(figmaPage)
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.currentFigmaPage.node === figmaPage.node },
                attrs: { href: "#" },
              },
              [_vm._v(" " + _vm._s(figmaPage.title))]
            ),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "iframe-wrapper" }, [
      _c("iframe", {
        style:
          "border: 1px solid rgba(0, 0, 0, 0.1); height: " +
          (_vm.windowHeight - 250) +
          "px",
        attrs: {
          title: "Figma Embed",
          src:
            "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FEk6SYyH4u33Ho6Ury92Jej%2FAtomic-Design-Docs%3Fnode-id%" +
            _vm.currentFigmaPage.node,
          allowfullscreen: "",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }