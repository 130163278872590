var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "card-body", class: { "fade-in-out": !_vm.isDataReady } },
      [
        _c("ChartWrapper", {
          attrs: {
            chartType: _vm.CHART_TYPES.BAR,
            chartData: _vm.chartData,
            useDummyData: _vm.isLoading || _vm.sampleDataMode,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-header-title" }, [
        _vm._v("Transaction Volume"),
      ]),
      _c("div", { staticClass: "chart-legend mt-0" }, [
        _c("div", [
          _c("span", { staticClass: "chart-legend-item" }, [
            _c("span", {
              staticClass: "chart-legend-indicator",
              staticStyle: { "background-color": "#2c7be5" },
            }),
            _vm._v("Deposit"),
          ]),
          _c("span", { staticClass: "chart-legend-item" }, [
            _c("span", {
              staticClass: "chart-legend-indicator",
              staticStyle: { "background-color": "#a6c5f7" },
            }),
            _vm._v("Verify"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }