<template>
  <div class="p-4">
    <ul class="nav nav-tabs nav-tabs-sm mb-4">
      <li
        class="nav-item"
        v-for="figmaPage in figmaPages"
        :key="figmaPage.node"
        @click="setFigmaNode(figmaPage)"
      >
        <a href="#" class="nav-link" :class="{ active: currentFigmaPage.node === figmaPage.node }">
          {{ figmaPage.title }}</a
        >
      </li>
    </ul>
    <div class="iframe-wrapper">
      <iframe
        title="Figma Embed"
        :style="`border: 1px solid rgba(0, 0, 0, 0.1); height: ${windowHeight - 250}px`"
        :src="`https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FEk6SYyH4u33Ho6Ury92Jej%2FAtomic-Design-Docs%3Fnode-id%${currentFigmaPage.node}`"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourcesFigma',
  data: () => ({
    windowHeight: window.innerHeight,
    currentFigmaPage: {
      node: '',
      title: '',
    },
    figmaPages: [
      {
        node: '3D512%253A14811%26mode%3Ddesign%26t%3Des45IYtQ0ZwGX796-1',
        title: 'Deposit',
      },
      {
        node: '3D512%253A14812%26mode%3Ddesign%26t%3Des45IYtQ0ZwGX796-1',
        title: 'Verify & Tax',
      },
      {
        node: '3D6837%253A7921%26mode%3Ddesign%26t%3Des45IYtQ0ZwGX796-1',
        title: 'PayLink Switch',
      },
      {
        node: '3D16751%253A25866%26mode%3Ddesign%26t%3Des45IYtQ0ZwGX796-1',
        title: 'PayLink Manage',
      },
      {
        node: '3D818%253A17319%26mode%3Ddesign%26t%3Des45IYtQ0ZwGX796-1',
        title: 'End States',
      },
      {
        node: '3D803%253A21693%26mode%3Ddesign%26t%3Des45IYtQ0ZwGX796-1',
        title: 'Inline',
      },
    ],
  }),
  methods: {
    setFigmaNode: function (figmaPage) {
      this.currentFigmaPage = figmaPage
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
  },
  mounted() {
    this.currentFigmaPage = this.figmaPages[0]
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped lang="scss">
.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
</style>
