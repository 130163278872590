var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress mb-3" },
    _vm._l(_vm.sections, function (ref, index) {
      var length = ref.length
      var color = ref.color
      return _c("div", {
        key: index,
        staticClass: "progress-bar",
        style: { width: length + "%", "background-color": color },
        attrs: { role: "progressbar" },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }