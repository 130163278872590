<template>
  <ConversionOptimizationManager
    :conversionOptimizationType="CONVERSION_OPTIMIZATION_TYPES.EXPERIMENT"
  >
    <template #introduction>
      <p>
        Experiments that occur after the initial screen and before the final screen are considered
        "middle-of-funnel." Middle-of-funnel experiments cannot be disabled as they are necessary
        for Atomic to fix and improve integrations with individual payroll providers. They are
        marked with this icon:
        <GitCommitIcon size="1x" />.
      </p>
    </template>
  </ConversionOptimizationManager>
</template>

<script>
import { CONVERSION_OPTIMIZATION_TYPES } from '@atomicfi/constants-shared'
import { GitCommitIcon } from 'vue-feather-icons'
import ConversionOptimizationManager from '@/components/Modules/ConversionOptimization/ConversionOptimizationManager'

export default {
  name: 'SettingsExperiments',
  components: {
    ConversionOptimizationManager,
    GitCommitIcon,
  },
  data() {
    return {
      CONVERSION_OPTIMIZATION_TYPES,
    }
  },
}
</script>
