var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("CustomSwitch", {
          attrs: {
            label: "Allow users to add cards",
            "helper-text":
              "Card details are encrypted and stored securely for 24 hours.",
            value: _vm.payLink.userProvidedCards.enabled,
          },
          on: { input: _vm.onChangeUserProvidedCardsEnabled },
        }),
      ],
      1
    ),
    _vm.payLink.userProvidedCards.enabled
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Approved BIN Codes")]),
          _c("br"),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              ' Only allow users to add cards with the following BIN codes. Enter as comma-separated list of 6-digit codes (e.g., "123456, 567890").'
            ),
          ]),
          _c("input", {
            staticClass: "form-control mt-3",
            attrs: {
              placeholder: "123456, 567890",
              type: "text",
              pattern: _vm.binCodesPattern,
            },
            domProps: {
              value: _vm.payLink.userProvidedCards.binCodes.join(", "),
            },
            on: { change: _vm.onChangeBinCodes },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }