<template>
  <table class="table table-sm table-nowrap pb-0 mb-n3 breakdown-table">
    <tbody>
      <span
        v-for="{ color, label, amount, percentage, subCategories, hasSubCategories } in items"
        :key="label"
      >
        <tr class="d-flex" :class="{ expandable: hasSubCategories }" v-b-toggle="label">
          <td class="col-1"><span class="dot" :style="{ color }">•</span></td>
          <td class="col-6">
            {{ label | startCase }}
            <ChevronDownIcon size="14" class="text-muted when-open" v-if="hasSubCategories" />
            <ChevronRightIcon size="14" class="text-muted when-closed" v-if="hasSubCategories" />
          </td>
          <td class="col-3 text-right">{{ amount | currency }}</td>
          <td class="col-2 text-right pe-0">{{ percentage | percent }}</td>
        </tr>
        <b-collapse :id="label">
          <tr v-for="{ label, amount } in subCategories" class="d-flex text-muted" :key="label">
            <td class="col-1"></td>
            <td class="col-6">{{ label }}</td>
            <td class="col-3 text-right">{{ amount | currency }}</td>
            <td class="col-2 text-right"></td>
          </tr>
        </b-collapse>
      </span>
    </tbody>
  </table>
</template>
<script>
import { ChevronDownIcon, ChevronRightIcon } from 'vue-feather-icons'

export default {
  name: 'BreakdownTable',
  components: { ChevronDownIcon, ChevronRightIcon },
  props: ['items'],
}
</script>
<style scoped lang="scss">
.dot {
  position: relative;
  top: 9px;
  font-size: 3rem;
  line-height: 0.1rem;
}

td {
  border: none !important;
  padding: 0.2rem;
}
.breakdown-table {
  i {
    position: relative;
    top: 1px;
  }
  .expandable {
    cursor: pointer;
  }
}
.collapsed > td > .when-open,
.not-collapsed > td > .when-closed {
  display: none;
}
</style>
