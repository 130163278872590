var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.w2s, function (ref) {
      var totalWages = ref.totalWages
      var year = ref.year
      var form = ref.form
      var parsedData = ref.parsedData
      return _c(
        "div",
        { key: year + totalWages, staticClass: "card" },
        [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("div", { staticClass: "d-flex flex-row mt-n2" }, [
                  _vm._m(0, true),
                  _c("div", { staticClass: "me-4" }, [
                    _c("h6", { staticClass: "text-muted text-uppercase m-0" }, [
                      _vm._v("year"),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("moment")(
                              year,
                              "timezone",
                              "Etc/UTC",
                              "YYYY"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("h6", { staticClass: "text-muted text-uppercase m-0" }, [
                      _vm._v("total wages"),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(totalWages)) + " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col text-right align-self-center" },
                [
                  _c(
                    "ViewTaskOutputFileButton",
                    { attrs: { taskId: _vm.taskId, fileId: form } },
                    [_vm._v("View Form")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          !_vm.isParsedDataEmpty(parsedData)
            ? _c("div", { staticClass: "card-body" }, [
                _c(
                  "h4",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle",
                        value: "collapse-parsedData",
                        expression: "'collapse-parsedData'",
                      },
                    ],
                  },
                  [
                    _c("ChevronRightIcon", {
                      staticClass: "toggle-icon",
                      attrs: { size: "1.2x" },
                    }),
                    _vm._v(" Parsed Data "),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("b-collapse", { attrs: { id: "collapse-parsedData" } }, [
            _c(
              "div",
              { staticClass: "list-group" },
              _vm._l(parsedData, function (value, parentLabel) {
                return _c(
                  "div",
                  { key: parentLabel, staticClass: "list-group-item" },
                  [
                    typeof value === "string" ||
                    typeof value === "number" ||
                    typeof value === "boolean"
                      ? _c("div", [
                          _c("div", { staticClass: "row align-items-center" }, [
                            _c(
                              "small",
                              { staticClass: "col text-uppercase text-muted" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("startCase")(parentLabel)) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col-auto" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("booleanValues")(value)) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    Array.isArray(value) && value.length > 0
                      ? _c("div", [
                          _c(
                            "small",
                            { staticClass: "col text-uppercase text-muted" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("startCase")(parentLabel)) +
                                  " "
                              ),
                            ]
                          ),
                          parentLabel.toUpperCase() === "CODES"
                            ? _c(
                                "div",
                                _vm._l(value, function (object) {
                                  return _c("div", { key: object }, [
                                    _c(
                                      "div",
                                      { staticClass: "row align-items-center" },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "col text-muted" },
                                          [_vm._v(" Code | Amount ")]
                                        ),
                                        _c("div", { staticClass: "col-auto" }, [
                                          _vm._v(
                                            _vm._s(object.code) +
                                              " | " +
                                              _vm._s(object.amount)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              )
                            : parentLabel.toUpperCase() === "OTHER"
                            ? _c(
                                "div",
                                _vm._l(value, function (object) {
                                  return _c("div", { key: object }, [
                                    _c(
                                      "div",
                                      { staticClass: "row align-items-center" },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "col text-muted" },
                                          [_vm._v(" Description | Amount ")]
                                        ),
                                        _c("div", { staticClass: "col-auto" }, [
                                          _vm._v(
                                            _vm._s(object.description) +
                                              " | " +
                                              _vm._s(object.amount)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              )
                            : _c(
                                "div",
                                _vm._l(value, function (object, index) {
                                  return _c(
                                    "div",
                                    { key: object + index },
                                    _vm._l(object, function (data, label) {
                                      return _c("div", { key: data }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row align-items-center",
                                          },
                                          [
                                            _c(
                                              "small",
                                              { staticClass: "col text-muted" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("startCase")(label)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-auto" },
                                              [_vm._v(" " + _vm._s(data) + " ")]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  )
                                }),
                                0
                              ),
                        ])
                      : _vm._e(),
                    typeof value === "object" && !Array.isArray(value)
                      ? _c(
                          "div",
                          [
                            _c(
                              "small",
                              { staticClass: "col text-uppercase text-muted" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("startCase")(parentLabel)) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(value, function (data, label) {
                              return _c("div", { key: data }, [
                                _c(
                                  "div",
                                  { staticClass: "row align-items-center" },
                                  [
                                    _c(
                                      "small",
                                      { staticClass: "col text-muted" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm._f("startCase")(label)) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-auto" }, [
                                      _vm._v(" " + _vm._s(data) + " "),
                                    ]),
                                  ]
                                ),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "me-4" }, [
      _c("h6", { staticClass: "text-muted text-uppercase m-0" }, [
        _vm._v("type"),
      ]),
      _c("div", [_vm._v("W2")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }