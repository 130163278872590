import UserService from '@/services/UserService'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
  },
  actions: {
    async getUsers({ commit }) {
      try {
        const response = await UserService.list()
        commit('setUsers', response.body.users)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
