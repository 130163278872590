<template>
  <div class="card">
    <div class="card-header">
      <div class="col">
        <h4 class="card-header-title">Events</h4>
      </div>
      <div class="col-auto me-0" v-if="events.length">
        <button class="btn btn-text btn-sm me-0" @click="$emit('clear')">Reset</button>
      </div>
    </div>
    <div v-if="events.length">
      <ul class="list-group list-group-flush">
        <li v-for="event in reversedEvents" :key="event.guid" class="list-group-item p-3">
          <EmulatorEventEntry :event="event" />
        </li>
      </ul>
    </div>
    <div class="card-body text-muted" v-else>No events yet.</div>
  </div>
</template>

<script>
import EmulatorEventEntry from '@/components/Modules/Emulator/EmulatorEventEntry'
import { cloneDeep } from 'lodash-es'

export default {
  name: 'EmulatorEvents',
  props: ['events'],
  components: { EmulatorEventEntry },
  computed: {
    reversedEvents() {
      const eventsCopy = cloneDeep(this.events)
      return eventsCopy.reverse()
    },
  },
  mounted() {},
}
</script>
