// These must be defined separately to avoid a circular dependency
// Both the authorization.js file and the store require these values
// and authorization.js depends on the store.
import { Customer } from '@/types'

export const PERMISSIONS = {
  ADD_CUSTOMERS: 'add-customers',
  ADD_USERS: 'add-users',
  DELETE_USERS: 'delete-users',
  EDIT_USER_ROLES: 'edit-user-roles',
  MANAGE_CREDENTIALS: 'manage-credentials',
  MANAGE_EXPERIMENTS: 'manage-experiments',
  MANAGE_FEATURES: 'manage-features',
  MANAGE_SURVEYS: 'manage-surveys',
  MANAGE_INSTITUTION_SETTINGS: 'manage-institution-settings',
  MANAGE_WEBHOOKS: 'manage-webhooks',
  SAVE_TRANSACT_CUSTOMIZATION: 'save-transact-customization',
  SEARCH_TASKS: 'search-tasks',
  USE_EMULATOR: 'use-emulator',
  VIEW_DESIGNS: 'view-designs',
  VIEW_TASK: 'view-task',
} as const

export type Permissions = { -readonly [Key in keyof typeof PERMISSIONS]: (typeof PERMISSIONS)[Key] }
export type Permission = Permissions[keyof Permissions]

export const PRODUCTION_ONLY_PERMISSIONS = [
  PERMISSIONS.MANAGE_EXPERIMENTS,
  PERMISSIONS.MANAGE_SURVEYS,
]

export function getCustomerPermissions(customer: Customer): Partial<Permissions> {
  const permissions: Partial<Permissions> = { ...PERMISSIONS }

  const canAddChildCustomers = customer.features.supportsChildCustomers

  if (!canAddChildCustomers) {
    delete permissions.ADD_CUSTOMERS
  }

  return permissions
}
