var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "EmulatorSectionHeader",
        { attrs: { title: _vm.title } },
        [_c("LanguageSelectorDropdown")],
        1
      ),
      _vm.shouldRenderTabs
        ? _c("div", {}, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "ul",
                  { staticClass: "nav nav-tabs nav-tabs-sm" },
                  _vm._l(_vm.customizations, function (customization, index) {
                    return _c(
                      "li",
                      { key: customization.tab, staticClass: "nav-item" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "nav-link",
                            class: { active: _vm.activeTabIndex === index },
                            attrs: { id: customization.tab + "-tab" },
                            on: {
                              click: function () {
                                return _vm.setActiveTabIndex(index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(customization.tab) + " ")]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "tab-content mt-4" },
        _vm._l(_vm.customizations, function (customization, index) {
          return _c(
            "div",
            {
              key: customization.tab,
              staticClass: "tab-pane",
              class: { active: _vm.activeTabIndex === index },
              attrs: {
                id: customization.tab,
                role: "tabpanel",
                "aria-labelledby": customization.tab + "-tab",
              },
            },
            [
              customization.tab === _vm.EMULATOR_CUSTOMIZATION_TABS.PHRASES
                ? _c(
                    "div",
                    _vm._l(customization.phrases, function (phrase) {
                      return _c("EmulatorCustomizationTextBox", {
                        key: phrase.label,
                        attrs: {
                          label: phrase.label,
                          category: _vm.title,
                          namespace: "i18n",
                          path:
                            _vm.settings.language +
                            "." +
                            phrase.phraseNamespace +
                            "." +
                            phrase.phraseKey,
                          dataType: "phrase",
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }