<template>
  <Card>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-title text-uppercase text-muted mb-2">
          {{ header }}
          <DerivedDataBadge v-if="isDerived" title="This value was derived from statement data" />
        </h6>
        <span class="h2 mb-0">{{ text }} {{ textQualifier }}</span>
      </div>
      <div class="col-auto">
        <span class="h2 fe text-muted mb-0" :class="spanClass"></span>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/MainContent/Card'
import DerivedDataBadge from '@/components/MainContent/DerivedDataBadge'

export default {
  name: 'TaskIncomeChartCard',
  components: {
    Card,
    DerivedDataBadge,
  },
  props: ['header', 'text', 'textQualifier', 'isDerived', 'spanClass'],
}
</script>
