<template>
  <div class="main-content">
    <TopNav
      :title="title"
      :imageFileId="imageFileId"
      :pretitle="pretitle"
      :badge="badge"
      :image="image"
      :imageTitle="imageTitle"
      :class="fullscreen ? '' : 'mb-5'"
    />
    <FadeTransition>
      <div v-if="fullscreen">
        <slot></slot>
      </div>
      <div v-else class="container">
        <div class="row justify-between">
          <div v-if="hasNavigationSlot" class="col-8 col-md-3 col-sm-12 col-xs-12">
            <slot name="navigation"></slot>
          </div>
          <div v-if="hasNavigationSlot" class="col-12 col-md-9 col-sm-12">
            <slot></slot>
          </div>
          <div v-else class="col-12">
            <slot></slot>
          </div>
        </div>
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import TopNav from '@/components/TopNav/TopNav'
export default {
  name: 'Wrapper',
  props: ['title', 'imageFileId', 'pretitle', 'badge', 'image', 'imageTitle', 'fullscreen'],
  components: { FadeTransition, TopNav },
  computed: {
    hasNavigationSlot() {
      return !!this.$slots.navigation
    },
  },
}
</script>
