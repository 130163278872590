import { DISTRIBUTION_TYPES } from '@/utils/constants'
import { phrases } from '@/lib/simulation-scenarios/phrases'
import { analyticsEvents } from '@/lib/simulation-scenarios/analytics-events'

export const ADD_TOTAL = {
  label: 'Total',
  value: DISTRIBUTION_TYPES.TOTAL,
  prompt: phrases.prompts.howManyAccountsAreOnFileQ,
  analyticsEventName: analyticsEvents.selectNumberOfAccounts,
  options: [
    {
      label: '0',
      value: 0,
      results: {
        status: 'success',
        newAccount: 'Total',
        addendum: null,
        exceptions: [],
        explainer: 'The final result is a new account being added for a total distribution.',
      },
    },
    {
      label: '1',
      value: 1,
      prompt: phrases.prompts.whatKindOfAcctOnFileQ,
      analyticsEventName: analyticsEvents.selectTypesOfAccounts,
      options: [
        {
          label: 'Total',
          value: DISTRIBUTION_TYPES.TOTAL,
          results: {
            status: 'success',
            newAccount: 'Total',
            oldAccount: 'Replaced',
            addendum: null,
            exceptions: [],
            explainer:
              'The final result is the old account being replaced with the new account added.',
          },
        },
        {
          label: 'Fixed',
          value: DISTRIBUTION_TYPES.FIXED,
          results: {
            status: 'success',
            newAccount: 'Total',
            oldAccount: 'No changes',
            addendum: null,
            exceptions: [],
            explainer:
              'The final result is a new account being added as the remainder, with the old account remaining unchanged.',
          },
        },
        {
          label: 'Percent',
          value: DISTRIBUTION_TYPES.PERCENT,
          results: {
            status: 'success',
            newAccount: 'Total',
            oldAccount: 'No changes',
            addendum: null,
            exceptions: [],
            explainer:
              'The final result is a new account being added as the remainder, with the old account remaining unchanged.',
          },
        },
      ],
    },
    {
      label: '2',
      value: 2,
      prompt: phrases.prompts.whatKindOfAcctOnFileQ,
      analyticsEventName: analyticsEvents.selectTypesOfAccounts,
      options: [
        {
          label: 'Fixed, Remainder',
          value: '1-fixed-1-remainder',
          results: {
            status: 'success',
            newAccount: 'remainder',
            oldAccounts: {
              A: 'No changes',
              B: 'replaced',
            },
            addendum: phrases.addendums.remainderDescription,
            exceptions: [],
            explainer:
              'The final result is a new account replacing the remainder, with the old fixed account remaining unchanged.',
          },
        },
        {
          label: 'Percent, Remainder',
          value: '1-percent-1-remainder',
          results: {
            status: 'success',
            newAccount: 'remainder',
            oldAccounts: {
              A: 'No changes',
              B: 'replaced',
            },
            addendum: phrases.addendums.remainderDescription,
            exceptions: [],
            explainer:
              'The final result is a new account replacing the remainder, with the old percent account remaining unchanged.',
          },
        },
      ],
    },
  ],
}
