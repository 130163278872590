import { getInstance } from '@/plugins/auth'
import { ATOMIC_CUSTOMER_APIS } from '@/utils/environment'
import { ENVIRONMENTS } from '@/utils/constants'
import store from '@/store'

export function interceptor(request) {
  const authService = getInstance()

  // If the API endpoint URL being used is one of the customer API ones, attached the bearer token
  if (ATOMIC_CUSTOMER_APIS.some((url) => request.url.startsWith(url))) {
    request.headers.set('Authorization', `Bearer ${authService.accessToken}`)
  }

  const activeCustomerId = localStorage.getItem('activeCustomerId')
  if (activeCustomerId) {
    request.headers.set('x-active-customer-id', activeCustomerId)
  }

  const email = authService.getEmail()
  if (email) {
    request.headers.set('x-user-email', email)
  }

  // Attach an active environment header for accurate authorization while working locally
  if (process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT) {
    request.headers.set('x-active-environment', store.state.environment.activeEnvironment)
  }

  return function (response) {
    if (response.status === 401 || response.status === 403) {
      authService.logout({ shouldClearActiveCustomerId: true })
    } else if (
      response.status >= 400 &&
      response.status < 500 &&
      [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.TEST].includes(process.env.NODE_ENV)
    ) {
      console.log('Error occurred in console.', response)
    }
  }
}
