var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "form-group": _vm.formGroupWrap } }, [
    _c("div", { staticClass: "custom-control custom-switch" }, [
      _c("input", {
        staticClass: "custom-control-input",
        attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
        domProps: { checked: _vm.value },
        on: { input: _vm.onInput },
      }),
      _c(
        "label",
        { staticClass: "custom-control-label", attrs: { for: _vm.id } },
        [
          _c("span", { class: { "sr-only": !_vm.showLabelText } }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ]
      ),
    ]),
    _vm.helperText
      ? _c("small", { staticClass: "form-text text-muted" }, [
          _vm._v(" " + _vm._s(_vm.helperText) + " "),
        ])
      : _vm._e(),
    _vm.$slots.helperText
      ? _c(
          "small",
          { staticClass: "form-text text-muted" },
          [_vm._t("helperText")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }