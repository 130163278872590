var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm._m(0),
        _c("Card", { staticClass: "border card-inactive" }, [
          _c("div", [
            _c("h2", { staticClass: "scenario-sim-header" }, [
              _vm._v("Distribution"),
            ]),
            _c("p", { staticClass: "text-secondary" }, [
              _vm._v("What type of distribution would you like to add?"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "btn-group-toggle row justify-between mt-5" },
            _vm._l(_vm.scenarios, function (scenario, index) {
              return _c("div", { key: index, staticClass: "col" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn w-100",
                    class: {
                      "btn-secondary": _vm.distributionType === scenario.value,
                      "btn-white": _vm.distributionType !== scenario.value,
                    },
                    attrs: { value: scenario.value },
                    on: { click: _vm.clearAmountAndSetDistributionType },
                  },
                  [_vm._v(" " + _vm._s(scenario.label) + " ")]
                ),
              ])
            }),
            0
          ),
          _vm.distributionTypeIsFractional
            ? _c(
                "div",
                {
                  staticClass: "mt-4 input-group input-group-merge col",
                  class: { "input-group-reverse": _vm.distributionTypeIsFixed },
                },
                [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      min: "0",
                      step: "1",
                      max: _vm.distributionTypeIsFixed ? null : 100,
                      placeholder: "Enter a distribution amount (optional)",
                    },
                    domProps: { value: _vm.distributionAmount },
                    on: {
                      input: function (e) {
                        return _vm.updateDistributionAmount(e.target.value)
                      },
                      keypress: _vm.validateInteger,
                    },
                  }),
                  _c("div", { staticClass: "input-group-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.distributionTypeIsFixed ? "$" : "%") +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _vm.distributionType
      ? _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm._l(
              _vm.scenarios.filter(function (scenario) {
                return scenario.value === _vm.distributionType
              }),
              function (scenario) {
                return _c("RecursiveScenarios", {
                  key: scenario.value,
                  attrs: { node: scenario },
                })
              }
            ),
            _c(
              "div",
              {
                staticClass:
                  "row align-items-center justify-content-center mb-4",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary w-25",
                    on: { click: _vm.reset },
                  },
                  [_vm._v("Reset")]
                ),
              ]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-items-center mb-4" }, [
      _c("h1", [_vm._v("Deposit Logic Simulator")]),
      _c("small", { staticClass: "text-secondary" }, [
        _vm._v(
          " This is a generalized version of our direct deposit switch logic; what occurs in our system may vary slightly from this UI due to limitations imposed by payroll providers. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }