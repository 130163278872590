<template>
  <div class="btn-group ms-3" role="group" aria-label="Button group with nested dropdown">
    <div class="btn-group calendar-dropdown" role="group">
      <button
        type="button"
        class="btn btn-sm btn-light datepicker"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click="showPicker()"
        :class="{ active: customDateRange }"
      >
        <v-date-picker
          ref="customDateRangePicker"
          v-model="customDateRange"
          :popover="{ visibility: !showDatePicker ? 'hidden' : 'focus' }"
          color="blue"
          :max-date="new Date()"
          :min-date="$moment(new Date()).subtract(1, 'year').toDate()"
          :attributes="pickerAttributes"
          :rows="2"
          is-dark
          is-range
        >
          <template v-slot="{ inputEvents }">
            <CalendarIcon size="15" />
            <span v-if="customDateRange" v-on="inputEvents" class="ms-1">
              <span>{{ customDateRange.start | moment('MMM D, YYYY') }}</span> —
              <span>{{ customDateRange.end | moment('MMM D, YYYY') }}</span>
            </span>
            <span class="ms-1" v-else>Custom</span>
          </template>
        </v-date-picker>
      </button>
    </div>
    <button
      v-for="option in dateRangeOptions"
      :key="option.label"
      @click="adjustDateRange(option)"
      type="button"
      class="btn btn-sm btn-light"
      :class="{ active: activeDateRangeOption && activeDateRangeOption.label === option.label }"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script>
import { CalendarIcon } from 'vue-feather-icons'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DateRangeBar',
  components: {
    CalendarIcon,
  },
  computed: {
    ...mapState('activity', ['activeDateRangeOption', 'dateRangeOptions', 'startDate', 'endDate']),
  },
  data: () => ({
    dateFormat: 'YYYY-MM-DD',
    customDateRange: undefined,
    showDatePicker: false,
    pickerAttributes: [
      {
        key: 'today',
        dot: true,
        dates: new Date(),
      },
    ],
  }),
  methods: {
    ...mapActions('activity', ['updateDateRangeOption']),
    showPicker() {
      this.$refs.customDateRangePicker.showPopover()
    },
    changedCustomDate() {
      if (!this.customDateRange) return
      this.$refs.customDateRangePicker.hidePopover()
      this.updateDateRangeOption({
        analytics: this.$analytics,
        customDateRange: this.customDateRange,
        option: { label: this.customDateRange },
      })
    },
    adjustDateRange(option) {
      this.customDateRange = undefined
      this.updateDateRangeOption({ analytics: this.$analytics, option })
    },
  },
  watch: {
    customDateRange: {
      handler() {
        this.changedCustomDate()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-dropdown {
  z-index: 10;
}

.btn-light,
.btn-white {
  color: #828c9a;
  background-color: #132b47;
  border-color: #244166;
  &.active {
    color: #fff;
    opacity: 1;
    background-color: #152e4d;
    z-index: 0;
    font-weight: 700;
  }
  &:hover {
    background-color: #152e4d;
    color: #fff;
    z-index: 11 !important;
  }
  &:focus {
    box-shadow: none;
  }
}
</style>
