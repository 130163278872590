import { render, staticRenderFns } from "./EmulatorCustomizationDropdown.vue?vue&type=template&id=685f39da&scoped=true"
import script from "./EmulatorCustomizationDropdown.vue?vue&type=script&lang=js"
export * from "./EmulatorCustomizationDropdown.vue?vue&type=script&lang=js"
import style0 from "./EmulatorCustomizationDropdown.vue?vue&type=style&index=0&id=685f39da&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "685f39da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src525800907/src/console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('685f39da')) {
      api.createRecord('685f39da', component.options)
    } else {
      api.reload('685f39da', component.options)
    }
    module.hot.accept("./EmulatorCustomizationDropdown.vue?vue&type=template&id=685f39da&scoped=true", function () {
      api.rerender('685f39da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationDropdown.vue"
export default component.exports