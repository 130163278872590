<template>
  <EmulatorCustomizationInputWrapper :label="label">
    <div class="row">
      <slot :value="value"></slot>
      <div class="col">
        <div class="form-group">
          <div class="input-group">
            <input
              v-if="isInput"
              class="form-control"
              :placeholder="placeholderValue"
              type="text"
              v-model="mutatedValue"
              @input="inputHandler"
            />
            <textarea
              v-else-if="isTextArea"
              class="form-control"
              :placeholder="placeholderValue"
              type="text"
              v-model="mutatedValue"
              @input="inputHandler"
            />
            <EmulatorCustomizationResetToDefault
              :disabled="!mutatedValue"
              :onClickHandler="resetToDefaultValue"
              toolTip="Reset to Default"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots.postInput" class="row">
      <div class="col">
        <slot name="postInput"></slot>
      </div>
    </div>
  </EmulatorCustomizationInputWrapper>
</template>

<script>
import customThemeActions from '@/mixins/custom-theme-actions'
import EmulatorCustomizationInputWrapper from '@/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationInputWrapper'
import EmulatorCustomizationResetToDefault from '@/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationResetToDefault'

export default {
  name: 'EmulatorCustomizationTextBox',
  components: {
    EmulatorCustomizationInputWrapper,
    EmulatorCustomizationResetToDefault,
  },
  props: {
    inputType: {
      type: String,
      default: 'input',
      validator: (value) => value === 'input' || value === 'textarea',
    },
    placeholder: {
      type: String,
    },
  },
  mixins: [customThemeActions],
  computed: {
    isInput() {
      return this.inputType === 'input'
    },
    isTextArea() {
      return this.inputType === 'textarea'
    },
    placeholderValue() {
      return this.defaultValue ? this.defaultValue : this.placeholder
    },
  },
  methods: {
    inputHandler(event) {
      this.emitMutationEvents({ value: event.target.value || this.defaultValue, action: 'update' })
    },
  },
}
</script>
