var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EmulatorCustomizationInputWrapper",
    { attrs: { label: _vm.label } },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("vSelect", {
            staticClass: "select-list",
            attrs: {
              placeholder: _vm.defaultValue,
              options: _vm.options,
              clearable: false,
              "aria-label": "Select " + _vm.label + " font",
            },
            on: { "option:selected": _vm.selectionHandler },
            model: {
              value: _vm.mutatedValue,
              callback: function ($$v) {
                _vm.mutatedValue = $$v
              },
              expression: "mutatedValue",
            },
          }),
          _c("EmulatorCustomizationResetToDefault", {
            attrs: {
              disabled: !_vm.mutatedValue,
              onClickHandler: _vm.resetToDefaultValue,
              toolTip: "Reset to Default",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }