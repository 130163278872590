<template>
  <div class="row">
    <TaskNav
      class="d-none d-md-inline"
      :product="task.product"
      :hasDistributionData="hasDistributionData"
      :hasEmploymentData="hasEmploymentData"
      :hasIncomeData="hasIncomeData"
      :hasPaystubData="hasPaystubData"
      :hasTimesheetData="hasTimesheetData"
      :hasIdentityData="hasIdentityData"
      :hasTaxesData="hasTaxesData"
      :hasEventsData="hasEventsData"
      v-if="displaySideNavForProducts.includes(task.product)"
    />
    <div
      :class="displaySideNavForProducts.includes(task.product) ? 'col-md-9 col-12' : 'col-12'"
      id="task-content"
    >
      <div id="employment" class="mt-5" v-if="hasEmploymentData">
        <TaskEmployment
          :user="task.user"
          :taskWorkflow="task.taskWorkflow"
          :product="task.product"
          :status="task.status"
          :failReason="task.failReason"
          :company="task.company"
          :deposit="task.settings && task.settings.deposit"
          :withhold="task.settings && task.settings.withhold"
          :employeeType="task.outputs.employeeType"
          :employmentStatus="task.outputs.employmentStatus"
          :employerName="task.outputs.employer && task.outputs.employer.name"
          :jobTitle="task.outputs.jobTitle"
          :startDate="task.outputs.startDate"
          :statements="task.outputs.statements"
          :minimumMonthsOfEmployment="task.outputs.minimumMonthsOfEmployment"
        />
      </div>

      <div id="income" v-if="hasIncomeData">
        <div class="header">
          <div class="header-body">
            <h2 class="header-title">Income</h2>
          </div>
        </div>
        <TaskIncomeChart
          :income="task.outputs.income"
          :incomeType="task.outputs.incomeType"
          :annualIncome="task.outputs.annualIncome"
          :hourlyIncome="task.outputs.hourlyIncome"
          :payCycle="task.outputs.payCycle"
          :nextExpectedPayDate="task.outputs.nextExpectedPayDate"
          :derivedOutputMetadata="task.derivedOutputMetadata"
          :statements="task.outputs.statements"
        />
      </div>

      <div id="task-timesheets-chart" v-if="hasTimesheetData">
        <div class="header mb-0">
          <div class="header-body">
            <h2 class="header-title">Time &amp; Attendance</h2>
          </div>
        </div>
        <TaskTimesheetsChart :timesheets="task.outputs.timesheets" />
      </div>

      <div id="paystubs" v-if="hasPaystubData">
        <div class="header mb-0">
          <div class="header-body">
            <h2 class="header-title">Paystubs</h2>
          </div>
        </div>
        <TaskPaystubs :taskId="task._id" :statements="task.outputs.statements" />
      </div>

      <div id="distributions" v-if="hasDistributionData">
        <div class="header">
          <div class="header-body">
            <h2 class="header-title">Distributions</h2>
          </div>
        </div>
        <TaskDistributions :accounts="task.outputs.accounts" />
      </div>

      <div id="tax-forms" v-if="hasTaxesData">
        <div class="header">
          <div class="header-body">
            <h2 class="header-title">Taxes</h2>
          </div>
        </div>
        <TaskW2s v-if="hasW2Data" :taskId="task._id" :w2s="task.outputs.w2s" />
        <Task1099s v-if="has1099Data" :taskId="task._id" :form1099s="task.outputs['1099s']" />
      </div>

      <div id="identity" v-if="hasIdentityData">
        <div class="header">
          <div class="header-body">
            <h2 class="header-title">Identity</h2>
          </div>
        </div>
        <TaskUserIdentity
          :firstName="task.outputs.firstName"
          :lastName="task.outputs.lastName"
          :dateOfBirth="task.outputs.dateOfBirth"
          :phone="task.outputs.phone"
          :email="task.outputs.email"
          :ssn="task.outputs.ssn"
          :postalCode="task.outputs.postalCode"
          :state="task.outputs.state"
          :city="task.outputs.city"
          :address="task.outputs.address"
        />
      </div>
      <div id="webhook-events" v-if="hasEventsData">
        <div class="header">
          <div class="header-body">
            <h2 class="header-title">Webhook Events</h2>
          </div>
        </div>
        <TaskEvents :eventRequests="eventRequests" :unsentEvents="unsentEvents" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Task1099s from '@/components/Modules/Task/TaskElement/Task1099s'
import TaskBase from '@/components/Modules/Task/TaskBase'
import TaskDistributions from '@/components/Modules/Task/TaskElement/TaskDistributions'
import TaskEmployment from '@/components/Modules/Task/TaskElement/TaskEmployment'
import TaskEvents from '@/components/Modules/Task/TaskElement/TaskEvents'
import TaskIncomeChart from '@/components/Modules/Task/TaskElement/TaskIncomeChart'
import TaskNav from '@/components/Modules/Task/TaskNav'
import TaskPaystubs from '@/components/Modules/Task/TaskElement/TaskPaystubs'
import TaskTimesheetsChart from '@/components/Modules/Task/TaskElement/TaskTimesheetsChart'
import TaskUserIdentity from '@/components/Modules/Task/TaskElement/TaskUserIdentity'
import TaskW2s from '@/components/Modules/Task/TaskElement/TaskW2s'

export default {
  name: 'TaskOverview',
  extends: TaskBase,
  components: {
    TaskDistributions,
    TaskEmployment,
    TaskEvents,
    TaskIncomeChart,
    TaskNav,
    TaskPaystubs,
    TaskTimesheetsChart,
    TaskUserIdentity,
    TaskW2s,
    Task1099s,
  },
  data() {
    return {
      displaySideNavForProducts: ['deposit', 'verify'],
      eventRequests: [],
      unsentEvents: [],
    }
  },
  computed: {
    hasEmploymentData() {
      return (
        this.task.company ||
        this.task.outputs.employeeType ||
        this.task.outputs.employmentStatus ||
        this.task.outputs.jobTitle ||
        this.task.outputs.startDate ||
        this.hasPaystubData
      )
    },
    hasDepositData() {
      return this.task.product === 'deposit'
    },
    hasIncomeData() {
      return (
        (this.task.outputs.income && this.task.outputs.incomeType) ||
        this.task.outputs.hourlyIncome ||
        this.task.outputs.annualIncome ||
        this.task.outputs.payCycle ||
        this.hasPaystubData
      )
    },
    hasTimesheetData() {
      return this.task.outputs.timesheets?.length
    },
    hasPaystubData() {
      return this.task.outputs.statements?.length
    },
    hasDistributionData() {
      return this.task.outputs.accounts?.length
    },
    hasTaxesData() {
      return this.task.outputs.w2s?.length || this.task.outputs['1099s']?.length
    },
    hasW2Data() {
      return this.task.outputs.w2s?.length
    },
    has1099Data() {
      return this.task.outputs['1099s']?.length
    },
    hasIdentityData() {
      return [
        'firstName',
        'lastName',
        'dateOfBirth',
        'phone',
        'email',
        'ssn',
        'postalCode',
        'state',
        'city',
        'address',
      ].some((key) => this.task.outputs[key])
    },
    hasEventsData() {
      return this.unsentEvents.length || this.eventRequests.length
    },
  },
  methods: {
    ...mapActions('task', ['getTaskEvents']),
  },
  async mounted() {
    const { eventRequests, unsentEvents } = await this.getTaskEvents({ taskId: this.task._id })
    this.eventRequests = eventRequests
    this.unsentEvents = unsentEvents
  },
}
</script>
