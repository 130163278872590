<template>
  <Card>
    <div class="row align-items-center gx-0">
      <div class="col">
        <h6 class="text-uppercase text-muted mb-2">{{ title }}</h6>
        <div class="row align-items-center g-0">
          <div class="col-auto">
            <VueSkeletonLoader
              v-if="isLoading"
              class="me-2"
              type="rect"
              :width="32"
              :height="24"
              color="rgba(227,236,248,0.6)"
              :rounded="true"
              animation="wave"
            />
            <span v-else class="h2 me-2 mb-0">{{ value }}</span>
          </div>
          <div class="col">
            <slot name="visual"></slot>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <slot name="icon"></slot>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/MainContent/Card.vue'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'MiniMetric',
  props: ['title', 'value', 'isLoading'],
  components: {
    Card,
    VueSkeletonLoader,
  },
}
</script>
