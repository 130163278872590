<template>
  <validation-observer v-slot="{ handleSubmit, invalid }" tag="div">
    <b-modal
      :id="id"
      :ref="id"
      :title="title"
      @close="$emit('close')"
      @hidden="$emit('hidden')"
      v-model="isVisible"
      no-close-on-backdrop
    >
      <form @submit.prevent="handleSubmit(submit)">
        <slot :disabled="isProcessing"></slot>
      </form>
      <template #modal-footer>
        <button type="button" class="btn btn-light" @click="onCancel" :disabled="isProcessing">
          Cancel
        </button>
        <button
          v-if="!submitButtonHidden"
          type="submit"
          @click="submit"
          :class="`btn btn-${submitButtonVariant}`"
          :disabled="isProcessing || invalid"
        >
          <span
            v-if="isProcessing"
            aria-hidden="true"
            class="spinner-border spinner-border-sm"
          ></span>
          {{ submitButtonText }}
        </button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
export default {
  name: 'ModalForm',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    submitButtonText: {
      type: String,
      default: 'Submit',
    },
    submitButtonVariant: {
      type: String,
      default: 'primary',
    },
    submitButtonHidden: {
      type: Boolean,
      default: false,
    },
    keepOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      state: 'idle',
    }
  },
  computed: {
    isProcessing() {
      return this.state === 'processing'
    },
  },
  methods: {
    hideModal() {
      this.isVisible = false
    },
    onCancel() {
      this.hideModal()
      this.$emit('cancel')
    },
    async submit() {
      try {
        this.state = 'processing'

        const submitResult = await this.onSubmit()

        !this.keepOpen && this.hideModal()

        this.state = 'idle'

        this.$emit('success', submitResult)
      } catch (error) {
        this.state = 'error'
        this.$emit('error', error)
      }
    },
  },
}
</script>

<style>
.close {
  float: right;
  font-size: 1.40625rem;
  font-weight: 600;
  line-height: 1;
  color: #95aac9;
  text-shadow: none;
  opacity: 0.5;
}
.close:hover {
  color: #95aac9;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal-header .close {
  padding: 0;
  height: 20px;
}
</style>
