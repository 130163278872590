<template>
  <ModalForm
    id="delete-user-modal"
    title="Delete User"
    :onSubmit="deleteUser"
    @success="onSuccess"
    submitButtonText="Delete User"
    @hidden="onHide"
    v-slot="{ disabled }"
  >
    <validation-provider
      :rules="{ is: userNameToDelete, required: true }"
      v-slot="{ classes, errors, touched }"
    >
      <div class="form-group">
        <label for="deleteUserText">
          Please type "{{ userNameToDelete }}" to confirm you want to delete this User
        </label>
        <input
          id="deleteUserText"
          type="text"
          :class="classes"
          class="form-control"
          v-model="deleteUserText"
          :placeholder="userNameToDelete"
          :disabled="disabled"
        />
        <div v-if="touched" class="invalid-feedback">{{ errors[0] }}</div>
      </div>
    </validation-provider>
  </ModalForm>
</template>

<script>
import { mapActions } from 'vuex'
import ModalForm from '@/components/MainContent/ModalForm'
import UserService from '@/services/UserService'

export default {
  name: 'SettingsTeamDeleteUserModal',
  components: {
    ModalForm,
  },
  data: () => ({
    deleteUserText: undefined,
  }),
  computed: {
    userNameToDelete: function () {
      return this.user.userName
    },
  },
  props: {
    user: Object,
  },
  methods: {
    ...mapActions('user', ['getUsers']),
    async deleteUser() {
      try {
        await UserService.remove(this.user.userId)

        this.$analytics.track({
          event: 'Delete User',
        })
      } catch (error) {
        this.$toasted.show('Error deleting.')
        console.log(error)
      }
    },
    onSuccess() {
      this.$toasted.success('User Deleted')
      this.getUsers()
    },
    onHide() {
      this.resetState()
    },
    resetState() {
      this.deleteUserText = undefined
    },
  },
}
</script>
