<template>
  <span class="badge bg-secondary-soft help" v-tooltip="title">Derived</span>
</template>

<script>
/*eslint-env jquery*/

export default {
  name: 'DerivedDataBadge',
  props: ['title'],
}
</script>

<style scoped lang="scss">
.help {
  cursor: help;
}
</style>
