var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-inactive" }, [
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c(
          "div",
          { staticClass: "row justify-content-center align-items-center" },
          [
            _c("div", { staticClass: "col" }, [
              _c("h4", { staticClass: "mb-1" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.endpoint.url,
                        expression: "endpoint.url",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error",
                      },
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Copy URL",
                        expression: "'Copy URL'",
                      },
                    ],
                    staticClass: "btn btn-link btn-sm p-0",
                    attrs: { type: "button", title: "Copy URL" },
                  },
                  [_c("CopyIcon", { attrs: { size: "14" } })],
                  1
                ),
                _c("span", { staticClass: "m-2" }, [
                  _vm._v(_vm._s(_vm.endpoint.url)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mb-1 d-flex align-items-center" },
                [
                  _c("KeyIcon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Secret",
                        expression: "'Secret'",
                      },
                    ],
                    attrs: { size: "14", title: "Secret" },
                  }),
                  _c(
                    "div",
                    { staticClass: "ms-2 text-secondary fs-4 fw-bold" },
                    [
                      _vm.endpointSecretName
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.endpointSecretName) + " "),
                          ])
                        : _c("span", { staticClass: "badge bg-warning-soft" }, [
                            _vm._v("NO SECRET ASSIGNED"),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "span",
                _vm._l(_vm.endpoint.eventTypes, function (eventType) {
                  return _c("span", { key: eventType }, [
                    _c("span", { staticClass: "badge bg-light" }, [
                      _vm._v(_vm._s(eventType)),
                    ]),
                    _c("span", [_vm._v(" ")]),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "col-auto" }, [
              _c("div", { staticClass: "col-auto" }),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: "update-endpoint-modal-" + _vm.endpoint._id,
                      expression: "`update-endpoint-modal-${endpoint._id}`",
                    },
                  ],
                  staticClass: "btn btn-rounded-circle btn-white btn-sm me-2",
                  attrs: { title: "Update endpoint" },
                },
                [_c("EditIcon", { attrs: { size: "14" } })],
                1
              ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: "remove-endpoint-modal-" + _vm.endpoint._id,
                      expression: "`remove-endpoint-modal-${endpoint._id}`",
                    },
                  ],
                  staticClass: "btn btn-rounded-circle btn-white btn-sm",
                  attrs: { title: "Remove endpoint" },
                },
                [_c("TrashIcon", { attrs: { size: "14" } })],
                1
              ),
            ]),
          ]
        ),
        _c(
          "ConfirmActionModal",
          {
            attrs: {
              id: "remove-endpoint-modal-" + _vm.endpoint._id,
              title: "Remove endpoint",
              "ok-variant": "danger",
              "ok-title": "Remove",
            },
            on: {
              "action-confirm": function ($event) {
                return _vm.$emit("remove", _vm.endpoint._id)
              },
            },
          },
          [_vm._v(" Are you sure you want to remove this endpoint? ")]
        ),
        _c("SettingsWebhooksUpdateEndpointModal", {
          attrs: { endpoint: _vm.endpoint },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }