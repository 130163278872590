var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showNewUserScreen
        ? _c("TaskOffState")
        : _c(
            "div",
            [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("form", { staticClass: "row align-items-center" }, [
                        _c("div", { staticClass: "col-auto pe-0" }, [
                          !_vm.loadingTasks
                            ? _c(
                                "div",
                                { staticStyle: { width: "16px" } },
                                [
                                  _c("SearchIcon", {
                                    staticClass: "text-muted",
                                    attrs: { size: "16" },
                                  }),
                                ],
                                1
                              )
                            : _c("div", {
                                staticClass:
                                  "spinner-border spinner-border-sm text-muted",
                                staticStyle: {
                                  position: "relative",
                                  top: "-1px",
                                },
                                attrs: { role: "status" },
                              }),
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _c("input", {
                            staticClass:
                              "form-control form-control-flush search",
                            attrs: {
                              type: "text",
                              placeholder:
                                "Search by user identifier or taskId",
                            },
                            domProps: { value: _vm.query },
                            on: { input: _vm.updateQuery },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [_c("TaskFilterProduct")],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [_c("TaskFilterStatus")],
                          1
                        ),
                        _c("div", { staticClass: "col-auto" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-sm",
                              attrs: {
                                type: "button",
                                disabled: _vm.loadingTasks,
                              },
                              on: { click: _vm.refresh },
                            },
                            [_vm._v(" Refresh ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm.showTaskSearchResults
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.taskGroups,
                      function (value, propertyName, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "col-12 d-flex align-items-stretch",
                            class: {
                              "card card-inactive p-3 card-group col-12":
                                value.length > 1,
                              "col-lg-6": value.length === 1,
                            },
                          },
                          [
                            _vm._l(value, function (task) {
                              return _c("TaskCard", {
                                key: task._id,
                                class: {
                                  "col-12 col-sm-6": value.length > 1,
                                },
                                attrs: { task: task },
                              })
                            }),
                            _c("div", { staticClass: "clear" }),
                          ],
                          2
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.showNoTasksFound ? _c("TaskNoTasksFound") : _vm._e(),
              _c(
                "div",
                { staticClass: "row align-items-center text-center mt-4 pb-4" },
                [
                  _c("div", { staticClass: "col" }, [
                    _vm.loadingTasks
                      ? _c("div", {
                          staticClass:
                            "spinner-border spinner-border-sm text-secondary",
                          attrs: { role: "status" },
                        })
                      : _vm._e(),
                    _vm.showLoadMoreButton
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-secondary",
                            on: { click: _vm.loadMore },
                          },
                          [_vm._v(" Load more ")]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }