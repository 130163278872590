<template>
  <div>
    <EmulatorSectionHeader title="Login Screen" />
    <div v-if="testCredentialsEnabled">
      <p class="text-secondary">
        Use the following test usernames to invoke an outcome. Any password is acceptable.
      </p>
      <p>
        <a href="https://docs.atomicfi.com/reference/transact-sdk#testing" target="_blank">
          Read more about testing in our Docs</a
        >.
      </p>
      <div v-for="(category, key) in categorizedTestCredentials" :key="key" class="mt-4">
        <h4>{{ category.title }}</h4>
        <ul class="list-group username-list">
          <li
            v-for="credentialSet in category.credentials"
            :key="credentialSet.username"
            class="list-group-item"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span class="fw-bold title" v-tooltip="`${credentialSet.description}`">
                {{ credentialSet.title }}
              </span>
            </div>
            <div class="copy-credential">
              <small class="text-secondary credential">{{
                loginTestCredential(credentialSet)
              }}</small>
              <button
                class="btn p-0"
                v-clipboard:copy="loginTestCredential(credentialSet)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onCopyError"
                v-tooltip="`Copy ${loginTestCredential(credentialSet)}`"
              >
                <CopyIcon size="14" class="me-1 p-0 m-0" />
                <span class="sr-only">Copy</span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <Alert type="warning" class="mb-4">
        Test credentials are disabled. You may only use real credentials for testing.
      </Alert>
      <p v-if="showLinkToEnableTestCredentials" class="text-secondary">
        If you would like to use test credentials, they can be enabled on
        <router-link :to="{ name: 'settings-general' }">the Features page</router-link>.
      </p>
      <p>
        <a href="https://docs.atomicfi.com/reference/transact-sdk#testing" target="_blank">
          Read more about testing in our Docs</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons'
import { mapGetters, mapState } from 'vuex'
import { hasPermissions } from '@/lib/authorization'
import { PERMISSIONS } from '@/lib/permissions'
import Alert from '@/components/MainContent/Alert'
import EmulatorSectionHeader from '@/components/Modules/Emulator/EmulatorSectionHeader'
import { EMULATOR_EVENT_TYPES } from '@/utils/constants'
import { requestDataFromTransact } from '@/utils/emulator'
import { getCategorizedTestCredentials } from '@atomicfi/constants-shared'

export default {
  name: 'EmulatorLogin',
  components: {
    Alert,
    CopyIcon,
    EmulatorSectionHeader,
  },
  computed: {
    ...mapGetters('customer', ['testCredentialsEnabled']),
    ...mapState('emulator', ['loginInputType', 'scope']),
    categorizedTestCredentials() {
      return getCategorizedTestCredentials([this.scope.name])
    },
    showLinkToEnableTestCredentials() {
      return hasPermissions(PERMISSIONS.MANAGE_FEATURES)
    },
  },
  methods: {
    onCopy(e) {
      this.$toasted.success(`Copied ${e.text} to clipboard.`)
    },
    onCopyError() {
      this.$toasted.error('Error copying to clipboard.')
    },
    loginTestCredential(credentialSet) {
      switch (this.loginInputType) {
        case 'tel':
          return this.$options.filters.phone(credentialSet.phoneNumber)
        case 'email':
          return credentialSet.email
        default:
          return credentialSet.username
      }
    },
  },
  mounted() {
    requestDataFromTransact({
      eventType: EMULATOR_EVENT_TYPES.GET_DATA_FROM_VUEX,
      payload: {
        pathToVuexData: 'currentStep.type',
        namespace: 'formFlow',
        storeActionPath: 'emulator/updateLoginInputType',
      },
    })
  },
}
</script>

<style scoped lang="scss">
.copy-credential {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.title,
.credential {
  font-size: 14px;
}

.title {
  border-bottom: 1px dashed var(--bs-gray-600);
  line-height: 1;

  &:hover {
    cursor: help;
  }
}

.list-group-item {
  padding: 1rem;
}

.username-list {
  background-color: white;
}
</style>
