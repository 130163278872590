var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", [
    _c("div", { staticClass: "row align-items-center" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "h6",
          { staticClass: "card-title text-uppercase text-muted mb-2" },
          [
            _vm._v(" " + _vm._s(_vm.header) + " "),
            _vm.isDerived
              ? _c("DerivedDataBadge", {
                  attrs: {
                    title: "This value was derived from statement data",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("span", { staticClass: "h2 mb-0" }, [
          _vm._v(_vm._s(_vm.text) + " " + _vm._s(_vm.textQualifier)),
        ]),
      ]),
      _c("div", { staticClass: "col-auto" }, [
        _c("span", {
          staticClass: "h2 fe text-muted mb-0",
          class: _vm.spanClass,
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }