var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table table-sm table-nowrap pb-0 mb-n3 breakdown-table" },
    [
      _c(
        "tbody",
        _vm._l(_vm.items, function (ref) {
          var color = ref.color
          var label = ref.label
          var amount = ref.amount
          var percentage = ref.percentage
          var subCategories = ref.subCategories
          var hasSubCategories = ref.hasSubCategories
          return _c(
            "span",
            { key: label },
            [
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: label,
                      expression: "label",
                    },
                  ],
                  staticClass: "d-flex",
                  class: { expandable: hasSubCategories },
                },
                [
                  _c("td", { staticClass: "col-1" }, [
                    _c(
                      "span",
                      { staticClass: "dot", style: { color: color } },
                      [_vm._v("•")]
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "col-6" },
                    [
                      _vm._v(" " + _vm._s(_vm._f("startCase")(label)) + " "),
                      hasSubCategories
                        ? _c("ChevronDownIcon", {
                            staticClass: "text-muted when-open",
                            attrs: { size: "14" },
                          })
                        : _vm._e(),
                      hasSubCategories
                        ? _c("ChevronRightIcon", {
                            staticClass: "text-muted when-closed",
                            attrs: { size: "14" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "col-3 text-right" }, [
                    _vm._v(_vm._s(_vm._f("currency")(amount))),
                  ]),
                  _c("td", { staticClass: "col-2 text-right pe-0" }, [
                    _vm._v(_vm._s(_vm._f("percent")(percentage))),
                  ]),
                ]
              ),
              _c(
                "b-collapse",
                { attrs: { id: label } },
                _vm._l(subCategories, function (ref) {
                  var label = ref.label
                  var amount = ref.amount
                  return _c(
                    "tr",
                    { key: label, staticClass: "d-flex text-muted" },
                    [
                      _c("td", { staticClass: "col-1" }),
                      _c("td", { staticClass: "col-6" }, [
                        _vm._v(_vm._s(label)),
                      ]),
                      _c("td", { staticClass: "col-3 text-right" }, [
                        _vm._v(_vm._s(_vm._f("currency")(amount))),
                      ]),
                      _c("td", { staticClass: "col-2 text-right" }),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }