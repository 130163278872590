var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "btn-group ms-3",
      attrs: {
        role: "group",
        "aria-label": "Button group with nested dropdown",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "btn-group calendar-dropdown",
          attrs: { role: "group" },
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-light datepicker",
              class: { active: _vm.customDateRange },
              attrs: {
                type: "button",
                "data-bs-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
              },
              on: {
                click: function ($event) {
                  return _vm.showPicker()
                },
              },
            },
            [
              _c("v-date-picker", {
                ref: "customDateRangePicker",
                attrs: {
                  popover: {
                    visibility: !_vm.showDatePicker ? "hidden" : "focus",
                  },
                  color: "blue",
                  "max-date": new Date(),
                  "min-date": _vm
                    .$moment(new Date())
                    .subtract(1, "year")
                    .toDate(),
                  attributes: _vm.pickerAttributes,
                  rows: 2,
                  "is-dark": "",
                  "is-range": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var inputEvents = ref.inputEvents
                      return [
                        _c("CalendarIcon", { attrs: { size: "15" } }),
                        _vm.customDateRange
                          ? _c(
                              "span",
                              _vm._g({ staticClass: "ms-1" }, inputEvents),
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        _vm.customDateRange.start,
                                        "MMM D, YYYY"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" — "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        _vm.customDateRange.end,
                                        "MMM D, YYYY"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _c("span", { staticClass: "ms-1" }, [
                              _vm._v("Custom"),
                            ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.customDateRange,
                  callback: function ($$v) {
                    _vm.customDateRange = $$v
                  },
                  expression: "customDateRange",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._l(_vm.dateRangeOptions, function (option) {
        return _c(
          "button",
          {
            key: option.label,
            staticClass: "btn btn-sm btn-light",
            class: {
              active:
                _vm.activeDateRangeOption &&
                _vm.activeDateRangeOption.label === option.label,
            },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.adjustDateRange(option)
              },
            },
          },
          [_vm._v(" " + _vm._s(option.label) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }