<template>
  <div class="row">
    <div class="card col-12">
      <div class="card-body">
        <Logo
          v-if="company"
          :branding="company.branding"
          :name="company.name"
          class="card-avatar card-avatar-top"
          size="lg"
        />

        <h2 class="card-title text-center" v-if="company">{{ company.name }}</h2>

        <p class="small text-center text-muted mb-1" v-if="jobTitle">
          {{ jobTitle | startCase }}
        </p>

        <p class="card-text text-center">
          <span class="badge bg-secondary-soft">{{ employeeType | startCase }}</span>
        </p>

        <div class="row g-0 border-top border-bottom">
          <div class="col-auto py-4 text-center flex-fill" v-if="hasStatements">
            <h6 class="text-uppercase text-muted">Total Earnings</h6>
            <h2 class="mb-0">{{ totalEarnings | currency }}</h2>
          </div>
          <div class="col-auto py-4 text-center flex-fill" v-if="startDate">
            <h6 class="text-uppercase text-muted">Hire Date</h6>
            <h2 class="mb-0">{{ startDate | moment('M/DD/YYYY') }}</h2>
          </div>
          <div class="col-auto py-4 text-center flex-fill" v-if="minimumMonthsOfEmployment">
            <h6 class="text-uppercase text-muted">Employed at least</h6>
            <h2 class="mb-0">{{ yearsAndMonthsEmployed }}</h2>
          </div>
        </div>
        <!-- List group -->
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="row">
              <div class="col">
                <small>Identifier</small>
              </div>
              <div class="col-auto">
                <div class="identifier">
                  <small>{{ user.identifier }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="employerName">
            <div class="row align-items-center">
              <div class="col">
                <small>Employer name in payroll system</small>
              </div>
              <div class="col-auto">
                <small>{{ employerName }}</small>
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="employmentStatus">
            <div class="row align-items-center">
              <div class="col">
                <small>Actively employed</small>
              </div>
              <div class="col-auto">
                <CheckCircleIcon size="16" v-if="isActivelyEmployed" class="text-success" />
                <XCircleIcon size="16" v-else class="text-danger" />
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="hasStatements">
            <div class="row align-items-center">
              <div class="col">
                <small>Recent pay statements</small>
              </div>
              <div class="col-auto">
                <CheckCircleIcon size="16" v-if="hasRecentStatements" class="text-success" />
                <XCircleIcon size="16" v-else class="text-danger" />
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="product === 'deposit' || status !== 'completed'">
            <div class="row align-items-center">
              <div class="col">
                <small>Status</small>
              </div>
              <div class="col-auto">
                <div v-if="status === 'completed'" class="text-success">
                  <span class="badge bg-success-soft">Completed</span>
                </div>
                <div v-else-if="status === 'failed'">
                  <span class="badge bg-danger-soft">Failed</span>
                </div>
                <div v-else><span class="badge bg-primary-soft">Processing</span></div>
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="failReason">
            <div class="row align-items-center">
              <div class="col">
                <small>Fail reason</small>
              </div>
              <div class="col-auto">
                <div class="d-flex justify-content-end">
                  <span class="badge bg-light">{{ failReason }}</span>
                </div>
                <small>{{ failReasons[failReason].description }}</small>
              </div>
            </div>
          </div>

          <div
            class="list-group-item"
            v-if="deposit && deposit.distributionAction && product === 'deposit'"
          >
            <div class="row align-items-center">
              <div class="col">
                <small>Action</small>
              </div>
              <div class="col-auto">
                <span class="badge bg-light capitalize">{{ deposit.distributionAction }}</span>
              </div>
            </div>
          </div>
          <div
            class="list-group-item"
            v-if="deposit && deposit.distributionType && product === 'deposit'"
          >
            <div class="row align-items-center">
              <div class="col">
                <small>Amount</small>
              </div>
              <div class="col-auto">
                <span class="capitalize">
                  <span class="badge bg-light" v-if="deposit.distributionType === 'total'">
                    Entire balance
                  </span>
                  <span class="badge bg-light" v-if="deposit.distributionType === 'percent'">
                    {{ deposit.distributionAmount }}%
                  </span>
                  <span class="badge bg-light" v-if="deposit.distributionType === 'fixed'">
                    {{ deposit.distributionAmount | currency }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <div
            class="list-group-item"
            v-if="deposit && deposit.routingNumber && product === 'deposit'"
          >
            <div class="row align-items-center">
              <div class="col">
                <small>Routing number</small>
              </div>
              <div class="col-auto">
                {{ deposit.routingNumber }}
              </div>
            </div>
          </div>
          <div
            class="list-group-item"
            v-if="deposit && deposit.accountNumber && product === 'deposit'"
          >
            <div class="row align-items-center">
              <div class="col">
                <small>Account number</small>
              </div>
              <div class="col-auto">
                ******{{ deposit.accountNumber.substring(deposit.accountNumber.length - 4) }}
              </div>
            </div>
          </div>
        </div>

        <div class="list-group list-group-flush" v-if="product === 'withhold'">
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Form Version</small>
              </div>
              <div class="col-auto">
                {{ formVersionMap[withhold.formVersion] }}
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Filing Status</small>
              </div>
              <div class="col-auto">
                {{ filingStatusMap[withhold.filingStatus] }}
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Multiple Jobs or Spouse Works</small>
              </div>
              <div class="col-auto">
                {{ withhold.multipleJobsOrSpouseWorks ? 'Yes' : 'No' }}
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Non-Resident Alien</small>
              </div>
              <div class="col-auto">{{ withhold.nonResidentAlien ? 'Yes' : 'No' }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Exempt</small>
              </div>
              <div class="col-auto">{{ withhold.exempt ? 'Yes' : 'No' }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Dependent Tax Credit Eligible</small>
              </div>
              <div class="col-auto">{{ withhold.dependentTaxCreditEligible ? 'Yes' : 'No' }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Dependents Under 17</small>
              </div>
              <div class="col-auto">{{ withhold.dependentsUnder17 }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Dependents Under 17 Amount</small>
              </div>
              <div class="col-auto">{{ withhold.dependentsUnder17Amount | currency }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Other Dependents</small>
              </div>
              <div class="col-auto">{{ withhold.otherDependents }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Other Dependents Amount</small>
              </div>
              <div class="col-auto">{{ withhold.otherDependentsAmount | currency }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Dependent Total Amount</small>
              </div>
              <div class="col-auto">{{ withhold.dependentAmount | currency }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Other Income Not From Jobs</small>
              </div>
              <div class="col-auto">{{ withhold.otherIncomeNotFromJobsAmount | currency }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Deductions</small>
              </div>
              <div class="col-auto">{{ withhold.deductionsAmount | currency }}</div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <small>Extra Withholding Per Pay Period</small>
              </div>
              <div class="col-auto">
                {{ withhold.extraWithholdingPerPayPeriodAmount | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/MainContent/Logo'
import sumOfDecimals from '@/lib/sum-of-decimals'
import { failReasons } from '@/lib/task-fail-reasons'
import { CheckCircleIcon, XCircleIcon } from 'vue-feather-icons'
export default {
  name: 'TaskEmployment',
  components: { CheckCircleIcon, Logo, XCircleIcon },
  props: {
    user: Object,
    taskWorkflow: Object,
    product: String,
    failReason: String,
    status: String,
    deposit: Object,
    withhold: Object,
    company: Object,
    employeeType: String,
    employerName: String,
    employmentStatus: String,
    jobTitle: String,
    startDate: String,
    statements: { type: Array, default: () => [] },
    minimumMonthsOfEmployment: Number,
  },
  data: () => ({
    failReasons,
    filingStatusMap: {
      'head-of-household': 'Head of Household',
      'married-filing-jointly-or-qualifying-widow-er':
        'Married Filing Jointly or Qualifying Widow(er)',
      'single-or-married-filing-separately': 'Single or Married Filing Separately',
    },
    formVersionMap: {
      'united-states-w-4': 'United States W-4',
    },
  }),
  computed: {
    totalEarnings() {
      return sumOfDecimals(this.statements.map(({ grossAmount }) => grossAmount))
    },
    isActivelyEmployed() {
      return this.employmentStatus === 'active'
    },
    hasStatements() {
      return this.statements.length > 0
    },
    hasRecentStatements() {
      if (!this.hasStatements) {
        return false
      }

      const mostRecentStatementDate = Math.max(...this.statements.map(({ date }) => new Date(date)))

      return this.$moment(mostRecentStatementDate).isAfter(this.$moment().subtract(1, 'month'))
    },
    yearsAndMonthsEmployed() {
      const years = Math.floor(this.minimumMonthsOfEmployment / 12)
      const months = this.minimumMonthsOfEmployment % 12

      const yearUnit = years > 1 ? 'years' : 'year'
      const monthUnit = months > 1 ? 'months' : 'month'

      const yearString = years ? `${years} ${yearUnit}` : ''
      const separator = years ? `${months ? ',' : ''} ` : ''
      const monthString = months ? `${months} ${monthUnit}` : ''

      return `${yearString}${separator}${monthString}`
    },
  },
}
</script>

<style scoped>
.identifier {
  max-width: 600px;
  text-align: right;
}
</style>
