import Vue from 'vue'
import store from '@/store'
import { getAtomicCustomerAPIFromEnvironment } from '@/utils/environment'
import { ENVIRONMENTS } from '@/utils/constants'

export default {
  create(payload) {
    return Vue.http.post(`${store.getters['environment/atomicCustomerAPI']}/user`, payload)
  },
  list() {
    return Vue.http.get(`${store.getters['environment/atomicCustomerAPI']}/user/list`)
  },
  remove(id) {
    return Vue.http.delete(`${store.getters['environment/atomicCustomerAPI']}/user/${id}`)
  },
  updateUserRoles(id, payload) {
    return Vue.http.put(
      `${store.getters['environment/atomicCustomerAPI']}/user/${id}/roles`,
      payload
    )
  },
  validateEmail(email) {
    return Vue.http.post(
      `${getAtomicCustomerAPIFromEnvironment(ENVIRONMENTS.PRODUCTION)}/user/validate-email`,
      {
        email,
      }
    )
  },
  updateUserAccounts(payload) {
    return Vue.http.patch(
      `${store.getters['environment/atomicCustomerAPI']}/user/accounts`,
      payload
    )
  },
}
