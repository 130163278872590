import ActivityService from '@/services/ActivityService'
import { decideBinSize } from '@/utils/activity'
import { TRANSACT_EVENTS } from '@/utils/constants'
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD'
const defaultDateRangeOption = { label: '30D', from: 0, end: 30 }

export default {
  namespaced: true,
  state: {
    sampleDataMode: false,
    dateRangeOptions: [
      { label: 'Today', from: 0, end: 0 },
      { label: 'Yesterday', from: 1, end: 1 },
      { label: '7D', from: 0, end: 7 },
      defaultDateRangeOption,
      { label: '3M', from: 0, end: 90 },
    ],
    activeDateRangeOption: defaultDateRangeOption,
    authenticationRate: undefined,
    endDate: _getCustomEndDate(defaultDateRangeOption),
    failureReasons: undefined,
    funnel: {
      isLoading: false,
      steps: [
        TRANSACT_EVENTS.INITIALIZED_TRANSACT,
        TRANSACT_EVENTS.SUBMITTED_CREDENTIALS,
        TRANSACT_EVENTS.TASK_DONE,
      ],
      filters: { 2: [{ property: 'status', operator: 'is', value: 'completed' }] },
      data: undefined,
    },
    linkedAccounts: undefined,
    startDate: _getCustomStartDate(defaultDateRangeOption),
    taskCounts: undefined,
    topConnections: undefined,
    transactionVolume: undefined,
    notBinnedUniqueUsers: undefined,
    binnedUniqueUsers: undefined,
  },
  mutations: {
    setAuthenticationRate(state, authenticationRate) {
      state.authenticationRate = authenticationRate
    },
    setFunnelData(state, funnelData) {
      state.funnel.data = funnelData
    },
    setFunnelIsLoading(state, isLoading) {
      state.funnel.isLoading = isLoading
    },
    setFunnelSteps(state, funnelSteps) {
      state.funnel.steps = funnelSteps
    },
    setFunnelFilters(state, funnelFilters) {
      state.funnel.filters = funnelFilters
    },
    setActiveDateRangeOption(state, activeDateRangeOption) {
      state.activeDateRangeOption = activeDateRangeOption
    },
    setStartDate(state, startDate) {
      state.startDate = startDate
    },
    setEndDate(state, endDate) {
      state.endDate = endDate
    },
    setTaskCounts(state, taskCounts) {
      state.taskCounts = taskCounts
    },
    setLinkedAccounts(state, linkedAccounts) {
      state.linkedAccounts = linkedAccounts
    },
    setTopConnections(state, topConnections) {
      state.topConnections = topConnections
    },
    setNotBinnedUniqueUsers(state, notBinnedUniqueUsers) {
      state.notBinnedUniqueUsers = notBinnedUniqueUsers
    },
    setBinnedUniqueUsers(state, binnedUniqueUsers) {
      state.binnedUniqueUsers = binnedUniqueUsers
    },
    setTransactionVolume(state, transactionVolume) {
      state.transactionVolume = transactionVolume
    },
    setFailureReasons(state, failureReasons) {
      state.failureReasons = failureReasons
    },
    setSampleDataMode(state, sampleDataMode) {
      state.sampleDataMode = sampleDataMode
    },
  },
  actions: {
    async getFunnel({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.funnel({
        steps: state.funnel.steps,
        filters: state.funnel.filters,
        startDate: state.startDate,
        endDate: state.endDate,
      })
      commit('setFunnelData', response.data)
    },
    async getTopConnections({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.topConnections({
        startDate: state.startDate,
        endDate: state.endDate,
      })
      commit('setTopConnections', response.data)
    },
    async getAuthenticationRate({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.authenticationRate({
        startDate: state.startDate,
        endDate: state.endDate,
      })
      commit('setAuthenticationRate', response.data)
    },
    async getTaskCounts({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.taskCounts({
        startDate: state.startDate,
        endDate: state.endDate,
      })
      commit('setTaskCounts', response.data)
    },
    async getNotBinnedUniqueUsers({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.uniqueUsers({
        startDate: state.startDate,
        endDate: state.endDate,
        bin: 'none',
      })
      commit('setNotBinnedUniqueUsers', response.data)
    },
    async getBinnedUniqueUsers({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.uniqueUsers({
        startDate: state.startDate,
        endDate: state.endDate,
        bin: decideBinSize(state.activeDateRangeOption.end),
      })
      commit('setBinnedUniqueUsers', response.data)
    },
    async getLinkedAccounts({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.linkedAccounts({
        startDate: state.startDate,
        endDate: state.endDate,
      })
      commit('setLinkedAccounts', response.data)
    },
    async getTransactionVolume({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.transactionVolume({
        startDate: state.startDate,
        endDate: state.endDate,
        bin: decideBinSize(state.activeDateRangeOption.end),
      })
      commit('setTransactionVolume', response.data)
    },
    async getFailureReasons({ commit, rootGetters, state }) {
      if (rootGetters['environment/isSandbox']) return

      const response = await ActivityService.failureReasons({
        startDate: state.startDate,
        endDate: state.endDate,
      })
      commit('setFailureReasons', response.data)
    },
    updateDateRangeOption({ commit }, { analytics, customDateRange, option }) {
      commit('setSampleDataMode', false)
      commit('setActiveDateRangeOption', option)

      if (customDateRange) {
        const startDate = moment(customDateRange.start).format(dateFormat)
        const endDate = moment(customDateRange.end).format(dateFormat)

        commit('setStartDate', startDate)
        commit('setEndDate', endDate)

        analytics.track({
          event: 'Changed Activity Date Range',
          payload: {
            option: 'Custom',
            startDate,
            endDate,
          },
        })
      } else {
        commit('setStartDate', _getCustomStartDate(option))
        commit('setEndDate', _getCustomEndDate(option))

        analytics.track({
          event: 'Changed Activity Date Range',
          payload: {
            option: option.label,
          },
        })
      }
    },
  },
}

function _getCustomStartDate(option) {
  return moment().subtract(option.end, 'day').format(dateFormat)
}

function _getCustomEndDate(option) {
  return moment().subtract(option.from, 'day').format(dateFormat)
}
