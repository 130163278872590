var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Form", {
    attrs: { onSubmit: _vm.update, submitButtonText: "Update" },
    on: { success: _vm.onSuccess, error: _vm.onError },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var disabled = ref.disabled
          return [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "mb-1", attrs: { for: "institution-name" } },
                    [_vm._v("Name")]
                  ),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _vm._v(
                      "This name will be used in communications with your customers."
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fields.name,
                        expression: "fields.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "institution-name",
                      placeholder: "ABC Credit Union",
                      type: "text",
                      disabled: disabled,
                    },
                    domProps: { value: _vm.fields.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.fields, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "mb-1",
                      attrs: { for: "institution-abbreviation" },
                    },
                    [_vm._v("Abbreviation")]
                  ),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _vm._v(
                      "This shortened version of the name may also be used in communications with your customers."
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fields.nickName,
                        expression: "fields.nickName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "institution-abbreviation",
                      placeholder: "ABCU",
                      type: "text",
                      disabled: disabled,
                    },
                    domProps: { value: _vm.fields.nickName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.fields, "nickName", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.logos, function (logo) {
                    return _c(
                      "div",
                      { key: logo.path, staticClass: "col-12 col-md-6" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "mb-1",
                                attrs: { for: "institution-abbreviation" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle me-1",
                                  },
                                  [_vm._v(_vm._s(logo.label))]
                                ),
                              ]
                            ),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted mb-4" },
                              [_vm._v(_vm._s(logo.helperText))]
                            ),
                            _vm.fields["branding." + logo.path + ".url"]
                              ? _c("div", { staticClass: "logo-outer" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "logo-inner",
                                      class: logo.wrapperClass,
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "logo",
                                        attrs: {
                                          src: _vm.fields[
                                            "branding." + logo.path + ".url"
                                          ],
                                          alt:
                                            _vm.fields.name +
                                            " " +
                                            logo.label +
                                            " preview",
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-rounded-circle btn-white btn-sm upper-right",
                                          on: {
                                            click: function () {
                                              return _vm.removeLogo(logo.path)
                                            },
                                          },
                                        },
                                        [
                                          _c("XIcon", {
                                            attrs: { size: "14" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("Dropzone", {
                                  attrs: {
                                    multi: false,
                                    public: true,
                                    "accepted-files": "image/png",
                                  },
                                  on: {
                                    updatedFiles: function (files) {
                                      return _vm.setLogo(logo.path, files)
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }