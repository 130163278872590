var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.accounts, function (ref, index) {
      var routingNumber = ref.routingNumber
      var accountNumber = ref.accountNumber
      var distributionType = ref.distributionType
      var distributionAmount = ref.distributionAmount
      return _c(
        "div",
        {
          key: accountNumber,
          staticClass: "row flex-nowrap",
          class: { "mt-n3": index !== 0 },
        },
        [
          _vm.hasMultipleAccounts
            ? _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass: "distribution-indicator",
                    class: { "distribution-secondary": index !== 0 },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          index !== 0
                            ? "/images/distribution-next.svg"
                            : "/images/distribution-first.svg",
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                "col-11": _vm.hasMultipleAccounts,
                "col-12": !_vm.hasMultipleAccounts,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card card-body d-flex flex-row justify-content-between",
                },
                [
                  _c("div", { staticClass: "d-flex flex-row mt-n2" }, [
                    _c("div", { staticClass: "me-4" }, [
                      _c(
                        "h6",
                        { staticClass: "text-muted text-uppercase m-0" },
                        [_vm._v("routing")]
                      ),
                      _c("div", [_vm._v(" " + _vm._s(routingNumber) + " ")]),
                    ]),
                    _c("div", [
                      _c(
                        "h6",
                        { staticClass: "text-muted text-uppercase m-0" },
                        [_vm._v("account")]
                      ),
                      _c("div", [_vm._v(" " + _vm._s(accountNumber) + " ")]),
                    ]),
                  ]),
                  _c("div", [
                    distributionType === "total"
                      ? _c("span", [_vm._v("100%")])
                      : _vm._e(),
                    distributionType === "percent"
                      ? _c("span", [_vm._v(_vm._s(distributionAmount) + "%")])
                      : _vm._e(),
                    distributionType === "fixed"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("currency")(distributionAmount))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }