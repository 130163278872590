<template>
  <div class="dropdown d-inline">
    <a href="#" class="small text-muted" data-toggle="dropdown" aria-expanded="false"
      >Product <ChevronDownIcon size="14" class="mb-1"
    /></a>
    <div class="dropdown-menu">
      <form>
        <a class="dropdown-item" href="#!" v-for="product in products" :key="product">
          <CheckIcon
            size="14"
            class="float-end text-secondary mt-1"
            v-if="filters.product.includes(product)"
          />
          <span
            @click="toggleProductFilter(product)"
            class="badge text-capitalize bg-primary-soft"
            >{{ product }}</span
          >
        </a>
      </form>
    </div>
  </div>
</template>

<script>
import { CheckIcon, ChevronDownIcon } from 'vue-feather-icons'
import TaskBase from '@/components/Modules/Task/TaskBase'
import { TASK_FILTER_PRODUCT_OPTIONS } from '@/lib/task-filter-product-options'

export default {
  name: 'TaskFilterProduct',
  components: { CheckIcon, ChevronDownIcon },
  extends: TaskBase,
  data() {
    return {
      products: TASK_FILTER_PRODUCT_OPTIONS,
    }
  },
}
</script>
