<template>
  <div class="dropdown d-inline">
    <a href="#" class="small text-muted" data-toggle="dropdown" aria-expanded="false"
      >Status <ChevronDownIcon size="14" class="mb-1"
    /></a>
    <div class="dropdown-menu">
      <form>
        <a class="dropdown-item" href="#!" v-for="(value, status) in statuses" :key="status">
          <CheckIcon
            size="14"
            class="float-end text-secondary mt-1"
            v-if="filters.externalStatus.includes(status)"
          />
          <span
            @click="toggleStatusFilter(status)"
            class="badge text-capitalize"
            :class="`bg-${value.class}-soft`"
            >{{ status }}</span
          >
        </a>
      </form>
    </div>
  </div>
</template>

<script>
import { CheckIcon, ChevronDownIcon } from 'vue-feather-icons'
import { statuses } from '@/lib/task-status'
import TaskBase from '@/components/Modules/Task/TaskBase'
export default {
  name: 'TaskFilterStatus',
  components: { CheckIcon, ChevronDownIcon },
  extends: TaskBase,
  data: () => ({
    statuses: statuses,
  }),
}
</script>
