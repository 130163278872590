<template>
  <ModalForm
    id="add-secret-modal"
    title="Add Secret"
    submitButtonText="Generate Secret"
    :onSubmit="generateSecret"
    @success="onSuccess"
    @hidden="onHide"
    v-slot="{ disabled }"
  >
    <validation-provider rules="required" v-slot="{ classes, errors, touched }">
      <div class="form-group">
        <label for="newSecretName">Name</label>
        <input
          id="newSecretName"
          type="text"
          :class="classes"
          class="form-control"
          v-model="newSecretName"
          placeholder="Default"
          :disabled="disabled"
        />
        <div class="invalid-feedback" v-if="touched">{{ errors[0] }}</div>
      </div>
    </validation-provider>
  </ModalForm>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import ModalForm from '@/components/MainContent/ModalForm'

export default {
  name: 'SettingsCredentialsAddSecretModal',
  components: {
    ModalForm,
    ValidationProvider,
  },
  data: () => ({
    newSecretName: undefined,
  }),
  methods: {
    ...mapActions('customer', ['addSecret']),
    async generateSecret() {
      await this.addSecret(this.newSecretName)

      this.$analytics.track({
        event: 'Add API Secret',
        payload: {
          secretName: this.newSecretName,
        },
      })

      this.resetState()
    },
    onSuccess() {
      this.$toasted.success('Secret Generated')
    },
    onHide() {
      this.resetState()
    },
    resetState() {
      this.newSecretName = undefined
    },
  },
}
</script>
