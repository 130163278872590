import store from '@/store'

export function emitEmulatorEvent({ type, payload }) {
  const encodedEvent = btoa(JSON.stringify({ type, payload }))
  _getiFrameElement()?.contentWindow?.postMessage(encodedEvent, '*')
}

/**
 * Parses window event responses, filters out noise and returns parsed JSON data
 * @param {Object} event event response
 * @param {Object} filters containing both namespace and path
 * @returns Object JSON (filtered by namespace/path if specified)
 */
export function parseWindowEvent(event, { namespace = '', path = '' }) {
  if (event.data?.source === 'vue-devtools-backend') return
  if (event.data?.type === 'vue-devtools-backend') return

  //filter out bad JSON responses
  let data = {}
  try {
    data = JSON.parse(event.data)
  } catch (e) {
    return
  }

  //return using filters, if provided
  if (namespace && path) {
    if (data.namespace === namespace && data.pathToVuexData === path) {
      return data.value
    }
    return
  }
  return data.value
}

export function requestDataFromTransact({ eventType, payload }) {
  window.addEventListener('message', _listenForTransactUpdate(payload))

  emitEmulatorEvent({
    type: eventType,
    payload: {
      pathToVuexData: payload.pathToVuexData,
      namespace: payload.namespace,
    },
  })
}

const _listenForTransactUpdate = function (payload) {
  const listenerFunctionInstance = this

  return (event) => {
    const data = parseWindowEvent(event, {
      namespace: payload.namespace,
      path: payload.pathToVuexData,
    })

    if (data) {
      store.dispatch(payload.storeActionPath, data)
      window.removeEventListener('message', listenerFunctionInstance)
    }
  }
}

function _getiFrameElement() {
  return document.querySelector('#atomic-transact-iframe')
}
