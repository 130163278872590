import { get, set } from 'lodash-es'

export const getCustomerUpdater = ({ getUpdates, localStateKey }) => {
  // This cannot be an arrow function since the this keyword needs to be dynamically bound
  return async function (newValue) {
    let updatingToast
    const previousValue = get(this, localStateKey)
    set(this, localStateKey, newValue)

    try {
      updatingToast = this.$toasted.show('Updating...', { duration: null })
      this.isUpdating = true

      await this.updateCustomer({ payload: getUpdates(newValue) })

      this.$analytics.track({
        event: 'Update Feature',
        payload: {
          feature: localStateKey,
          newValue,
        },
      })

      updatingToast.goAway(0)
      this.$toasted.success('Update successful.')
    } catch (error) {
      updatingToast.goAway(0)
      this.$toasted.error('Error updating.')
      set(this, localStateKey, previousValue)
    } finally {
      this.isUpdating = false
    }
  }
}
