<template>
  <div class="card">
    <div class="card-body text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <img
            src="/images/illustrations/dashboard-offstate.svg"
            alt="..."
            class="img-fluid mb-4"
            style="max-width: 320px"
          />
          <h2>Welcome to Atomic</h2>
          <p class="text-muted">It looks like you're new. Ready to get started?</p>
          <router-link
            v-if="canViewCredentials"
            class="btn btn-primary lift me-3"
            to="/settings/credentials"
            >Get API keys</router-link
          >
          <router-link
            v-if="canUseEmulator"
            class="btn btn-outline lift btn-outline-primary"
            to="/emulator"
            >Try the Emulator</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasPermissions } from '@/lib/authorization'
import { PERMISSIONS } from '@/lib/permissions'

export default {
  name: 'TaskOffState',
  computed: {
    canUseEmulator() {
      return hasPermissions(PERMISSIONS.USE_EMULATOR)
    },
    canViewCredentials() {
      return hasPermissions(PERMISSIONS.MANAGE_CREDENTIALS)
    },
  },
}
</script>
