var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("input", {
        class: { dark: _vm.settings.theme.dark },
        attrs: { type: "checkbox", id: "toggle" },
        on: { input: _vm.toggleColorMode },
      }),
      _c("label", {
        class: { dark: _vm.settings.theme.dark },
        attrs: { for: "toggle" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }