var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "funnel-container",
      style: "height: " + (_vm.height + 100) + "px;",
    },
    [
      !_vm.isDataReady
        ? _c("div", { staticClass: "centered" }, [
            _c("div", {
              staticClass:
                "spinner-border spinner-border-md text-muted spinner",
              attrs: {
                "data-test-id": "search-spinner",
                role: "status",
                "aria-hidden": "true",
              },
            }),
          ])
        : _vm._e(),
      _vm.isDataReady && _vm.insufficientData
        ? _c("div", { staticClass: "centered alert alert-secondary" }, [
            _vm._v(" Not enough activity. "),
          ])
        : _vm._e(),
      _c("vue-funnel-graph", {
        key: _vm.documentWidth,
        class: {
          loading: !_vm.isDataReady,
          "insufficient-data": _vm.insufficientData,
        },
        attrs: {
          width: _vm.documentWidth,
          height: _vm.height,
          labels: _vm.labels,
          values: _vm.values,
          colors: _vm.colors,
          "sub-labels": _vm.subLabels,
          direction: _vm.direction,
          "gradient-direction": _vm.gradientDirection,
          animated: true,
          "display-percentage": true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }