<template>
  <div>
    <div class="form-group">
      <label for="selectorSearch">Role</label>
      <validation-provider name="Roles" :rules="validationRules" v-slot="{ classes, errors }">
        <v-select v-model="newSelection" :options="options" :class="classes">
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              id="selectorSearch"
              ref="userInput"
              :required="!newSelection"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
        <div class="invalid-feedback">{{ errors[0] }}</div>
      </validation-provider>
    </div>
    <div v-if="isCollapsible" v-b-toggle="'newSelectionDetails'">
      <p class="card-header-title">
        <ChevronRightIcon class="toggle-icon" />
        <span class="toggle-title">Role Permissions</span>
      </p>
    </div>
    <b-collapse v-if="isCollapsible" id="newSelectionDetails" class="mt-4">
      <RolePermissionsTable v-if="newSelectionDetails" :userRole="newSelectionDetails" />
      <p v-else>Please select a role.</p>
    </b-collapse>
    <div class="mt-4" v-else>
      <RolePermissionsTable v-if="newSelectionDetails" :userRole="newSelectionDetails" />
      <p v-else>Please select a role.</p>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
import { ENVIRONMENTS } from '@/utils/constants'
import { mapGetters } from 'vuex'
import { roles } from '@/lib/authorization'
import { startCase } from 'lodash-es'
import RolePermissionsTable from '@/components/Modules/Settings/RolePermissions/RolePermissionsTable'

export default {
  name: 'UserRoleSelector',
  components: {
    RolePermissionsTable,
    ChevronRightIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    isCollapsible: {
      type: Boolean,
      default: true,
    },
    needsValidation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newSelection: undefined,
      startCase,
      ENVIRONMENTS,
    }
  },
  watch: {
    newSelection: function (val) {
      this.$emit('selectionChanged', val.value)
    },
  },
  computed: {
    validationRules() {
      return this.needsValidation
        ? { required: { message: 'Please select an available role.' } }
        : {}
    },
    newSelectionDetails() {
      if (!this.newSelection) {
        return
      }

      return roles.find((role) => role.value === this.newSelection.value)
    },
  },
}
</script>

<style scoped>
.toggle-title {
  margin-left: 0.5em;
  vertical-align: middle;
}

.toggle-icon {
  transition: 300ms transform ease-in-out;
}

.not-collapsed .toggle-icon {
  transform: rotate(90deg);
}
</style>
