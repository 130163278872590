import { groupBy } from 'lodash-es'

export function getProductTitle(task) {
  return `${task.product}${task.additionalProduct ? ` /  ${task.additionalProduct}` : ''}`
}

export function getObjectsGroupedByYearAndMonth(objects) {
  const groupedByYear = groupBy(objects, ({ date }) => new Date(date).getUTCFullYear())

  const groupedByYearAndMonth = Object.fromEntries(
    Object.entries(groupedByYear).map(([year, objects]) => [
      year,
      groupBy(objects, ({ date }) => new Date(date).getUTCMonth()),
    ])
  )

  return groupedByYearAndMonth
}

export function getYearsOfPaymentHistory(statements) {
  return Object.keys(getObjectsGroupedByYearAndMonth(statements))
}
