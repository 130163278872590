var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ModalForm",
    {
      attrs: {
        id: "edit-user-role-modal",
        title: "Update user role",
        "on-submit": _vm.editUserRole,
        submitButtonText: "Update role",
      },
      on: { success: _vm.onSuccess, error: _vm.onError },
    },
    [
      _c("UserRoleSelector", {
        attrs: { options: _vm.user.availableRoles },
        on: { selectionChanged: _vm.updateRole },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }