var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MiniMetric", {
    attrs: {
      title: "New Monitored Accounts",
      value: _vm.monitoredAccounts.toLocaleString(),
      isLoading: !_vm.isDataReady,
    },
    scopedSlots: _vm._u(
      [
        _vm.isDataReady && _vm.increasePercentage
          ? {
              key: "visual",
              fn: function () {
                return [
                  _c("span", { staticClass: "badge bg-success-soft mt-n1" }, [
                    _vm._v("+" + _vm._s(_vm.increasePercentage) + "%"),
                  ]),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "icon",
          fn: function () {
            return [
              _c("ActivityIcon", {
                staticClass: "h2 text-muted mb-0",
                attrs: { size: "20" },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }