<template>
  <div>
    <TaskOffState v-if="showNewUserScreen" />
    <div v-else>
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <form class="row align-items-center">
                <div class="col-auto pe-0">
                  <div v-if="!loadingTasks" style="width: 16px">
                    <SearchIcon size="16" class="text-muted" />
                  </div>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm text-muted"
                    role="status"
                    style="position: relative; top: -1px"
                  ></div>
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control form-control-flush search"
                    placeholder="Search by user identifier or taskId"
                    :value="query"
                    @input="updateQuery"
                  />
                </div>
                <div class="col-auto">
                  <TaskFilterProduct />
                </div>
                <div class="col-auto">
                  <TaskFilterStatus />
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    @click="refresh"
                    class="btn btn-primary btn-sm"
                    :disabled="loadingTasks"
                  >
                    Refresh
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showTaskSearchResults" class="row">
        <div
          v-for="(value, propertyName, index) in taskGroups"
          :key="index"
          :class="{
            'card card-inactive p-3 card-group col-12': value.length > 1,
            'col-lg-6': value.length === 1,
          }"
          class="col-12 d-flex align-items-stretch"
        >
          <TaskCard
            :task="task"
            v-for="task in value"
            :key="task._id"
            :class="{
              'col-12 col-sm-6': value.length > 1,
            }"
          />
          <div class="clear" />
        </div>
      </div>
      <TaskNoTasksFound v-if="showNoTasksFound" />
      <div class="row align-items-center text-center mt-4 pb-4">
        <div class="col">
          <div
            v-if="loadingTasks"
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          />
          <button
            v-if="showLoadMoreButton"
            class="btn btn-sm btn-outline-secondary"
            @click="loadMore"
          >
            Load more
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskBase from '@/components/Modules/Task/TaskBase'
import TaskCard from '@/components/Modules/Task/TaskCard'
import TaskFilterProduct from '@/components/Modules/Task/TaskFilter/TaskFilterProduct'
import TaskFilterStatus from '@/components/Modules/Task/TaskFilter/TaskFilterStatus'
import TaskNoTasksFound from '@/components/Modules/Task/TaskNoTasksFound'
import TaskOffState from '@/components/Modules/Task/TaskOffState'
import { debounce } from 'lodash-es'
import { mapState } from 'vuex'
import { SearchIcon } from 'vue-feather-icons'

export default {
  name: 'Tasks',
  components: {
    SearchIcon,
    TaskCard,
    TaskFilterProduct,
    TaskFilterStatus,
    TaskNoTasksFound,
    TaskOffState,
  },
  extends: TaskBase,
  watch: {
    activeEnvironment() {
      this.getTaskList()
    },
  },
  data: () => ({
    query: new URLSearchParams(window.location.search).get('query'),
  }),
  computed: {
    taskGroups: function () {
      let taskGroups = {}
      this.tasks.forEach(function (task) {
        if (task.taskWorkflow) {
          if (taskGroups[task.taskWorkflow]) {
            taskGroups[task.taskWorkflow].push(task)
          } else {
            taskGroups[task.taskWorkflow] = [task]
          }
        } else {
          taskGroups[task._id] = [task]
        }
      })
      return taskGroups
    },
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('task', ['filters']),
    hasTasks() {
      return this.tasks.length > 0
    },
    showLoadMoreButton() {
      return this.hasTasks && !this.loadingTasks
    },
    showNewUserScreen() {
      return !this.hasTasks && !this.loadingTasks && !this.filters.query
    },
    showNoTasksFound() {
      return !this.hasTasks && !this.loadingTasks && this.filters.query
    },
    showTaskSearchResults() {
      return this.hasTasks
    },
  },
  methods: {
    async loadMore() {
      this.$analytics.track({
        event: 'Load More Tasks',
        payload: {
          skip: this.tasks.length,
        },
      })

      await this.loadMoreTasks()
    },
    updateQuery(e) {
      this.query = e.target.value

      this.identifierSearch()
    },
    identifierSearch: debounce(function () {
      const query = this.query

      this.$analytics.track({
        event: 'Search Tasks',
        payload: {
          query,
        },
      })

      this.updateQueryFilter(query)
    }, 500),
    async refresh() {
      this.$analytics.track({
        event: 'Refresh Tasks',
      })

      await this.getTaskList()
    },
  },
  async mounted() {
    await this.updateQueryFilter(this.query)
  },
}
</script>

<style scoped lang="scss">
.card-group {
  .card {
    border-radius: 0px;
    margin: 0px;
    border: 1px solid #edf2f9;
  }
}
</style>
