<template>
  <div>
    <div class="card" v-for="{ totalWages, year, form } in form1099s" :key="year+totalWages">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <div class="d-flex flex-row mt-n2">
              <div class="me-4">
                <h6 class="text-muted text-uppercase m-0">type</h6>
                <div>
                  1099
                </div>
              </div>
              <div class="me-4">
                <h6 class="text-muted text-uppercase m-0">year</h6>
                <div>
                  {{ year | moment('YYYY') }}
                </div>
              </div>

              <div>
                <h6 class="text-muted text-uppercase m-0">total wages</h6>
                <div>
                  {{ totalWages | currency }}
                </div>
              </div>
            </div>
          </div>
          <div class="col text-right align-self-center">
            <ViewTaskOutputFileButton :taskId="taskId" :fileId="form"
              >View Form</ViewTaskOutputFileButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewTaskOutputFileButton from '@/components/MainContent/ViewTaskOutputFileButton'

export default {
  name: 'Task1099s',
  components: { ViewTaskOutputFileButton },
  props: ['taskId', 'form1099s'],
}
</script>
