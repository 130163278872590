var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.title,
          expression: "title",
        },
      ],
      staticClass: "badge bg-secondary-soft help",
    },
    [_vm._v("Derived")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }