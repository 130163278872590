import Mixpanel from '@/plugins/mixpanel'

import store from '../store'

let analyticsInstance

export default {
  install: (Vue) => {
    Mixpanel.install()

    analyticsInstance = {
      track: ({ event, payload }) => {
        const mixpanelInstance = Mixpanel.get()

        mixpanelInstance.track(event, {
          ...payload,
          customer: store.state.customer.activeCustomer?.name,
          customerId: store.state.customer.activeCustomer?._id,
          parentCustomerId: store.state.customer.activeCustomer?.parentCustomer,
          customerHasWebhookEndpoints: store.state.customer.activeCustomer
            ? store.getters['customer/hasWebhooks']
            : undefined,
          environment: store.state.environment.activeEnvironmentInitialized
            ? store.state.environment.activeEnvironment
            : undefined,
          windowWidth: document.documentElement.clientWidth,
          windowHeight: document.documentElement.clientHeight,
        })
      },
    }

    Vue.prototype.$analytics = analyticsInstance
  },
  get() {
    return analyticsInstance
  },
}
