var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown d-inline" }, [
    _c(
      "a",
      {
        staticClass: "small text-muted",
        attrs: {
          href: "#",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _vm._v("Status "),
        _c("ChevronDownIcon", { staticClass: "mb-1", attrs: { size: "14" } }),
      ],
      1
    ),
    _c("div", { staticClass: "dropdown-menu" }, [
      _c(
        "form",
        _vm._l(_vm.statuses, function (value, status) {
          return _c(
            "a",
            {
              key: status,
              staticClass: "dropdown-item",
              attrs: { href: "#!" },
            },
            [
              _vm.filters.externalStatus.includes(status)
                ? _c("CheckIcon", {
                    staticClass: "float-end text-secondary mt-1",
                    attrs: { size: "14" },
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "badge text-capitalize",
                  class: "bg-" + value.class + "-soft",
                  on: {
                    click: function ($event) {
                      return _vm.toggleStatusFilter(status)
                    },
                  },
                },
                [_vm._v(_vm._s(status))]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }