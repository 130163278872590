<template>
  <div class="avatar" :class="logoClasses">
    <SecureImage
      v-if="branding.logo"
      :source="branding.logo.url"
      class="avatar-img"
      :style="`background-color: ${logoBackgroundColor}; padding: ${imagePadding};`"
    />
    <span v-if="!branding.logo && name" class="avatar-title rounded-circle">{{
      name.charAt(0)
    }}</span>
  </div>
</template>

<script>
import SecureImage from '@/components/MainContent/SecureImage'
export default {
  name: 'Logo',
  props: {
    branding: { type: Object, default: () => ({}) },
    name: String,
    size: String,
  },
  components: { SecureImage },
  computed: {
    imagePadding() {
      return (
        {
          xs: '3px',
          lg: '10px',
        }[this.size] ?? ''
      )
    },
    logoBackgroundColor() {
      return this.branding.logo?.backgroundColor || this.branding.color || '#FAFAFA'
    },
    showBorder() {
      const whites = ['white', '#fff', '#FFF', '#ffffff', '#FFFFFF']

      return whites.includes(this.logoBackgroundColor)
    },
    sizeClass() {
      return this.size ? `avatar-${this.size}` : ''
    },
    logoClasses() {
      return `${this.sizeClass} ${this.showBorder ? 'border' : ''}`
    },
  },
}
</script>
<style lang="scss" scoped>
.border {
  border: 1px solid #e5e5e5;
}

.avatar {
  border-radius: 20px;
  overflow: hidden;
}

.logo-outer {
  border-radius: 100px;
  overflow: hidden;
  img {
    padding: 5px;
  }
}

.upper-right {
  position: absolute;
  right: -8px;
  top: -8px;
}

.avatar-img {
  object-fit: scale-down;
}
</style>
