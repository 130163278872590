<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Top Failure Reasons</h4>
    </div>
    <div class="card-body" :class="{ 'fade-in-out': !isDataReady }">
      <div class="chart chart-appended">
        <ChartWrapper
          :chartType="CHART_TYPES.DOUGHNUT"
          :chartData="chartData"
          :useDummyData="isLoading || sampleDataMode"
        />
      </div>
      <div id="trafficChartLegend" class="chart-legend">
        <div v-if="isDataReady && !sampleDataMode && chartData.labels.length">
          <span v-for="(item, index) in chartData.labels" :key="index" class="chart-legend-item">
            <span
              class="chart-legend-indicator"
              :style="`background-color: ${chartColors[index]}`"
            ></span>
            {{ item }}
          </span>
        </div>
        <div v-else>
          <span v-for="(label, index) in dummyLabels" :key="label" class="chart-legend-item">
            <span
              class="chart-legend-indicator"
              :style="`background-color: ${chartColors[index]}`"
            ></span>
            {{ label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ChartWrapper from '@/components/Charts/ChartWrapper'
import { CHART_TYPES } from '@/utils/constants'
import { failReasons } from '@/lib/task-fail-reasons'

export default {
  name: 'FailureReasons',
  components: { ChartWrapper },
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('activity', ['failureReasons', 'activeDateRangeOption', 'sampleDataMode']),
    topFailureReasons() {
      let failureReasonsCopy = this.failureReasons
      return failureReasonsCopy.splice(0, 4)
    },
    isDataReady() {
      return !!(this.sampleDataMode || !this.isLoading)
    },
    chartData() {
      if (this.isLoading || this.sampleDataMode) return {}
      return {
        labels: this.topFailureReasons.map((item) => {
          return this.failReasonsDictionary[item.failreason]?.miniTitle
        }),
        datasets: [
          {
            data: this.topFailureReasons.map((item) => {
              return item.count
            }),
            backgroundColor: this.chartColors,
          },
        ],
      }
    },
  },
  data() {
    return {
      isLoading: true,
      failReasonsDictionary: failReasons,
      CHART_TYPES,
      chartColors: ['#2C7BE5', '#A6C5F7', '#D2DDEC', '#E3ECF8'],
      dummyLabels: ['Bad Creds', 'Unsupported', 'Unexpected'],
    }
  },
  methods: {
    ...mapActions('activity', ['getFailureReasons']),
    async getData() {
      this.isLoading = true
      await this.getFailureReasons()
      this.isLoading = false
    },
  },
  async mounted() {
    this.getData()
  },
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.getData()
    },
  },
}
</script>

<style lang="scss">
.chart-legend {
  height: 20px;
  overflow-y: hidden;
}
</style>
