import store from '@/store'

export function featureUniqueForAtomic() {
  const activeCustomer = store.state?.customer?.activeCustomer
  const molecularBankId = '5e978dcf3abbf90008a00b7a'

  return (
    activeCustomer._id === molecularBankId ||
    activeCustomer.name === 'Molecular Bank' ||
    activeCustomer.parentCustomer === molecularBankId
  )
}
