var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.bannoStatus === "active"
      ? _c(
          "div",
          [
            _c("Form", {
              attrs: {
                onSubmit: _vm.saveSettings,
                submitButtonText: "Save",
                title: "External Application Configuration",
              },
              on: { success: _vm.onSuccess, error: _vm.onError },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var disabled = ref.disabled
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "alert alert-light mb-4",
                            attrs: { role: "alert" },
                          },
                          [
                            _vm._v(
                              " Enter your external application configuration from Banno People here. "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("validation-provider", {
                              staticClass: "col-12 form-group",
                              attrs: { rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var classes = ref.classes
                                      var errors = ref.errors
                                      var touched = ref.touched
                                      return [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "client-id" },
                                          },
                                          [_vm._v("Client ID")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.fields.clientId,
                                              expression: "fields.clientId",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: classes,
                                          attrs: {
                                            id: "client-id",
                                            placeholder: "Client ID",
                                            type: "text",
                                            disabled: disabled,
                                          },
                                          domProps: {
                                            value: _vm.fields.clientId,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.fields,
                                                "clientId",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        touched
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "invalid-feedback",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("validation-provider", {
                              staticClass: "col-12 form-group",
                              attrs: { rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var classes = ref.classes
                                      var errors = ref.errors
                                      var touched = ref.touched
                                      return [
                                        _c("HideableInput", {
                                          attrs: {
                                            label: "Client Secret",
                                            "input-props": {
                                              class: classes,
                                              disabled: disabled,
                                              placeholder: "Client Secret",
                                            },
                                            touched: touched,
                                            errors: errors,
                                          },
                                          on: {
                                            toggleShowValue:
                                              _vm.onClientSecretToggleShowValue,
                                          },
                                          model: {
                                            value: _vm.fields.clientSecret,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fields,
                                                "clientSecret",
                                                $$v
                                              )
                                            },
                                            expression: "fields.clientSecret",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("validation-provider", {
                              staticClass: "col-12 form-group",
                              attrs: { rules: "required|url" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var classes = ref.classes
                                      var errors = ref.errors
                                      var touched = ref.touched
                                      return [
                                        _c(
                                          "label",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value:
                                                  "The URL for your Banno application",
                                                expression:
                                                  "'The URL for your Banno application'",
                                              },
                                            ],
                                            staticClass: "form-label",
                                            attrs: { for: "environment" },
                                          },
                                          [_vm._v(" Environment ")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.fields.environment,
                                              expression: "fields.environment",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: classes,
                                          attrs: {
                                            id: "environment",
                                            placeholder:
                                              "https://MY.BANNO-URL.COM",
                                            type: "url",
                                            disabled: disabled,
                                          },
                                          domProps: {
                                            value: _vm.fields.environment,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.fields,
                                                "environment",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        touched
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "invalid-feedback",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1127822885
              ),
            }),
            _c(
              "Card",
              {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("h4", { staticClass: "card-header-title" }, [
                            _vm._v("Redirect URIs"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2029200451
                ),
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-light mb-4",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      " Enter these redirect URIs into your external application configuration in Banno People in this order. "
                    ),
                  ]
                ),
                _c("CopyableInput", {
                  staticClass: "mb-4",
                  attrs: { title: "URI 1", value: _vm.uri1 },
                  on: {
                    copy: function () {
                      return _vm.onCopyRedirectUri(1)
                    },
                  },
                }),
                _c("CopyableInput", {
                  attrs: { title: "URI 2", value: _vm.uri2 },
                  on: {
                    copy: function () {
                      return _vm.onCopyRedirectUri(2)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm.bannoStatus === "inactive"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "alert alert-light mb-4", attrs: { role: "alert" } },
            [
              _vm._v(
                " Your Banno integration is inactive. Please contact your Customer Success Manager to reactivate it. "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }