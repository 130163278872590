<template>
  <div class="mt-10">
    <Card class="border card-inactive">
      <div>
        <h2 class="scenario-sim-header">Current Accounts</h2>
        <span class="text-secondary">{{ node.prompt }}</span>
      </div>

      <div v-if="hasOptions" class="btn-group-toggle row justify-between mt-5">
        <div v-for="(option, index) in node.options" :key="index" class="col">
          <button
            class="btn my-1 w-100"
            :value="option.value"
            :class="{
              'btn-secondary': selectedOption === option.value,
              'btn-white': selectedOption !== option.value,
            }"
            @click="() => toggleShowChildren(option.value)"
          >
            {{ option.label }}
          </button>
        </div>
      </div>

      <template v-if="node.addendum" #footer>
        <span>{{ node.addendum }}</span>
      </template>
    </Card>

    <Card v-if="results" class="border">
      <div>
        <h2 class="scenario-sim-header">Outcome</h2>
        <p class="text-secondary">
          Given the options you selected, these are the changes you could expect to see:
        </p>
      </div>
      <Card class="card-inactive">
        <div>
          <span>New Account: {{ newAccountInfo }}</span>
        </div>
        <div v-if="results.oldAccounts">
          <div v-for="(account, index) in results.oldAccounts" :key="index">
            <span>Old Account {{ index }}: {{ account }}</span>
          </div>
        </div>
        <div v-if="results.oldAccount">
          <span>Old Account: {{ results.oldAccount }}</span>
        </div>
      </Card>
      <div class="text-left col">
        <p class="text-secondary">
          {{ results.explainer }} This user can expect to see the change reflected in their payroll
          within 1-2 pay cycles.
        </p>
        <Alert v-if="results.addendum" type="secondary" class="mt-2 mb-0">{{
          results.addendum
        }}</Alert>
      </div>
      <template v-if="results.exceptions.length > 0" #footer>
        <h3 class="text-center scenario-sim-header">Exceptions</h3>
        <div v-for="(exception, index) in results.exceptions" :key="index">
          <Alert type="warning" class="mb-1">{{ exception.explanation }}</Alert>
        </div>
      </template>
    </Card>

    <RecursiveScenarios
      v-else
      v-for="option in filteredOptions"
      :key="option.value"
      :node="option"
    />
  </div>
</template>

<script>
import Card from '@/components/MainContent/Card'
import Alert from '@/components/MainContent/Alert'
import { mapState } from 'vuex'
import { DISTRIBUTION_TYPES } from '@/utils/constants'

export default {
  name: 'RecursiveScenarios',
  data() {
    return {
      showChildren: false,
      selectedOption: undefined,
      results: undefined,
      distributionTypes: DISTRIBUTION_TYPES,
    }
  },
  components: { Alert, Card },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('scenarioSimulator', ['distributionAmount', 'distributionType']),
    hasOptions() {
      const { options } = this.node
      return options && options.length > 0
    },
    filteredOptions() {
      return this.node.options.filter((option) => option.value === this.selectedOption)
    },
    newAccountInfo() {
      const newAccount = this.distributionAmount || this.results.newAccount

      if (this.distributionType === this.distributionTypes.FIXED && this.distributionAmount) {
        return `$${newAccount}`
      } else if (
        this.distributionType === this.distributionTypes.PERCENT &&
        this.distributionAmount
      ) {
        return `${newAccount}%`
      } else {
        return newAccount
      }
    },
  },
  methods: {
    toggleShowChildren(selectedOption) {
      // clear results from state to ensure we only display one result at a time
      this.results = undefined

      const option = this.node.options.find((option) => option.value === selectedOption)

      if (option.results) {
        this.results = option.results

        if (this.distributionAmount) {
          this.results.newAccount = this.distributionAmount
        }
      }

      this.$analytics.track({
        event: this.node.analyticsEventName,
        payload: {
          selectedOptionValue: option.value,
          selectedOptionLabel: option.label,
          distributionAmount: this.distributionAmount ?? '',
          distributionType: this.distributionType,
        },
      })

      this.selectedOption = selectedOption
      this.showChildren = true
    },
  },
}
</script>

<style scoped lang="scss">
.border {
  &:before {
    content: '';
    border-left: 2px solid var(--bs-gray-300);
    height: 26px;
    position: absolute;
    align-self: center;
    top: -26px;
  }
}
</style>
