<template>
  <div class="mb-4 d-flex justify-content-between align-items-center">
    <h3 class="m-0 pe-2">{{ title }}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmulatorSectionHeader',
  props: ['title'],
}
</script>
