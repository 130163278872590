var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h6", { staticClass: "header-pretitle mb-3" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }