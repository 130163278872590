<template>
  <div>
    <label :for="id" class="form-label">{{ label }}</label>
    <div class="input-group">
      <input
        :id="id"
        :type="shouldShowValue ? 'text' : 'password'"
        class="form-control"
        @input="onInput"
        @blur="$emit('blur')"
        :value="value"
        v-bind="inputProps"
      />
      <button class="btn btn-outline-secondary" type="button" @click="toggleShowValue">
        <EyeOffIcon v-if="shouldShowValue" size="14" class="me-1 p-0 m-0" />
        <EyeIcon v-else size="14" class="me-1 p-0 m-0" />
        {{ shouldShowValue ? 'Hide' : 'Show' }}
      </button>
      <div v-if="touched" class="invalid-feedback">{{ errors[0] }}</div>
    </div>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons'
import { kebabCase, uniqueId } from 'lodash-es'

export default {
  name: 'HideableInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: String,
    inputProps: {
      type: Object,
      default: () => ({}),
    },
    touched: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input', 'toggleShowValue'],
  components: {
    EyeIcon,
    EyeOffIcon,
  },
  computed: {
    id() {
      return uniqueId(kebabCase(this.label) + '-')
    },
  },
  data() {
    return {
      shouldShowValue: false,
    }
  },
  methods: {
    toggleShowValue() {
      this.shouldShowValue = !this.shouldShowValue
      this.$emit('toggleShowValue', { shouldShowValue: this.shouldShowValue })
    },
    onInput(e) {
      this.$emit('input', e.target.value)
    },
  },
}
</script>

<style scoped>
/* Forces the error state border on the input to appear on top of the button */
.input-group > input.is-invalid + button {
  z-index: 1;
}
</style>
