<template>
  <div class="card-footer d-flex justify-content-between align-items-center flex-wrap">
    <div class="tasks-count mb-2 mb-md-0">
      <template v-if="totalResults === 0"> 0 tasks </template>
      <template v-else>
        {{ startTaskIndex }}-{{ endTaskIndex }} of {{ totalResults }} tasks
      </template>
    </div>

    <div class="d-flex align-items-center">
      <ul
        v-if="pages.length > 0"
        class="list-pagination-prev pagination pagination-tabs card-pagination mb-0"
      >
        <li class="page-item">
          <a
            data-test-id="data-pager-prev"
            class="page-link ps-2 pe-2"
            :class="{ disabled: isFirstPage }"
            @click.prevent="previousPage"
            href="#"
          >
            <ArrowLeftIcon size="16" class="me-1" /> Prev
          </a>
        </li>
      </ul>

      <ul v-if="showPages" class="list-pagination pagination pagination-tabs card-pagination mb-0">
        <li
          v-for="page in pages"
          :key="page"
          :data-test-id="'data-pager-page-' + page"
          :class="{ active: page === currentPage }"
          class="page-item"
        >
          <a class="page-link" href="#" @click.prevent="gotoPage(page)">{{ page }}</a>
        </li>
      </ul>

      <ul
        v-if="pages.length > 0"
        class="list-pagination-next pagination pagination-tabs card-pagination mb-0"
      >
        <li class="page-item">
          <a
            data-test-id="data-pager-next"
            class="page-link ps-2 pe-0"
            @click.prevent="nextPage"
            href="#"
            :class="{ disabled: isLastPage }"
          >
            Next
            <ArrowRightIcon size="16" class="ms-1" />
          </a>
        </li>
      </ul>

      <div class="tasks-per-page ms-4">
        <label for="tasks-per-page" class="visually-hidden">Tasks per page</label>
        <select
          id="tasks-per-page"
          class="form-select form-select-sm"
          v-model.number="localItemsPerPage"
          @change="onItemsPerPageChange"
          :disabled="loading"
        >
          <option value="25">25 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon, ArrowRightIcon } from 'vue-feather-icons'

export default {
  name: 'TaskPager',
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 25,
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    maxPageButtons: {
      type: Number,
      default: 5,
    },
    showPages: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localItemsPerPage: this.itemsPerPage,
    }
  },
  watch: {
    itemsPerPage(newValue) {
      this.localItemsPerPage = newValue
    },
  },
  computed: {
    totalPages() {
      return Math.max(1, Math.ceil(this.totalResults / this.localItemsPerPage))
    },
    isFirstPage() {
      return this.currentPage === 1
    },
    isLastPage() {
      return this.currentPage === this.totalPages
    },
    pages() {
      if (this.totalPages <= 1) {
        return []
      }

      if (this.totalPages <= this.maxPageButtons) {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1)
      } else {
        let startPage = Math.max(this.currentPage - Math.floor(this.maxPageButtons / 2), 1)
        let endPage = startPage + this.maxPageButtons - 1

        if (endPage > this.totalPages) {
          endPage = this.totalPages
          startPage = Math.max(1, endPage - this.maxPageButtons + 1)
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
      }
    },
    startTaskIndex() {
      return (this.currentPage - 1) * this.localItemsPerPage + 1
    },
    endTaskIndex() {
      return Math.min(this.currentPage * this.localItemsPerPage, this.totalResults)
    },
  },
  methods: {
    gotoPage(page) {
      if (page !== this.currentPage) {
        this.$emit('changePage', page)
      }
    },
    nextPage() {
      if (!this.isLastPage) {
        this.$emit('changePage', this.currentPage + 1)
      }
    },
    previousPage() {
      if (!this.isFirstPage) {
        this.$emit('changePage', this.currentPage - 1)
      }
    },
    onItemsPerPageChange() {
      this.$emit('changeItemsPerPage', this.localItemsPerPage)
    },
  },
}
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.page-link {
  padding: 0.5rem 1rem;
  color: var(--bs-primary);
  background-color: transparent;
  border: none;

  &:hover {
    color: var(--bs-primary);
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.page-item {
  &.active .page-link {
    color: var(--bs-primary);
    font-weight: bold;
    background-color: transparent;
  }
}

.list-pagination {
  margin-bottom: 0;
}

.card-pagination.pagination-tabs {
  margin: 0;
  border: none;
}

.card-footer {
  flex-wrap: wrap;
  position: sticky;
  bottom: 0;
  background: white;
  width: 100%;
  border-top: 1px solid #e2ebf6;
}

.tasks-count {
  font-size: 0.875rem;
}

.tasks-per-page {
  min-width: 150px;
}
</style>
