var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("HasPermissionTable", {
    attrs: {
      label: _vm.permission,
      rowData: _vm.roleValues,
      hasPermissionFunction: _vm.permissionBelongsToUserRole,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }