var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { id: "role-permissions-modal", title: "Role Permissions" },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-light",
                  attrs: { type: "button" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("Close")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [_c("RolePermissionsTable", { attrs: { userRole: _vm.userRole } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }