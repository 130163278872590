var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown d-inline" }, [
    _c(
      "a",
      {
        staticClass: "small",
        attrs: {
          href: "#",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _vm._v(" Date Range "),
        _c("ChevronDownIcon", {
          staticClass: "mb-1",
          attrs: { size: "14", disabled: _vm.isDisabled },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dropdown-menu p-3",
        staticStyle: { "min-width": "300px" },
      },
      [
        _c("div", { staticClass: "quick-filters mb-3" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-wrap gap-2" },
            _vm._l(_vm.presets, function (preset) {
              return _c(
                "button",
                {
                  key: preset.label,
                  staticClass: "btn btn-sm",
                  class: _vm.isActivePreset(preset)
                    ? "btn-primary"
                    : "btn-light",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.applyPreset(preset)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(preset.label) + " ")]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "dropdown-divider" }),
        _c("v-date-picker", {
          attrs: {
            "max-date": new Date(),
            attributes: _vm.pickerAttributes,
            "is-range": "",
          },
          on: { input: _vm.onDateRangeChange },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var inputEvents = ref.inputEvents
                var inputValue = ref.inputValue
                return [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "input",
                      _vm._g(
                        {
                          staticClass: "form-control form-control-sm",
                          attrs: { placeholder: "Start date" },
                          domProps: { value: inputValue.start },
                        },
                        inputEvents.start
                      )
                    ),
                    _c("span", { staticClass: "mx-2" }, [_vm._v("to")]),
                    _c(
                      "input",
                      _vm._g(
                        {
                          staticClass: "form-control form-control-sm",
                          attrs: { placeholder: "End date" },
                          domProps: { value: inputValue.end },
                        },
                        inputEvents.end
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dateRange,
            callback: function ($$v) {
              _vm.dateRange = $$v
            },
            expression: "dateRange",
          },
        }),
        _c("div", { staticClass: "mt-2 d-flex justify-content-between" }, [
          _vm.hasDateRange
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-outline-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.resetDateRange.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" Reset ")]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary ms-auto",
              attrs: { type: "button", disabled: !_vm.isValidRange },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.applyDateRange.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Apply ")]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }