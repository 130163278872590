<template>
  <Form
    :onSubmit="addUser"
    @success="onSuccess"
    @error="onError"
    submitButtonText="Save"
    v-slot="{ disabled }"
  >
    <div class="form-group">
      <label for="user-first-name">First Name</label>
      <input
        id="user-first-name"
        type="text"
        class="form-control"
        v-model="user.givenName"
        placeholder="Johnny"
        required
        :disabled="disabled"
      />
    </div>
    <div class="form-group">
      <label for="user-last-name">Last Name</label>
      <input
        id="user-last-name"
        type="text"
        class="form-control"
        v-model="user.familyName"
        placeholder="Appleseed"
        required
        :disabled="disabled"
      />
    </div>
    <div class="form-group">
      <label for="user-email">Email</label>
      <input
        id="user-email"
        type="email"
        class="form-control"
        v-model="user.email"
        placeholder="jappleseed@example.org"
        required
        :disabled="disabled"
      />
    </div>
    <div class="form-group">
      <label for="user-phone-number">Phone Number (optional)</label>
      <input
        id="user-phone-number"
        type="phone"
        class="form-control"
        v-model="user.phoneNumber"
        placeholder="1234567890"
        :disabled="disabled"
      />
    </div>
    <UserRoleSelector @selectionChanged="updateUserRole" :options="availableRoles" />
  </Form>
</template>

<script>
const phone = require('phone')
import UserService from '@/services/UserService'
import { getAvailableUserRoles } from '@/lib/authorization'
import { startCase } from 'lodash-es'
import UserRoleSelector from '@/components/Shared/UserRoleSelector'
import Form from '@/components/MainContent/Form'

export default {
  name: 'UserAddForm',
  props: ['customerId'],
  components: {
    Form,
    UserRoleSelector,
  },
  data() {
    return {
      availableRoles: getAvailableUserRoles(),
      newUserRole: undefined,
      startCase,
      user: {
        familyName: undefined,
        givenName: undefined,
        email: undefined,
        phoneNumber: undefined,
      },
    }
  },
  methods: {
    updateUserRole(role) {
      this.newUserRole = role
    },
    async addUser() {
      // API only accepts phone numbers in E.164 format
      const e164Format = phone(this.user.phoneNumber)
      this.user.phoneNumber = e164Format[0]

      await UserService.create({ roles: [this.newUserRole], ...this.user })
    },
    onSuccess() {
      this.$analytics.track({
        event: 'Add User',
        payload: {
          role: this.newUserRole,
        },
      })

      this.$toasted.success('User succesfully added')
      this.$router.push({ name: 'settings-team' })
    },
    onError() {
      this.$toasted.show('Error adding user')
    },
  },
}
</script>
