<template>
  <div>
    <div>
      <input
        type="checkbox"
        id="toggle"
        :class="{ dark: settings.theme.dark }"
        @input="toggleColorMode"
      />
      <label for="toggle" :class="{ dark: settings.theme.dark }"></label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EmulatorColorModeSelector',
  components: {},
  computed: {
    ...mapState('emulator', ['colorModes', 'settings']),
    selectedColorMode() {
      return this.colorModes.find((colorMode) => colorMode.isDark === this.settings.theme.dark)
    },
  },
  methods: {
    ...mapActions('emulator', ['updateDarkMode']),
    toggleColorMode() {
      this.updateDarkMode({ analytics: this.$analytics, darkMode: !this.settings.theme.dark })
    },
  },
}
</script>

<style scoped lang="scss">
div {
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  position: relative;
  display: block;
  width: 55px;
  height: 30px;
  border-radius: 100px;
  background-color: #fff;
  &.dark {
    background-color: #000839;
  }
  overflow: hidden;
  cursor: pointer;
  &:before,
  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 3px;
    left: 3px;
    transition: 0.5s ease;
  }
  &:before {
    background-color: #ffb020;
  }

  &:after {
    // Moon circle before scale
    background-color: #ffb020;
    left: -50px;
    opacity: 0;
    transform: scale(0.00001);
    transition-property: scale, transform;
  }
}

input[type='checkbox'] {
  display: none;
  &:checked + label {
    &:before {
      //  Dark Circle
      opacity: 1;
      background-color: #fff;
      transform: translateX(24px);
    }

    &:after {
      // Moon circle after scale
      opacity: 1;
      background-color: #000839;

      transform: translateX(67px) scale(1);
    }
  }
}
</style>
