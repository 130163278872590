var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EmulatorMutationPreview", {
    attrs: { mutation: _vm.mutation, arrowClass: "mb-3" },
    scopedSlots: _vm._u([
      {
        key: "before",
        fn: function () {
          return [
            _c("ColorSwatch", {
              attrs: {
                color: _vm.mutation.valueBeforeChanges,
                showLabel: true,
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "after",
        fn: function () {
          return [
            _c("ColorSwatch", {
              attrs: { color: _vm.mutation.value, showLabel: true },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }