<template>
  <Card>
    <div class="row align-items-center">
      <div class="col">
        <div v-if="displayEventType">
          <span class="badge bg-light mb-2" style="font-size: 14px">
            <span class="fw-bold">{{ event.payload.eventType }}</span>
          </span>
        </div>
        <div>
          <span v-if="!displayEventType" class="badge bg-light me-3" style="font-size: 12px">
            <span class="fw-bold">POST</span>
          </span>
          <small class="text-muted">{{ event.createdAt }}</small>
        </div>
        <div class="mt-1">
          <code class="text-secondary">{{
            event.endpointUrl || 'https://your-domain.com/api/webhooks/atomic'
          }}</code>
        </div>
      </div>
      <div v-if="hasResponse" class="col-auto capitalize">
        <span class="badge bg-success-soft" :class="eventStatus.class">
          <span class="event-status">
            {{ event.status }}
          </span>
          <component :is="eventStatus.component" size="1x" />
        </span>
      </div>
      <div class="col-auto">
        <div v-b-toggle="event._id">
          <ChevronDownIcon class="toggle-icon" />
        </div>
      </div>
    </div>
    <b-collapse :id="event._id">
      <div class="mt-4">
        <h5>Request Headers</h5>
        <div class="card mb-3">
          <div class="card-body">
            <pre class="mb-0">{{ event.headers }}</pre>
          </div>
        </div>
        <h5 class="mt-4">Request Body</h5>
        <div class="card mb-3">
          <div class="card-body">
            <pre class="mb-0">{{ event.payload }}</pre>
          </div>
        </div>
        <div v-if="hasResponse">
          <hr class="my-4" />
          <h5>Response Headers</h5>
          <div class="card mb-3">
            <div class="card-body">
              <pre class="mb-0">{{ event.responses[0].headers }}</pre>
            </div>
          </div>
          <div class="row align-items-center mt-4 mb-1">
            <div class="col"><h5 class="m-0">Response Body</h5></div>
            <div class="col text-end">
              <span class="badge" :class="eventResponseStatus.class">
                Status: {{ event.responses[0].status }}
              </span>
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-body">
              <pre class="mb-0">{{ event.responses[0].data }}</pre>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </Card>
</template>
<script>
import { ChevronDownIcon, CheckIcon, XIcon } from 'vue-feather-icons'
import { isSuccessfulStatus } from '@/utils/event'
import Card from '@/components/MainContent/Card'

export default {
  name: 'EventRequest',
  components: {
    Card,
    ChevronDownIcon,
    CheckIcon,
    XIcon,
  },
  props: {
    event: { type: Object },
    displayEventType: { type: Boolean, default: false },
  },
  computed: {
    hasResponse() {
      return this.event?.responses?.length
    },
    eventStatus() {
      return {
        class: this.event.status === 'sent' ? 'bg-success-soft' : 'bg-danger-soft',
        component: this.event.status === 'sent' ? CheckIcon : XIcon,
      }
    },
    eventResponseStatus() {
      return {
        class: isSuccessfulStatus({ status: this.event.responses[0].status })
          ? 'bg-success-soft'
          : 'bg-danger-soft',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-icon {
  transition: 300ms transform ease-in-out;
}
.not-collapsed .toggle-icon {
  transform: rotate(180deg);
}
.event-status {
  vertical-align: middle;
}
</style>
