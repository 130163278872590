<template>
  <EmulatorCustomizationInputWrapper :label="label">
    <div class="form-group">
      <vSelect
        class="select-list"
        :placeholder="defaultValue"
        :options="options"
        v-model="mutatedValue"
        @option:selected="selectionHandler"
        :clearable="false"
        :aria-label="'Select ' + label + ' font'"
      />
      <EmulatorCustomizationResetToDefault
        :disabled="!mutatedValue"
        :onClickHandler="resetToDefaultValue"
        toolTip="Reset to Default"
      />
    </div>
  </EmulatorCustomizationInputWrapper>
</template>

<script>
import vSelect from 'vue-select'
import customThemeActions from '@/mixins/custom-theme-actions'
import EmulatorCustomizationInputWrapper from '@/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationInputWrapper'
import EmulatorCustomizationResetToDefault from '@/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationResetToDefault'

export default {
  name: 'EmulatorCustomizationDropdown',
  components: {
    vSelect,
    EmulatorCustomizationInputWrapper,
    EmulatorCustomizationResetToDefault,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectionChangedHandler: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [customThemeActions],
  methods: {
    selectionHandler(selectedItem) {
      this.emitMutationEvents({ value: selectedItem, action: 'update' })
      this.selectionChangedHandler({ label: this.label, value: this.mutatedValue })
    },
  },
}
</script>

<style lang="scss" scoped>
.select-list {
  background-color: white;
  flex: 1;
  margin-right: 5px;
}

.form-group {
  display: flex;
}

.selected-font {
  font-size: 12px;
  color: var(--bs-gray-500);
}
</style>
