var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.displaySideNavForProducts.includes(_vm.task.product)
        ? _c("TaskNav", {
            staticClass: "d-none d-md-inline",
            attrs: {
              product: _vm.task.product,
              hasDistributionData: _vm.hasDistributionData,
              hasEmploymentData: _vm.hasEmploymentData,
              hasIncomeData: _vm.hasIncomeData,
              hasPaystubData: _vm.hasPaystubData,
              hasTimesheetData: _vm.hasTimesheetData,
              hasIdentityData: _vm.hasIdentityData,
              hasTaxesData: _vm.hasTaxesData,
              hasEventsData: _vm.hasEventsData,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          class: _vm.displaySideNavForProducts.includes(_vm.task.product)
            ? "col-md-9 col-12"
            : "col-12",
          attrs: { id: "task-content" },
        },
        [
          _vm.hasEmploymentData
            ? _c(
                "div",
                { staticClass: "mt-5", attrs: { id: "employment" } },
                [
                  _c("TaskEmployment", {
                    attrs: {
                      user: _vm.task.user,
                      taskWorkflow: _vm.task.taskWorkflow,
                      product: _vm.task.product,
                      status: _vm.task.status,
                      failReason: _vm.task.failReason,
                      company: _vm.task.company,
                      deposit: _vm.task.settings && _vm.task.settings.deposit,
                      withhold: _vm.task.settings && _vm.task.settings.withhold,
                      employeeType: _vm.task.outputs.employeeType,
                      employmentStatus: _vm.task.outputs.employmentStatus,
                      employerName:
                        _vm.task.outputs.employer &&
                        _vm.task.outputs.employer.name,
                      jobTitle: _vm.task.outputs.jobTitle,
                      startDate: _vm.task.outputs.startDate,
                      statements: _vm.task.outputs.statements,
                      minimumMonthsOfEmployment:
                        _vm.task.outputs.minimumMonthsOfEmployment,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasIncomeData
            ? _c(
                "div",
                { attrs: { id: "income" } },
                [
                  _vm._m(0),
                  _c("TaskIncomeChart", {
                    attrs: {
                      income: _vm.task.outputs.income,
                      incomeType: _vm.task.outputs.incomeType,
                      annualIncome: _vm.task.outputs.annualIncome,
                      hourlyIncome: _vm.task.outputs.hourlyIncome,
                      payCycle: _vm.task.outputs.payCycle,
                      nextExpectedPayDate: _vm.task.outputs.nextExpectedPayDate,
                      derivedOutputMetadata: _vm.task.derivedOutputMetadata,
                      statements: _vm.task.outputs.statements,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasTimesheetData
            ? _c(
                "div",
                { attrs: { id: "task-timesheets-chart" } },
                [
                  _vm._m(1),
                  _c("TaskTimesheetsChart", {
                    attrs: { timesheets: _vm.task.outputs.timesheets },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasPaystubData
            ? _c(
                "div",
                { attrs: { id: "paystubs" } },
                [
                  _vm._m(2),
                  _c("TaskPaystubs", {
                    attrs: {
                      taskId: _vm.task._id,
                      statements: _vm.task.outputs.statements,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasDistributionData
            ? _c(
                "div",
                { attrs: { id: "distributions" } },
                [
                  _vm._m(3),
                  _c("TaskDistributions", {
                    attrs: { accounts: _vm.task.outputs.accounts },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasTaxesData
            ? _c(
                "div",
                { attrs: { id: "tax-forms" } },
                [
                  _vm._m(4),
                  _vm.hasW2Data
                    ? _c("TaskW2s", {
                        attrs: {
                          taskId: _vm.task._id,
                          w2s: _vm.task.outputs.w2s,
                        },
                      })
                    : _vm._e(),
                  _vm.has1099Data
                    ? _c("Task1099s", {
                        attrs: {
                          taskId: _vm.task._id,
                          form1099s: _vm.task.outputs["1099s"],
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.hasIdentityData
            ? _c(
                "div",
                { attrs: { id: "identity" } },
                [
                  _vm._m(5),
                  _c("TaskUserIdentity", {
                    attrs: {
                      firstName: _vm.task.outputs.firstName,
                      lastName: _vm.task.outputs.lastName,
                      dateOfBirth: _vm.task.outputs.dateOfBirth,
                      phone: _vm.task.outputs.phone,
                      email: _vm.task.outputs.email,
                      ssn: _vm.task.outputs.ssn,
                      postalCode: _vm.task.outputs.postalCode,
                      state: _vm.task.outputs.state,
                      city: _vm.task.outputs.city,
                      address: _vm.task.outputs.address,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasEventsData
            ? _c(
                "div",
                { attrs: { id: "webhook-events" } },
                [
                  _vm._m(6),
                  _c("TaskEvents", {
                    attrs: {
                      eventRequests: _vm.eventRequests,
                      unsentEvents: _vm.unsentEvents,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-body" }, [
        _c("h2", { staticClass: "header-title" }, [_vm._v("Income")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header mb-0" }, [
      _c("div", { staticClass: "header-body" }, [
        _c("h2", { staticClass: "header-title" }, [
          _vm._v("Time & Attendance"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header mb-0" }, [
      _c("div", { staticClass: "header-body" }, [
        _c("h2", { staticClass: "header-title" }, [_vm._v("Paystubs")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-body" }, [
        _c("h2", { staticClass: "header-title" }, [_vm._v("Distributions")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-body" }, [
        _c("h2", { staticClass: "header-title" }, [_vm._v("Taxes")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-body" }, [
        _c("h2", { staticClass: "header-title" }, [_vm._v("Identity")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-body" }, [
        _c("h2", { staticClass: "header-title" }, [_vm._v("Webhook Events")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }