<template>
  <div class="col-sm-3 col-4">
    <affix
      class="menu sidebar-menu"
      relative-element-selector="#task-content"
      :offset="{ top: 40, bottom: 40 }"
      :scroll-affix="false"
    >
      <nav>
        <scrollactive
          class="nav nav-pills flex-column"
          :offset="150"
          active-class="active"
          :highlightFirstItem="true"
        >
          <li class="nav-item" v-if="hasEmploymentData || hasEventsData">
            <a class="nav-link scrollactive-item" href="#employment">Overview</a>
          </li>
          <li class="nav-item" v-if="hasIncomeData">
            <a class="nav-link scrollactive-item" href="#income">Income</a>
          </li>
          <li class="nav-item" v-if="hasTimesheetData">
            <a class="nav-link scrollactive-item" href="#task-timesheets-chart"
              >Time &amp; Attendance</a
            >
          </li>
          <li class="nav-item" v-if="hasPaystubData">
            <a class="nav-link scrollactive-item" href="#paystubs">Paystubs</a>
          </li>
          <li class="nav-item" v-if="hasDistributionData">
            <a class="nav-link scrollactive-item" href="#distributions">Pay Distributions</a>
          </li>
          <li class="nav-item" v-if="hasTaxesData">
            <a class="nav-link scrollactive-item" href="#tax-forms">Taxes</a>
          </li>
          <li class="nav-item" v-if="hasIdentityData">
            <a class="nav-link scrollactive-item" href="#identity">Identity</a>
          </li>
          <li class="nav-item" v-if="hasEventsData">
            <a class="nav-link scrollactive-item" href="#webhook-events">Webhook Events</a>
          </li>
        </scrollactive>
      </nav>
    </affix>
  </div>
</template>

<script>
export default {
  name: 'TaskNav',
  props: [
    'product',
    'hasDistributionData',
    'hasDepositData',
    'hasEmploymentData',
    'hasIncomeData',
    'hasPaystubData',
    'hasTimesheetData',
    'hasIdentityData',
    'hasTaxesData',
    'hasEventsData',
  ],
}
</script>

<style scoped lang="scss">
.scrollaffix-sidebar {
  width: 204px;
  font-size: 14px;
}

.nav-item a {
  transform-origin: 5px 0;
  color: #999;
  font-size: 15px;
  transition: all 0.15s;
  &.active {
    transform: scale(1.1);
    // font-weight: bold;
    color: var(--primary);
    background-color: transparent;
  }
}
</style>
