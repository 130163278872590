<template>
  <transition name="slide-fade" mode="out-in">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'SlideFadeTransition',
}
</script>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.2s ease-in;
}
.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
}
</style>
