import { DISTRIBUTION_TYPES } from '@/utils/constants'
import { phrases } from '@/lib/simulation-scenarios/phrases'
import { exceptions } from '@/lib/simulation-scenarios/exceptions'
import { analyticsEvents } from '@/lib/simulation-scenarios/analytics-events'

export const ADD_FIXED = {
  label: 'Fixed',
  value: DISTRIBUTION_TYPES.FIXED,
  prompt: phrases.prompts.howManyAccountsAreOnFileQ,
  analyticsEventName: analyticsEvents.selectNumberOfAccounts,
  options: [
    {
      label: '0',
      value: 0,
      results: {
        status: 'success',
        newAccount: 'Amount chosen by user',
        addendum: phrases.addendums.checkRemainder,
        exceptions: [],
        explainer: 'The final result is a new account added for the selected distribution amount.',
      },
    },
    {
      label: '1',
      value: 1,
      prompt: phrases.prompts.whatKindOfAcctOnFileQ,
      analyticsEventName: analyticsEvents.selectTypesOfAccounts,
      options: [
        {
          label: 'Total',
          value: DISTRIBUTION_TYPES.TOTAL,
          results: {
            status: 'success',
            newAccount: 'Amount chosen by user',
            oldAccount: 'remainder',
            addendum: phrases.addendums.remainderDescription,
            exceptions: [],
            explainer:
              'The final result is a new account added for the selected distribution amount. The old account will remain unchanged.',
          },
        },
        {
          label: 'Fixed',
          value: DISTRIBUTION_TYPES.FIXED,
          results: {
            status: 'success',
            newAccount: 'Amount chosen by user',
            oldAccount: 'No changes',
            addendum: phrases.addendums.checkRemainder,
            exceptions: [],
            explainer:
              'The final result is a new account added for the selected distribution amount. The old account will remain unchanged.',
          },
        },
        {
          label: 'Percent',
          value: DISTRIBUTION_TYPES.PERCENT,
          results: {
            status: 'success',
            newAccount: 'Amount chosen by user',
            oldAccount: 'No changes',
            addendum: null,
            exceptions: [exceptions.gusto.mixedDistributionsNotAllowed],
            explainer:
              'The final result is a new account added for the selected distribution amount. The old account will remain unchanged.',
          },
        },
      ],
    },
    {
      label: '2',
      value: 2,
      prompt: phrases.prompts.whatKindOfAcctOnFileQ,
      analyticsEventName: analyticsEvents.selectTypesOfAccounts,
      options: [
        {
          label: 'Fixed, Remainder',
          value: '1-fixed-1-remainder',
          results: {
            status: 'success',
            newAccount: 'Amount chosen by user',
            oldAccounts: {
              A: 'No changes',
              B: 'remainder',
            },
            addendum: phrases.addendums.remainderDescription,
            exceptions: [exceptions.walmart.onlyTwoAccountsAllowed],
            explainer:
              'The final result is a new account added for the selected distribution amount. The old accounts will remain unchanged.',
          },
        },
        {
          label: 'Percent, Remainder',
          value: '1-percent-1-remainder',
          results: {
            status: 'success',
            newAccount: 'Amount chosen by user',
            oldAccounts: {
              A: 'No changes',
              B: 'remainder',
            },
            addendum: phrases.addendums.remainderDescription,
            exceptions: [
              exceptions.walmart.onlyTwoAccountsAllowed,
              exceptions.gusto.mixedDistributionsNotAllowed,
            ],
            explainer:
              'The final result is a new account added for the selected distribution amount. The old accounts will remain unchanged.',
          },
        },
      ],
    },
  ],
}
