<template>
  <div class="card">
    <div class="card-body text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <img
            src="/images/illustrations/tasks-offstate.svg"
            alt="..."
            class="img-fluid mt-n5 mb-5"
            style="max-width: 400px"
          />
          <h2>No tasks found.</h2>
          <p class="text-muted">Try adjusting your search settings.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskNoTasksFound',
}
</script>
