<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Transaction Volume</h4>
      <div class="chart-legend mt-0">
        <div>
          <span class="chart-legend-item"
            ><span class="chart-legend-indicator" style="background-color: #2c7be5"></span
            >Deposit</span
          ><span class="chart-legend-item"
            ><span class="chart-legend-indicator" style="background-color: #a6c5f7"></span
            >Verify</span
          >
        </div>
      </div>
    </div>
    <div class="card-body" :class="{ 'fade-in-out': !isDataReady }">
      <ChartWrapper
        :chartType="CHART_TYPES.BAR"
        :chartData="chartData"
        :useDummyData="isLoading || sampleDataMode"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ChartWrapper from '@/components/Charts/ChartWrapper'
import { CHART_TYPES } from '@/utils/constants'
import { decideBinSize, generateChartData, createDataSetFromChartData } from '@/utils/activity'

export default {
  name: 'TransactionVolume',
  components: { ChartWrapper },
  computed: {
    ...mapState('activity', [
      'transactionVolume',
      'activeDateRangeOption',
      'startDate',
      'endDate',
      'sampleDataMode',
    ]),
    ...mapState('environment', ['activeEnvironment']),
    chartData() {
      if (this.isLoading || this.sampleDataMode) return {}
      const binSize = decideBinSize(this.activeDateRangeOption.end)
      const generatedChartData = generateChartData({
        binSize,
        startDate: this.startDate,
        endDate: this.endDate,
        items: this.transactionVolume,
      })
      return {
        labels: Object.keys(generatedChartData),
        datasets: [
          {
            label: 'Deposit',
            data: createDataSetFromChartData({
              generatedChartData,
              filter: (item) => item.product === 'deposit',
            }),
          },
          {
            label: 'Verify',
            backgroundColor: '#d2ddec',
            data: createDataSetFromChartData({
              generatedChartData,
              filter: (item) => item.product === 'verify',
            }),
          },
        ],
      }
    },
    isDataReady() {
      return !!(this.sampleDataMode || !this.isLoading)
    },
  },
  data() {
    return {
      isLoading: true,
      CHART_TYPES,
    }
  },
  methods: {
    ...mapActions('activity', ['getTransactionVolume']),
    async getData() {
      this.isLoading = true
      await this.getTransactionVolume()
      this.isLoading = false
    },
  },
  async mounted() {
    this.getData()
  },
  watch: {
    'activeDateRangeOption.label'() {
      this.getData()
    },
    activeEnvironment() {
      this.getData()
    },
  },
}
</script>

<style lang="scss"></style>
