var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-4" }, [
    _c("h4", [_vm._v("User Link")]),
    _c(
      "small",
      { staticClass: "form-text text-muted" },
      [
        _vm._v(
          " Continuous Access tasks are run at a preset frequency with the user's linked accounts to monitor payroll data. Ensure that you have configured "
        ),
        _c("router-link", { attrs: { to: "/settings/webhooks" } }, [
          _vm._v("webhooks"),
        ]),
        _vm._v(" to listen for the associated "),
        _c(
          "a",
          {
            attrs: {
              href: "https://docs.atomicfi.com/reference/webhooks",
              target: "_blank",
            },
          },
          [_vm._v("events")]
        ),
        _vm._v(". The data will also be retrievable via our "),
        _c(
          "a",
          {
            attrs: {
              href: "https://docs.atomicfi.com/reference/api",
              target: "_blank",
            },
          },
          [_vm._v("API endpoints")]
        ),
        _vm._v(". "),
      ],
      1
    ),
    _c("div", { staticClass: "d-flex flex-wrap form-group mt-4" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-6 p-2" },
        [
          _c("label", [_vm._v("Payroll Data Categories To Monitor")]),
          _c("v-select", {
            attrs: {
              value: _vm.userLinkContinuousAccess.payrollDataTypesToMonitor,
              reduce: function (ref) {
                var value = ref.value

                return value
              },
              options: _vm.payrollDataSnapshotTypes,
              multiple: "",
            },
            on: {
              input: function ($event) {
                return _vm.onChange("payrollDataTypesToMonitor", $event)
              },
            },
          }),
          _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v("The fields to monitor on the user's accounts."),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-6 p-2" },
        [
          _c("label", [_vm._v("Frequency")]),
          _c("v-select", {
            attrs: {
              value: _vm.userLinkContinuousAccess.frequency,
              disabled: true,
              clearable: false,
            },
          }),
          _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(
              "How often Atomic checks the payroll system for modifications. Please contact customerhelp@atomicfi.com to have frequency updated."
            ),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "d-flex flex-wrap form-group mt-4" },
      [
        _c("CustomSwitch", {
          staticClass: "col-12",
          attrs: {
            value: _vm.userLinkContinuousAccess.timePeriod.enabled,
            label: "Duration of Continuous Access",
            helperText:
              "Enable Continuous Access for a limited duration. Without a set duration, Continuous Access will run indefinitely.",
          },
          on: {
            input: function ($event) {
              return _vm.onChange("timePeriod.enabled", $event)
            },
          },
        }),
        _vm.userLinkContinuousAccess.timePeriod.enabled
          ? _c("div", { staticClass: "col-12 col-md-6 p-2" }, [
              _c("label", [_vm._v("Duration Amount")]),
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "number", min: "1" },
                domProps: {
                  value: _vm.userLinkContinuousAccess.timePeriod.amount,
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange(
                      "timePeriod.amount",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "The amount of time Continuous Access will be enabled on the user's account."
                ),
              ]),
            ])
          : _vm._e(),
        _vm.userLinkContinuousAccess.timePeriod.enabled
          ? _c(
              "div",
              { staticClass: "col-12 col-md-6 p-2" },
              [
                _c("label", [_vm._v("Duration Unit")]),
                _c("v-select", {
                  attrs: {
                    value: _vm.userLinkContinuousAccess.timePeriod.scale,
                    clearable: false,
                    options: ["week", "month", "year"],
                  },
                  on: {
                    input: function ($event) {
                      return _vm.onChange("timePeriod.scale", $event)
                    },
                  },
                }),
                _c("small", { staticClass: "form-text text-muted" }, [
                  _vm._v(
                    "The units you would like to set for the duration of Continuous Access; i.e. 2 months or 5 weeks."
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }