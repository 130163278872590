<template>
  <div class="mb-4">
    <h4>User Link</h4>
    <small class="form-text text-muted">
      Continuous Access tasks are run at a preset frequency with the user's linked accounts to
      monitor payroll data. Ensure that you have configured
      <router-link to="/settings/webhooks">webhooks</router-link> to listen for the associated
      <a href="https://docs.atomicfi.com/reference/webhooks" target="_blank">events</a>. The data
      will also be retrievable via our
      <a href="https://docs.atomicfi.com/reference/api" target="_blank">API endpoints</a>.
    </small>
    <div class="d-flex flex-wrap form-group mt-4">
      <div class="col-12 col-md-6 p-2">
        <label>Payroll Data Categories To Monitor</label>
        <v-select
          :value="userLinkContinuousAccess.payrollDataTypesToMonitor"
          @input="onChange('payrollDataTypesToMonitor', $event)"
          :reduce="({ value }) => value"
          :options="payrollDataSnapshotTypes"
          multiple
        ></v-select>
        <small class="form-text text-muted">The fields to monitor on the user's accounts.</small>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label>Frequency</label>
        <v-select
          :value="userLinkContinuousAccess.frequency"
          :disabled="true"
          :clearable="false"
        ></v-select>
        <small class="form-text text-muted"
          >How often Atomic checks the payroll system for modifications. Please contact
          customerhelp@atomicfi.com to have frequency updated.</small
        >
      </div>
    </div>
    <div class="d-flex flex-wrap form-group mt-4">
      <CustomSwitch
        class="col-12"
        :value="userLinkContinuousAccess.timePeriod.enabled"
        @input="onChange('timePeriod.enabled', $event)"
        label="Duration of Continuous Access"
        helperText="Enable Continuous Access for a limited duration. Without a set duration, Continuous Access will run indefinitely."
      />

      <div v-if="userLinkContinuousAccess.timePeriod.enabled" class="col-12 col-md-6 p-2">
        <label>Duration Amount</label>
        <input
          class="form-control"
          type="number"
          @input="onChange('timePeriod.amount', $event.target.value)"
          :value="userLinkContinuousAccess.timePeriod.amount"
          min="1"
        />
        <small class="form-text text-muted"
          >The amount of time Continuous Access will be enabled on the user's account.</small
        >
      </div>

      <div v-if="userLinkContinuousAccess.timePeriod.enabled" class="col-12 col-md-6 p-2">
        <label>Duration Unit</label>
        <v-select
          :value="userLinkContinuousAccess.timePeriod.scale"
          @input="onChange('timePeriod.scale', $event)"
          :clearable="false"
          :options="['week', 'month', 'year']"
        ></v-select>
        <small class="form-text text-muted"
          >The units you would like to set for the duration of Continuous Access; i.e. 2 months or 5
          weeks.</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import CustomSwitch from '@/components/MainContent/CustomSwitch'
import { PAYROLL_DATA_SNAPSHOT_TYPES } from '@/utils/constants'
import { startCase } from 'lodash-es'

export default {
  name: 'SettingsFeaturesUserLinkMonitoring',
  props: ['userLinkContinuousAccess', 'title'],
  components: { CustomSwitch },
  data() {
    return {
      payrollDataSnapshotTypes: Object.values(PAYROLL_DATA_SNAPSHOT_TYPES).map((value) => ({
        value,
        label: startCase(value),
      })),
    }
  },
  methods: {
    onChange(key, value) {
      this.$emit('input', {
        key,
        value,
      })
    },
  },
}
</script>
