<template>
  <div>
    <EmulatorSectionHeader title="Styles" />
    <EmulatorCustomizationTextBox
      category="Brand"
      label="Brand Color"
      namespace="theme"
      path="colors.brand"
      dataType="color"
    >
      <template v-slot="{ value }">
        <div class="col-auto">
          <ColorSwatch :color="value" />
        </div>
      </template>
      <template #postInput>
        <Alert type="info" class="mb-4">
          Brand color can be saved as your default to the database or overridden with the
          <code class="me-2">theme.brandColor</code>
          <a
            href="https://docs.atomicfi.com/reference/transact-sdk#parameters--optional-properties"
            target="_blank"
            >Transact SDK parameter</a
          >.
        </Alert>
      </template>
    </EmulatorCustomizationTextBox>

    <EmulatorCustomizationTextBox
      category="Button Styles"
      label="Button Border Radius"
      namespace="theme"
      path="buttons.borderRadius"
      dataType="string"
    />

    <EmulatorCustomizationTextBox
      v-if="customCssEnabled"
      category="Custom Styles"
      label="Custom CSS"
      namespace="theme"
      path="customCSS"
      dataType="string"
      inputType="textarea"
      placeholder="Paste or type your CSS here"
    >
      <template #postInput>
        <Alert type="warning" class="mb-4">
          <p>We cannot guarantee your custom CSS will work as expected.</p>
          <p>
            Custom CSS cannot override certain content, including references to Atomic. Some
            references to Atomic will be automatically applied if you use this feature.
          </p>
          <p class="disclaimer">
            Disclaimer: Our support agreement does not include assistance with modifying or
            debugging your custom CSS.
          </p>
        </Alert>
      </template>
    </EmulatorCustomizationTextBox>
  </div>
</template>

<script>
import Alert from '@/components/MainContent/Alert'
import ColorSwatch from '@/components/MainContent/ColorSwatch'
import EmulatorSectionHeader from '@/components/Modules/Emulator/EmulatorSectionHeader'
import EmulatorCustomizationTextBox from '@/components/Modules/Emulator/CustomizationInputs/EmulatorCustomizationTextBox'
import { mapState } from 'vuex'

export default {
  name: 'EmulatorStyles',
  components: {
    Alert,
    ColorSwatch,
    EmulatorSectionHeader,
    EmulatorCustomizationTextBox,
  },
  computed: {
    ...mapState('customer', ['activeCustomer']),
    customCssEnabled() {
      return this.activeCustomer?.features?.customCSS?.enabled
    },
  },
}
</script>

<style scoped lang="scss">
code {
  color: white;
}

a {
  color: white;
  text-decoration: underline;
}

.disclaimer {
  font-size: 12px;
}
</style>
