<template>
  <div>
    <div v-if="!enabled" class="alert alert-warning">
      These settings only apply when fractional deposit is enabled.
    </div>

    <div>
      <label
        >Configure the minimum and maximum amounts users can select for fractional deposit.</label
      >

      <div class="flex-container">
        <span class="form-text text-muted help-text">
          <info-icon class="info-icon" size="1.2x" />
          Warning and error messages can be customized in the emulator.
          <router-link class="nav-link nav-link-override" to="/emulator">Take Me There</router-link>
        </span>
      </div>
      <Card>
        <template v-slot:header>
          <h4>Fixed</h4>
        </template>
        <SettingsFractionalDepositLimit
          limitDirection="minimum"
          :data="data.fixed.minimum || {}"
          @input="updateLimit($event, 'minimum', distributionTypes.FIXED)"
        />
        <SettingsFractionalDepositLimit
          limitDirection="maximum"
          :data="data.fixed.maximum || {}"
          @input="updateLimit($event, 'maximum', distributionTypes.FIXED)"
        />
      </Card>
      <Card>
        <template v-slot:header>
          <h4>Percent</h4>
        </template>
        <SettingsFractionalDepositLimit
          limitDirection="minimum"
          :data="data.percent.minimum || {}"
          @input="updateLimit($event, 'minimum', distributionTypes.PERCENT)"
          :fractionalType="distributionTypes.PERCENT"
          :max="100"
        />
        <SettingsFractionalDepositLimit
          limitDirection="maximum"
          :data="data.percent.maximum || {}"
          @input="updateLimit($event, 'maximum', distributionTypes.PERCENT)"
          :fractionalType="distributionTypes.PERCENT"
          :max="100"
        />
      </Card>
    </div>
  </div>
</template>

<script>
import { merge } from 'lodash-es'
import { InfoIcon } from 'vue-feather-icons'
import { DISTRIBUTION_TYPES } from '@/utils/constants'
import SettingsFractionalDepositLimit from '@/components/Modules/Settings/FractionalDeposit/SettingsFractionalDepositLimit.vue'
import Card from '@/components/MainContent/Card'

export default {
  name: 'SettingsBoundedFractionalDeposit',
  components: {
    Card,
    SettingsFractionalDepositLimit,
    InfoIcon,
  },
  props: {
    enabled: Boolean,
    data: Object,
  },
  methods: {
    updateLimit($event, type, depositType) {
      this.$emit(`${depositType}Input`, merge(this.data[depositType], { [type]: $event }))
    },
  },
  data() {
    return {
      distributionTypes: DISTRIBUTION_TYPES,
    }
  },
}
</script>
<style>
.help-text {
  align-items: center;
  display: flex;
  margin-bottom: 24px;
}

.info-icon {
  color: var(--bs-gray-600);
  margin-right: 0.4em;
}

.flex-container {
  display: flex;
}

.nav-link-override {
  display: inline;
  margin-left: 0.4em;
  padding: unset;
}
</style>
