var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "badge text-capitalize",
      class: [_vm.backgroundClass, _vm.isFailed ? "failed" : undefined],
    },
    [_vm._v(" " + _vm._s(_vm.realtimeStatus) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }