<template>
  <div class="progress mb-3">
    <div
      v-for="({ length, color }, index) in sections"
      :key="index"
      class="progress-bar"
      role="progressbar"
      :style="{ width: length + '%', 'background-color': color }"
    />
  </div>
</template>
<script>
export default {
  name: 'BreakdownBar',
  props: ['sections'],
}
</script>
