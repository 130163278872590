var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ModalForm",
    {
      attrs: {
        id: _vm.id,
        title: _vm.title,
        onSubmit: _vm.submitForm,
        submitButtonText: _vm.title,
      },
      on: { success: _vm.onSuccess, error: _vm.onError, hidden: _vm.onHide },
    },
    [
      _c("validation-provider", {
        attrs: { name: "Endpoint URL", rules: "required|url" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var classes = ref.classes
              var errors = ref.errors
              var touched = ref.touched
              return [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Endpoint URL")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formValues.url,
                        expression: "formValues.url",
                      },
                    ],
                    staticClass: "form-control",
                    class: classes,
                    attrs: { type: "text", placeholder: "https://" },
                    domProps: { value: _vm.formValues.url },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.formValues, "url", $event.target.value)
                      },
                    },
                  }),
                  touched
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(errors[0])),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        attrs: {
          name: "Events",
          rules: { required: { message: "Please choose at least one event." } },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var classes = ref.classes
              var errors = ref.errors
              return [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "events" } }, [
                      _vm._v("Select events to listen to"),
                    ]),
                    _c("v-select", {
                      class: classes,
                      attrs: {
                        multiple: "",
                        options: _vm.availableWebhookEventTypes,
                        "close-on-select": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "search",
                            fn: function (ref) {
                              var attributes = ref.attributes
                              var events = ref.events
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "vs__search",
                                        attrs: {
                                          id: "events",
                                          required:
                                            !_vm.formValues.eventTypes.length,
                                        },
                                      },
                                      "input",
                                      attributes,
                                      false
                                    ),
                                    events
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.formValues.eventTypes,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValues, "eventTypes", $$v)
                        },
                        expression: "formValues.eventTypes",
                      },
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        attrs: { name: "Secret ID", rules: "required" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var classes = ref.classes
              return [
                _c("label", [_vm._v("Secret")]),
                _c("v-select", {
                  class: classes,
                  attrs: {
                    options: _vm.credentials.secrets,
                    getOptionLabel: function (ref) {
                      var name = ref.name

                      return name || "Default"
                    },
                    getOptionKey: function (ref) {
                      var _id = ref._id

                      return _id
                    },
                    reduce: function (ref) {
                      var _id = ref._id

                      return _id
                    },
                    clearable: false,
                  },
                  model: {
                    value: _vm.formValues.secretId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formValues, "secretId", $$v)
                    },
                    expression: "formValues.secretId",
                  },
                }),
                _c("small", { staticClass: "form-text text-muted" }, [
                  _vm._v(
                    " The secret will be used to sign the webhook payload. "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }