import EmployerService from '@/services/EmployerService'
import CustomerService from '@/services/CustomerService'
import UserService from '@/services/UserService'

export default {
  namespaced: true,
  state: {
    magicLinkSent: false,
    companyExists: false,
    email: '',
    givenName: '',
    familyName: '',
    companyName: '',
    isLoading: false,
    emailValidationError: undefined,
    emailIsValidated: false,
    generalError: false,
    agreedToTerms: false,
  },
  mutations: {
    setMagicLinkSent(state, magicLinkSent) {
      state.magicLinkSent = magicLinkSent
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setCompanyExists(state, companyExists) {
      state.companyExists = companyExists
    },
    setEmailValidationError(state, emailValidationError) {
      state.emailValidationError = emailValidationError
    },
    setEmailIsValidated(state, emailIsValidated) {
      state.emailIsValidated = emailIsValidated
    },
    setGeneralError(state, generalError) {
      state.generalError = generalError
    },
    setEmail(state, email) {
      state.email = email
    },
    setGivenName(state, givenName) {
      state.givenName = givenName
    },
    setFamilyName(state, familyName) {
      state.familyName = familyName
    },
    setCompanyName(state, companyName) {
      state.companyName = companyName
    },
    setAgreedToTerms(state, agreedToTerms) {
      state.agreedToTerms = agreedToTerms
    },
  },
  actions: {
    async signupCustomer({ commit, state }) {
      commit('setIsLoading', true)
      try {
        await CustomerService.signup({
          user: {
            email: state.email,
            givenName: state.givenName,
            familyName: state.familyName,
          },
          customer: {
            name: state.companyName,
          },
        })
        commit('setIsLoading', false)
        commit('setMagicLinkSent', true)
      } catch (error) {
        commit('setIsLoading', false)
        commit('setGeneralError', true)
      }
    },
    async validateEmail({ commit, state }) {
      commit('setIsLoading', true)
      commit('setEmailValidationError', undefined)
      try {
        const result = await UserService.validateEmail(state.email)
        commit('setIsLoading', false)
        if (result.data.success) {
          if (result.data.domainHasUsers) commit('setCompanyExists', true)
          commit('setEmailIsValidated', true)
        }
      } catch (error) {
        commit('setIsLoading', false)
        if (error.data?.message) {
          commit('setEmailValidationError', error.data.message)
        } else {
          commit('setEmailValidationError', 'Something went wrong validating your email.')
        }
      }
    },
    changeEmail({ commit }) {
      commit('setEmail', '')
      commit('setCompanyExists', false)
      commit('setEmailIsValidated', false)
    },
  },
}
