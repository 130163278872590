var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert d-flex align-items-start",
      class: "alert-" + _vm.type,
      attrs: { role: "alert" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          _c(_vm.icon, {
            tag: "component",
            staticClass: "me-2",
            attrs: { size: "20" },
          }),
        ],
        1
      ),
      _c("div", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }