import Vue from 'vue'
import store from '@/store'

export default {
  get(id) {
    return Vue.http.get(`${store.getters['environment/atomicCustomerAPI']}/task/${id}`)
  },
  async list({ filters, skip = 0, limit = 25, sortField = 'createdAt', sortOrder = -1 }) {
    try {
      const payload = {
        skip,
        limit,
        sortField,
        sortOrder,
        ...filters,
      }

      const response = await Vue.http.post(
        `${store.getters['environment/atomicCustomerAPI']}/task/list`,
        payload
      )

      const data = await response.json()

      if (!data) {
        return { tasks: [], total: 0 }
      }

      return {
        tasks: data.data || [],
        total: data.total || 0,
      }
    } catch (error) {
      throw error
    }
  },
  generateTaskOutputFileUrl({ taskId, fileId }) {
    return Vue.http.get(
      `${store.getters['environment/atomicCustomerAPI']}/task/${taskId}/file/${fileId}/generate-url`
    )
  },
  getTaskEvents({ taskId }) {
    return Vue.http.get(`${store.getters['environment/atomicCustomerAPI']}/task/${taskId}/events`)
  },

  exportCsv(params) {
    const baseUrl = `${store.getters['environment/atomicCustomerAPI']}/task/export`

    const queryParams = {
      query: params.query || undefined,
      startDate: params.startDate || undefined,
      endDate: params.endDate || undefined,
      externalStatus: params.externalStatus?.join(','),
      product: params.product?.join(','),
      sortField: params.sortField,
      sortOrder: params.sortOrder,
    }

    const cleanParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value !== undefined)
    )

    const queryString = new URLSearchParams(cleanParams).toString()
    const url = queryString ? `${baseUrl}?${queryString}` : baseUrl

    return Vue.http.get(url, {
      responseType: 'blob',
    })
  },
}
