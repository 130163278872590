<template>
  <EventRequest :event="mappedEventFromResponse" />
</template>
<script>
import EventRequest from '@/components/Modules/Event/EventRequest'
import { pick } from 'lodash-es'
import { isSuccessfulStatus } from '@/utils/event'

export default {
  name: 'SettingsWebhooksResponse',
  components: { EventRequest },
  props: {
    response: Object,
  },
  computed: {
    mappedEventFromResponse() {
      return {
        _id: new Date().toISOString(),
        ...pick(this.response, ['createdAt', 'endpointUrl']),
        status: isSuccessfulStatus({ status: this.response.response.status }) ? 'sent' : 'failed',
        headers: this.response.request.headers,
        payload: this.response.request.payload,
        responses: [
          {
            status: this.response.response.status,
            headers: this.response.response.headers,
            data: this.response.response.data,
          },
        ],
      }
    },
  },
}
</script>
