<template>
  <div class="icon-outer">
    <div class="icon-inner" :class="{ dark: settings.theme.dark }">
      <img :src="value" :alt="label" class="icon" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmulatorImagePreview',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('emulator', ['settings']),
  },
}
</script>

<style lang="scss" scoped>
.icon-outer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-inner {
  align-items: center;
  border-radius: 16px;
  display: inline-flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  width: 80px;

  &.dark {
    background-color: black;
    border: none;
  }
}

.icon {
  display: block;
  max-height: 50%;
  max-width: 50%;
  object-fit: scale-down;
}
</style>
