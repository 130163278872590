<template>
  <div>
    <Alert v-if="usesSso" class="mb-3" type="info">
      Adding new team members <span v-if="managesRolesInIdp">and editing their role</span> is done
      via your organization's SSO provider.
    </Alert>
    <div v-if="isLoading" class="col-12 text-center mt-3 mb-3">
      <div class="spinner-border text-primary" role="status" />
    </div>
    <div v-else-if="users" class="user-list">
      <UserCard
        v-for="user in sortedUsers"
        :key="user.user_id"
        :user="user"
        @delete="openDeleteUserModal"
        @editRole="openEditModal"
        @viewRolePermissions="openRolePermissionsModal"
      />
    </div>
    <UserEditRoleModal
      :user="userToEdit"
      @onSuccess="() => populateUsers({ withLoadingState: false })"
    />
    <SettingsTeamDeleteUserModal
      :user="userToDelete"
      @onSuccess="() => populateUsers({ withLoadingState: false })"
    />
    <RolePermissionsModal :userRole="userRole" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Alert from '@/components/MainContent/Alert'
import SettingsBase from './SettingsBase'
import UserCard from './SettingsTeam/UserCard'
import UserEditRoleModal from '@/components/Modules/Settings/SettingsTeam/UserEditRoleModal'
import SettingsTeamDeleteUserModal from './SettingsTeamDeleteUserModal.vue'
import RolePermissionsModal from '@/components/Modules/Settings/RolePermissions/RolePermissionsModal'
import { getAvailableUserRoles, getUserRoleLevel, roles } from '@/lib/authorization'
import { partition } from 'lodash-es'

export default {
  name: 'SettingsTeam',
  extends: SettingsBase,
  components: {
    RolePermissionsModal,
    Alert,
    UserCard,
    UserEditRoleModal,
    SettingsTeamDeleteUserModal,
  },
  data: () => ({ isLoading: false, userToEdit: {}, userRole: {}, userToDelete: {} }),
  computed: {
    ...mapGetters('customer', ['usesSso', 'managesRolesInIdp']),
    ...mapState('user', ['users']),
    sortedUsers() {
      const [you, ...otherUsers] = partition(
        this.users,
        (user) => this.$auth.user.sub === user.userId
      )

      return [you, ...otherUsers].flat()
    },
  },
  methods: {
    ...mapActions('user', ['getUsers']),

    async populateUsers({ withLoadingState = true } = {}) {
      try {
        if (withLoadingState) {
          this.isLoading = true
        }

        await this.getUsers()
      } finally {
        this.isLoading = false
      }
    },
    openEditModal(user) {
      const level = getUserRoleLevel(user.roles[0])

      this.userToEdit = {
        userId: user.userId,
        availableRoles: getAvailableUserRoles(level),
      }

      this.$bvModal.show('edit-user-role-modal')
    },
    openRolePermissionsModal(user) {
      this.userRole = roles.find((role) => role.value === user.roles[0])

      this.$bvModal.show('role-permissions-modal')
    },
    openDeleteUserModal(user) {
      this.userToDelete = {
        userId: user.userId,
        userName: user?.givenName + ' ' + user?.familyName,
      }

      this.$bvModal.show('delete-user-modal')
    },
  },
  async mounted() {
    await this.populateUsers()
  },
}
</script>

<style scoped lang="scss">
// keeps dropdown menu from being cut off
.user-list {
  overflow-y: auto;
}
</style>
