var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CustomSwitch", {
        attrs: {
          value: _vm.fractionalDeposits.enabled,
          helperText:
            "Allows your users\n      to customize their deposit distribution in Transact.",
          label: "Fractional Deposits",
        },
        on: { input: _vm.toggleFractionalDepositFeature },
      }),
      _vm.fractionalDeposits.enabled
        ? _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("Select a primary distribution type")]),
                  _c("v-select", {
                    attrs: {
                      options: _vm.distributionTypeOptions,
                      reduce: function (option) {
                        return option.value
                      },
                      clearable: false,
                    },
                    on: { input: _vm.updateType },
                    model: {
                      value: _vm.fractionalDeposits.distribution.default,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.fractionalDeposits.distribution,
                          "default",
                          $$v
                        )
                      },
                      expression: "fractionalDeposits.distribution.default",
                    },
                  }),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _vm.fractionalDeposits.distribution.default === "total"
                      ? _c("span", [
                          _vm._v(
                            " A total distribution will suggest the user switches 100% of their paycheck "
                          ),
                        ])
                      : _vm._e(),
                    _vm.fractionalDeposits.distribution.default === "fixed"
                      ? _c("span", [
                          _vm._v(
                            " A fixed distribution will suggest the user switches a fixed amount of their paycheck "
                          ),
                        ])
                      : _vm._e(),
                    _vm.fractionalDeposits.distribution.default === "percent"
                      ? _c("span", [
                          _vm._v(
                            " A percent distribution will suggest the user switches a percentage of their paycheck "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm.fractionalDeposits.distribution.default === "percent"
                ? _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _vm._v("Suggested Percent (whole percentages)"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "input-group input-group-merge mb-2" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.fractionalDeposits.distribution
                                  .percentAmount,
                              expression:
                                "fractionalDeposits.distribution.percentAmount",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            placeholder: "25",
                            min: "0",
                            max: "100",
                            step: "1",
                            oninput: "validity.valid||(value='');",
                          },
                          domProps: {
                            value:
                              _vm.fractionalDeposits.distribution.percentAmount,
                          },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.fractionalDeposits.distribution,
                                  "percentAmount",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.updateAmount($event, "percentAmount")
                              },
                            ],
                          },
                        }),
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v("%"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.fractionalDeposits.distribution.default !== "total"
                ? _c("div", { staticClass: "form-group" }, [
                    _vm.fractionalDeposits.distribution.default === "percent"
                      ? _c("p", [
                          _vm._v(
                            " Some payroll systems only support fixed amounts. For these payroll systems, we will suggest the amount you specify here. "
                          ),
                        ])
                      : _vm._e(),
                    _c("label", [_vm._v("Suggested Amount (whole dollars)")]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-group input-group-merge input-group-reverse mb-2",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.fractionalDeposits.distribution.fixedAmount,
                              expression:
                                "fractionalDeposits.distribution.fixedAmount",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            placeholder: "100",
                            min: "0",
                            step: "1",
                            oninput: "validity.valid||(value='');",
                          },
                          domProps: {
                            value:
                              _vm.fractionalDeposits.distribution.fixedAmount,
                          },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.fractionalDeposits.distribution,
                                  "fixedAmount",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.updateAmount($event, "fixedAmount")
                              },
                            ],
                          },
                        }),
                        _c("div", { staticClass: "input-group-text px-2" }, [
                          _vm._v("$"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }