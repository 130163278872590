<template>
  <div>
    <TaskOffState v-if="showNewUserScreen" />

    <ul class="nav nav-tabs mb-3">
      <li class="nav-item" v-for="product in productOptions" :key="product">
        <a
          class="nav-link"
          :class="{ active: isProductActive(product) }"
          href="#"
          :id="`${product}-tab`"
          @click.prevent="selectProduct(product)"
        >
          {{ product | startCase }}
        </a>
      </li>
    </ul>

    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <form class="row align-items-center" @submit.prevent>
              <div class="col-auto pe-0">
                <div v-if="!loadingTasks" style="width: 16px">
                  <SearchIcon size="16" class="text-muted" />
                </div>
                <div
                  v-else
                  class="spinner-border spinner-border-sm text-muted"
                  role="status"
                  style="position: relative; top: -1px"
                ></div>
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control form-control-flush search"
                  placeholder="Search by user identifier or taskId"
                  :value="query"
                  @input="updateQuery"
                />
              </div>
              <div class="col-auto">
                <TaskFilterStatus />
              </div>
              <div class="col-auto">
                <TaskFilterDateRange />
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click="resetFilters"
                  class="btn btn-outline-secondary btn-sm me-2"
                  :disabled="loadingTasks"
                >
                  Reset
                </button>
                <button
                  type="button"
                  @click="showDownloadConfirmation"
                  class="btn btn-outline-primary btn-sm me-2"
                >
                  Export
                </button>
                <button
                  type="button"
                  @click="refresh"
                  class="btn btn-primary btn-sm"
                  :disabled="loadingTasks"
                >
                  Refresh
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <template>
          <section class="table-responsive mb-0" v-if="tasks && tasks.length > 0">
            <table class="table card-table mb-0">
              <thead>
                <tr>
                  <th @click="onSortChange('createdAt')" class="sortable">
                    Date
                    <span v-if="sortField === 'createdAt'">
                      <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                  </th>
                  <th @click="onSortChange('user.identifier')" class="sortable">
                    User ID
                    <span v-if="sortField === 'user.identifier'">
                      <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                  </th>
                  <th @click="onSortChange('externalStatus')" class="sortable">
                    Status
                    <span v-if="sortField === 'externalStatus'">
                      <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                  </th>
                  <th @click="onSortChange('company.name')" class="sortable">
                    Employer
                    <span v-if="sortField === 'company.name'">
                      <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                  </th>
                  <th @click="onSortChange('connector.name')" class="sortable">
                    Payroll
                    <span v-if="sortField === 'connector.name'">
                      <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                  </th>

                  <th
                    v-if="isDepositProduct"
                    @click="onSortChange('settings.deposit.distributionType')"
                    class="sortable"
                  >
                    Allocation
                    <span v-if="sortField === 'settings.deposit.distributionType'">
                      <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                  </th>
                  <th
                    v-if="isDepositProduct"
                    @click="onSortChange('settings.deposit.distributionAmount')"
                    class="sortable"
                  >
                    Amount
                    <span v-if="sortField === 'settings.deposit.distributionAmount'">
                      <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                  </th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                <TaskRow
                  v-for="task in tasks"
                  :key="task._id"
                  :task="task"
                  :currentProduct="currentProduct"
                />
              </tbody>
            </table>
          </section>

          <TaskNoTasksFound v-else-if="!loadingTasks" />

          <div v-if="loadingTasks" class="text-center py-4">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </div>

      <TaskPager
        :itemsPerPage="itemsPerPage"
        :totalResults="totalTasks"
        :displayedTasks="tasks.length"
        :currentPage="currentPage"
        :loading="loadingTasks"
        @changePage="onPageChange"
        @changeItemsPerPage="onItemsPerPageChange"
      />

      <transition name="modal">
        <div
          v-if="showDownloadModal"
          class="modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="downloadModalLabel"
          style="display: block"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="downloadModalLabel">Download Confirmation</h5>
                <button
                  type="button"
                  class="btn-close"
                  @click="closeModal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p>Total Records: {{ totalTasks }}</p>
                <p>
                  You are about to export all tasks matching your current filters. This may include
                  tasks not currently loaded in the table. Do you want to proceed?
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="closeModal"
                  :disabled="downloadingCSV"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="confirmDownload"
                  :disabled="downloadingCSV"
                >
                  <span
                    v-if="downloadingCSV"
                    class="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                  {{ downloadingCSV ? 'Downloading...' : 'Download CSV' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import TaskBase from '@/components/Modules/Task/TaskBase'
import TaskRow from '@/components/Modules/Task/TaskRow'
import TaskFilterStatus from '@/components/Modules/Task/TaskFilter/TaskFilterStatus'
import TaskNoTasksFound from '@/components/Modules/Task/TaskNoTasksFound'
import TaskOffState from '@/components/Modules/Task/TaskOffState'
import TaskFilterDateRange from '@/components/Modules/Task/TaskFilter/TaskFilterDateRange'
import { debounce } from 'lodash-es'
import { mapState, mapActions } from 'vuex'
import { SearchIcon } from 'vue-feather-icons'
import moment from 'moment'
import TaskService from '@/services/TaskService'
import { TASK_FILTER_PRODUCT_OPTIONS } from '@/lib/task-filter-product-options'
import { PRODUCTS } from '@atomicfi/constants-shared'
import TaskPager from '@/components/Modules/Task/TaskPager'

export default {
  name: 'Tasks',
  components: {
    SearchIcon,
    TaskRow,
    TaskFilterStatus,
    TaskFilterDateRange,
    TaskNoTasksFound,
    TaskOffState,
    TaskPager,
  },
  extends: TaskBase,
  watch: {
    activeEnvironment: {
      immediate: true,
      async handler() {
        await this.initializeDefaultProduct()
      },
    },
  },
  data: () => ({
    showDownloadModal: false,
    downloadingCSV: false,
    isResetting: false,
    sortField: 'createdAt',
    sortOrder: -1,
    productOptions: Object.values(TASK_FILTER_PRODUCT_OPTIONS),
    defaultProduct: 'DEPOSIT',
    currentPage: 1,
    itemsPerPage: 25,
  }),
  computed: {
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('task', ['loadingTasks', 'filters', 'tasks', 'totalTasks', 'limit', 'skip']),
    hasTasks() {
      return Array.isArray(this.tasks) && this.tasks.length > 0
    },
    showNewUserScreen() {
      return (
        !this.hasTasks &&
        !this.loadingTasks &&
        !this.filters.query &&
        (!this.filters.product || this.filters.product.length === 0) &&
        (!this.filters.externalStatus || this.filters.externalStatus.length === 0) &&
        !this.filters.startDate &&
        !this.filters.endDate
      )
    },
    showNoTasksFound() {
      return !this.hasTasks && !this.loadingTasks && this.filters.query
    },
    showTaskSearchResults() {
      return this.hasTasks || this.loadingTasks
    },
    query: {
      get() {
        return this.$store.state.task.filters.query
      },
      set(value) {
        this.updateQueryFilter(value)
      },
    },
    isLoading() {
      return this.loadingTasks || this.downloadingCSV || this.isResetting
    },
    currentProduct() {
      return (this.filters.product?.[0] || this.defaultProduct || '').toLowerCase()
    },
    isDepositProduct() {
      return this.currentProduct === PRODUCTS.DEPOSIT.toLowerCase()
    },
  },
  methods: {
    ...mapActions('task', ['getTaskList', 'updateQueryFilter']),
    updateQuery(e) {
      this.query = e.target.value
      this.debouncedIdentifierSearch()
    },
    debouncedIdentifierSearch: debounce(function () {
      const query = this.query
      this.updateQueryFilter(query)
      this.currentPage = 1
      this.getTaskList()
    }, 500),
    async refresh() {
      if (!this.loadingTasks) {
        await this.getTaskList()
      }
    },
    async resetFilters() {
      if (this.loadingTasks) return

      try {
        this.isResetting = true
        this.query = ''
        await this.$store.dispatch('task/resetFilters')

        this.currentPage = 1
        this.itemsPerPage = 25
        this.sortField = 'createdAt'
        this.sortOrder = -1

        await this.getTaskList()
      } catch (error) {
        console.error('Failed to reset filters:', error)
        this.$toasted.error('Failed to reset filters. Please try again.')
      } finally {
        this.isResetting = false
      }
    },
    async confirmDownload() {
      let url, link
      try {
        this.downloadingCSV = true

        const exportFilters = {
          query: this.filters.query || undefined,
          externalStatus: this.filters.externalStatus?.filter(Boolean) || undefined,
          product: this.filters.product?.filter(Boolean) || undefined,
          startDate: this.filters.startDate || undefined,
          endDate: this.filters.endDate || undefined,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        }

        const response = await TaskService.exportCsv(exportFilters)
        const blob = await response.blob()

        url = window.URL.createObjectURL(blob)
        link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `tasks-export-${moment().format('YYYY-MM-DD')}.csv`)
        document.body.appendChild(link)
        link.click()

        this.$analytics.track('Clicked Export Tasks Button', {
          environment: this.activeEnvironment,
        })

        this.$toasted.success('Tasks exported successfully')
        this.closeModal()
      } catch (error) {
        console.error('Export failed:', error)
        this.$toasted.error(error.message || 'Failed to export tasks. Please try again.')
      } finally {
        if (url) window.URL.revokeObjectURL(url)
        if (link && link.parentNode) link.parentNode.removeChild(link)
        this.downloadingCSV = false
      }
    },
    showDownloadConfirmation() {
      this.showDownloadModal = true
      document.addEventListener('keydown', this.handleModalKeydown)
      document.body.classList.add('modal-open')
    },
    getFilters() {
      return {
        query: this.filters.query || undefined,
        externalStatus: this.filters.externalStatus?.filter(Boolean) || undefined,
        product: this.filters.product?.filter(Boolean) || undefined,
        startDate: this.filters.startDate || undefined,
        endDate: this.filters.endDate || undefined,
      }
    },
    closeModal() {
      this.showDownloadModal = false
      this.downloadingCSV = false
      document.removeEventListener('keydown', this.handleModalKeydown)
      document.body.classList.remove('modal-open')
    },
    handleModalKeydown(event) {
      if (event.key === 'Escape') {
        this.closeModal()
      }
    },
    onSortChange(key) {
      if (this.sortField === key) {
        this.sortOrder = this.sortOrder === 1 ? -1 : 1
      } else {
        this.sortField = key
        this.sortOrder = -1
      }
      this.currentPage = 1
      this.getTaskList()
    },
    isProductActive(product) {
      if (!this.filters.product || this.filters.product.length === 0) {
        return product === this.productOptions[0]
      }
      return this.filters.product.includes(product)
    },
    async selectProduct(product) {
      const newFilters = {
        ...this.filters,
        product: [product],
      }

      await this.$store.commit('task/updateFilters', newFilters)
      this.currentPage = 1
      await this.getTaskList()
    },
    async initializeDefaultProduct() {
      if (!this.filters.product || this.filters.product.length === 0) {
        const firstProduct = this.productOptions[0]
        await this.$store.commit('task/setFilterProduct', [firstProduct])
      }
      this.currentPage = 1
      await this.getTaskList()
    },
    async onItemsPerPageChange(newValue) {
      this.itemsPerPage = newValue
      this.currentPage = 1
      await this.getTaskList()
    },
    async onPageChange(page) {
      this.currentPage = page
      await this.getTaskList()
    },
    async getTaskList() {
      await this.$store.dispatch('task/getTaskList', {
        skip: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        filters: this.getFilters(),
      })
    },
  },
  beforeDestroy() {
    if (this.debouncedIdentifierSearch) {
      this.debouncedIdentifierSearch.cancel()
    }
    if (this.showDownloadModal) {
      document.removeEventListener('keydown', this.handleModalKeydown)
      document.body.classList.remove('modal-open')
    }
  },
}
</script>

<style scoped lang="scss">
.table-responsive {
  margin-bottom: 0;
}

.table {
  &.card-table {
    th {
      font-weight: bold;
    }
    td {
      vertical-align: middle;
    }
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
  }

  .modal-content {
    &:focus {
      outline: none;
    }
  }

  .btn-close:focus,
  .btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

.modal-backdrop {
  opacity: 0.5;
}

.modal-backdrop-enter-active,
.modal-backdrop-leave-active {
  transition: opacity 0.3s ease;
}

.modal-backdrop-enter-from,
.modal-backdrop-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

body.modal-open {
  overflow: hidden;
  padding-right: 17px;
}

.input-group-sm {
  min-width: 170px;

  input[type='date'] {
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }
}

.gap-2 {
  gap: 0.5rem !important;
}

.sortable {
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.table {
  &.card-table {
    th {
      font-weight: bold;
      transition: background-color 0.2s ease;

      svg {
        display: inline-block;
        vertical-align: middle;
        color: #6e84a3;
      }
    }
  }
}

.card-header {
  top: 0;
  z-index: 9;
  position: sticky;
  background: white;
  width: 100%;
  border-bottom: 1px solid #e2ebf6;
}

.nav-tabs {
  border-bottom: 1px solid #e3ebf6;

  .nav-link {
    color: #95aac9;
    border: none;
    padding: 1rem 1.5rem;
    font-weight: 500;

    &:hover {
      border: none;
      color: #12263f;
    }

    &.active {
      color: var(--bs-primary);
      border-bottom: 2px solid var(--bs-primary);
    }
  }

  .nav-item {
    margin: 0;

    &.show .nav-link {
      background-color: transparent;
      border-color: transparent transparent var(--bs-primary);
      color: #12263f;
    }
  }

  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
  }
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #95aac9;
}
.nav-tabs .nav-item {
  margin: 0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent transparent var(--bs-primary);
  color: #12263f;
}
.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.375rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-primary);
  color: #fff;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
</style>
