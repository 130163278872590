import { PRODUCTS, SCOPES } from '@atomicfi/constants-shared'

export const SCOPE_OPTIONS = [
  {
    label: 'UserLink',
    name: SCOPES.USER_LINK,
    useCases: ['Direct Deposit Switch', 'Employee Data', 'Tax Docs'],
    products: [
      {
        title: 'Direct Deposit Switching',
        name: PRODUCTS.DEPOSIT,
        generallyAvailable: true,
      },
      {
        title: 'Payroll Data',
        name: PRODUCTS.VERIFY,
        generallyAvailable: true,
      },
      // @TODO I think these have been hard coded since they are not official? Should investigate.
      {
        title: 'Tax Documents',
        name: 'tax',
        generallyAvailable: false,
      },
    ],
  },
  {
    label: 'PayLink',
    name: SCOPES.PAY_LINK,
    useCases: ['Payment Method Switch', 'Bill Presentment', 'Bill Pay'],
    products: [
      {
        title: 'Payment Method Switching',
        name: PRODUCTS.SWITCH,
        generallyAvailable: true,
      },
      {
        title: 'Bill Presentment',
        name: PRODUCTS.PRESENT,
        generallyAvailable: true,
      },
    ],
  },
]
