<template>
  <div class="wrapper" :class="{ 'with-height': !contentLoaded }">
    <slot v-if="contentLoaded"></slot>
    <div class="overlay" v-if="!contentLoaded || contentLoading">
      <b-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentLoader',
  props: {
    contentLoaded: {
      type: Boolean,
      default: false,
    },
    contentLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;

  &.with-height {
    min-height: 300px;
  }
}

.overlay {
  align-items: center;
  backdrop-filter: blur(4px);
  border-radius: 4px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100; /* ensure all UI elements are obscured, particularly DataPager buttons */
}
</style>
