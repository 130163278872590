<template>
  <div class="dropdown">
    <button
      class="btn btn-white dropdown-toggle"
      type="button"
      id="language-selector"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ selectedLanguage.flag }} {{ selectedLanguage.title }}
      <ChevronDownIcon size="14" />
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="language-selector">
      <button
        class="dropdown-item"
        type="button"
        :value="language.code"
        v-for="language in languages"
        :key="language.code"
        @click="() => selectLanguage(language.code)"
      >
        {{ language.flag }} {{ language.title }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ChevronDownIcon } from 'vue-feather-icons'

export default {
  name: 'LanguageSelectorDropdown',
  components: {
    ChevronDownIcon,
  },
  computed: {
    ...mapState('emulator', ['languages', 'settings']),
    selectedLanguage() {
      return this.languages.find((language) => language.code === this.settings.language)
    },
  },
  methods: {
    ...mapActions('emulator', ['updateLanguage']),
    selectLanguage(language) {
      this.updateLanguage({ analytics: this.$analytics, language })
    },
  },
}
</script>
