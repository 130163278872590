var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeCustomer && _vm.hasChildCustomers
        ? _c(
            "button",
            {
              staticClass: "switcher-toggle",
              attrs: { title: "Switch customers" },
              on: { click: _vm.toggleSwitcher },
            },
            [
              _c("CustomerListItem", {
                attrs: {
                  customer: _vm.activeCustomer,
                  truncateWidth: 20,
                  avatarClass: "avatar-xs",
                  condensed: true,
                  showSwitcher: true,
                },
              }),
            ],
            1
          )
        : _vm.activeCustomer
        ? _c("CustomerListItem", {
            attrs: {
              customer: _vm.activeCustomer,
              truncateWidth: 20,
              avatarClass: "avatar-xs",
              condensed: true,
            },
          })
        : _vm._e(),
      _vm.hasChildCustomers
        ? _c(
            "b-sidebar",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showSwitcher,
                  expression: "showSwitcher",
                },
              ],
              attrs: {
                "bg-variant": "white",
                backdrop: true,
                "no-header": true,
                shadow: "",
              },
              model: {
                value: _vm.showSwitcher,
                callback: function ($$v) {
                  _vm.showSwitcher = $$v
                },
                expression: "showSwitcher",
              },
            },
            [
              _c("div", { staticClass: "offcanvas-body" }, [
                _c("div", { staticClass: "child-customer-search-container" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 input-group input-group-rounded input-group-merge input-group-reverse",
                    },
                    [
                      _c("input", {
                        ref: "searchInput",
                        staticClass:
                          "form-control list-search form-control-rounded",
                        attrs: {
                          disabled: _vm.isLoading,
                          type: "search",
                          placeholder: "Search",
                        },
                        domProps: { value: _vm.searchQuery },
                        on: { input: _vm.filterChildCustomers },
                      }),
                      _c(
                        "span",
                        { staticClass: "input-group-text input-group-rounded" },
                        [
                          _vm.state === "loading-search"
                            ? _c("span", {
                                staticClass:
                                  "spinner-border spinner-border-sm text-muted",
                                attrs: {
                                  "data-test-id": "search-spinner",
                                  role: "status",
                                  "aria-hidden": "true",
                                },
                              })
                            : _vm._e(),
                          _vm.state !== "loading-search"
                            ? _c("SearchIcon", {
                                staticClass: "ms-1 text-muted",
                                attrs: { size: "16" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "list-group-flush mt-n3" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "list-group-item mx-n4 px-4 border-bottom-3",
                        attrs: { href: "#" },
                      },
                      [
                        _c("CustomerListItem", {
                          attrs: {
                            truncateWidth: 18,
                            customer: _vm.customer,
                            showTimestamp: true,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleCustomerClick(_vm.customer)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "child-customer-list-container scroll-shadow",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col list-group list-group-flush list-group-focus list",
                      },
                      _vm._l(
                        _vm.childCustomerSearchResults,
                        function (customer) {
                          return _c(
                            "a",
                            {
                              key: customer._id,
                              staticClass: "list-group-item",
                              attrs: { href: "#" },
                            },
                            [
                              _c("CustomerListItem", {
                                class: { "disable-item": !customer.approved },
                                attrs: {
                                  truncateWidth: 18,
                                  customer: customer,
                                  showTimestamp: true,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleCustomerClick(customer)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "child-customer-pagination-container" },
                  [
                    _vm.childCustomerSearchOptions.totalPages > 1
                      ? _c("ul", { staticClass: "pagination mb-0 mt-4" }, [
                          _c(
                            "li",
                            {
                              staticClass: "page-item w-50",
                              class: { disabled: _vm.isPrevPageButtonDisabled },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "page-link d-flex w-100 h-100 align-items-center justify-content-center",
                                  attrs: {
                                    disabled: _vm.isPrevPageButtonDisabled,
                                  },
                                  on: {
                                    click: function () {
                                      return _vm.changePage(-1)
                                    },
                                  },
                                },
                                [
                                  _vm.state === "loading-prev"
                                    ? _c("div", {
                                        staticClass:
                                          "spinner-border spinner-border-sm text-muted",
                                        attrs: { role: "status" },
                                      })
                                    : _c(
                                        "div",
                                        [
                                          _c("ArrowLeftIcon", {
                                            staticClass: "me-1",
                                            attrs: { size: "16" },
                                          }),
                                          _vm._v(" Prev page "),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "page-item w-50",
                              class: { disabled: _vm.isNextPageButtonDisabled },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "page-link d-flex w-100 h-100 align-items-center justify-content-center",
                                  attrs: {
                                    disabled: _vm.isNextPageButtonDisabled,
                                  },
                                  on: {
                                    click: function () {
                                      return _vm.changePage(1)
                                    },
                                  },
                                },
                                [
                                  _vm.state === "loading-next"
                                    ? _c("div", {
                                        staticClass:
                                          "spinner-border spinner-border-sm text-muted",
                                        attrs: { role: "status" },
                                      })
                                    : _c(
                                        "div",
                                        [
                                          _vm._v(" Next page "),
                                          _c("ArrowRightIcon", {
                                            staticClass: "ms-1",
                                            attrs: { size: "16" },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }