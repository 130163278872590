<template>
  <div :class="{ 'form-group': formGroupWrap }">
    <div class="custom-control custom-switch">
      <input
        :id="id"
        :checked="value"
        @input="onInput"
        type="checkbox"
        class="custom-control-input"
        :disabled="disabled"
      />
      <label class="custom-control-label" :for="id">
        <span :class="{ 'sr-only': !showLabelText }">{{ label }}</span>
      </label>
    </div>
    <small v-if="helperText" class="form-text text-muted">
      {{ helperText }}
    </small>
    <small v-if="$slots.helperText" class="form-text text-muted">
      <slot name="helperText"></slot>
    </small>
  </div>
</template>

<script>
import { uniqueId } from 'lodash-es'

export default {
  name: 'CustomSwitch',
  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    helperText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabelText: {
      type: Boolean,
      default: true,
    },
    formGroupWrap: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: uniqueId('customSwitch-'),
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<style scoped lang="scss">
.custom-switch {
  padding-left: 3.5rem;
}
.custom-switch .custom-control-label::before {
  left: -3.5rem;
  width: 3rem;
  pointer-events: all;
  border-radius: 0.5625rem;
}
.custom-switch .custom-control-label::after {
  top: 0.20313rem;
  left: -3.5rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: #b1c2d9;
  border-radius: 0.5625rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #e3ebf6;
  transform: translateX(2rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 123, 229, 0.5);
}
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
  opacity: 0.6;
}
.custom-switch .custom-control-input:disabled + label:hover {
  cursor: not-allowed;
}
.custom-switch {
  min-height: 1.5rem;
}
.custom-switch .custom-control-label::before {
  top: 0;
  height: 1.5rem;
  border-radius: 0.75rem;
}
.custom-switch .custom-control-label::after {
  top: 0.1875rem;
  left: -3.3125rem;
  background-color: #ffffff;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(1.5rem);
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #00d97e;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00d97e;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #0dff9a;
  background-color: #0dff9a;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.15rem rgba(0, 217, 126, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00d97e;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.20313rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #4b39ef;
  background-color: #4b39ef;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: transparent;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4b39ef;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #cbdef9;
  border-color: #cbdef9;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #95aac9;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer;
}
.custom-control-label::before {
  position: absolute;
  top: 0.20313rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #d8dae5;
  border: #b1c2d9 solid 0;
}
.custom-control-label::after {
  position: absolute;
  top: 0.20313rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}
</style>
