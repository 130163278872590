<template>
  <ModalForm
    :id="id"
    :title="title"
    :onSubmit="submitForm"
    :submitButtonText="title"
    @success="onSuccess"
    @error="onError"
    @hidden="onHide"
  >
    <validation-provider
      name="Endpoint URL"
      rules="required|url"
      v-slot="{ classes, errors, touched }"
    >
      <div class="form-group">
        <label>Endpoint URL</label>
        <input
          type="text"
          class="form-control"
          :class="classes"
          v-model="formValues.url"
          placeholder="https://"
        />
        <div v-if="touched" class="invalid-feedback">{{ errors[0] }}</div>
      </div>
    </validation-provider>

    <validation-provider
      name="Events"
      :rules="{ required: { message: 'Please choose at least one event.' } }"
      v-slot="{ classes, errors }"
    >
      <div class="form-group">
        <label for="events">Select events to listen to</label>
        <v-select
          v-model="formValues.eventTypes"
          multiple
          :options="availableWebhookEventTypes"
          :close-on-select="false"
          :class="classes"
        >
          <template #search="{ attributes, events }">
            <input
              id="events"
              class="vs__search"
              :required="!formValues.eventTypes.length"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
        <div class="invalid-feedback">{{ errors[0] }}</div>
      </div>
    </validation-provider>

    <validation-provider name="Secret ID" rules="required" v-slot="{ classes }">
      <label>Secret</label>
      <v-select
        v-model="formValues.secretId"
        :options="credentials.secrets"
        :getOptionLabel="({ name }) => name || 'Default'"
        :getOptionKey="({ _id }) => _id"
        :reduce="({ _id }) => _id"
        :class="classes"
        :clearable="false"
      />
      <small class="form-text text-muted">
        The secret will be used to sign the webhook payload.
      </small>
    </validation-provider>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/MainContent/ModalForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SettingsWebhooksEndpointModalForm',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    initialFormValues: {
      type: Object,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formValues: {
        ...this.initialFormValues,
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters('customer', ['availableWebhookEventTypes', 'credentials']),
  },
  components: {
    ModalForm,
  },
  methods: {
    onSuccess() {
      this.$emit('success')
    },
    onError() {
      this.$emit('error')
    },
    onHide() {
      this.resetState()
    },
    resetState() {
      this.formValues = {
        ...this.initialFormValues,
      }
    },
    async submitForm() {
      await this.onSubmit(this.formValues)
    },
  },
}
</script>
