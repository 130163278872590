var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EmulatorMutationPreview", {
    attrs: { mutation: _vm.mutation },
    scopedSlots: _vm._u([
      {
        key: "before",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex w-50 justify-content-end" }, [
              _c("span", { staticClass: "previous-value text-secondary" }, [
                _vm._v(_vm._s(_vm.mutation.valueBeforeChanges)),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "after",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex w-50 justify-content-start" }, [
              _c("span", { staticClass: "text-primary" }, [
                _vm._v(_vm._s(_vm.mutation.value)),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }