export const phrases = {
  addendums: {
    remainderDescription:
      'The remainder account will receive whatever is left of the paycheck after all other distributions have been funded.',
    mustBe100Percent: 'Must be a 100% distribution to succeed.',
    mustAddTo100Percent: 'Must add up to 100% to succeed.',
    checkRemainder: 'When this is allowed, any remainder will be issued via check.',
  },
  prompts: {
    howManyAccountsAreOnFileQ: 'How many accounts are on file already?',
    whatKindOfAcctOnFileQ: 'What type(s) of account(s) are currently on file?',
  },
}
