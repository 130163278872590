var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { "with-height": !_vm.contentLoaded } },
    [
      _vm.contentLoaded ? _vm._t("default") : _vm._e(),
      !_vm.contentLoaded || _vm.contentLoading
        ? _c(
            "div",
            { staticClass: "overlay" },
            [_c("b-spinner", { attrs: { variant: "primary", large: "" } })],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }