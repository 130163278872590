var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "card-body", class: { "fade-in-out": !_vm.isDataReady } },
      [
        _c(
          "div",
          { staticClass: "chart chart-appended" },
          [
            _c("ChartWrapper", {
              attrs: {
                chartType: _vm.CHART_TYPES.DOUGHNUT,
                chartData: _vm.chartData,
                useDummyData: _vm.isLoading || _vm.sampleDataMode,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-legend", attrs: { id: "trafficChartLegend" } },
          [
            _vm.isDataReady &&
            !_vm.sampleDataMode &&
            _vm.chartData.labels.length
              ? _c(
                  "div",
                  _vm._l(_vm.chartData.labels, function (item, index) {
                    return _c(
                      "span",
                      { key: index, staticClass: "chart-legend-item" },
                      [
                        _c("span", {
                          staticClass: "chart-legend-indicator",
                          style: "background-color: " + _vm.chartColors[index],
                        }),
                        _vm._v(" " + _vm._s(item) + " "),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  _vm._l(_vm.dummyLabels, function (label, index) {
                    return _c(
                      "span",
                      { key: label, staticClass: "chart-legend-item" },
                      [
                        _c("span", {
                          staticClass: "chart-legend-indicator",
                          style: "background-color: " + _vm.chartColors[index],
                        }),
                        _vm._v(" " + _vm._s(label) + " "),
                      ]
                    )
                  }),
                  0
                ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-header-title" }, [
        _vm._v("Top Failure Reasons"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }