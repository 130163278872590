<template>
  <HasPermissionTable
    :label="permission"
    :rowData="roleValues"
    :hasPermissionFunction="permissionBelongsToUserRole"
  />
</template>

<script>
import HasPermissionTable from '@/components/Modules/Settings/RolePermissions/HasPermissionTable'
import { roles } from '@/lib/authorization'

export default {
  name: 'PermissionRolesTable',
  components: {
    HasPermissionTable,
  },
  props: {
    permission: {
      type: String,
      required: true,
    },
  },
  computed: {
    roleValues() {
      return roles.map((role) => role.value)
    },
  },
  methods: {
    permissionBelongsToUserRole(environment, roleValue) {
      return roles
        .find((role) => role.value === roleValue)
        .permissions[environment].includes(this.permission)
    },
  },
}
</script>
