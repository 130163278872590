var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.uploading
      ? _c("div", { staticClass: "dz-message" }, [_vm._m(0)])
      : _c("div", [_vm._m(1)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spinner-grow", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("form", { staticClass: "dropzone dropzone-multiple" }, [
      _c(
        "div",
        { staticStyle: { display: "none" }, attrs: { id: "dropzone-message" } },
        [
          _c("span", { staticClass: "dropzone-title" }, [
            _vm._v("Drop files here or click to select"),
          ]),
        ]
      ),
      _c("div", { staticClass: "dz-preview" }, [
        _c("div", { staticClass: "dz-preview-inner" }, [_c("div")]),
      ]),
      _c("div", { staticClass: "fallback" }, [
        _c("div", { staticClass: "custom-file" }, [
          _c("input", {
            staticClass: "custom-file-input",
            attrs: {
              type: "file",
              id: "customFileUploadMultiple",
              multiple: "",
            },
          }),
          _c(
            "label",
            {
              staticClass: "custom-file-label",
              attrs: { for: "customFileUploadMultiple" },
            },
            [_vm._v("Choose file")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }