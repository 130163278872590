var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SettingsWebhooksEndpointModalForm", {
    attrs: {
      id: "add-endpoint-modal",
      title: "Add endpoint",
      onSubmit: _vm.addNewEndpoint,
      initialFormValues: _vm.newEndpoint,
    },
    on: { success: _vm.onSuccess, error: _vm.onError },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }