<template>
  <div class="log-wrapper" @click="toggleShowDetails">
    <div class="row">
      <div class="col">
        <span class="badge bg-success-soft" v-if="event.category === 'finish'">finish</span>
        <span class="badge bg-warning-soft" v-else-if="event.category === 'close'">close</span>
        <span class="badge bg-primary-soft" v-else-if="event.category === 'interaction'"
          >interaction</span
        >
        <span class="badge bg-warning-soft" v-else-if="event.category === 'data-request'"
          >data request</span
        >
        <br />
        <small class="text-secondary" v-if="event.payload && event.payload.name">{{
          event.payload.name
        }}</small>
      </div>
    </div>
    <div v-if="showDetails && event.payload">
      <div class="alert alert-light mt-3 mb-0" role="alert">
        <pre>{{ event.payload }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmulatorEventEntry',
  props: ['event'],
  data: () => ({
    showDetails: false,
  }),
  methods: {
    toggleShowDetails() {
      this.showDetails = !this.showDetails
    },
  },
}
</script>

<style scoped>
.log-wrapper {
  cursor: pointer;
}
</style>
