var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("h4", { staticClass: "card-header-title" }, [_vm._v("Security")]),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Signature verification")]),
        _c("p", { staticClass: "mb-5" }, [
          _vm._v(
            " To validate a webhook request came from Atomic, we suggest verifying the payloads as stated in the "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://docs.atomicfi.com/guides/webhooks/how-to-secure-webhook-endpoints",
                target: "_blank",
              },
            },
            [_vm._v("documentation")]
          ),
          _vm._v(". "),
        ]),
        _c("h4", [_vm._v("IP Allowlisting")]),
        _c("p", [
          _vm._v(
            " You may additionally only allow traffic into your webhook endpoints from the following IP addresses: "
          ),
        ]),
        _c("div", { staticClass: "row mb-6" }, [
          _c("div", { staticClass: "col-12 col-lg-6 mb-3" }, [
            _c("h5", [_vm._v("Production")]),
            _c("pre", [_vm._v("34.224.55.47\n54.160.71.206\n")]),
          ]),
          _c("div", { staticClass: "col-12 col-lg-6 mb-3" }, [
            _c("h5", [_vm._v("Sandbox")]),
            _c("pre", { staticClass: "mb-0" }, [
              _vm._v("34.203.158.15\n52.205.207.85\n"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }