var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: "Click to edit",
          expression: "'Click to edit'",
        },
      ],
      staticClass:
        "card-body review-item d-flex align-items-center justify-content-center",
      on: { click: _vm.editPendingMutation },
    },
    [
      _vm._t("before"),
      _c("ArrowRightIcon", {
        staticClass: "mx-3 flex-shrink-0",
        class: _vm.arrowClass,
        attrs: { size: "16" },
      }),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }