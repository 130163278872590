var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "funnel-settings" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-light",
          attrs: { title: "Explore" },
          on: { click: _vm.toggleSettings },
        },
        [_vm._v("Funnel Builder")]
      ),
      _c(
        "b-sidebar",
        {
          directives: [
            {
              name: "scroll-lock",
              rawName: "v-scroll-lock",
              value: _vm.showSettings,
              expression: "showSettings",
            },
          ],
          attrs: {
            "bg-variant": "dark",
            backdrop: true,
            "no-header": true,
            width: "35em",
            shadow: "lg",
            right: "",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function (ref) {
                var hide = ref.hide
                return [
                  _c("div", { staticClass: "p-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-lg w-100 btn-primary mt-auto",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            return _vm.applySettings(hide)
                          },
                        },
                      },
                      [_vm._v(" Apply Settings ")]
                    ),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showSettings,
            callback: function ($$v) {
              _vm.showSettings = $$v
            },
            expression: "showSettings",
          },
        },
        [
          _c("div", { staticClass: "offcanvas-body" }, [
            _c("div", { staticClass: "mb-4" }, [
              _c("h1", { staticClass: "header-title" }, [
                _vm._v("Funnel Builder"),
              ]),
              _c("p", { staticClass: "header-subtitle" }, [
                _vm._v(
                  " Construct your own custom funnel to learn more about how your users behave within Atomic's SDK. To learn more about which events you'd like to use, see our "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-white",
                    attrs: {
                      target: "_blank",
                      href: "https://docs.atomicfi.com/reference/transact-sdk#event-listeners__interaction-events",
                    },
                  },
                  [_vm._v("interaction event documentation")]
                ),
                _vm._v(". "),
              ]),
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("h4", { staticClass: "card-header-title" }, [
                  _vm._v("Build a funnel"),
                ]),
                _c(
                  "a",
                  { attrs: { href: "#" }, on: { click: _vm.addEmptyStep } },
                  [
                    _c("PlusIcon", {
                      staticClass: "text-white",
                      attrs: { size: "15" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "list-group list-group-flush steps-list",
                      attrs: { tag: "ul" },
                      on: { change: _vm.eventOrderChanged },
                      model: {
                        value: _vm.events,
                        callback: function ($$v) {
                          _vm.events = $$v
                        },
                        expression: "events",
                      },
                    },
                    _vm._l(_vm.events, function (event, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "list-group-item" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-auto move-icon mt-2" },
                              [
                                _c("MenuIcon", {
                                  staticClass: "text-muted",
                                  attrs: { size: "10" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-auto badge-container mt-2" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge bg-primary-soft merge-into-header",
                                  },
                                  [_vm._v(_vm._s(index + 1))]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col event-name" }, [
                              _c(
                                "div",
                                { staticClass: "event-select" },
                                [
                                  _c("v-select", {
                                    staticClass: "vs__dark",
                                    attrs: {
                                      value: _vm.getFriendlyEventName(
                                        _vm.events[index]
                                      ),
                                      options: _vm.lexicon,
                                      clearable: false,
                                    },
                                    on: {
                                      "option:selected": _vm.eventNameChanged,
                                    },
                                    model: {
                                      value: _vm.events[index],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.events, index, $$v)
                                      },
                                      expression: "events[index]",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "actions btn-group" },
                                    [
                                      _vm.getEventFilters(event).length && event
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-outline-dark",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addBlankFilter(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("FilterIcon", {
                                                staticClass: "text-muted",
                                                attrs: { size: "14" },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-dark",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeEvent(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("XIcon", {
                                            staticClass: "text-muted",
                                            attrs: { size: "14" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.filters[index]
                                ? _c(
                                    "div",
                                    { staticClass: "filters list-group" },
                                    _vm._l(
                                      _vm.filters[index],
                                      function (filter, filterIndex) {
                                        return _c(
                                          "li",
                                          {
                                            key: filterIndex,
                                            staticClass: "list-group-item",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "actions btn-group",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-outline-dark",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFilter(
                                                          index,
                                                          filterIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("XIcon", {
                                                      staticClass: "text-muted",
                                                      attrs: { size: "14" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row ms-0 me-0 mb-0",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-auto ps-0 pe-0 d-flex align-items-center justify-content-center",
                                                  },
                                                  [
                                                    _c("FilterIcon", {
                                                      staticClass: "text-muted",
                                                      attrs: { size: "14" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col pe-0" },
                                                  [
                                                    _c("v-select", {
                                                      ref:
                                                        index +
                                                        "-" +
                                                        filterIndex,
                                                      refInFor: true,
                                                      staticClass:
                                                        "vs__dark vs__transparent vs__small",
                                                      attrs: {
                                                        options:
                                                          _vm.getEventFilters(
                                                            event
                                                          ),
                                                        clearable: false,
                                                        "dropdown-should-open":
                                                          _vm.filters[index][
                                                            filterIndex
                                                          ].property
                                                            ? undefined
                                                            : _vm.filterDropdownShouldOpen(
                                                                index,
                                                                filterIndex
                                                              ),
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectedFilter(
                                                            index,
                                                            filterIndex
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.filters[index][
                                                            filterIndex
                                                          ].property,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.filters[index][
                                                              filterIndex
                                                            ],
                                                            "property",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "filters[index][filterIndex].property",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm.filters[index][filterIndex]
                                                  .property
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row ms-0 me-0 mb-0 pe-0",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass: "col-1",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-1 text-secondary d-flex align-items-center",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _c("small", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    filter.operator
                                                                  )
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-10 pe-0",
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              ref:
                                                                index +
                                                                "-" +
                                                                filterIndex +
                                                                "-value",
                                                              refInFor: true,
                                                              staticClass:
                                                                "vs__dark vs__small",
                                                              attrs: {
                                                                options:
                                                                  _vm.getEventFilterOptions(
                                                                    _vm.filters[
                                                                      index
                                                                    ][
                                                                      filterIndex
                                                                    ].property
                                                                  ),
                                                                "dropdown-should-open":
                                                                  _vm.filters[
                                                                    index
                                                                  ][filterIndex]
                                                                    .value
                                                                    ? undefined
                                                                    : _vm.filterOptionDropdownShouldOpen(
                                                                        index,
                                                                        filterIndex
                                                                      ),
                                                                clearable: false,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectedFilterValue(
                                                                      index,
                                                                      filterIndex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.filters[
                                                                    index
                                                                  ][filterIndex]
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .filters[
                                                                        index
                                                                      ][
                                                                        filterIndex
                                                                      ],
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "filters[index][filterIndex].value",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("h4", { staticClass: "card-header-title" }, [
                  _vm._v("Suggested funnels"),
                ]),
              ]),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "list-group list-group-flush my-n3" },
                  _vm._l(_vm.suggestions, function (suggestion) {
                    return _c(
                      "div",
                      {
                        key: suggestion.title,
                        staticClass: "list-group-item",
                        on: {
                          click: function ($event) {
                            return _vm.setActiveSuggestion(suggestion)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-auto" }, [
                            _c(
                              "a",
                              { staticClass: "avatar", attrs: { href: "#" } },
                              [
                                _c("img", {
                                  staticClass: "avatar-img rounded",
                                  attrs: { src: suggestion.image, alt: "..." },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col ms-n2" }, [
                            _c("h4", { staticClass: "mb-1" }, [
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v(_vm._s(suggestion.title)),
                              ]),
                            ]),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                " " + _vm._s(suggestion.description) + " "
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-auto" },
                            [
                              _c("CheckIcon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.activeSuggestion === suggestion.title,
                                    expression:
                                      "activeSuggestion === suggestion.title",
                                  },
                                ],
                                staticClass: "h2 text-success mb-0",
                                attrs: { size: "20" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }