export function defaultLineOptions() {
  return {
    gridLines: {
      color: '#000',
      borderColor: '#000',
    },
    layout: {
      padding: {
        top: 1.5, // half border width
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: '#283E59',
          },
          ticks: {
            callback: function (value) {
              return value + 'k'
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || ''
          var yLabel = item.yLabel
          var content = ''

          if (data.datasets.length > 1) {
            content += '<span class="popover-body-label me-auto">' + label + '</span>'
          }

          content += '<span class="popover-body-value">' + yLabel + '</span>'
          return content
        },
      },
    },
  }
}

export function defaultLineDummyData() {
  return {
    labels: [
      'Oct 1',
      'Oct 3',
      'Oct 6',
      'Oct 9',
      'Oct 12',
      'Oct 5',
      'Oct 18',
      'Oct 21',
      'Oct 24',
      'Oct 27',
      'Oct 30',
    ],
    datasets: [
      {
        // label: 'Completed',
        data: [0, 10, 5, 15, 10, 20, 15, 25, 20, 30, 25],
      },
      {
        // label: 'Failed ',
        data: [2, 12, 35, 25, 36, 25, 34, 16, 4, 14, 15],
        hidden: true,
      },
    ],
  }
}

export function defaultSparkOptions() {
  return {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      mode: 'index',
      intersect: false,
      custom: function () {},
    },
    scales: {
      yAxes: [
        {
          display: false,
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        hoverRadius: 0,
        radius: 0,
      },
    },
    tooltips: {
      enabled: false,
      mode: 'index',
      intersect: false,
      custom: function (model) {},
      callbacks: {},
    },
  }
}

export function defaultSparkDummyData() {
  return {
    labels: new Array(12).fill('Label'),
    datasets: [
      {
        data: [0, 15, 10, 25, 30, 15, 40, 50, 80, 60, 55, 65],
      },
    ],
  }
}

export function defaultBarOptions() {
  return {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value
          },
        },
      },
    },
  }
}

export function defaultBarDummyData() {
  return {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Deposit',
        data: [25, 20, 30, 22, 17, 10, 18, 26, 28, 26, 20, 32],
      },
      {
        label: 'Verify',
        data: [15, 10, 20, 12, 7, 0, 8, 16, 18, 16, 10, 22],
        backgroundColor: '#d2ddec',
      },
    ],
  }
}

export function defaultDoughnutOptions() {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          afterLabel: function () {
            return '%'
          },
        },
      },
    },
  }
}

export function defaultDoughnutDummyData() {
  return {
    labels: ['Bad Credentials', 'Not Supported', 'Account Lockout'],
    datasets: [
      {
        data: [60, 25, 15],
        backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC'],
      },
      {
        data: [15, 45, 20],
        backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC'],
        hidden: true,
      },
    ],
  }
}
