<template>
  <SettingsWebhooksEndpointModalForm
    id="add-endpoint-modal"
    title="Add endpoint"
    :onSubmit="addNewEndpoint"
    @success="onSuccess"
    @error="onError"
    :initialFormValues="newEndpoint"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { WEBHOOK_EVENT_TYPES } from '@atomicfi/webhook-shared'
import SettingsWebhooksEndpointModalForm from '@/components/Modules/Settings/SettingsWebhooksEndpointModalForm.vue'

const getNewEndpoint = () => ({
  url: '',
  eventTypes: [WEBHOOK_EVENT_TYPES.TASK_STATUS_UPDATED],
})

export default {
  name: 'SettingsWebhooksAddEndpointModal',
  data: () => ({
    newEndpoint: getNewEndpoint(),
  }),
  components: {
    SettingsWebhooksEndpointModalForm,
  },
  methods: {
    ...mapActions('customer', ['updateCustomer']),
    async addNewEndpoint(payload) {
      await this.updateCustomer({ payload, serviceFn: 'addWebhookEndpoint' })

      this.$analytics.track({
        event: 'Add Webhook Endpoint',
      })
    },
    onSuccess() {
      this.$toasted.success('Endpoint successfully added')
    },
    onError() {
      this.$toasted.error('Error adding endpoint')
    },
  },
}
</script>
