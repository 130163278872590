<template>
  <span class="badge text-capitalize" :class="[backgroundClass, isFailed ? 'failed' : undefined]">
    {{ realtimeStatus }}
  </span>
</template>

<script>
import { statuses } from '@/lib/task-status'

export default {
  name: 'TaskStatus',
  props: ['task'],
  data: () => ({
    statuses,
    status: undefined,
  }),
  computed: {
    backgroundClass() {
      return `bg-${this.statuses[this.realtimeStatus].class}-soft`
    },
    isFailed() {
      return this.realtimeStatus === 'failed'
    },
    realtimeStatus() {
      return this.status || this.task.externalStatus
    },
  },
}
</script>

<style scoped>
.failed {
  border: 1px dashed var(--bs-danger);
  cursor: help;
}
</style>
