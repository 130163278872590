var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "glassmorphic card" }, [
        _c("div", { staticClass: "card-body" }, [
          !_vm.isDataReady
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-nowrap justify-content-center align-middle",
                },
                _vm._l(this.maximumItems, function (index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "connection justify-content-center align-middle",
                    },
                    [
                      _c("VueSkeletonLoader", {
                        attrs: {
                          type: "rect",
                          width: 64,
                          height: 64,
                          color: "rgba(227,236,248,0.6)",
                          rounded: true,
                          animation: "fade",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "text-center mt-2 text-secondary" },
                        [
                          _c("VueSkeletonLoader", {
                            staticClass: "d-inline-block",
                            attrs: {
                              type: "rect",
                              width: 32,
                              height: 24,
                              color: "rgba(227,236,248,0.3)",
                              rounded: true,
                              animation: "fade",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-nowrap justify-content-center align-middle",
                },
                _vm._l(_vm.resizedTopConnections, function (connection) {
                  return _c(
                    "div",
                    {
                      key: connection.name,
                      staticClass:
                        "connection justify-content-center align-middle",
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            mouseover: function ($event) {
                              return _vm.hoverTopConnection(connection.name)
                            },
                          },
                        },
                        [
                          _c("Logo", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: connection.name,
                                expression: "connection.name",
                              },
                            ],
                            attrs: {
                              branding: connection.cache.branding,
                              name: connection.name,
                              size: "lg",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "text-center mt-2 text-secondary" },
                        [_vm._v(" " + _vm._s(connection.count) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }