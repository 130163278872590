<template>
  <li class="nav-item" tabindex="0" v-tooltip="tooltip" :class="{ 'not-allowed': isDisabled }">
    <button
      @click="() => onClick(name)"
      :class="{ active: activeNavItem === name }"
      class="nav-link btn btn-link"
      :disabled="isDisabled"
    >
      <component :is="icon" size="18" class="me-3 mt-1" /> {{ formattedName }}
    </button>
  </li>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { startCase } from 'lodash-es'

export default {
  name: 'EmulatorNavigationItem',
  props: ['name', 'icon', 'requiresInitialization'],
  computed: {
    ...mapState('emulator', ['activeNavItem']),
    ...mapGetters('emulator', ['isInitialized']),
    formattedName() {
      return startCase(this.name)
    },
    isDisabled() {
      return this.requiresInitialization && !this.isInitialized
    },
    tooltip() {
      return this.isDisabled
        ? 'You must initialize Transact before going to this screen.'
        : undefined
    },
  },
  methods: {
    ...mapMutations('emulator', ['setActiveNavItem']),
    onClick(name) {
      this.setActiveNavItem(name)

      this.$analytics.track({
        event: 'Click Emulator Navigation Item',
        payload: {
          name,
        },
      })
    },
  },
}
</script>

<style scoped>
.not-allowed {
  cursor: not-allowed;
}
</style>
