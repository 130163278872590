import { mapMutations, mapState } from 'vuex'
import { EMULATOR_DATA_TYPES, EMULATOR_EVENT_TYPES } from '@/utils/constants'
import { emitEmulatorEvent, parseWindowEvent } from '@/utils/emulator'
import { debounce, get } from 'lodash-es'

export default {
  props: {
    category: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
      validator: (value) => Object.values(EMULATOR_DATA_TYPES).includes(value),
    },
    getDefaultValueEventType: {
      type: String,
      default: EMULATOR_EVENT_TYPES.GET_DATA_FROM_VUEX,
    },
    label: {
      type: String,
      required: true,
    },
    namespace: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    listenerFunction: undefined,
    defaultValue: '',
    mutatedValue: '',
    listItems: [],
  }),
  computed: {
    ...mapState('customer', ['activeCustomer']),
    ...mapState('emulator', ['settings', 'activeNavItem', 'activeTabIndex', 'pendingMutations']),
    customerValuePath() {
      return `${this.namespace}.${this.path}`
    },
    pathToVuexData() {
      return 'defaults.' + this.path
    },
    existingCustomerValue() {
      return get(this.activeCustomer, `transactConfiguration.${this.customerValuePath}`)
    },
    id() {
      return this.customerValuePath
    },
    valueBeforeChanges() {
      return this.existingCustomerValue ?? this.defaultValue
    },
    value() {
      return this.mutatedValue || this.defaultValue
    },
  },
  methods: {
    ...mapMutations('emulator', ['addOrUpdateMutation']),
    resetToDefaultValue() {
      this.mutatedValue = ''
      this.emitMutationEvents({ value: this.defaultValue, action: 'reset' })

      this.$analytics.track({
        event: 'Reset Emulator Customization',
        payload: {
          id: this.id,
          namespace: this.namespace,
          path: this.path,
        },
      })
    },
    emitMutationEvents({ value, action }) {
      const payload = {
        namespace: this.namespace,
        path: this.path,
        value,
      }

      const pathsToExcludeFromLiveUpdate = ['customCSS']

      if (!pathsToExcludeFromLiveUpdate.includes(this.path)) {
        emitEmulatorEvent({
          type: EMULATOR_EVENT_TYPES.UPDATE_CUSTOMIZATION,
          payload,
        })
      }

      const pendingMutationPayload = {
        ...payload,
        action,
        category: this.category,
        dataType: this.dataType,
        id: this.id,
        label: this.label,
        language: this.settings.language,
        navItem: this.activeNavItem,
        tabIndex: this.activeTabIndex,
        valueBeforeChanges: this.valueBeforeChanges,
      }

      this.addOrUpdateMutation(pendingMutationPayload)
      this.trackEmulatorCustomization(value)
    },
    trackEmulatorCustomization: debounce(function (value) {
      this.$analytics.track({
        event: 'Customize Emulator Value',
        payload: {
          id: this.id,
          namespace: this.namespace,
          path: this.path,
          value,
        },
      })
    }, 500),
    setMutatedValue() {
      const pendingMutation = this.pendingMutations.find(({ id }) => id === this.id)

      switch (pendingMutation?.action) {
        case 'reset':
          this.mutatedValue = ''
          break
        case 'update':
          this.mutatedValue = pendingMutation.value
          break
        // this will happen if there is no pending mutation
        default:
          this.mutatedValue = this.existingCustomerValue ?? ''
      }
    },
    getDefaultValue() {
      emitEmulatorEvent({
        type: this.getDefaultValueEventType,
        payload: {
          pathToVuexData: this.pathToVuexData,
          namespace: this.namespace,
        },
      })
    },
  },
  mounted() {
    let ctx = this

    this.setMutatedValue()

    // Create a reference to the function to later remove it
    this.listenerFunction = function (event) {
      const data = parseWindowEvent(event, { namespace: ctx.namespace, path: ctx.pathToVuexData })

      if (data) {
        ctx.defaultValue = data
      }
    }

    window.addEventListener('message', this.listenerFunction)
    this.getDefaultValue()
  },
  destroyed() {
    // Removing the function eliminates iframe messaging noise
    window.removeEventListener('message', this.listenerFunction)
  },
  watch: {
    'settings.language': function () {
      this.setMutatedValue()
      this.getDefaultValue()
    },
  },
}
