var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EmulatorSectionHeader", { attrs: { title: "Review Changes" } }),
      _vm._l(_vm.pendingMutations, function (mutation) {
        return _c(
          "div",
          { key: mutation.id, staticClass: "card" },
          [
            _c("div", { staticClass: "card-header pe-1 me-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("h6", { staticClass: "header-pretitle mb-2" }, [
                  _vm._v(_vm._s(mutation.category)),
                ]),
                _c("h4", { staticClass: "card-header-title me-auto" }, [
                  _vm._v(_vm._s(mutation.label)),
                ]),
              ]),
              _c("div", { staticClass: "col-auto pe-0 me-0" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Remove",
                        expression: "'Remove'",
                      },
                    ],
                    staticClass: "btn btn-link btn-sm",
                    on: {
                      click: function ($event) {
                        return _vm.removeMutationHandler(mutation)
                      },
                    },
                  },
                  [_c("XIcon", { attrs: { size: "14" } })],
                  1
                ),
              ]),
            ]),
            mutation.dataType === _vm.EMULATOR_DATA_TYPES.COLOR
              ? _c("EmulatorColorMutationPreview", {
                  attrs: { mutation: mutation },
                })
              : mutation.dataType === _vm.EMULATOR_DATA_TYPES.IMAGE
              ? _c("EmulatorImageMutationPreview", {
                  attrs: { mutation: mutation },
                })
              : _c("EmulatorStringMutationPreview", {
                  attrs: { mutation: mutation },
                }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }