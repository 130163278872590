var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Alert", { staticClass: "mb-3", attrs: { type: "info" } }, [
        _vm._v(" This page is for viewing roles and permissions only. "),
        _vm.showEditRoleMessage
          ? _c(
              "span",
              [
                _vm._v(" To edit user roles, go to the "),
                _c(
                  "router-link",
                  {
                    staticClass: "alert-link",
                    attrs: { to: { name: "settings-team" } },
                  },
                  [_vm._v("Team page")]
                ),
                _vm._v(". "),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "Card",
        [
          _c("UserRoleSelector", {
            attrs: {
              options: _vm.roles,
              isCollapsible: false,
              needsValidation: false,
            },
          }),
        ],
        1
      ),
      _c(
        "Card",
        [
          _c("label", { attrs: { for: "permissionSelector" } }, [
            _vm._v("Permission"),
          ]),
          _c("v-select", {
            attrs: {
              options: _vm.availablePermissions,
              getOptionLabel: _vm.getPermissionLabel,
              id: "permissionSelector",
            },
            model: {
              value: _vm.selectedPermission,
              callback: function ($$v) {
                _vm.selectedPermission = $$v
              },
              expression: "selectedPermission",
            },
          }),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _vm.selectedPermission
                ? _c("PermissionRolesTable", {
                    attrs: { permission: _vm.selectedPermission },
                  })
                : _c("p", [_vm._v("Please select a permission.")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }