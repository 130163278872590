<template>
  <EmulatorMutationPreview :mutation="mutation">
    <template #before>
      <EmulatorImagePreview
        :value="mutation.valueBeforeChanges"
        :label="`${mutation.label} icon before`"
      />
    </template>
    <template #after>
      <EmulatorImagePreview :value="mutation.value" :label="`${mutation.label} icon after`" />
    </template>
  </EmulatorMutationPreview>
</template>

<script>
import EmulatorImagePreview from '@/components/Modules/Emulator/EmulatorImagePreview'
import EmulatorMutationPreview from '@/components/Modules/Emulator/EmulatorMutationPreview'

export default {
  name: 'EmulatorImageMutationPreview',
  components: {
    EmulatorImagePreview,
    EmulatorMutationPreview,
  },
  props: {
    mutation: {
      type: Object,
      required: true,
    },
  },
}
</script>
