var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "selectorSearch" } }, [_vm._v("Role")]),
          _c("validation-provider", {
            attrs: { name: "Roles", rules: _vm.validationRules },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var classes = ref.classes
                  var errors = ref.errors
                  return [
                    _c("v-select", {
                      class: classes,
                      attrs: { options: _vm.options },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "search",
                            fn: function (ref) {
                              var attributes = ref.attributes
                              var events = ref.events
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        ref: "userInput",
                                        staticClass: "vs__search",
                                        attrs: {
                                          id: "selectorSearch",
                                          required: !_vm.newSelection,
                                        },
                                      },
                                      "input",
                                      attributes,
                                      false
                                    ),
                                    events
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.newSelection,
                        callback: function ($$v) {
                          _vm.newSelection = $$v
                        },
                        expression: "newSelection",
                      },
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.isCollapsible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: "newSelectionDetails",
                  expression: "'newSelectionDetails'",
                },
              ],
            },
            [
              _c(
                "p",
                { staticClass: "card-header-title" },
                [
                  _c("ChevronRightIcon", { staticClass: "toggle-icon" }),
                  _c("span", { staticClass: "toggle-title" }, [
                    _vm._v("Role Permissions"),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.isCollapsible
        ? _c(
            "b-collapse",
            { staticClass: "mt-4", attrs: { id: "newSelectionDetails" } },
            [
              _vm.newSelectionDetails
                ? _c("RolePermissionsTable", {
                    attrs: { userRole: _vm.newSelectionDetails },
                  })
                : _c("p", [_vm._v("Please select a role.")]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "mt-4" },
            [
              _vm.newSelectionDetails
                ? _c("RolePermissionsTable", {
                    attrs: { userRole: _vm.newSelectionDetails },
                  })
                : _c("p", [_vm._v("Please select a role.")]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }