<template>
  <div>
    <div
      v-if="sampleDataMode"
      class="alert alert-primary mb-0 rounded-0 text-center sample-data-notice"
      role="alert"
    >
      {{ noDataText }}
    </div>
    <div class="header bg-dark pb-5">
      <div class="ms-4 me-4 mb-3">
        <div class="header-body">
          <div class="row align-items-end">
            <div class="col">
              <h5 class="header-pretitle">
                <span class="badge bg-primary-soft merge-into-header">Beta</span>
              </h5>
              <h1 class="header-title text-white">
                Activity
                <DateRangeBar />
              </h1>
            </div>
            <div class="col-auto"><FunnelSettings /></div>
          </div>
        </div>
      </div>
      <Funnel class="mt-0 mb-7" />
    </div>
    <div class="mt-n6 container straddle-fold">
      <h4 class="text-secondary mb-4">Top Connections</h4>
      <TopConnections />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 col-xl">
          <AuthenticationRate />
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <MonitoredAccounts />
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <CompletedTasks />
        </div>

        <div class="col-12 col-lg-6 col-xl">
          <UniqueUsers />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-8">
          <TransactionVolume />
        </div>
        <div class="col-12 col-xl-4">
          <FailureReasons />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import FunnelSettings from '@/components/Modules/Activity/Elements/FunnelSettings'
import DateRangeBar from '@/components/Modules/Activity/Elements/DateRangeBar'
import FailureReasons from '@/components/Modules/Activity/Conversion/FailureReasons'
import Funnel from '@/components/Modules/Activity/Conversion/Funnel'
import AuthenticationRate from '@/components/Modules/Activity/Conversion/AuthenticationRate'
import CompletedTasks from '@/components/Modules/Activity/Conversion/CompletedTasks'
import MonitoredAccounts from '@/components/Modules/Activity/Conversion/MonitoredAccounts'
import UniqueUsers from '@/components/Modules/Activity/Conversion/UniqueUsers'
import TopConnections from '@/components/Modules/Activity/Conversion/TopConnections'
import TransactionVolume from '@/components/Modules/Activity/Conversion/TransactionVolume'

export default {
  name: 'Conversion',
  components: {
    AuthenticationRate,
    CompletedTasks,
    DateRangeBar,
    FailureReasons,
    Funnel,
    FunnelSettings,
    MonitoredAccounts,
    TopConnections,
    TransactionVolume,
    UniqueUsers,
  },
  computed: {
    ...mapGetters('environment', ['isSandbox']),
    ...mapState('activity', ['sampleDataMode']),
    noDataText() {
      if (this.isSandbox) return `👋 You're in sandbox mode, and this is sample data.`
      return `👋 Data was sparse, so we're showing you sample data.`
    },
  },
  methods: {
    ...mapMutations('activity', ['setSampleDataMode']),
  },
  mounted() {
    this.setSampleDataMode(false)
  },
}
</script>

<style lang="scss" scoped>
.sample-data-notice {
  border: none;
  position: sticky;
  top: 0px;
  z-index: 20;
}

.straddle-fold {
  margin-top: -8.8rem !important;
  overflow: hidden;
}

.header.bg-dark .header-body,
.header.bg-hero .header-body {
  border: none;
}
</style>
