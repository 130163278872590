<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import {
  defaultLineOptions,
  defaultSparkOptions,
  defaultLineDummyData,
  defaultSparkDummyData,
} from '@/utils/chart'

export default {
  props: ['options', 'chartData', 'spark', 'useDummyData'],
  extends: Line,
  name: 'LineChart',
  mixins: [reactiveProp],
  methods: {
    triggerRenderChart() {
      let options = this.options || defaultLineOptions()
      let data = this.chartData
      if (this.spark) {
        options = defaultSparkOptions()
        data = !this.chartData || this.useDummyData ? defaultSparkDummyData() : this.chartData
      } else {
        data = !this.chartData || this.useDummyData ? defaultLineDummyData() : this.chartData
      }
      this.renderChart(data, options)
    },
  },
  mounted() {
    this.triggerRenderChart()
  },
  watch: {
    'chartData.labels': function () {
      this.triggerRenderChart()
    },
  },
}
</script>
