<template>
  <div>
    <div v-if="bannoStatus === 'active'">
      <Form
        :onSubmit="saveSettings"
        v-slot="{ disabled }"
        submitButtonText="Save"
        @success="onSuccess"
        @error="onError"
        title="External Application Configuration"
      >
        <div class="alert alert-light mb-4" role="alert">
          Enter your external application configuration from Banno People here.
        </div>
        <div class="row">
          <validation-provider
            rules="required"
            v-slot="{ classes, errors, touched }"
            class="col-12 form-group"
          >
            <label for="client-id" class="form-label">Client ID</label>
            <input
              id="client-id"
              class="form-control"
              :class="classes"
              placeholder="Client ID"
              type="text"
              v-model="fields.clientId"
              :disabled="disabled"
            />
            <div v-if="touched" class="invalid-feedback">{{ errors[0] }}</div>
          </validation-provider>
          <validation-provider
            rules="required"
            v-slot="{ classes, errors, touched }"
            class="col-12 form-group"
          >
            <HideableInput
              v-model="fields.clientSecret"
              label="Client Secret"
              :input-props="{ class: classes, disabled, placeholder: 'Client Secret' }"
              :touched="touched"
              :errors="errors"
              @toggleShowValue="onClientSecretToggleShowValue"
            />
          </validation-provider>
          <validation-provider
            rules="required|url"
            v-slot="{ classes, errors, touched }"
            class="col-12 form-group"
          >
            <label
              for="environment"
              class="form-label"
              v-tooltip="'The URL for your Banno application'"
            >
              Environment
            </label>
            <input
              id="environment"
              class="form-control"
              :class="classes"
              placeholder="https://MY.BANNO-URL.COM"
              type="url"
              v-model="fields.environment"
              :disabled="disabled"
            />
            <div v-if="touched" class="invalid-feedback">{{ errors[0] }}</div>
          </validation-provider>
        </div>
      </Form>
      <Card>
        <template #header>
          <h4 class="card-header-title">Redirect URIs</h4>
        </template>
        <div class="alert alert-light mb-4" role="alert">
          Enter these redirect URIs into your external application configuration in Banno People in
          this order.
        </div>
        <CopyableInput
          title="URI 1"
          :value="uri1"
          class="mb-4"
          @copy="() => onCopyRedirectUri(1)"
        />
        <CopyableInput title="URI 2" :value="uri2" @copy="() => onCopyRedirectUri(2)" />
      </Card>
    </div>
    <div v-else-if="bannoStatus === 'inactive'">
      <div class="alert alert-light mb-4" role="alert">
        Your Banno integration is inactive. Please contact your Customer Success Manager to
        reactivate it.
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import { pick } from 'lodash-es'
import Card from '@/components/MainContent/Card'
import CopyableInput from '@/components/MainContent/CopyableInput'
import Form from '@/components/MainContent/Form'
import HideableInput from '@/components/MainContent/HideableInput'
import SettingsBase from './SettingsBase'

const uriPrefix = 'https://api.atomicfi.com/integrations/banno/customer/'

export default {
  name: 'SettingsBanno',
  extends: SettingsBase,
  components: {
    Card,
    CopyableInput,
    Form,
    HideableInput,
    ValidationProvider,
  },
  data() {
    return {
      shouldShowClientSecret: false,
    }
  },
  computed: {
    ...mapGetters('customer', ['bannoStatus']),
    fields() {
      return pick(this.activeCustomer.features.banno, ['clientId', 'clientSecret', 'environment'])
    },
    uri1() {
      return uriPrefix + this.activeCustomer._id
    },
    uri2() {
      return this.uri1 + '/transact'
    },
  },
  methods: {
    async saveSettings() {
      const payload = Object.keys(this.fields).reduce(
        (fieldsToSave, field) => ({
          [`features.banno.${field}`]: this.fields[field],
          ...fieldsToSave,
        }),
        {}
      )

      await this.updateCustomer({ payload })

      this.$analytics.track({
        event: 'Save Banno External Application Configuration',
      })
    },
    onSuccess() {
      this.$toasted.success('Configuration successfully saved')
    },
    onError() {
      this.$toasted.error('Error saving configuration')
    },
    onClientSecretToggleShowValue({ shouldShowValue }) {
      this.$analytics.track({
        event: 'Toggle Banno Client Secret Visibility',
        payload: {
          visible: shouldShowValue,
        },
      })
    },
    onCopyRedirectUri(uriNumber) {
      this.$analytics.track({
        event: `Copy Banno Redirect URI ${uriNumber}`,
      })
    },
  },
}
</script>
