<template>
  <div class="card flex-fill">
    <div class="card-body">
      <div class="row align-items-center prevent-wrap">
        <div class="col-auto">
          <div class="avatar avatar-sm icon-product">
            <img
              :src="`/images/icon-${task.product}.svg`"
              alt=""
              aria-hidden="true"
              class="w-100"
            />
          </div>
        </div>
        <div class="col ms-n2 task-title">
          <h4 class="card-title mb-1">
            <span class="capitalize">{{ productTitle }}</span>
          </h4>
          <p class="card-text small text-muted mb-1 has-ellipsis">
            {{ task.user.identifier }}
          </p>
        </div>
        <div class="col-auto d-flex flex-column align-items-end">
          <BoxIcon
            v-if="isTestTask"
            size="18"
            class="d-block text-muted mb-2"
            v-tooltip="`Test task`"
          />
          <small class="text-muted">{{ task.createdAt | moment('from', 'now') }}</small>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row align-items-center">
        <div class="col">
          <router-link
            :to="{ name: 'tasks-detail', params: { id: task._id } }"
            class="btn btn-sm btn-outline-secondary"
            :aria-label="`View ${productTitle} task for user ${task.user.identifier}`"
          >
            View Task
          </router-link>
          <a
            v-if="showBannoPeopleLink"
            :href="`https://banno.com/a/people/${bannoShortId}/users/${task.user.identifier}`"
            class="btn btn-sm btn-outline-secondary ms-4"
            target="_blank"
            :aria-label="`View User ${task.user.identifier} on Banno People`"
            v-tooltip="'View on Banno People'"
          >
            View User
            <ExternalLinkIcon size="14" class="ms-2 align-middle" />
          </a>
        </div>
        <div class="col-auto">
          <TaskStatus v-tooltip="taskFailReasonDescription" :task="task" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskService from '@/services/TaskService'
import TaskStatus from '@/components/Modules/Task/TaskElement/TaskStatus'
import { failReasons } from '@/lib/task-fail-reasons'
import { getProductTitle } from '@/utils/task'
import { BANNO_STATUSES, TASK_TYPES } from '@/utils/constants'
import { BoxIcon } from 'vue-feather-icons'
import { mapGetters, mapState } from 'vuex'
import { ExternalLinkIcon } from 'vue-feather-icons'

export default {
  name: 'TaskCard',
  props: {
    task: Object,
  },
  components: { TaskStatus, BoxIcon, ExternalLinkIcon },
  computed: {
    ...mapGetters('customer', ['bannoStatus']),
    ...mapState('customer', ['activeCustomer']),
    bannoShortId() {
      return this.activeCustomer.features?.banno?.shortId
    },
    productTitle() {
      return getProductTitle(this.task)
    },
    showBannoPeopleLink() {
      return this.bannoStatus === BANNO_STATUSES.ACTIVE && this.bannoShortId
    },
    subtitle() {
      return this.task.product
    },
    taskFailReasonDescription() {
      const { failReason } = this.task

      return failReason ? `${failReason}: ${failReasons[failReason]?.description}` : undefined
    },
    isTestTask() {
      return this.task.type === TASK_TYPES.TEST
    },
  },
}
</script>

<style scoped>
.icon-product {
  /** Optically align icon with text */
  margin-top: -4px;
}

.prevent-wrap {
  flex-wrap: nowrap;
}

/*
  The user identifier can be extremely long sometimes, so this solution:
  https://css-tricks.com/flexbox-truncated-text/
  Helps truncate the text down to a reasonable size
 */
.task-title {
  min-width: 0;
}

.has-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 60px);
}
</style>
