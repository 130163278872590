var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SettingsWebhooksEndpointModalForm", {
    attrs: {
      id: "update-endpoint-modal-" + _vm.endpoint._id,
      title: "Update endpoint",
      onSubmit: _vm.updateEndpoint,
      initialFormValues: _vm.endpoint,
    },
    on: { success: _vm.onSuccess, error: _vm.onError },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }