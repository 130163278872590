<template>
  <div>
    <!-- Open Sidebar Button -->
    <button
      v-if="activeCustomer && hasChildCustomers"
      @click="toggleSwitcher"
      class="switcher-toggle"
      title="Switch customers"
    >
      <CustomerListItem
        :customer="activeCustomer"
        :truncateWidth="20"
        avatarClass="avatar-xs"
        :condensed="true"
        :showSwitcher="true"
      />
    </button>
    <CustomerListItem
      v-else-if="activeCustomer"
      :customer="activeCustomer"
      :truncateWidth="20"
      avatarClass="avatar-xs"
      :condensed="true"
    />

    <!-- Sidebar -->
    <b-sidebar
      v-if="hasChildCustomers"
      v-model="showSwitcher"
      bg-variant="white"
      :backdrop="true"
      :no-header="true"
      shadow
      v-scroll-lock="showSwitcher"
    >
      <div class="offcanvas-body">
        <div class="child-customer-search-container">
          <div class="mb-4 input-group input-group-rounded input-group-merge input-group-reverse">
            <input
              @input="filterChildCustomers"
              :value="searchQuery"
              :disabled="isLoading"
              class="form-control list-search form-control-rounded"
              type="search"
              placeholder="Search"
              ref="searchInput"
            />
            <span class="input-group-text input-group-rounded">
              <span
                v-if="state === 'loading-search'"
                data-test-id="search-spinner"
                class="spinner-border spinner-border-sm text-muted"
                role="status"
                aria-hidden="true"
              ></span>
              <SearchIcon v-if="state !== 'loading-search'" class="ms-1 text-muted" size="16" />
            </span>
          </div>
          <div class="list-group-flush mt-n3">
            <a class="list-group-item mx-n4 px-4 border-bottom-3" href="#">
              <CustomerListItem
                :truncateWidth="18"
                :customer="customer"
                @click.native="handleCustomerClick(customer)"
                :showTimestamp="true"
              />
            </a>
          </div>
        </div>
        <div class="child-customer-list-container scroll-shadow">
          <div class="col list-group list-group-flush list-group-focus list">
            <a
              class="list-group-item"
              href="#"
              v-for="customer in childCustomerSearchResults"
              :key="customer._id"
            >
              <CustomerListItem
                :truncateWidth="18"
                :customer="customer"
                @click.native="handleCustomerClick(customer)"
                :showTimestamp="true"
                :class="{ 'disable-item': !customer.approved }"
              />
            </a>
          </div>
        </div>
        <div class="child-customer-pagination-container">
          <ul v-if="childCustomerSearchOptions.totalPages > 1" class="pagination mb-0 mt-4">
            <li class="page-item w-50" :class="{ disabled: isPrevPageButtonDisabled }">
              <button
                :disabled="isPrevPageButtonDisabled"
                @click="() => changePage(-1)"
                class="page-link d-flex w-100 h-100 align-items-center justify-content-center"
              >
                <div
                  v-if="state === 'loading-prev'"
                  class="spinner-border spinner-border-sm text-muted"
                  role="status"
                />
                <div v-else>
                  <ArrowLeftIcon size="16" class="me-1" />
                  Prev page
                </div>
              </button>
            </li>
            <li class="page-item w-50" :class="{ disabled: isNextPageButtonDisabled }">
              <button
                :disabled="isNextPageButtonDisabled"
                @click="() => changePage(1)"
                class="page-link d-flex w-100 h-100 align-items-center justify-content-center"
              >
                <div
                  v-if="state === 'loading-next'"
                  class="spinner-border spinner-border-sm text-muted"
                  role="status"
                />
                <div v-else>
                  Next page
                  <ArrowRightIcon size="16" class="ms-1" />
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { ArrowLeftIcon, ArrowRightIcon, SearchIcon } from 'vue-feather-icons'

const { mapActions, mapMutations, mapState } = createNamespacedHelpers('customer')
import CustomerListItem from './CustomerListItem.vue'
import { debounce, omit, pick } from 'lodash-es'

export default {
  name: 'CustomerSwitcher',
  components: { ArrowLeftIcon, ArrowRightIcon, CustomerListItem, SearchIcon },
  computed: {
    ...mapState([
      'customer',
      'childCustomers',
      'childCustomerSearchOptions',
      'childCustomerSearchResults',
      'activeCustomer',
    ]),
    hasChildCustomers() {
      return this.childCustomers.length > 0
    },
    isNextPageButtonDisabled() {
      return !this.childCustomerSearchOptions.hasNextPage || this.isLoading
    },
    isPrevPageButtonDisabled() {
      return !this.childCustomerSearchOptions.hasPrevPage || this.isLoading
    },
    isLoading() {
      return this.state.startsWith('loading')
    },
  },
  data() {
    return {
      searchQuery: undefined,
      showSwitcher: false,
      state: 'idle',
    }
  },
  methods: {
    ...mapActions(['searchChildCustomers', 'switchToCustomer']),
    handleCustomerClick(selectedCustomer) {
      if (selectedCustomer.approved) {
        this.switchToCustomer(selectedCustomer)
        this.$router.push('/')
        this.$toasted.success(`Switched to ${selectedCustomer.name}`)
      }
    },
    async changePage(increment) {
      try {
        this.state = `loading-${increment > 0 ? 'next' : 'prev'}`

        const queryStringParams = {
          ...pick(this.childCustomerSearchOptions, ['limit']),
          page: this.childCustomerSearchOptions.page + increment,
          q: this.searchQuery,
        }

        await this.searchChildCustomers({
          queryStringParams,
        })

        this.trackChildCustomerSearch(queryStringParams)
      } finally {
        this.state = 'idle'
      }
    },
    filterChildCustomers: debounce(async function (event) {
      try {
        this.searchQuery = event.target.value
        this.state = `loading-search`

        const queryStringParams = {
          ...pick(this.childCustomerSearchOptions, ['limit']),
          page: 1,
          q: this.searchQuery,
        }

        await this.searchChildCustomers({
          queryStringParams,
        })

        this.trackChildCustomerSearch(queryStringParams)
      } finally {
        this.state = 'idle'
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    }, 500),
    toggleSwitcher() {
      this.showSwitcher = !this.showSwitcher

      this.$analytics.track({
        event: 'Toggle Customer Switcher',
        payload: {
          showSwitcher: this.showSwitcher,
        },
      })
    },
    trackChildCustomerSearch(payload) {
      this.$analytics.track({
        event: 'Search Child Customers',
        payload: {
          ...omit(payload, ['q']),
          query: payload.q,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
$spacing: 1.5rem;
.offcanvas-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing;
}

.child-customer-search-container,
.child-customer-pagination-container {
  flex: 0 1 auto;
}

.child-customer-list-container {
  flex: 1 1 auto;
  margin-left: -$spacing;
  margin-right: -$spacing;
  overflow-y: auto;
  padding-left: $spacing;
  padding-right: $spacing;
}

.switcher-toggle {
  background: transparent;
  border: none;
}

.disable-item {
  cursor: not-allowed;
}
</style>
