var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", [
    _c("div", { staticClass: "row align-items-center gx-0" }, [
      _c("div", { staticClass: "col" }, [
        _c("h6", { staticClass: "text-uppercase text-muted mb-2" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("div", { staticClass: "row align-items-center g-0" }, [
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _vm.isLoading
                ? _c("VueSkeletonLoader", {
                    staticClass: "me-2",
                    attrs: {
                      type: "rect",
                      width: 32,
                      height: 24,
                      color: "rgba(227,236,248,0.6)",
                      rounded: true,
                      animation: "wave",
                    },
                  })
                : _c("span", { staticClass: "h2 me-2 mb-0" }, [
                    _vm._v(_vm._s(_vm.value)),
                  ]),
            ],
            1
          ),
          _c("div", { staticClass: "col" }, [_vm._t("visual")], 2),
        ]),
      ]),
      _c("div", { staticClass: "col-auto" }, [_vm._t("icon")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }