import mixpanel from 'mixpanel-browser'

const MixpanelPlugin = {
  install() {
    mixpanel.init(process.env.VUE_APP_MIXPANEL, {
      api_host: 'https://ingest.atomicfi.com',
      debug: process.env.NODE_ENV === 'development',
    })
  },
  get() {
    return mixpanel
  },
}

export default MixpanelPlugin
