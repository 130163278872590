












import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import FullLoader from '@/components/MainContent/FullLoader.vue'
import Ably from '@/plugins/ably'
import LaunchDarkly from '@/plugins/launch-darkly'
import { getInstance } from '@/plugins/auth'
import { mapActions, mapState, mapGetters } from 'vuex'
import { ENVIRONMENTS } from '@/utils/constants'

const App = Vue.extend({
  name: 'App',
  components: {
    FullLoader,
  },
  computed: {
    ...mapState('customer', ['activeCustomer', 'customer']),
    ...mapGetters('environment', ['accessNotEnabled']),
  },
  data: () => ({
    initialized: false,
  }),
  methods: {
    ...mapActions('environment', ['updateActiveEnvironment']),
  },
  async mounted() {
    const authService = getInstance()

    authService.$watch('loading', async (loading: boolean) => {
      if (loading === false && authService.isAuthenticated) {
        Vue.use(Ably)

        Vue.use(LaunchDarkly, {
          kind: 'user',
          key: authService.user.sub,
          customer: this.customer.name,
        })
        try {
          await this.$launchDarkly.waitForInitialization()
        } catch (e) {
          Sentry.captureException(e)
        }

        if (this.accessNotEnabled) {
          await this.updateActiveEnvironment(ENVIRONMENTS.SANDBOX)
        }

        this.initialized = true
      }
    })
  },
})

export default App
