var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group form-check" }, [
    _c("input", {
      staticClass: "form-check-input",
      attrs: { disabled: _vm.disabled, id: _vm.id, type: "checkbox" },
      domProps: { checked: _vm.value },
      on: { input: _vm.onInput },
    }),
    _c("label", { staticClass: "form-check-label", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
    _vm.helperText
      ? _c("small", { staticClass: "form-text text-muted" }, [
          _vm._v(_vm._s(_vm.helperText)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }