<template>
  <div class="dropdown show d-inline">
    <a
      href="#"
      class="dropdown-toggle small text-muted text-capitalize"
      aria-expanded="false"
      role="button"
      id="environmentDropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
    >
      <span v-if="changingEnvironment" class="spinner-border spinner-border-sm" role="status" />
      <span v-else>
        {{ activeEnvironment }}
      </span>
      <ChevronDownIcon size="14" />
    </a>
    <div class="dropdown-menu" aria-labelledby="environmentDropdown">
      <a
        class="dropdown-item"
        href="javascript:;"
        v-for="environment in availableEnvironments"
        :key="environment"
      >
        <span @click="toggleEnvironment(environment)" class="text-capitalize">{{
          environment
        }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ChevronDownIcon } from 'vue-feather-icons'
export default {
  name: 'EnvironmentSwitcher',
  components: { ChevronDownIcon },
  computed: {
    ...mapState('environment', ['activeEnvironment', 'changingEnvironment']),
    ...mapGetters('environment', ['availableEnvironments']),
  },
  methods: {
    ...mapActions('environment', ['updateActiveEnvironment']),
    toggleEnvironment(environment) {
      if (environment !== this.activeEnvironment) {
        this.updateActiveEnvironment(environment)
      }
    },
  },
}
</script>
