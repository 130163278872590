import Vue from 'vue'
import Vuex from 'vuex'
import { ENVIRONMENTS } from '@/utils/constants'
import { hasProductionAccess } from '@/lib/authorization'
import { getAtomicCustomerAPIFromEnvironment } from '@/utils/environment'
import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    // we must default to something in order for permissions to be retrieved
    // defaulting to sandbox since it's the safer of the two
    activeEnvironment: ENVIRONMENTS.SANDBOX,
    activeEnvironmentInitialized: false,
    changingEnvironment: false,
  },
  mutations: {
    setActiveEnvironment(state, environment) {
      state.activeEnvironment = environment
      localStorage.setItem('activeEnvironment', environment)
    },
    setActiveEnvironmentInitialized(state, environmentInitialized) {
      state.activeEnvironmentInitialized = environmentInitialized
    },
    setChangingEnvironment(state, changing) {
      state.changingEnvironment = changing
    },
  },
  actions: {
    updateDefaultEnvironment({ commit }) {
      const defaultEnvironment =
        hasProductionAccess() &&
        localStorage.getItem('activeEnvironment') === ENVIRONMENTS.PRODUCTION
          ? ENVIRONMENTS.PRODUCTION
          : ENVIRONMENTS.SANDBOX

      commit('setActiveEnvironment', defaultEnvironment)
      commit('setActiveEnvironmentInitialized', true)
    },
    async updateActiveEnvironment({ state, commit, dispatch, rootState }, environment) {
      commit('setChangingEnvironment', true)

      try {
        commit('setActiveEnvironment', environment)

        await dispatch('customer/getCustomer', environment, { root: true })
        await dispatch('emulator/resetProducts', null, { root: true })

        // redirect to the homepage in case the user is on a page
        // where they don't have permission in the new environment
        router.push('/activity')
      } catch (err) {
        // We were unable to switch to the other environment's customer, so notify the user
        alert(
          `${rootState.customer.activeCustomer.name} is not available in the ${environment} environment.`
        )
      }

      commit('setChangingEnvironment', false)
    },
  },
  getters: {
    availableEnvironments() {
      return [...(hasProductionAccess() ? [ENVIRONMENTS.PRODUCTION] : []), ENVIRONMENTS.SANDBOX]
    },
    atomicCustomerAPI(state) {
      return getAtomicCustomerAPIFromEnvironment(state.activeEnvironment)
    },
    isProduction(state) {
      return state.activeEnvironment === ENVIRONMENTS.PRODUCTION
    },
    isSandbox(state) {
      return state.activeEnvironment === ENVIRONMENTS.SANDBOX
    },
    accessNotEnabled(state, getters, rootState) {
      return (
        !getters.isSandbox &&
        rootState.customer.activeCustomer &&
        !rootState.customer.activeCustomer.credentials.enabled
      )
    },
  },
}
