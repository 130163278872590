import { PERMISSIONS } from '@/lib/permissions'
import { hasPermissions } from '@/lib/authorization'
import { BANNO_STATUSES } from '@/utils/constants'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    settingsNavigation: [
      {
        title: 'Institution',
        route: 'settings-general',
        canViewRoute: () => hasPermissions(PERMISSIONS.MANAGE_INSTITUTION_SETTINGS),
      },
      {
        title: 'Team',
        route: 'settings-team',
        canViewRoute: () => true,
      },
      {
        title: 'Roles & Permissions',
        route: 'settings-role-permissions',
        canViewRoute: () => true,
      },
      {
        title: 'Credentials',
        route: 'settings-credentials',
        canViewRoute: () => hasPermissions(PERMISSIONS.MANAGE_CREDENTIALS),
      },
      {
        title: 'Features',
        route: 'settings-features',
        canViewRoute: () => hasPermissions(PERMISSIONS.MANAGE_FEATURES),
      },
      {
        title: 'Webhooks',
        route: 'settings-webhooks',
        canViewRoute: () => hasPermissions(PERMISSIONS.MANAGE_WEBHOOKS),
      },
      {
        title: 'Experiments',
        route: 'settings-experiments',
        canViewRoute: () =>
          hasPermissions(PERMISSIONS.MANAGE_EXPERIMENTS) &&
          store.getters['environment/isProduction'],
      },
      {
        title: 'Surveys',
        route: 'settings-surveys',
        canViewRoute: () =>
          hasPermissions(PERMISSIONS.MANAGE_SURVEYS) && store.getters['environment/isProduction'],
      },
      {
        title: 'Banno',
        route: 'settings-banno',
        canViewRoute: () => {
          return (
            hasPermissions(PERMISSIONS.MANAGE_FEATURES) &&
            store.getters['customer/bannoStatus'] !== BANNO_STATUSES.DISABLED
          )
        },
      },
    ],
    resourcesNavigation: [
      {
        title: 'Figma',
        route: 'resources-figma',
        canViewRoute: () => hasPermissions(PERMISSIONS.VIEW_DESIGNS),
      },
      {
        title: 'Deposit Logic',
        route: 'deposit-scenario-simulator',
        canViewRoute: () => true,
      },
    ],
    employersNavigation: [
      {
        title: 'Employers',
        route: 'view-employers',
        canViewRoute: () => true, // temporary
      },
      {
        title: 'Invites',
        route: 'employers-invites',
        canViewRoute: () => true, // temporary
      },
    ],
  },
}
