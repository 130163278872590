<template>
  <div>
    <div class="form-group">
      <div>
        <label>{{ limitLabel }}</label>
        <div class="limit-settings-row">
          <div
            class="input-group input-group-merge mb-2 row-amount"
            :class="{ 'input-group-reverse': isFixed }"
          >
            <input
              v-model.number="amount"
              type="number"
              class="form-control"
              placeholder="No limit"
              min="0"
              step="1"
              autocomplete="off"
              :max="max"
            />
            <div class="input-group-text px-2">{{ isFixed ? '$' : '%' }}</div>
          </div>
          <div class="row-required">
            <input
              v-model="type"
              class="form-check-input limit-check"
              type="checkbox"
              :id="`${limitDirection}-${fractionalType}-type`"
            />
            <label class="form-check-label" :for="`${limitDirection}-${fractionalType}-type`"
              >Enforced</label
            >
          </div>
        </div>
      </div>

      <small class="form-text text-muted">
        <span v-if="amountIsNotSet"> No limit will be enforced. </span>
        <span v-else-if="isHardLimit">
          Users will be
          <span class="error-copy">prevented from proceeding</span>
          further if their entered amount {{ limitVerbiage }} this amount.
        </span>
        <span v-else>
          Users will be
          <span class="warn-copy">warned</span>
          if their entered amount {{ limitVerbiage }} this amount.
        </span>
      </small>
    </div>
  </div>
</template>

<script>
import { debounce, capitalize } from 'lodash-es'
import { DISTRIBUTION_LIMIT_TYPES, DISTRIBUTION_TYPES } from '@/utils/constants'

export default {
  name: 'SettingsFractionalDepositLimit',
  components: {},
  props: {
    limitDirection: String,
    data: {
      type: Object,
      properties: {
        amount: Number,
        type: String,
      },
    },
    fractionalType: { type: String, default: DISTRIBUTION_TYPES.FIXED },
    max: Number,
  },
  computed: {
    amountIsNotSet: function () {
      return !this.amount
    },
    limitLabel: function () {
      return `${capitalize(this.limitDirection)} amount`
    },
    limitVerbiage: function () {
      return this.limitDirection === 'maximum' ? 'exceeds' : 'does not meet'
    },
    isHardLimit: function () {
      return this.data.type === DISTRIBUTION_LIMIT_TYPES.ERROR
    },
    isFixed() {
      return this.fractionalType === DISTRIBUTION_TYPES.FIXED
    },
    amount: {
      get() {
        return this.data.amount
      },
      set: debounce(function ($event) {
        // currently vue doesn't let you return a null value instead of an empty string https://github.com/vuejs/core/issues/3378
        // from an input bound with v-model.number, so we set the $event to null
        // if the $event is an empty string
        if ($event === '') {
          $event = null
        }
        this.$emit('input', { amount: $event })
      }, 1000),
    },
    type: {
      get() {
        return this.data.type === DISTRIBUTION_LIMIT_TYPES.ERROR
      },
      set(checked) {
        const type = checked ? DISTRIBUTION_LIMIT_TYPES.ERROR : DISTRIBUTION_LIMIT_TYPES.WARN
        this.$emit('input', { type })
      },
    },
  },
}
</script>
<style>
.limit-check {
  margin-right: 0.6em;
}

.error-copy {
  color: var(--bs-danger);
  font-weight: 700;
}

.warn-copy {
  color: var(--bs-warning);
  font-weight: 700;
}

.limit-settings-row {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.row-amount {
  flex: 1;
}

.row-required {
  flex: 0 0 auto;
  margin-left: 1em;
  padding-bottom: 0.4em;
}
</style>
