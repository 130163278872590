var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-wrapper scroll-shadow" }, [
    _c("table", { staticClass: "table table-sm table-nowrap" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm._f("startCase")(_vm.label)))]),
          _c("th", { staticClass: "text-center" }, [_vm._v("Production")]),
          _c("th", { staticClass: "text-center" }, [_vm._v("Sandbox")]),
        ]),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.rowData, function (data) {
          return _c(
            "tr",
            { key: data },
            [
              _c("td", [_vm._v(_vm._s(_vm._f("startCase")(data)))]),
              _c("PermissionCell", {
                attrs: {
                  hasPermission: _vm.hasPermissionFunction(
                    _vm.ENVIRONMENTS.PRODUCTION,
                    data
                  ),
                },
              }),
              _c("PermissionCell", {
                attrs: {
                  hasPermission: _vm.hasPermissionFunction(
                    _vm.ENVIRONMENTS.SANDBOX,
                    data
                  ),
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }