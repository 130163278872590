import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vue from 'vue'
import VueResource from 'vue-resource'
import VueMask from 'v-mask'
import App from './App.vue'
import { interceptor } from './lib/http'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import { initCharts } from './lib/chart'
import VueNotifications from 'vue-notification'
import truncateFilter from './filters/truncate'
import phoneFilter from './filters/phone'
import VueFuse from 'vue-fuse'
import Toasted from 'vue-toasted'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueClipboard from 'vue-clipboard2'
import VCalendar from 'v-calendar'
import VScrollLock from 'v-scroll-lock'
import VTooltip from 'v-tooltip'
import { Auth0Plugin } from './plugins/auth'
import Analytics from './plugins/analytics'
import currencyFilter from './filters/currency'
import startCaseFilter from './filters/startCase'
import percentFilter from './filters/percent'
import Scrollactive from 'vue-scrollactive'
import Affix from 'vue-affix'
import { CollapsePlugin, SpinnerPlugin } from 'bootstrap-vue'
import { BootstrapVue } from 'bootstrap-vue'
import * as registry from './components/registry'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import Hotjar from '@hotjar/browser'
import VueEasyLightbox from 'vue-easy-lightbox'

{
  const hotjarSiteId = 3369319
  const hotjarVersion = 6

  Hotjar.init(hotjarSiteId, hotjarVersion)
}

Sentry.init({
  Vue,
  dsn: 'https://b053f63a781640aca543fb7ef33cd7ce@o516219.ingest.sentry.io/6422180',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'localhost',
        process.env.VUE_APP_PRODUCTION_ATOMIC_CUSTOMER_API,
        process.env.VUE_APP_SANDBOX_ATOMIC_CUSTOMER_API,
      ],
    }),
  ],
  tracesSampleRate: 0.25,
  environment: process.env.NODE_ENV,
})

Vue.config.productionTip = false

// Initialize global Chart.js options
initCharts()
registry.registerValidation()

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VCalendar)
Vue.use(VScrollLock)
Vue.use(VTooltip)
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  onAuthenticatedCallback: async () => {
    // Customer metadata is needed in state before rendering some routes
    // Therefore we must load it immediately after authenticating the user
    await store.dispatch('environment/updateDefaultEnvironment')
    await store.dispatch('customer/getCustomer')
  },
  onRedirectCallback: (appState: any) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
  },
})
Vue.use(Analytics)
Vue.use(Toasted, { duration: 2000, position: 'bottom-right' })
Vue.use(VueResource)
Vue.use(VueMoment, {
  moment,
})
Vue.use(VueMask)
Vue.use(VueFuse)
Vue.use(Scrollactive)
Vue.use(Affix)
Vue.use(SpinnerPlugin)
Vue.use(VueNotifications)
Vue.use(VueEasyLightbox)

// this is set so that the clipboard components will work within modals.
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.filter('phone', phoneFilter)
Vue.filter('percent', percentFilter)
Vue.filter('truncate', truncateFilter)
Vue.filter('currency', currencyFilter)
Vue.filter('startCase', startCaseFilter)
Vue.use(CollapsePlugin)

registry.registerGlobal()

Vue.http.interceptors.push(interceptor as any)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
