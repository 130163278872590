var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "btn w-100 btn-white",
      class: { active: _vm.productIsIncluded },
      on: {
        click: function ($event) {
          return _vm.$emit("toggle", _vm.product)
        },
      },
    },
    [
      _vm.showProductOrderBadge
        ? _c(
            "span",
            { staticClass: "badge bg-secondary-soft rounded-circle me-1" },
            [
              _vm._v(
                " " + _vm._s(_vm.products.indexOf(_vm.product.name) + 1) + " "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.product.title) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }