var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    { staticClass: "mt-4 mb-0" },
    [
      _c("div", { staticClass: "wrapper" }, [
        _vm.conversionOptimization.screenshotUrl
          ? _c("div", { staticClass: "screenshot-wrapper" }, [
              _c("img", {
                staticClass: "cursor-zoom mw-100",
                attrs: {
                  src: _vm.conversionOptimization.screenshotUrl,
                  alt: _vm.conversionOptimization.name + " screenshot}",
                },
                on: { click: _vm.toggleScreenshotLightbox },
              }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "content-wrapper" }, [
          _vm.conversionOptimization.scopes.length
            ? _c(
                "div",
                { staticClass: "mb-3" },
                _vm._l(_vm.conversionOptimization.scopes, function (scope) {
                  return _c(
                    "span",
                    {
                      key: scope,
                      staticClass: "badge bg-primary-soft text-uppercase me-2",
                    },
                    [_vm._v(_vm._s(scope))]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center my-3",
            },
            [
              _c("h5", { staticClass: "name mb-0" }, [
                _vm._v(_vm._s(_vm.conversionOptimization.name)),
              ]),
              _c(
                "div",
                [
                  _vm.showToggle
                    ? _c("CustomSwitch", {
                        attrs: {
                          showLabelText: false,
                          formGroupWrap: false,
                          label: "Toggle " + _vm.conversionOptimization.name,
                          disabled: _vm.state === "toggling",
                        },
                        model: {
                          value: _vm.isEnabled,
                          callback: function ($$v) {
                            _vm.isEnabled = $$v
                          },
                          expression: "isEnabled",
                        },
                      })
                    : _vm._e(),
                  _vm.showMiddleOfFunnelIcon
                    ? _c("GitCommitIcon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value:
                              "Middle-of-funnel " +
                              _vm.conversionOptimization.type,
                            expression:
                              "`Middle-of-funnel ${conversionOptimization.type}`",
                          },
                        ],
                        staticClass: "middle-of-funnel-icon cursor-help",
                      })
                    : _vm._e(),
                  _vm.showImplementedIcon
                    ? _c("TrendingUpIcon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Implemented in Transact",
                            expression: "'Implemented in Transact'",
                          },
                        ],
                        staticClass: "text-success cursor-help",
                      })
                    : _vm._e(),
                  _vm.showUnimplementedIcon
                    ? _c("TrendingDownIcon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Not implemented in Transact",
                            expression: "'Not implemented in Transact'",
                          },
                        ],
                        staticClass: "text-warning cursor-help",
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "metadata my-3 d-flex" }, [
            _vm.conversionOptimization.startDate ||
            _vm.conversionOptimization.endDate
              ? _c(
                  "div",
                  { staticClass: "pe-4 d-flex align-items-center" },
                  [
                    _c("CalendarIcon", {
                      staticClass: "me-2",
                      attrs: { size: "1x" },
                    }),
                    _vm.conversionOptimization.startDate
                      ? _c(
                          "time",
                          {
                            attrs: {
                              datetime: _vm.conversionOptimization.startDate,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$moment
                                  .utc(_vm.conversionOptimization.startDate)
                                  .format("MMM Do")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.conversionOptimization.startDate &&
                    _vm.conversionOptimization.endDate
                      ? _c("span", { staticClass: "mx-1" }, [_vm._v("—")])
                      : _vm._e(),
                    _vm.conversionOptimization.endDate
                      ? _c(
                          "time",
                          {
                            attrs: {
                              datetime: _vm.conversionOptimization.endDate,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$moment
                                  .utc(_vm.conversionOptimization.endDate)
                                  .format("MMM Do")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("UsersIcon", { staticClass: "me-2", attrs: { size: "1x" } }),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.conversionOptimization.trafficSplitTesting) +
                      "% Testing"
                  ),
                ]),
                _c("span", { staticClass: "mx-1" }, [_vm._v("/")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.conversionOptimization.trafficSplitControl) +
                      "% Control"
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isCompleted
                    ? _vm.conversionOptimization.results
                    : _vm.conversionOptimization.background
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _c("vue-easy-lightbox", {
        attrs: {
          visible: _vm.screenshotLightboxVisible,
          imgs: _vm.conversionOptimization.screenshotUrl,
        },
        on: { hide: _vm.toggleScreenshotLightbox },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }