import Vue from 'vue'
import store from '@/store'
import urlGenerator from '@/lib/url-generator'

export default {
  getEmployer({ employerId }) {
    return Vue.http.get(`${store.getters['environment/atomicCustomerAPI']}/employers/${employerId}`)
  },
  getEmployerEmployees({ employerId }) {
    return Vue.http.get(
      `${store.getters['environment/atomicCustomerAPI']}/employers/${employerId}/employees`
    )
  },
  getEmployerEmployerDocuments({ employerId }) {
    return Vue.http.get(
      `${store.getters['environment/atomicCustomerAPI']}/employers/${employerId}/employer-documents`
    )
  },
  listEmployers({ queryStringParams = {} }) {
    return Vue.http.get(
      urlGenerator(`${store.getters['environment/atomicCustomerAPI']}/employers`, queryStringParams)
    )
  },
  listInvites({ queryStringParams = {} }) {
    return Vue.http.get(
      urlGenerator(
        `${store.getters['environment/atomicCustomerAPI']}/employers/invites`,
        queryStringParams
      )
    )
  },
  createInvite(payload) {
    return Vue.http.post(
      `${store.getters['environment/atomicCustomerAPI']}/employers/invites`,
      payload
    )
  },
}
