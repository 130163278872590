<template>
  <SettingsWebhooksEndpointModalForm
    :id="`update-endpoint-modal-${endpoint._id}`"
    title="Update endpoint"
    :onSubmit="updateEndpoint"
    @success="onSuccess"
    @error="onError"
    :initialFormValues="endpoint"
  />
</template>

<script>
import { mapActions } from 'vuex'
import SettingsWebhooksEndpointModalForm from '@/components/Modules/Settings/SettingsWebhooksEndpointModalForm.vue'

export default {
  name: 'SettingsWebhooksUpdateEndpointModal',
  props: {
    endpoint: {
      type: Object,
      required: true,
    },
  },
  components: {
    SettingsWebhooksEndpointModalForm,
  },
  methods: {
    ...mapActions('customer', ['updateCustomer']),
    async updateEndpoint(payload) {
      await this.updateCustomer({ payload, serviceFn: 'updateWebhookEndpoint' })

      this.$analytics.track({
        event: 'Update Webhook Endpoint',
      })
    },
    onSuccess() {
      this.$toasted.success('Endpoint successfully updated')
    },
    onError() {
      this.$toasted.error('Error updating endpoint')
    },
  },
}
</script>
