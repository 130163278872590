<template>
  <ModalForm
    id="edit-user-role-modal"
    title="Update user role"
    :on-submit="editUserRole"
    submitButtonText="Update role"
    @success="onSuccess"
    @error="onError"
  >
    <UserRoleSelector @selectionChanged="updateRole" :options="user.availableRoles" />
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/MainContent/ModalForm'
import UserRoleSelector from '@/components/Shared/UserRoleSelector'
import UserService from '@/services/UserService'

export default {
  name: 'UserEditRoleModal',
  components: { UserRoleSelector, ModalForm },
  data() {
    return {
      selectedRole: undefined,
    }
  },
  props: ['user'],
  methods: {
    updateRole(role) {
      this.selectedRole = role
    },
    async editUserRole() {
      try {
        await UserService.updateUserRoles(this.user.userId, { roles: [this.selectedRole] })

        this.$analytics.track({
          event: 'Update User Role',
          payload: {
            role: this.selectedRole,
          },
        })
      } catch (e) {
        this.$toasted.show('Error saving.')
      }
    },
    onSuccess() {
      this.$toasted.success('User role successfully updated')
      this.$emit('onSuccess')
    },
    onError() {
      this.$toasted.error('Error updating user role')
    },
  },
}
</script>
