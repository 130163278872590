<template>
  <img v-if="source" :src="source" />
</template>

<script>
export default {
  props: ['source'],
  name: 'SecureImage',
}
</script>
