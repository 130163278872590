var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "form-label", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c("div", { staticClass: "input-group" }, [
      _c(
        "input",
        _vm._b(
          {
            staticClass: "form-control",
            attrs: {
              id: _vm.id,
              type: _vm.shouldShowValue ? "text" : "password",
            },
            domProps: { value: _vm.value },
            on: {
              input: _vm.onInput,
              blur: function ($event) {
                return _vm.$emit("blur")
              },
            },
          },
          "input",
          _vm.inputProps,
          false
        )
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary",
          attrs: { type: "button" },
          on: { click: _vm.toggleShowValue },
        },
        [
          _vm.shouldShowValue
            ? _c("EyeOffIcon", {
                staticClass: "me-1 p-0 m-0",
                attrs: { size: "14" },
              })
            : _c("EyeIcon", {
                staticClass: "me-1 p-0 m-0",
                attrs: { size: "14" },
              }),
          _vm._v(" " + _vm._s(_vm.shouldShowValue ? "Hide" : "Show") + " "),
        ],
        1
      ),
      _vm.touched
        ? _c("div", { staticClass: "invalid-feedback" }, [
            _vm._v(_vm._s(_vm.errors[0])),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }