var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showNewUserScreen ? _c("TaskOffState") : _vm._e(),
      _c(
        "ul",
        { staticClass: "nav nav-tabs mb-3" },
        _vm._l(_vm.productOptions, function (product) {
          return _c("li", { key: product, staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.isProductActive(product) },
                attrs: { href: "#", id: product + "-tab" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.selectProduct(product)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm._f("startCase")(product)) + " ")]
            ),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "form",
                  {
                    staticClass: "row align-items-center",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "col-auto pe-0" }, [
                      !_vm.loadingTasks
                        ? _c(
                            "div",
                            { staticStyle: { width: "16px" } },
                            [
                              _c("SearchIcon", {
                                staticClass: "text-muted",
                                attrs: { size: "16" },
                              }),
                            ],
                            1
                          )
                        : _c("div", {
                            staticClass:
                              "spinner-border spinner-border-sm text-muted",
                            staticStyle: { position: "relative", top: "-1px" },
                            attrs: { role: "status" },
                          }),
                    ]),
                    _c("div", { staticClass: "col" }, [
                      _c("input", {
                        staticClass: "form-control form-control-flush search",
                        attrs: {
                          type: "text",
                          placeholder: "Search by user identifier or taskId",
                        },
                        domProps: { value: _vm.query },
                        on: { input: _vm.updateQuery },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [_c("TaskFilterStatus")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [_c("TaskFilterDateRange")],
                      1
                    ),
                    _c("div", { staticClass: "col-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary btn-sm me-2",
                          attrs: { type: "button", disabled: _vm.loadingTasks },
                          on: { click: _vm.resetFilters },
                        },
                        [_vm._v(" Reset ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-primary btn-sm me-2",
                          attrs: { type: "button" },
                          on: { click: _vm.showDownloadConfirmation },
                        },
                        [_vm._v(" Export ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { type: "button", disabled: _vm.loadingTasks },
                          on: { click: _vm.refresh },
                        },
                        [_vm._v(" Refresh ")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card-body p-0" },
            [
              [
                _vm.tasks && _vm.tasks.length > 0
                  ? _c("section", { staticClass: "table-responsive mb-0" }, [
                      _c("table", { staticClass: "table card-table mb-0" }, [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "sortable",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortChange("createdAt")
                                  },
                                },
                              },
                              [
                                _vm._v(" Date "),
                                _vm.sortField === "createdAt"
                                  ? _c("span", [
                                      _c("i", {
                                        class:
                                          _vm.sortOrder === 1
                                            ? "fa fa-arrow-up"
                                            : "fa fa-arrow-down",
                                      }),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "sortable",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortChange("user.identifier")
                                  },
                                },
                              },
                              [
                                _vm._v(" User ID "),
                                _vm.sortField === "user.identifier"
                                  ? _c("span", [
                                      _c("i", {
                                        class:
                                          _vm.sortOrder === 1
                                            ? "fa fa-arrow-up"
                                            : "fa fa-arrow-down",
                                      }),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "sortable",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortChange("externalStatus")
                                  },
                                },
                              },
                              [
                                _vm._v(" Status "),
                                _vm.sortField === "externalStatus"
                                  ? _c("span", [
                                      _c("i", {
                                        class:
                                          _vm.sortOrder === 1
                                            ? "fa fa-arrow-up"
                                            : "fa fa-arrow-down",
                                      }),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "sortable",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortChange("company.name")
                                  },
                                },
                              },
                              [
                                _vm._v(" Employer "),
                                _vm.sortField === "company.name"
                                  ? _c("span", [
                                      _c("i", {
                                        class:
                                          _vm.sortOrder === 1
                                            ? "fa fa-arrow-up"
                                            : "fa fa-arrow-down",
                                      }),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "sortable",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSortChange("connector.name")
                                  },
                                },
                              },
                              [
                                _vm._v(" Payroll "),
                                _vm.sortField === "connector.name"
                                  ? _c("span", [
                                      _c("i", {
                                        class:
                                          _vm.sortOrder === 1
                                            ? "fa fa-arrow-up"
                                            : "fa fa-arrow-down",
                                      }),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm.isDepositProduct
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "sortable",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSortChange(
                                          "settings.deposit.distributionType"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Allocation "),
                                    _vm.sortField ===
                                    "settings.deposit.distributionType"
                                      ? _c("span", [
                                          _c("i", {
                                            class:
                                              _vm.sortOrder === 1
                                                ? "fa fa-arrow-up"
                                                : "fa fa-arrow-down",
                                          }),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isDepositProduct
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "sortable",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSortChange(
                                          "settings.deposit.distributionAmount"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Amount "),
                                    _vm.sortField ===
                                    "settings.deposit.distributionAmount"
                                      ? _c("span", [
                                          _c("i", {
                                            class:
                                              _vm.sortOrder === 1
                                                ? "fa fa-arrow-up"
                                                : "fa fa-arrow-down",
                                          }),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c("th"),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.tasks, function (task) {
                            return _c("TaskRow", {
                              key: task._id,
                              attrs: {
                                task: task,
                                currentProduct: _vm.currentProduct,
                              },
                            })
                          }),
                          1
                        ),
                      ]),
                    ])
                  : !_vm.loadingTasks
                  ? _c("TaskNoTasksFound")
                  : _vm._e(),
                _vm.loadingTasks
                  ? _c("div", { staticClass: "text-center py-4" }, [_vm._m(0)])
                  : _vm._e(),
              ],
            ],
            2
          ),
          _c("TaskPager", {
            attrs: {
              itemsPerPage: _vm.itemsPerPage,
              totalResults: _vm.totalTasks,
              displayedTasks: _vm.tasks.length,
              currentPage: _vm.currentPage,
              loading: _vm.loadingTasks,
            },
            on: {
              changePage: _vm.onPageChange,
              changeItemsPerPage: _vm.onItemsPerPageChange,
            },
          }),
          _c("transition", { attrs: { name: "modal" } }, [
            _vm.showDownloadModal
              ? _c(
                  "div",
                  {
                    staticClass: "modal",
                    staticStyle: { display: "block" },
                    attrs: {
                      tabindex: "-1",
                      role: "dialog",
                      "aria-labelledby": "downloadModalLabel",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-dialog modal-dialog-centered",
                        attrs: { role: "document" },
                      },
                      [
                        _c("div", { staticClass: "modal-content" }, [
                          _c("div", { staticClass: "modal-header" }, [
                            _c(
                              "h5",
                              {
                                staticClass: "modal-title",
                                attrs: { id: "downloadModalLabel" },
                              },
                              [_vm._v("Download Confirmation")]
                            ),
                            _c("button", {
                              staticClass: "btn-close",
                              attrs: { type: "button", "aria-label": "Close" },
                              on: { click: _vm.closeModal },
                            }),
                          ]),
                          _c("div", { staticClass: "modal-body" }, [
                            _c("p", [
                              _vm._v(
                                "Total Records: " + _vm._s(_vm.totalTasks)
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " You are about to export all tasks matching your current filters. This may include tasks not currently loaded in the table. Do you want to proceed? "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-secondary",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.downloadingCSV,
                                },
                                on: { click: _vm.closeModal },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.downloadingCSV,
                                },
                                on: { click: _vm.confirmDownload },
                              },
                              [
                                _vm.downloadingCSV
                                  ? _c("span", {
                                      staticClass:
                                        "spinner-border spinner-border-sm me-2",
                                      attrs: { role: "status" },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.downloadingCSV
                                        ? "Downloading..."
                                        : "Download CSV"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spinner-border text-primary", attrs: { role: "status" } },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }