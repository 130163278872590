var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-3 col-4" },
    [
      _c(
        "affix",
        {
          staticClass: "menu sidebar-menu",
          attrs: {
            "relative-element-selector": "#task-content",
            offset: { top: 40, bottom: 40 },
            "scroll-affix": false,
          },
        },
        [
          _c(
            "nav",
            [
              _c(
                "scrollactive",
                {
                  staticClass: "nav nav-pills flex-column",
                  attrs: {
                    offset: 150,
                    "active-class": "active",
                    highlightFirstItem: true,
                  },
                },
                [
                  _vm.hasEmploymentData || _vm.hasEventsData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#employment" },
                          },
                          [_vm._v("Overview")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasIncomeData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#income" },
                          },
                          [_vm._v("Income")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasTimesheetData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#task-timesheets-chart" },
                          },
                          [_vm._v("Time & Attendance")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasPaystubData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#paystubs" },
                          },
                          [_vm._v("Paystubs")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasDistributionData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#distributions" },
                          },
                          [_vm._v("Pay Distributions")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasTaxesData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#tax-forms" },
                          },
                          [_vm._v("Taxes")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasIdentityData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#identity" },
                          },
                          [_vm._v("Identity")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasEventsData
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link scrollactive-item",
                            attrs: { href: "#webhook-events" },
                          },
                          [_vm._v("Webhook Events")]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }