<script>
import { mixins, Bar } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'BasicBarChart',
  extends: Bar,
  props: ['gridLines', 'chartData', 'yAxisCallback', 'tooltipLabelCallback', 'displayLegend'],
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        legend: { display: this.displayLegend || false },
        layout: {
          padding: {
            top: 1.5, // half border width
          },
        },
        tooltips: {
          position: 'nearest',
          callbacks: {
            label:
              this.tooltipLabelCallback ||
              ((tooltipItems) => {
                return this.$options.filters.currency(tooltipItems.yLabel)
              }),
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              ...(this.gridLines && { gridLines: this.gridLines }),
              ticks: {
                callback:
                  this.yAxisCallback ||
                  function (value) {
                    return '$' + value / 1000 + 'k'
                  },
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>

<style scoped lang="scss"></style>
