import {
  initialize as initializeLaunchDarkly,
  LDClient,
  LDSingleKindContext,
} from 'launchdarkly-js-client-sdk'
import { VueConstructor } from 'vue/types/vue'

let launchDarklyClient: LDClient

const LaunchDarklyPlugin = {
  install(Vue: VueConstructor, { kind, key, ...customAttributes }: LDSingleKindContext) {
    const launchDarklyContext = { kind, key, ...customAttributes }

    launchDarklyClient = initializeLaunchDarkly(
      process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
      launchDarklyContext
    )

    Vue.prototype.$launchDarkly = launchDarklyClient
  },
}

export const getClient = () => launchDarklyClient

export default LaunchDarklyPlugin
