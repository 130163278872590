var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card flex-fill" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row align-items-center prevent-wrap" }, [
        _c("div", { staticClass: "col-auto" }, [
          _c("div", { staticClass: "avatar avatar-sm icon-product" }, [
            _c("img", {
              staticClass: "w-100",
              attrs: {
                src: "/images/icon-" + _vm.task.product + ".svg",
                alt: "",
                "aria-hidden": "true",
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "col ms-n2 task-title" }, [
          _c("h4", { staticClass: "card-title mb-1" }, [
            _c("span", { staticClass: "capitalize" }, [
              _vm._v(_vm._s(_vm.productTitle)),
            ]),
          ]),
          _c(
            "p",
            { staticClass: "card-text small text-muted mb-1 has-ellipsis" },
            [_vm._v(" " + _vm._s(_vm.task.user.identifier) + " ")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-auto d-flex flex-column align-items-end" },
          [
            _vm.isTestTask
              ? _c("BoxIcon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Test task",
                      expression: "`Test task`",
                    },
                  ],
                  staticClass: "d-block text-muted mb-2",
                  attrs: { size: "18" },
                })
              : _vm._e(),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                _vm._s(_vm._f("moment")(_vm.task.createdAt, "from", "now"))
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "card-footer" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-sm btn-outline-secondary",
                attrs: {
                  to: { name: "tasks-detail", params: { id: _vm.task._id } },
                  "aria-label":
                    "View " +
                    _vm.productTitle +
                    " task for user " +
                    _vm.task.user.identifier,
                },
              },
              [_vm._v(" View Task ")]
            ),
            _vm.showBannoPeopleLink
              ? _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "View on Banno People",
                        expression: "'View on Banno People'",
                      },
                    ],
                    staticClass: "btn btn-sm btn-outline-secondary ms-4",
                    attrs: {
                      href:
                        "https://banno.com/a/people/" +
                        _vm.bannoShortId +
                        "/users/" +
                        _vm.task.user.identifier,
                      target: "_blank",
                      "aria-label":
                        "View User " +
                        _vm.task.user.identifier +
                        " on Banno People",
                    },
                  },
                  [
                    _vm._v(" View User "),
                    _c("ExternalLinkIcon", {
                      staticClass: "ms-2 align-middle",
                      attrs: { size: "14" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("TaskStatus", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.taskFailReasonDescription,
                  expression: "taskFailReasonDescription",
                },
              ],
              attrs: { task: _vm.task },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }