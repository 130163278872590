var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ConversionOptimizationManager", {
    attrs: {
      conversionOptimizationType: _vm.CONVERSION_OPTIMIZATION_TYPES.EXPERIMENT,
    },
    scopedSlots: _vm._u([
      {
        key: "introduction",
        fn: function () {
          return [
            _c(
              "p",
              [
                _vm._v(
                  ' Experiments that occur after the initial screen and before the final screen are considered "middle-of-funnel." Middle-of-funnel experiments cannot be disabled as they are necessary for Atomic to fix and improve integrations with individual payroll providers. They are marked with this icon: '
                ),
                _c("GitCommitIcon", { attrs: { size: "1x" } }),
                _vm._v(". "),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }