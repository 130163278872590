var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasEventRequestsData
      ? _c(
          "div",
          { attrs: { "data-test-id": "event-requests" } },
          [
            _vm._m(0),
            !_vm.hasWebhooks && !_vm.hasUnsentEventsData
              ? _c("Alert", { staticClass: "mb-3", attrs: { type: "info" } }, [
                  _c("span", [
                    _vm._v(" Webhook endpoints are not currently configured. "),
                    _vm.canManageWebhooks
                      ? _c(
                          "span",
                          [
                            _vm._v(
                              " To change your webhooks settings, go to the "
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "alert-link",
                                attrs: { to: { name: "settings-webhooks" } },
                              },
                              [_vm._v(" Webhooks page")]
                            ),
                            _vm._v("."),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm._l(_vm.eventRequests, function (event) {
              return _c("EventRequest", {
                key: event._id,
                attrs: { event: event, displayEventType: true },
              })
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.hasUnsentEventsData
      ? _c(
          "div",
          { attrs: { "data-test-id": "unsent-events" } },
          [
            _vm._m(1),
            _c("Alert", { staticClass: "mb-3", attrs: { type: "info" } }, [
              _c("div", [
                _c("p", [
                  !_vm.hasWebhooks
                    ? _c("span", [
                        _vm._v(
                          "Webhook endpoints are not currently configured. "
                        ),
                      ])
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      " When the task was run, you were not subscribed to all task events. Below are the events you would have received if you were subscribed to all events. "
                    ),
                  ]),
                ]),
                _vm.canManageWebhooks
                  ? _c(
                      "span",
                      [
                        _vm._v(" To change your webhooks settings, go to the "),
                        _c(
                          "router-link",
                          {
                            staticClass: "alert-link",
                            attrs: { to: { name: "settings-webhooks" } },
                          },
                          [_vm._v("Webhooks page")]
                        ),
                        _vm._v(". "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._l(_vm.unsentEvents, function (event) {
              return _c("EventRequest", {
                key: event._id,
                attrs: { event: event, displayEventType: true },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-body" }, [
      _c("h4", { staticClass: "header-title" }, [_vm._v("Sent Events")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-body" }, [
      _c("h4", { staticClass: "header-title" }, [_vm._v("Unsent Events")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }