var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EmulatorMutationPreview", {
    attrs: { mutation: _vm.mutation },
    scopedSlots: _vm._u([
      {
        key: "before",
        fn: function () {
          return [
            _c("EmulatorImagePreview", {
              attrs: {
                value: _vm.mutation.valueBeforeChanges,
                label: _vm.mutation.label + " icon before",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "after",
        fn: function () {
          return [
            _c("EmulatorImagePreview", {
              attrs: {
                value: _vm.mutation.value,
                label: _vm.mutation.label + " icon after",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }