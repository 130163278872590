<template>
  <div class="mt-3 p-4">
    <h2 class="">Transact SDK Emulator</h2>
    <p class="text-secondary mb-5">Choose a solution to kick this off 🚀</p>
    <ScopeOption
      @selected="selectScope"
      :hideIcon="true"
      v-for="scopeOption in filteredScopeOptions"
      :key="scopeOption.name"
      :scopeOption="scopeOption"
    >
    </ScopeOption>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { SCOPES } from '@atomicfi/constants-shared'
import ScopeOption from '@/components/MainContent/ScopeOption'
import { hasScope } from '@/lib/authorization'
import { SCOPE_OPTIONS } from '@/utils/scope-options'

export default {
  name: 'EmulatorScopeSelector',
  components: {
    ScopeOption,
  },
  emits: ['selectedScope'],
  data: () => ({
    selectedScope: undefined,
    scopeOptions: SCOPE_OPTIONS,
    SCOPES,
  }),
  computed: {
    ...mapState('emulator', ['scope']),
    filteredScopeOptions() {
      return this.scopeOptions.filter((scopeOption) => hasScope(scopeOption.name))
    },
  },
  methods: {
    ...mapMutations('emulator', ['setScope', 'setProducts']),
    selectScope(scopeOption) {
      this.selectedScope = scopeOption
      this.setScope(scopeOption)
      this.setProducts([scopeOption.products[0].name])
      this.$emit('selectedScope')
    },
  },
  mounted() {
    if (!this.scope && this.filteredScopeOptions?.length === 1) {
      this.selectScope(this.filteredScopeOptions[0])
    }
  },
}
</script>

<style lang="scss" scoped></style>
