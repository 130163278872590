<template>
  <label
    class="btn w-100 btn-white"
    :class="{ active: productIsIncluded }"
    @click="$emit('toggle', product)"
  >
    <span v-if="showProductOrderBadge" class="badge bg-secondary-soft rounded-circle me-1">
      {{ products.indexOf(product.name) + 1 }}
    </span>
    {{ product.title }}
  </label>
</template>

<script>
export default {
  name: 'EmulatorProductSelector',
  props: ['product', 'products'],
  computed: {
    productIsIncluded() {
      return this.products.includes(this.product.name)
    },
    showProductOrderBadge() {
      return this.products.includes(this.product.name) && this.products.length > 1
    },
  },
}
</script>
